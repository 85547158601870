import moment from "moment";
import styles from "./NurseNotes.module.css";
import { NurseNotesType } from "./NurseNoteThumbnail";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { useState } from "react";
import nurseNotesAPI from "./nurseNotesAPI";
import { useSelector } from "react-redux";

type PropsType = {
  nurse_note: NurseNotesType;
  index: number;
  on_escalation_ack: (index: number, ack_time: Date) => void;
};

export const NurseNotesDetails = (props: PropsType) => {
  const nurseNote = props.nurse_note;
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);

  const [isLoading, setLoading] = useState(false);
  const [ackError, setAckError] = useState(false);

  const ackPriorityNote = async (nurse_note_id: number) => {
    setLoading(true);
    setAckError(false);
    nurseNotesAPI
      .ackPriorityNote(site_id, token, nurse_note_id)
      .then((resp) => {
        if (resp.status === 200) {
          props.on_escalation_ack(props.index, resp.data["ack_time"]);
        } else {
          setAckError(true);
        }
      })
      .catch(() => setAckError(true));
    setLoading(false);
  };

  return (
    <div className={styles["details-container"]}>
      <div className={styles["details-content"]}>
        <div>
          <b>{nurseNote.nurse_email}</b> from <b>Athelas</b>
        </div>

        <div className={styles["detail-label"]}>DATE RECORDED</div>
        <div className={styles["details-date"]}>
          {moment(nurseNote.created_at).format("MMM D, YYYY h:mm A")}
        </div>

        {nurseNote.escalation_note ? (
          nurseNote.escalation_note.trim() !== "" ? (
            <>
              <div
                className={styles["detail-label"]}
                style={{ color: "rgb(207, 133, 22)", fontWeight: "bold" }}
              >
                PRIORITY NOTES
                {nurseNote.escalation_level
                  ? " (LEVEL " + nurseNote.escalation_level + ")"
                  : ""}
              </div>
              <div className={styles["details-nurse-note"]}>
                {nurseNote.escalation_note}
              </div>
              <div
                className={styles["detail-label"]}
                style={{ color: "rgb(207, 133, 22)", fontWeight: "bold" }}
              >
                ACKNOWLEDGED BY PROVIDER
              </div>
              <div className={styles["details-date"]}>
                {nurseNote.escalation_ack_date === null ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    Not acknowledged yet
                    <div
                      style={{
                        padding: "16px",
                        marginLeft: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <AthelasButton
                        variant="info"
                        size="small"
                        text={"Acknowledge Now"}
                        disabled={isLoading}
                        onClick={() => ackPriorityNote(nurseNote.nurse_note_id)}
                      />
                      {ackError ? (
                        <p style={{ fontSize: 14, color: "red" }}>
                          Sorry, an error occurred.
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  moment(nurseNote.escalation_ack_date).format(
                    "MMM D, YYYY h:mm A"
                  )
                )}
              </div>
            </>
          ) : null
        ) : null}

        <div className={styles["detail-label"]}>NURSE NOTE</div>
        <div className={styles["details-nurse-note"]}>{nurseNote.notes}</div>

        <div className={styles["detail-label"]}>REVIEW TIME</div>
        <div className={styles["details-nurse-note"]}>
          {nurseNote.review_time} minutes
        </div>
      </div>
    </div>
  );
};
