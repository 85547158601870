import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useWindowSize } from "react-use";
import axios from "axios";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-virtualized/styles.css"; // only needs to be imported once

import MobileNavbar from "./app/MobileNavbar";
import DesktopNavbar from "./app/DesktopNavbar";
import { HomePage } from "./features/home/HomePage";
import { AuthPage } from "./features/user/AuthPage";
import { PatientsList } from "./features/patients/PatientsList";
import { SinglePatientPage } from "./features/patients/SinglePatientPage";
import { ReportsList } from "./features/reports/ReportsList";
import { SingleReportPage } from "./features/reports/SingleReportPage";
import { TestResultsList } from "./features/testResults/TestResultsList";
import { ClaimsList } from "./features/claims/ClaimsList";
import { SingleClaimPage } from "./features/claims/SingleClaimPage";
import { CardiacCareList } from "./features/cardiacCare/cardiacCare";
import { DocumentsPage } from "./features/documents/DocumentsPage";
import { EnrollmentList } from "./features/enrollment/EnrollmentList";
import { Scribble } from "./features/scribble/Scribble";
import { DrChrono } from "./features/drchrono/DrChrono";
import { NurseNotesList } from "./features/nurseNotes/NurseNotesList";
import { ClaimsOverview } from "./features/claimsOverview/ClaimsOverview";
import { SingleClaim } from "./features/claimsOverview/SingleClaim";
import { MedicalScribe } from "./features/scribe/MedicalScribe";
import { NursePortal } from "./features/nurse_portal/NursePortal";
import { logout } from "./features/user/userSlice";
import { OnboardPage } from "./features/onboard/OnboardPage";
import { UserOnboardPage } from "./features/userOnboard/UserOnboardPage";
import { ResetPasswordPage } from "./features/user/ResetPasswordPage";
import { PatientConnect } from "./features/patientConnect/patientConnect";
import { PatientConnectComposer } from "./features/patientConnect/patientConnectComposer";
import { PatientConnectConfirm } from "./features/patientConnect/PatientConnectConfirm";
import { ChronicCareList } from "./features/chronicCare/ChronicCareList";
import { SingleIntakeFormPage } from "./features/chronicCare/SingleIntakeFormPage";
import { SingleUnapprovedCarePlanPage } from "./features/chronicCare/SingleUnapprovedCarePlanPage";
import { SingleApprovedCarePlanPage } from "./features/chronicCare/SingleApprovedCarePlanPage";
import { Settings } from "./features/settings/Settings";
import { isInIframe, makeUUID } from "./utils/utils";
import { trackEvent } from "./utils/tracking";
import ComingSoon from "./features/medications/comingSoon";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const authToken = useSelector((state) => state.user.token);

  return (
    <Route {...rest}>
      {authToken !== "" ? children : <Redirect to="/login" />}
    </Route>
  );
}

function Main() {
  const { width } = useWindowSize();

  const userEmail = useSelector((state) => state.user.email);

  const [tracked, setTracked] = useState(false);
  if (!tracked) {
    const persistent_cookie = localStorage.getItem("persistent_cookie");
    if (!persistent_cookie) {
      localStorage.setItem("persistent_cookie", makeUUID(100));
    }
    localStorage.setItem("user_email", userEmail);

    trackEvent("view", "opened_doctor_portal");
    setTracked(true);
  }

  let navbar;
  if (isInIframe()) {
    navbar = <></>;
  } else if (width >= 1024) {
    navbar = <DesktopNavbar />;
  } else {
    navbar = <MobileNavbar />;
  }

  return (
    <>
      {navbar}

      <div className="mainContent">
        <PrivateRoute exact path="/home">
          <HomePage />
        </PrivateRoute>
        <PrivateRoute exact path="/patients/:patientId">
          <SinglePatientPage />
        </PrivateRoute>
        <PrivateRoute exact path="/patients">
          <PatientsList />
        </PrivateRoute>
        <PrivateRoute exact path="/reports/:reportId">
          <SingleReportPage />
        </PrivateRoute>
        <PrivateRoute exact path="/reports">
          <ReportsList />
        </PrivateRoute>
        <PrivateRoute exact path="/test_results">
          <TestResultsList />
        </PrivateRoute>
        <PrivateRoute exact path="/cardiac_care">
          <CardiacCareList />
        </PrivateRoute>
        <PrivateRoute exact path="/chronic_care">
          <ChronicCareList />
        </PrivateRoute>
        <PrivateRoute exact path="/chronic_care/patient_intake_form/:id">
          <SingleIntakeFormPage />
        </PrivateRoute>
        <PrivateRoute exact path="/chronic_care/unapproved_care_plan/:id">
          <SingleUnapprovedCarePlanPage />
        </PrivateRoute>
        <PrivateRoute exact path="/chronic_care/approved_care_plan/:id">
          <SingleApprovedCarePlanPage />
        </PrivateRoute>
        <PrivateRoute exact path="/documents">
          <DocumentsPage />
        </PrivateRoute>
        <PrivateRoute exact path="/patient_medications">
          <ComingSoon />
        </PrivateRoute>
        <PrivateRoute exact path="/enrollment">
          <EnrollmentList />
        </PrivateRoute>
        <PrivateRoute exact path="/claims">
          <ClaimsList />
        </PrivateRoute>
        <PrivateRoute exact path="/claims/:claimID">
          <SingleClaimPage />
        </PrivateRoute>
        <PrivateRoute exact path="/connect">
          <PatientConnect />
        </PrivateRoute>
        <PrivateRoute exact path="/connectComposer">
          <PatientConnectComposer />
        </PrivateRoute>
        <PrivateRoute exact path="/connectConfirm">
          <PatientConnectConfirm />
        </PrivateRoute>
        <PrivateRoute exact path="/nurseNotes">
          <NurseNotesList />
        </PrivateRoute>
        <PrivateRoute exact path="/claimsOverview">
          <ClaimsOverview />
        </PrivateRoute>
        <PrivateRoute exact path="/medical_scribe">
          <MedicalScribe />
        </PrivateRoute>
        <PrivateRoute exact path="/nurse_portal">
          <NursePortal />
        </PrivateRoute>
        <PrivateRoute exact path="/claimsOverview/:claim_id">
          <SingleClaim />
        </PrivateRoute>
        <PrivateRoute exact path="/settings">
          <Settings />
        </PrivateRoute>
        {/* go to patients list by default */}
        <PrivateRoute exact path="/">
          <Redirect to="/home" />
        </PrivateRoute>
      </div>
    </>
  );
}

function App() {
  const authToken = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  // intercept all axios responses and
  // logout when seeing 401 and 409 (token expired)
  axios.interceptors.response.use(null, function (error) {
    if (window.location.href.indexOf("/login") === -1) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 409)
      ) {
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  });

  // use mobile view when in an iframe
  if (isInIframe()) {
    document
      .getElementById("root")
      .style.setProperty("grid-template-columns", "1fr");
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          {authToken === "" ? <AuthPage /> : <Redirect to="/" />}
        </Route>
        <Route path="/onboard/:token">
          {authToken === "" ? <OnboardPage /> : <Redirect to="/" />}
        </Route>
        <Route path="/users/onboard/:token">
          {authToken === "" ? <UserOnboardPage /> : <Redirect to="/" />}
        </Route>
        <Route path="/password_reset">
          {authToken === "" ? <ResetPasswordPage /> : <Redirect to="/" />}
        </Route>
        <Route path="/scribble">
          {authToken === "" ? <AuthPage forScribble={true} /> : <Scribble />}
        </Route>
        <Route path="/drchrono">
          {authToken === "" ? <AuthPage forChrono={true} /> : <DrChrono />}
        </Route>

        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
