import styles from "../../../features/patients/PatientsList.module.css";
import Form from "react-bootstrap/Form";
import { ReactComponent as CrossSvg } from "../../../app/assets/cross.svg";

export type PropsType = {
  text: String;
  enabled: Boolean;
  onClick: any;
};

export const ToggleSelect = (props: PropsType) => {
  return (
    <Form.Group>
      <div className={styles.TableFilters}>
        <div
          className={props.enabled ? "FilterSelected" : "FilterUnselected"}
          onClick={props.onClick}
        >
          <CrossSvg />
          <span>{props.text}</span>
        </div>
      </div>
    </Form.Group>
  );
};
