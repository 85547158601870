import styles from "../../../features/patients/PatientsList.module.css";

type PropsType = {
  title: String;
  subtitle: String;
  children: any;
};

export const SummaryCard = (props: PropsType) => {
  return (
    <div className={styles.SimpleSummaryCard} style={{ marginBottom: "48px" }}>
      <div className={styles.CardTestTitle}>
        {props.title && <span className={styles.TestType}>{props.title}</span>}
        {props.subtitle && (
          <span className={styles.TestTypeSmall}>{props.subtitle}</span>
        )}
      </div>
      {props.children}
    </div>
  );
};
