import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import userReducer from "../features/user/userSlice";
import patientsReducer from "../features/patients/patientsSlice";
import medicationPatientsReducer from "../features/medications/medicationSlice";
import reportsReducer from "../features/reports/reportsSlice";
import homeReducer from "../features/home/homeSlice";
import headerReducer from "../features/header/headerSlice";
import testResultsReducer from "../features/testResults/testResultsSlice";
import claimsReducer from "../features/claims/claimsSlice";
import onboardReducer from "../features/onboard/onboardSlice";
import userOnboardReducer from "../features/userOnboard/userOnboardSlice";
import documentsReducer from "../features/documents/documentsSlice";
import notesReducer from "../features/scribble/ScribbleSlice";
import nurseNotesReducer from "../features/nurseNotes/nurseNotesSlice";
import enrollmentReducer from "../features/enrollment/enrollmentSlice";
import claimsOverviewReducer from "../features/claimsOverview/claimsOverviewSlice";
import chronicCareReducer from "../features/chronicCare/chronicCareSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const reducers = combineReducers({
  user: userReducer,
  patients: patientsReducer,
  medicationPatients: medicationPatientsReducer,
  reports: reportsReducer,
  home: homeReducer,
  header: headerReducer,
  testResults: testResultsReducer,
  onboard: onboardReducer,
  claims: claimsReducer,
  userOnboard: userOnboardReducer,
  documents: documentsReducer,
  notes: notesReducer,
  nurseNotes: nurseNotesReducer,
  enrollment: enrollmentReducer,
  claimsOverview: claimsOverviewReducer,
  chronicCare: chronicCareReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };
