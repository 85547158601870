import { Column, Table, AutoSizer } from "react-virtualized";

import { PythonDatetimeStrToDDMMMYYYY } from "../../../utils/date";

import styles from "../PatientsList.module.css";

const BlookdPressureHistoryTable = ({ testResults }) => {
  function ResultDate({ cellData }) {
    return PythonDatetimeStrToDDMMMYYYY(cellData);
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          className={styles.HistoryTable}
          width={width}
          height={height}
          headerHeight={46}
          rowHeight={46}
          rowCount={testResults.length}
          rowGetter={({ index }) => testResults[testResults.length - 1 - index]}
          headerClassName={styles.TableHeader}
          rowClassName={({ index }) =>
            index !== -1 ? styles.ResultHistoryTableRow : styles.HeaderRow
          }
        >
          <Column
            label="Date"
            dataKey="test_time"
            width={width / 3}
            cellRenderer={ResultDate}
          />
          <Column label="Systolic" dataKey="systolic" width={width / 3} />
          <Column label="Diastolic" dataKey="diastolic" width={width / 3} />
        </Table>
      )}
    </AutoSizer>
  );
};

export default BlookdPressureHistoryTable;
