import { useHistory } from "react-router-dom";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { Header } from "../header/Header";
import styles from "./PatientConnect.module.css";
import { Column, Table, AutoSizer } from "react-virtualized";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";
import { useSelector, useDispatch } from "react-redux";
import { fetchPatients, selectAllPatients } from "../patients/patientsSlice";
import { useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import React from "react";
import { ExportPatientConnectListAsCSVFile } from "../../utils/csv";

type PropsType = {};

export function PatientConnect(props: PropsType) {
  const history = useHistory();

  const [fetchedPatients, setFetchedPatients] = useState(false);
  const site_id = useSelector((state: any) => state.user.currSiteId);
  const token = useSelector((state: any) => state.user.token);
  const raw_patients = useSelector(selectAllPatients);
  const patientsStatus = useSelector((state: any) => state.patients.status);
  const dispatch = useDispatch();

  if (!fetchedPatients) {
    dispatch(fetchPatients({ site_id, token }));

    setFetchedPatients(true);
  }

  let counts = { total: 0, true: 0, false: 0 };
  for (const status of raw_patients.map((data) => data.onboarding_status)) {
    const enrolled = status === "Enrolled";
    counts[enrolled] = counts[enrolled] ? counts[enrolled] + 1 : 1;
    counts["total"] = counts["total"] ? counts["total"] + 1 : 1;
  }

  var numeral = require("numeral");
  const enrollmentPercentage = Math.round(
    (counts[true] / counts["total"]) * 100
  );
  const potentialEnrollmentPercentage = Math.round(
    (((counts["total"] - counts[true]) * 0.4 + counts[true]) /
      counts["total"]) *
      100
  );
  const revenuePerPatient = 100 * 12;
  const currentRevenue = counts[true] * revenuePerPatient;
  const potentialRevenue =
    ((counts["total"] * potentialEnrollmentPercentage) / 100) *
    revenuePerPatient;
  const revenuePercentIncrease =
    "+" + Math.round((potentialRevenue / currentRevenue) * 100) + "%";

  const patients = raw_patients.filter((data) => {
    const filter =
      data.onboarding_status === "Pending" &&
      data.referral_authorization_id &&
      data.rpm_onboard_status === "PENDING";
    return filter;
  });

  const [excludePatients, setExcludePatients] = useState(new Set());
  const [sendRemindersToDelta, setSendRemindersToDelta] = useState(0);

  const downLoadPatientsConnectCSV = async () => {
    ExportPatientConnectListAsCSVFile(patients, "patient_connect_data.csv");
  };

  if (patients.length === 0) {
    return (
      <>
        {" "}
        <Header title="Patient Connect" showBackButton={true}></Header>
        <div className={styles.SuperContainer}>
          <div className={styles.explanationDiv}>
            <h3>
              <b>Let's get started</b>
            </h3>
            <p>
              Use Patient Connect to boost your practice's enrollment rates. You
              have not prescribed Athelas RPM to any patients yet. To get
              started, first visit the 'Enrollment' tab and enroll the patients
              you would like Athelas RPM deployed to.
            </p>
            <p>
              After enrolling patients, come back here to use Patient Connect
            </p>
            <div style={{ width: "50%" }}>
              <AthelasButton
                text="Enroll your Patients"
                onClick={() => history.push("enrollment")}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  if (patientsStatus !== "succeeded") {
    return (
      <>
        <div className={styles.SuperContainer}>
          <div className={styles.explanationDiv}>
            <h3>
              <b>Why Patient Connect?</b>
            </h3>
            <p>
              Boost enrollment (and reimbursement rates) by messaging patients
              directly with easy-to-use templates. A message sent directly from
              you will improve opt-in by up to 35%.
            </p>

            <div style={{ height: 70, width: 70, margin: "48px auto" }}>
              <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header title="Patient Connect" showBackButton={true}></Header>

      <div className={styles.SuperContainer}>
        <div className={styles.Container}>
          <div className={styles.explanationDiv}>
            <h3>
              <b>Why Patient Connect?</b>
            </h3>
            <p>
              Boost enrollment (and reimbursement rates) by messaging patients
              directly with easy-to-use templates. A message sent directly from
              you will improve opt-in by up to 35%.
            </p>
            <p>
              <b className={styles.blueBold}>
                NOTE: All responses will be handled by Athelas and not shown to
                you.
              </b>
            </p>
            <div style={{ width: "75%", maxWidth: 300 }}>
              <AthelasButton
                text={"Send Enrollment Messages"}
                onClick={() => {
                  const filtered_patients = patients
                    .filter((p) => !excludePatients.has(p.id))
                    .map((p) => p.id);
                  history.push({
                    pathname: "/connectComposer",
                    state: {
                      patients: filtered_patients,
                      site_id: site_id,
                      token: token,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.currentDiv}>
            <p className={styles.currentDivTitle}>CURRENT</p>
            <p className={styles.currentStatistic}>
              <span style={{ fontSize: "200%" }}>{enrollmentPercentage}</span>%
            </p>
            <p className={styles.enrollmentText}>enrollment</p>
            <p className={styles.currentExplanation}>
              This represents roughly ${numeral(currentRevenue).format("0,0")}{" "}
              in annual revenue for your practice.
            </p>
          </div>
          <div className={styles.potentialDiv}>
            <p className={styles.potentialDivTitle}>AFTER BOOST</p>
            <p className={styles.potentialStatistic}>
              <span style={{ fontSize: "200%" }}>
                {potentialEnrollmentPercentage}
              </span>
              %
            </p>
            <p className={styles.enrollmentText}>enrollment</p>
            <p className={styles.potentialExplanation}>
              This represents roughly ${numeral(potentialRevenue).format("0,0")}{" "}
              in annual revenue for your practice. ({revenuePercentIncrease})
            </p>
          </div>
        </div>
        <div className={styles.patientSelectDiv}>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ width: "28%" }}>
              <ToggleSelect
                text={`${
                  patients.length + sendRemindersToDelta
                } patients selected`}
                enabled={false}
              />
            </div>
            <div style={{ paddingLeft: "570px" }}>
              <AthelasButton
                text={"Download as CSV"}
                onClick={downLoadPatientsConnectCSV}
              />
            </div>
          </div>
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height * 0.9}
                headerHeight={60}
                rowHeight={68}
                rowCount={patients.length}
                onRowClick={({ rowData }) => {}}
                rowGetter={({ index }) => patients[index]}
                rowClassName={({ index }) =>
                  index !== -1 ? styles.TableRow : styles.HeaderRow
                }
              >
                <Column
                  label="Select"
                  dataKey="submitted_at"
                  width={width / 8}
                  cellRenderer={(props) => {
                    const patient_id = props?.rowData?.id;

                    return (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            const s = excludePatients;
                            s.delete(patient_id);
                            setExcludePatients(s);
                            setSendRemindersToDelta(sendRemindersToDelta + 1);
                          } else {
                            const s = excludePatients;
                            s.add(patient_id);
                            setExcludePatients(s);
                            setSendRemindersToDelta(sendRemindersToDelta - 1);
                          }
                        }}
                        defaultChecked={!excludePatients.has(patient_id)}
                      />
                    );
                  }}
                />
                <Column
                  label="Patient Name"
                  dataKey="patient_name"
                  width={(width / 8) * 2}
                />
                <Column
                  label="Prescriber Name"
                  dataKey="prescriber_name"
                  width={width / 8}
                />
                <Column
                  label="Date of Birth"
                  dataKey="date_of_birth"
                  width={width / 8}
                />
                <Column
                  label="Devices"
                  dataKey="test_types"
                  width={(width / 8) * 2}
                />
                <Column
                  label="Prescription Status"
                  dataKey="onboarding_status"
                  width={width / 8}
                  cellRenderer={(props) => "Approved"}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </>
  );
}
