// react
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// my content
import { Header } from "../header/Header";
import styles from "./Claims.module.css";

import { fetchAllClaims, selectAllClaims } from "./claimsSlice";
import { statusThunk } from "../user/userSlice";
import { ReactComponent as SearchSvg } from "../patients/SearchIcon.svg";

// third party
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Column, Table, AutoSizer } from "react-virtualized";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-dates/initialize";
import fileDownload from "js-file-download";

import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import { IsoDateToDDMMMYYYY } from "../../utils/date";
import claimsAPI from "./claimsAPI";

const DownloadCell = ({ patient_id, test_date, patient_name }) => {
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);

  const [isDownloading, setIsDownloading] = useState(false);
  const OnDownloadClick = async (e) => {
    // stop for row clicking
    e.stopPropagation();
    setIsDownloading(true);
    const res = await claimsAPI.downloadSingleClaimPDF(
      site_id,
      token,
      patient_id,
      test_date
    );
    fileDownload(res.data, `${patient_name}.pdf`);
    setIsDownloading(false);
  };

  return (
    <div>
      <Button
        variant="primary"
        disabled={isDownloading}
        onClick={OnDownloadClick}
      >
        {isDownloading ? "Downloading..." : "Download"}
      </Button>
    </div>
  );
};

export const ClaimsList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.claims.status);
  const error = useSelector((state) => state.claims.error);
  const claims = useSelector(selectAllClaims);

  // patient name search term
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchTermChanged = (e) => {
    setSearchTerm(e.target.value);
  };

  // reduce CPT filter values
  const cptCodes = [...new Set(claims.map((c) => c.cpt_code))];
  const CPTOptions = cptCodes.map((cpt_code) => {
    return (
      <option value={cpt_code} key={cpt_code}>
        {cpt_code}
      </option>
    );
  });

  const [CPTSelected, setCPTSelected] = useState("");
  const onCPTSelectedChanged = (e) => {
    setCPTSelected(e.target.value);
  };

  // reduce status values
  const claimstatuses = [...new Set(claims.map((c) => c.status))];
  const statusOptions = claimstatuses.map((status) => {
    return (
      <option value={status} key={status}>
        {status}
      </option>
    );
  });
  const [statusSelected, setStatusSelected] = useState("");
  const onStatusSelectedChanged = (e) => {
    setStatusSelected(e.target.value);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchAllClaims({ site_id, token }));
    }
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const claimsFilterFunc = (claim) => {
    // filter by submitted dates
    if (startDate !== null) {
      if (claim.submitDateTimeMil < startDate.valueOf()) {
        return false;
      }
    }

    if (endDate !== null) {
      if (claim.submitDateTimeMil > endDate.valueOf()) {
        return false;
      }
    }

    if (searchTerm !== "") {
      const patientName = claim.patient_name.toLowerCase();
      const term = searchTerm.toLowerCase();

      if (!patientName.includes(term)) {
        return false;
      }
    }

    // filter on CPT code
    if (CPTSelected !== "") {
      if (claim.cpt_code !== CPTSelected) {
        return false;
      }
    }

    // filter on Status
    if (statusSelected !== "") {
      if (claim.status !== statusSelected) {
        return false;
      }
    }

    return true;
  };
  const filteredClaims = claims.filter(claimsFilterFunc);

  function LastUpdateDate({ cellData }) {
    return IsoDateToDDMMMYYYY(cellData);
  }

  function AmountRender({ cellData, rowData }) {
    if (cellData) {
      return `$ ${cellData / 100}`;
    } else {
      return `$ ${rowData.estimated_value}*`;
    }
  }

  function StatusRender({ cellData }) {
    return (
      <>
        <div className={styles[cellData]}>{cellData}</div>
      </>
    );
  }

  const DownloadCellRender = ({ rowData }) => {
    return (
      <DownloadCell
        patient_id={rowData.patient_id}
        test_date={rowData.test_date}
        patient_name={rowData.patient_name}
      />
    );
  };

  const [dateRangeFocus, setDateRangeFocus] = useState(null);
  const onFocusChange = (focusedInput) => {
    setDateRangeFocus(focusedInput);
  };

  let content;
  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <>
        <div>
          <div className={styles.SearchSection}>
            <SearchSvg className={styles.SearchIcon} />
            <Form.Control
              placeholder="Search patient by name"
              bsPrefix={styles.SearchBox}
              value={searchTerm}
              onChange={onSearchTermChanged}
            />
          </div>
        </div>
        <div className={styles.TableCardContainer}>
          <div className={styles.TableHeader}>
            <Form inline>
              <Form.Group className="select-form-group">
                <Form.Control
                  as="select"
                  bsPrefix={
                    CPTSelected === "" ? "SelectPlaceHolder" : "SelectInput"
                  }
                  id="CPTSelect"
                  value={CPTSelected}
                  onChange={onCPTSelectedChanged}
                  style={{ width: "220px" }}
                >
                  <option value="" selected>
                    Filter By CPT Code
                  </option>
                  {CPTOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group className="select-form-group">
                <Form.Control
                  as="select"
                  bsPrefix={
                    statusSelected === "" ? "SelectPlaceHolder" : "SelectInput"
                  }
                  id="statusSelect"
                  value={statusSelected}
                  onChange={onStatusSelectedChanged}
                  style={{ width: "180px" }}
                >
                  <option value="" selected>
                    Filter By Status
                  </option>
                  {statusOptions}
                </Form.Control>
              </Form.Group>
              <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="cliam_start_date" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="cliam_end_date" // PropTypes.string.isRequired,
                onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                focusedInput={dateRangeFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={onFocusChange} // PropTypes.func.isRequired,
                noBorder={true}
                isOutsideRange={() => false}
                showClearDates={true}
              />
            </Form>
          </div>
          <div className={styles.ClaimsTableContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={60}
                  rowHeight={68}
                  rowCount={filteredClaims.length}
                  onRowClick={({ rowData }) =>
                    history.push(
                      `/claims/${rowData.claim_id}-${rowData.cpt_code}`
                    )
                  }
                  rowGetter={({ index }) => filteredClaims[index]}
                  rowClassName={({ index }) =>
                    index !== -1 ? styles.TableRow : styles.HeaderRow
                  }
                >
                  <Column
                    label="Submit Date"
                    dataKey="submitted_at"
                    width={width / 6}
                    cellRenderer={LastUpdateDate}
                  />
                  <Column
                    label="Patient Name"
                    dataKey="patient_name"
                    width={width / 6}
                  />
                  <Column
                    label="CPT Code"
                    dataKey="cpt_code"
                    width={width / 6}
                  />
                  <Column
                    label="Status"
                    dataKey="status"
                    cellRenderer={StatusRender}
                    width={width / 6}
                  />
                  <Column
                    label="Claim Amount"
                    dataKey="amount_in_usd_cents"
                    width={width / 6}
                    cellRenderer={AmountRender}
                  />
                  <Column
                    label="Download"
                    dataKey="patient_id"
                    width={width / 6}
                    cellRenderer={DownloadCellRender}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
        <br></br>
        <div>
          * Amount is the claim amount. Note: payment amount is subject to
          insurance reimbursements and adjustments.
        </div>
      </>
    );
  } else if (status === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Claims" />

      <div className={styles.ContentContainer}>
        <div className={styles.ClaimContainer}>{content}</div>
      </div>
    </>
  );
};
