import styles from "./PillButton.module.css";
export type PropsType = {
  text: string;
  variant: "danger" | "info" | "disabled";
  onClick: () => void;
  disabled?: boolean;
};

export const PillButton = (props: PropsType) => {
  const colorMap = {
    danger: {
      color: "#CF5E5E",
      backgroundColor: "#FDE7E7",
    },
    info: {
      color: "#42A1F8",
      backgroundColor: "#ECF6FE",
    },
    disabled: {
      color: "#969696",
      backgroundColor: "#ebebeb",
    },
  };

  let variant = props.variant;
  if (props.disabled) {
    variant = "disabled";
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: colorMap[variant]["backgroundColor"],
          color: colorMap[variant]["color"],
        }}
        className={styles["pill-button"]}
        onClick={props.disabled ? () => {} : props.onClick}
      >
        {props.text} {">"}
      </div>
    </div>
  );
};
