import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import styles from "./Header.module.css";

import OnePager from "./one_pager.png";

export const RCMReferralModal = ({
  showRCMReferralModal,
  handleCloseRCMReferralModal,
  handleRCMRequestDemo,
}) => {
  return (
    <Modal
      dialogClassName={styles.RCMReferralModal}
      show={showRCMReferralModal}
      onHide={handleCloseRCMReferralModal}
    >
      <Modal.Header>
        <Modal.Title>Get AI-powered medical billing from Athelas</Modal.Title>
        <Button variant="primary" onClick={handleRCMRequestDemo}>
          Contact A Rep
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.RCMModalBody}>
          <div>
            <img
              src={OnePager}
              alt="sense_mockup"
              className={styles.OnePagerImage}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseRCMReferralModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
