import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// react bootstrap
import { Column, Table, AutoSizer } from "react-virtualized";
import Loader from "react-loader-spinner";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";

import { Header } from "../header/Header";
import { fetchIntakeFormsAndCarePlans } from "./chronicCareSlice";
import styles from "../chronicCare/ChronicCareList.module.css";
import { PythonDatetimeStrToDDMMMYYYY } from "../../utils/date";

import { statusThunk } from "../user/userSlice";

const CCMStatuses = {
  PATIENT_INTAKE_FORM: "Intake Form",
  APPROVED_CARE_PLAN: "Approved Care Plan",
  UNAPPROVED_CARE_PLAN: "Unapproved Care Plan",
};

const CCMRecordURLs = {
  PATIENT_INTAKE_FORM: "patient_intake_form/",
  APPROVED_CARE_PLAN: "approved_care_plan/",
  UNAPPROVED_CARE_PLAN: "unapproved_care_plan/",
};

export const ChronicCareList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.chronicCare.status);
  const error = useSelector((state) => state.chronicCare.error);
  const entries = useSelector((state) => state.chronicCare.entries);

  const [entriesToDisplay, setEntriesToDisplay] = useState([]);
  const [displayIntakeForms, setDisplayIntakeForms] = useState(true);
  const [displayUnapprovedCarePlans, setDisplayUnapprovedCarePlans] = useState(
    true
  );
  const [displayApprovedCarePlans, setDisplayApprovedCarePlans] = useState(
    true
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchIntakeFormsAndCarePlans({ site_id, token }));
    }
  });

  useEffect(() => {
    if (status === "succeeded") {
      let newEntriesToDisplay = [];
      if (displayIntakeForms) {
        newEntriesToDisplay = newEntriesToDisplay.concat(
          entries["patient_intake_forms"]
        );
      }
      if (displayUnapprovedCarePlans) {
        newEntriesToDisplay = newEntriesToDisplay.concat(
          entries["unapproved_care_plans"]
        );
      }
      if (displayApprovedCarePlans) {
        newEntriesToDisplay = newEntriesToDisplay.concat(
          entries["approved_care_plans"]
        );
      }
      setEntriesToDisplay(newEntriesToDisplay);
    }
  }, [
    displayIntakeForms,
    displayUnapprovedCarePlans,
    displayApprovedCarePlans,
    entries,
  ]);

  const StatusCell = ({ cellData }) => {
    return (
      <>
        <div className={styles.CCMStatusText + " " + styles[cellData]}>
          {CCMStatuses[cellData]}
        </div>
      </>
    );
  };

  const SubmissionDate = ({ cellData }) => {
    if (cellData && cellData !== "No Result") {
      return PythonDatetimeStrToDDMMMYYYY(cellData).split(" - ")[0];
    } else {
      return cellData;
    }
  };

  let content;
  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={60}
            rowHeight={68.35}
            rowCount={entriesToDisplay.length}
            onRowClick={({ rowData }) => {
              history.push(
                "/chronic_care/" + CCMRecordURLs[rowData.type] + rowData.id
              );
            }}
            rowGetter={({ index }) => entriesToDisplay[index]}
            rowClassName={({ index }) =>
              index !== -1 ? styles.TableRow : styles.HeaderRow
            }
          >
            <Column
              label="Patient ID"
              dataKey="patient_id"
              width={(width - 100) / 6}
            />
            <Column
              label="First Name"
              dataKey="first_name"
              width={(width - 100) / 6}
            />
            <Column
              label="Last Name"
              dataKey="last_name"
              width={(width - 100) / 6}
            />
            <Column
              label="Status"
              dataKey="type"
              width={(width - 100) / 4}
              cellRenderer={StatusCell}
            />
            <Column
              label="Submission Date"
              dataKey="submission_date"
              className={styles.SubmissionDate}
              cellRenderer={SubmissionDate}
              width={(width - 100) / 6}
            />
          </Table>
        )}
      </AutoSizer>
    );
  } else if (status === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Chronic Care Program"></Header>

      <div className={styles.ContentContainer}>
        <div className={styles.CCMContainer}>
          <div className={styles.TableCardContainer}>
            <div className={styles.TableHeader}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ marginTop: 10, marginRight: 10 }}>
                  <ToggleSelect
                    text="Patient Intake Forms"
                    enabled={displayIntakeForms}
                    onClick={() => {
                      setDisplayIntakeForms(!displayIntakeForms);
                    }}
                  />
                </div>
                <div style={{ marginTop: 10, marginRight: 10 }}>
                  <ToggleSelect
                    text="Unapproved Care Plans"
                    enabled={displayUnapprovedCarePlans}
                    onClick={() => {
                      setDisplayUnapprovedCarePlans(
                        !displayUnapprovedCarePlans
                      );
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <ToggleSelect
                    text="Approved Care Plans"
                    enabled={displayApprovedCarePlans}
                    onClick={() => {
                      setDisplayApprovedCarePlans(!displayApprovedCarePlans);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.CCMTable}>{content}</div>
            <div className={styles.CCMFooter}>
              {`Total entries: ${entriesToDisplay.length}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
