import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import userAPI from "../user/userAPI";

// init state
const initialState = {
  status: "idle",
  token: window.location.href.split("/")[5],
  error: "",
  email: "",
  active: false,
  password_resetted: false,
};

// thunks
export const fetchUserOnboardProgress = createAsyncThunk(
  "user/onboard/progress",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await userAPI.onboardProgress(token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const onboardResetPassword = createAsyncThunk(
  "user/onboardResetPassword",
  async ({ token, new_password }) => {
    try {
      const response = await userAPI.onboardResetPassword(token, new_password);
      return {
        status: response.status,
        message: response.data.message,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: err.response.data.message,
      };
    }
  }
);

// slice
const userOnboardSlice = createSlice({
  name: "userOnboard",
  initialState,
  extraReducers: {
    [fetchUserOnboardProgress.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUserOnboardProgress.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        state.active = action.payload.active;
        state.password_resetted = action.payload.password_resetted;
        state.email = action.payload.email;

        state.status = "successed";
      }
    },
    [fetchUserOnboardProgress.rejected]: (state, action) => {
      if (state.status === "loading") {
        console.log(action);
        state.status = "failure";
      }
    },
  },
});

export default userOnboardSlice.reducer;
