import React from "react";
import { Column, Table } from "react-virtualized";
import styles from "./TestResultLimitTable.module.css";
import patientsAPI from "../patients/patientsAPI";
import { useSelector } from "react-redux";

const TestResultTable = ({
  patientsWithCustomLimits,
  setPatientsWithCustomLimits,
}) => {
  const site_id = useSelector((state) => state.user.currSiteId);
  const authToken = useSelector((state) => state.user.token);

  const DefaultRenderer = ({ cellData }) => {
    return cellData;
  };

  const nameRenderer = ({ rowData }) => {
    return rowData.first_name + " " + rowData.last_name;
  };

  const diastolicRenderer = ({ rowData }) => {
    return rowData.diastolic_high + "/" + rowData.diastolic_low;
  };

  const systolicRenderer = ({ rowData }) => {
    return rowData.systolic_high + "/" + rowData.systolic_low;
  };

  const deleteRenderer = ({ cellData }) => {
    return (
      <div>
        <a
          className={styles.Button}
          onClick={() => onDeleteCellClick(cellData)}
        >
          X
        </a>
      </div>
    );
  };

  const onDeleteCellClick = (cellData) => {
    console.log(cellData);
    setPatientsWithCustomLimits((current) =>
      current.filter((patient) => {
        return patient.test_result_limit_id !== cellData;
      })
    );
    patientsAPI
      .deletePatientTestResultLimit(site_id, authToken, cellData)
      .then((res) => {
        console.log("Deleted from db");
      })
      .catch((e) => {
        console.log("Failiure to delete from db.");
      });
  };

  const height = 200;
  const width = 650;
  return (
    <div style={{ marginTop: 30 }}>
      <p>The patients below have custom bounds set.</p>
      <div className={styles.TableCardContainer}>
        <Table
          width={width}
          height={height}
          headerHeight={20}
          rowHeight={30}
          rowCount={patientsWithCustomLimits.length || 0}
          rowGetter={({ index }) => patientsWithCustomLimits[index]}
          rowClassName={({ index }) =>
            index !== -1 ? styles.TableRow : styles.HeaderRow
          }
        >
          <Column
            label="Patient Name"
            dataKey="first_name"
            width={(width / 12) * 4}
            cellRenderer={nameRenderer}
          />
          <Column
            label="Systolic"
            dataKey="systolic_high"
            width={(width / 12) * 2}
            cellRenderer={systolicRenderer}
          />
          <Column
            label="Diastolic"
            dataKey="diastolic_high"
            width={(width / 12) * 2}
            cellRenderer={diastolicRenderer}
          />

          <Column
            label="Delete Entry"
            dataKey="test_result_limit_id"
            width={(width / 12) * 2}
            cellRenderer={deleteRenderer}
          />
        </Table>
      </div>
    </div>
  );
};

export default TestResultTable;
