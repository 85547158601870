import React from "react";

export const MedicalScribe = (props) => {
  return (
    <div>
      <iframe
        allow="camera;microphone"
        allowFullScreen={true}
        height={1500}
        title={"Athelas Medical Scribe"}
        width="100%"
        src="https://athelas.retool.com/embedded/public/69b5f3d1-666f-4843-b4b1-9dd8a7cb3686"
      ></iframe>
    </div>
  );
};
