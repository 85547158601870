import moment from "moment";
import styles from "./NurseNotes.module.css";

export type NurseNotesType = {
  created_at: Date;
  escalation_note: string;
  escalation_level: number;
  escalation_ack_date: Date;
  notes: string;
  nurse_email: string;
  patient_id: number;
  review_time: number;
  index: number;
  nurse_note_id: number;
};

type PropsType = {
  nurse_note: NurseNotesType;
  index: number;
  onClick: (index: number) => void;
  activeIndex: number;
  color: string;
};

export const NurseNoteThumbnail = (props: PropsType) => {
  const nurseNote = props.nurse_note;

  return (
    <div
      className={
        props.activeIndex === props.index
          ? styles["nurse-notes-thumbnail-container-active"]
          : styles["nurse-notes-thumbnail-container"]
      }
      onClick={() => {
        props.onClick(props.index);
      }}
    >
      <p className={styles["nurse-notes-flex-box"]}>
        <p className={styles["nurse-title"]}>ATHELAS NURSE</p>
        <p className={styles["date"]}>
          {moment(nurseNote.created_at).format("MMM D, YYYY")}
        </p>
      </p>
      <p className={styles["nurse-notes-thumbnail-email-style"]}>
        {nurseNote.nurse_email}
      </p>
      <p className={styles["nurse-notes-note"]}>
        {nurseNote.notes.substring(0, 30)}{" "}
        {nurseNote.notes.length > 30 ? "..." : ""}
      </p>

      {nurseNote.escalation_note ===
      null ? null : nurseNote.escalation_note.trim() === "" ? null : (
        <p
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 0,
          }}
        >
          <p className={styles["escalated-note"]}>
            PRIORITY
            {nurseNote.escalation_level
              ? " LEVEL " + nurseNote.escalation_level
              : ""}
          </p>
          <p
            className={styles["escalation_ack_date"]}
            style={{ marginBottom: 0 }}
          >
            {nurseNote.escalation_ack_date === null ? (
              "Not acknowledged"
            ) : (
              <>
                <p style={{ marginBottom: 0 }}>Acknowledged on: </p>
                <p style={{ marginBottom: 0 }}>
                  {moment(nurseNote.escalation_ack_date).format("MMM D, YYYY")}
                </p>
              </>
            )}
          </p>
        </p>
      )}
    </div>
  );
};
