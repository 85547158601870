import styles from "./AthelasButton.module.css";
import Tooltip from "@mui/material/Tooltip";

type PropsType = {
  text: string;
  onClick: any;
  variant?: string;
  size?: "regular" | "small";
  radius?: any;
  disabled?: boolean;
  disabledToolTipText?: string;
  toolTipText?: string;
};

export function AthelasButton(props: PropsType) {
  const variant = props.variant ? props.variant : "basic";
  const diabledToolTipText = props.disabledToolTipText
    ? props.disabledToolTipText
    : "";

  const toolTipText = props.toolTipText ? props.toolTipText : "";

  let color = "#42A1F8";
  if (variant === "success") {
    color = "#1dbf66";
  } else if (variant === "danger") {
    color = "#FC5378";
  } else if (variant === "search") {
    color = "#97adad";
  }

  const isSmall = props.size === "small";
  const radius = props.radius ? parseInt(props.radius) : 100;

  return (
    <Tooltip title={props.disabled ? diabledToolTipText : toolTipText}>
      <div className={styles.AthelasButton}>
        <div
          style={{
            padding: isSmall ? 8 : 12,
            paddingLeft: 24,
            paddingRight: 24,
            backgroundColor: props.disabled ? "#666666" : color,
            fontSize: isSmall ? "80%" : "100%",
            borderRadius: radius,
            color: props.disabled ? "#ffffff" : "white",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: props.disabled ? "none" : "0px 0px 10px #AAA",
            border: "none",
            opacity: props.disabled ? "0.7" : "",
          }}
          className={styles.AthelasButton}
          onClick={() => {
            if (props.disabled == null || !props.disabled) {
              props.onClick();
            }
          }}
        >
          {props.text}
        </div>
      </div>
    </Tooltip>
  );
}
