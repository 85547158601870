import { Header } from "../header/Header";
import styles from "./Settings.module.css";

import { SettingsThreshold } from "./SettingsThreshold";

type PropsType = {};

export function Settings(props: PropsType) {
  return (
    <>
      <Header title="Settings"></Header>
      <div>
        <div className={styles.Container}>
          <SettingsThreshold />
        </div>
      </div>
    </>
  );
}
