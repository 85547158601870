import Chart from "react-apexcharts";

type PropsType = {
  categories: Array<any>;
  series: Array<number>;
  hideToolbar?: boolean;
  tickAmount?: number;
  yTitle?: string;
  xTitle?: string;
};

export const LineChart = (props: PropsType) => {
  const data = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: !props.hideToolbar,
        },
      },
      xaxis: {
        categories: props.categories,
        tickAmount: props.tickAmount || 10,
        labels: {
          show: props.tickAmount === 0 ? false : true,
        },
        title: {
          text: props.xTitle || "",
        },
      },
      yaxis: {
        round: true,
        title: {
          text: props.yTitle || "",
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        width: 3,
      },
    },
    series: [
      {
        name: "series-1",
        data: props.series,
      },
    ],
  };
  return (
    <Chart
      options={data.options}
      series={data.series}
      type="line"
      width="100%"
    />
  );
};
