import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  isRejectedWithValue,
} from "@reduxjs/toolkit";

import chronicCareAPI from "./chronicCareAPI";

const chronicCareAdapter = createEntityAdapter({
  selectId: (form) => form.id,
});

const initialState = chronicCareAdapter.getInitialState({
  status: "idle",
  error: null,
  entries: [],
  singleEntryStatus: "idle",
  currRecordInfo: null,
  signedUrl: null,
  approveCarePlanStatus: "idle",
});

export const fetchIntakeFormsAndCarePlans = createAsyncThunk(
  "chronicCare/fetchIntakeFormsAndCarePlans",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await chronicCareAPI.getIntakeFormsAndCarePlans(
        site_id,
        token
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPatientIntakeForm = createAsyncThunk(
  "chronicCare/fetchPatientIntakeForm",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await chronicCareAPI.getPatientIntakeForm(id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUnapprovedCarePlan = createAsyncThunk(
  "chronicCare/fetchUnapprovedCarePlan",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await chronicCareAPI.getUnapprovedCarePlan(id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchApprovedCarePlan = createAsyncThunk(
  "chronicCare/fetchApprovedCarePlan",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await chronicCareAPI.getApprovedCarePlan(id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCarePlanSignedUrl = createAsyncThunk(
  "chronicCare/fetchCarePlanSignedUrl",
  async ({ bucketUrl, token }, { rejectWithValue }) => {
    try {
      const response = await chronicCareAPI.getCarePlanSignedUrl(
        bucketUrl,
        token
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const approveCarePlan = createAsyncThunk(
  "chronicCare/approveCarePlan",
  async ({ carePlanId, approverPrescriberId, token }, { rejectWithValue }) => {
    try {
      const response = await chronicCareAPI.approveCarePlan(
        carePlanId,
        approverPrescriberId,
        token
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const chronicCareSlice = createSlice({
  name: "chronicCare",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchIntakeFormsAndCarePlans.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchIntakeFormsAndCarePlans.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        state.status = "succeeded";
        state.entries = action.payload;
      }
    },
    [fetchIntakeFormsAndCarePlans.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchPatientIntakeForm.pending]: (state, action) => {
      state.singleEntryStatus = "loading";
      state.error = null;
    },
    [fetchPatientIntakeForm.fulfilled]: (state, action) => {
      if (state.singleEntryStatus === "loading") {
        state.singleEntryStatus = "succeeded";
        state.currRecordInfo = action.payload;
      }
    },
    [fetchPatientIntakeForm.rejected]: (state, action) => {
      if (state.singleEntryStatus === "loading") {
        state.singleEntryStatus = "failed";
        state.error = action.payload;
      }
    },
    [fetchUnapprovedCarePlan.pending]: (state, action) => {
      state.singleEntryStatus = "loading";
      state.error = null;
    },
    [fetchUnapprovedCarePlan.fulfilled]: (state, action) => {
      if (state.singleEntryStatus === "loading") {
        state.singleEntryStatus = "succeeded";
        state.currRecordInfo = action.payload;
      }
    },
    [fetchUnapprovedCarePlan.rejected]: (state, action) => {
      if (state.singleEntryStatus === "loading") {
        state.singleEntryStatus = "failed";
        state.error = action.payload;
      }
    },
    [fetchApprovedCarePlan.pending]: (state, action) => {
      state.singleEntryStatus = "loading";
      state.error = null;
    },
    [fetchApprovedCarePlan.fulfilled]: (state, action) => {
      if (state.singleEntryStatus === "loading") {
        state.singleEntryStatus = "succeeded";
        state.currRecordInfo = action.payload;
      }
    },
    [fetchApprovedCarePlan.rejected]: (state, action) => {
      if (state.singleEntryStatus === "loading") {
        state.singleEntryStatus = "failed";
        state.error = action.payload;
      }
    },
    [fetchCarePlanSignedUrl.fulfilled]: (state, action) => {
      state.signedUrl = action.payload["signed_url"];
    },
    [approveCarePlan.pending]: (state, action) => {
      state.approveCarePlanStatus = "loading";
      state.error = null;
    },
    [approveCarePlan.fulfilled]: (state, action) => {
      if (state.approveCarePlanStatus === "loading") {
        state.approveCarePlanStatus = "succeeded";
      }
    },
    [approveCarePlan.rejected]: (state, action) => {
      if (state.approveCarePlanStatus === "loading") {
        state.approveCarePlanStatus = "failed";
        state.error = action.payload;
      }
    },
  },
});

export const { reset } = chronicCareSlice.actions;

export default chronicCareSlice.reducer;
