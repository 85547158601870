import axios from "axios";

const sitesAPI = {
  getLatestTestResults: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/latest_test_results`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  approveReferralAuthorizations: (token, site_id, signature, patient_ids) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/referral_authorizations`,
      {
        site_id,
        signature,
        patient_ids,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getSiteSummary: (site_id, token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/sites/${site_id}/summary`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getSitePrescribers: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/rpm_prescribers`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  submitReferrals: (site_id, token, numbers, names) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/add_referrals`,
      {
        numbers,
        names,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  submitRCMRequestForDemo: (site_id, token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/rcm_request_demo`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  setAlertingConditions: (siteID, token, alertingConditions) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/${siteID}/set_alerting_conditions`,
      {
        alerting_conditions: alertingConditions,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getAlertingConditions: (siteID, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${siteID}/get_alerting_conditions`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getAllSites: (token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/sites/list/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAllPatientsWithCustomLimits: (siteID, token, test_type) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${siteID}/get_all_patients_with_custom_limits?test_result_type=${test_type}`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  getSiteEligibility: (siteID, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${siteID}/get_site_enrollment_eligibility_conditions`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default sitesAPI;
