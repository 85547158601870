import {
  AtomCalendar,
  StatusPill,
  HorizontalStats,
  SummaryCard,
} from "../../../app/Atoms";

type PropsType = {
  status: string;
  name: string;
  dates: Array<String>;
  lastTaken: string;
  totalDays: number;
};

export const MedicationSummary = (props: PropsType) => {
  /*
    name: "Tylenol",
    last_taken: "March 8th, 2021",
    compliant: 43,
    status: "On Schedule",
    dates: ["03-05-2021", "04-05-2021", "03-05-2020"],
  */

  const activated = new Date(props.activated);
  const variant = props.status === "absent" ? "danger" : "success";
  const adherence_variant = props.adherence <= 50 ? "danger" : "success";
  const adherence_text = `Adherence: ${props.adherence}%`;
  const statusText =
    props.status === "absent"
      ? "State: Not in Medication Tray"
      : "State: In Medication Tray";

  return (
    <>
      <SummaryCard title={props.name} subtitle={"(1 taken every 24 hours)"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "24px",
          }}
        >
          <AtomCalendar start={props.lastTaken} dates={props.dates} />
          <div
            style={{
              maxHeight: 200,
              fontSize: 12,
            }}
          ></div>
          <div style={{ width: "100%", marginLeft: "16px" }}>
            <HorizontalStats
              leftStat={`Activated: ${activated.toDateString()}`}
              rightStat={`Days used: ${props.totalDays}`}
            />
            <div style={{ marginBottom: "24px" }}>
              <StatusPill variant={variant} text={statusText} />
            </div>
            <StatusPill variant={adherence_variant} text={adherence_text} />
          </div>
        </div>
      </SummaryCard>
    </>
  );
};
