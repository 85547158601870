import axios from "axios";

const userAPI = {
  login: (email, password) => {
    return axios.post(`${process.env.REACT_APP_HOST}/auth/login`, {
      email,
      password,
    });
  },
  status: (token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/auth/status`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  verifyMFA: (email, otp) => {
    return axios.post(`${process.env.REACT_APP_HOST}/auth/verify_otp`, {
      email,
      otp,
    });
  },
  impersonate: (email, token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/auth/impersonate`,
      {
        email,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  stopImpersonate: (token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/auth/impersonate/stop`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  resetPassword: (token, email, old_password, new_password) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/auth/reset_password`,
      {
        email,
        old_password,
        new_password,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  resetPasswordViaToken: (token, new_password) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/auth/reset_password_via_token`,
      {
        new_password,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  sendPasswordResetEmail: (email) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/auth/send_password_reset_email`,
      {
        email: email,
      }
    );
  },
  onboardProgress: (token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/users/onboard/progress`, {
      headers: { Authorization: `${token}` },
    });
  },
  onboardResetPassword: (token, new_password) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/users/onboard/reset_password`,
      {
        new_password,
      },
      {
        headers: { Authorization: `${token}` },
      }
    );
  },
};

export default userAPI;
