import axios from "axios";

const nurseNotesAPI = {
  get: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/nursenotes`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  ackPriorityNote: (site_id, token, nurse_note_id) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/ack_priority_note`,
      {
        nurse_note_id: nurse_note_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default nurseNotesAPI;
