const os = require("os");

export function ExportTestResultsAsCSVFile(testResults, fileName) {
  // specify how you want to handle null values here
  const replacer = (key, value) => (value === null ? "" : value);
  const header = [
    "patient",
    "patient_date_of_birth",
    "prescriber_full_name",
    "test_time",
    "test_type",
    "display_test_value",
    "percentile_change",
    "cpt_codes",
  ];
  const csv = [
    header.join(","), // header row first
    ...testResults.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);

  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function ExportPatientListAsCSVFile(patientList, fileName) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = [
    "id",
    "first_name",
    "last_name",
    "last_result_datetime",
    "date_of_birth",
    "insurance_name",
    "patient_email",
    "patient_phone_number",
    "onboarding_status",
    "devices",
    "prescriber_name",
    "prescriber_email",
    "prescriber_id",
    "referral_authorization_id",
    "referral_statement",
  ];
  const csv = [
    header.join(","), // header row first
    ...patientList.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);
  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function ExportEnrollmentPatientListAsCSVFile(patients, fileName) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = [
    "id",
    "first_name",
    "last_name",
    "date_of_birth",
    "prescriber_name",
    "devices",
    "insurance_name",
    "icd10_code",
    "rpm_onboard_status",
  ];
  const csv = [
    header.join(","), // header row first
    ...patients.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);
  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function ExportPatientConnectListAsCSVFile(patients, fileName) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = [
    "id",
    "first_name",
    "last_name",
    "date_of_birth",
    "prescriber_name",
    "devices",
    "insurance_name",
    "rpm_onboard_status",
  ];
  const csv = [
    header.join(","), // header row first
    ...patients.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);
  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function ExportTestResultListAsCSVFile(patients, fileName) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = [
    "patient",
    "patient_date_of_birth",
    "prescriber_full_name",
    "test_type",
    "display_test_value",
    "percentile_change",
    "test_time",
  ];
  const csv = [
    header.join(","), // header row first
    ...patients.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);
  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function ExportNurseNoteListAsCSVFile(patients, fileName) {
  const replacer = (key, value) =>
    value === null ? "" : Number.isInteger(value) ? value : value.trimLeft();
  const header = [
    "created_at",
    "patient_first_name",
    "patient_last_name",
    "prescriber_name",
    "notes",
    "escalation_notes",
    "escalation_level",
  ];
  const csv = [
    header.join(","), // header row first
    ...patients.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);
  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function ExportTestResultsSinglePatientAsCSVFile(patients, fileName) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = [
    "patient",
    "test_type",
    "test_time",
    "prescriber_full_name",
    "systolic",
    "diastolic",
    "bpm",
    "weight",
    "glucose_value",
    "spo2",
  ];
  const csv = [
    header.join(","), // header row first
    ...patients.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join(os.EOL);
  var link = document.createElement("a");
  link.download = `${fileName}.csv`;
  var blob = new Blob([csv], { type: "text/csv" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
}
