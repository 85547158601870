import axios from "axios";

const claimsOverviewAPI = {
  get_all_time_claims_metrics: (site_id: number, token: string) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/claims/${site_id}/all_time_claims_metrics`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  get_filtered_site_claims: (
    site_id: number,
    token: string,
    to_date: string,
    from_date: string,
    claim_status: string,
    patient_first_name: string,
    patient_last_name: string,
    insurance_name: string,
    provider_first_name: string,
    provider_last_name: string
  ) => {
    let data: object = {
      to_time: to_date,
      from_time: from_date,
      claim_status: claim_status,
      patient_first_name: patient_first_name,
      patient_last_name: patient_last_name,
      insurance_name: insurance_name,
      provider_first_name: provider_first_name,
      provider_last_name: provider_last_name,
    };

    return axios.post(
      `${process.env.REACT_APP_HOST}/claims/${site_id}/filtered_site_claims`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  get_insurance_names: (site_id: number, token: string) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/claims/${site_id}/get_submitted_insurance_names`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  get_single_claim_info: (site_id: number, token: string, claim_id: number) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/claims/${site_id}/get_single_claim/${claim_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  update_claim_status_for_single_claim: (
    site_id: number,
    token: string,
    claim_id: number,
    status_to_update: string
  ) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/claims/${site_id}/update_claim_status_from_doctor_app/${claim_id}`,
      {
        status_to_update: status_to_update,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  get_filtered_claims_as_csv: (
    site_id: number,
    token: string,
    to_date: string,
    from_date: string,
    claim_status: string,
    patient_first_name: string,
    patient_last_name: string,
    insurance_name: string,
    provider_first_name: string,
    provider_last_name: string
  ) => {
    let data: object = {
      to_time: to_date,
      from_time: from_date,
      claim_status: claim_status,
      patient_first_name: patient_first_name,
      patient_last_name: patient_last_name,
      insurance_name: insurance_name,
      provider_first_name: provider_first_name,
      provider_last_name: provider_last_name,
    };
    console.log("filtered_claims_as_csv");
    console.log(site_id);

    return axios.post(
      `${process.env.REACT_APP_HOST}/claims/${site_id}/filtered_site_claims_as_file`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default claimsOverviewAPI;
