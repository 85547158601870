import Chart from "react-apexcharts";

type PropsType = {
  data: any;
};

export const PiChart = (props: PropsType) => {
  const keys = Object.keys(props.data);
  const counts = keys.map((key) => props.data[key]);

  const data = {
    series: counts,

    options: {
      chart: {
        type: "donut",
      },
      labels: keys.map((key) => key.replace("_", " ").toLowerCase()),
      colors: [
        "#42A1F8",
        "#FC5378",
        "#42A1F8",
        "#FC5378",
        "#42A1F8",
        "#42F8C1",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return <Chart options={data.options} series={data.series} type="donut" />;
};
