import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

// react bootstrap
import Loader from "react-loader-spinner";

import { Header } from "../header/Header";
import styles from "../chronicCare/ChronicCareList.module.css";

import { fetchPatientIntakeForm } from "./chronicCareSlice";
import { isInIframe } from "../../utils/utils";

export const SingleIntakeFormPage = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.chronicCare.singleEntryStatus);
  const info = useSelector((state) => state.chronicCare.currRecordInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPatientIntakeForm({ id, token }));
  }, []);

  const IntakeField = ({ fieldName, fieldValue }) => {
    return (
      <div className={styles.FieldCardInfo}>
        <div className={styles.FieldCardInfoText}>{fieldName}</div>
        <div>{fieldValue}</div>
      </div>
    );
  };

  let content;
  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <>
        <Header
          title={info["first_name"] + " " + info["last_name"]}
          showBackButton={true && !isInIframe()}
          iframeView={isInIframe()}
        />

        <div className={styles.FormContainer}>
          <div className={styles.FormCard}>
            <div className={styles.FormCardTitle}>
              <span className={styles.FormCardTitleText}>
                Patient Intake Form (ID: {info["id"]})
              </span>
            </div>
            <IntakeField
              fieldName={"Patient ID"}
              fieldValue={info["patient_id"]}
            />
            <IntakeField
              fieldName={"First Name"}
              fieldValue={info["first_name"]}
            />
            <IntakeField
              fieldName={"Last Name"}
              fieldValue={info["last_name"]}
            />
            <IntakeField fieldName={"Email"} fieldValue={info["email"]} />
            <IntakeField
              fieldName={"Phone #"}
              fieldValue={info["phone_number"]}
            />
            <IntakeField
              fieldName={"Submission Date"}
              fieldValue={info["submission_date"]}
            />
          </div>
        </div>
      </>
    );
  }
  return <>{content}</>;
};
