import axios from "axios";

const testResultsAPI = {
  getByIds: (ids, token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/test_results/by_ids`,
      {
        ids,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getTestResultPageSlice: (
    site_id,
    token,
    prescriber_id,
    out_of_range,
    large_change,
    page_size,
    offset
  ) => {
    let query_string = "";
    if (prescriber_id !== null && prescriber_id !== "selected") {
      query_string = query_string + "prescriber_id=" + prescriber_id;
    }
    if (out_of_range) {
      query_string = query_string + "&out_of_range=true";
    }
    if (large_change) {
      query_string = query_string + "&large_change=true";
    }
    query_string = query_string + "&page_size=" + page_size;
    query_string = query_string + "&offset=" + offset;

    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/test_results?${query_string}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getLastThousandTestResultSlice: (
    site_id,
    token,
    prescriber_id,
    out_of_range,
    large_change,
    page_size,
    offset
  ) => {
    let query_string = "";
    if (prescriber_id !== null && prescriber_id !== "selected") {
      query_string = query_string + "prescriber_id=" + prescriber_id;
    }
    if (out_of_range) {
      query_string = query_string + "&out_of_range=true";
    }
    if (large_change) {
      query_string = query_string + "&large_change=true";
    }
    query_string = query_string + "&page_size=" + page_size;
    query_string = query_string + "&offset=" + offset;

    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/test_results?${query_string}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default testResultsAPI;
