import { useSelector } from "react-redux";
const querystring = require("querystring");

export const NursePortal = (props) => {
  const authToken = useSelector((state) => state.user.token);
  const encoded = querystring.stringify({ authToken: authToken });
  const url = `https://athelas.retool.com/embedded/public/f0ba19be-5f86-4899-b264-61ec0683f3de#${encoded}`;
  return (
    <div>
      <iframe
        allowFullScreen={true}
        height={1500}
        title={"Athelas Nurse portal"}
        width="100%"
        src={url}
      ></iframe>
    </div>
  );
};
