export function makeUUID(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/*
 * Returns whether or not the bracket configuration is correct for Patient Comms
 *
 * correct example: "[patient_name] is Jerry"
 * incorrect example: "[patient_name is Jerry" // is missing a ] character
 */
export function hasValidBrackets(text: string, search: string) {
  let num_opens = 0;
  let num_closes = 0;

  let count = 0;
  for (var i = 0; i < text.length; i++) {
    const char = text[i];
    if (char === "[") {
      count++;
      num_opens++;
    } else if (char === "]") {
      count--;
      num_closes++;
    }

    if (count !== 0 && count !== 1) {
      return false;
    }
  }
  if (num_opens !== num_closes) return false;

  return true;
}

export function findInvalidPatientConnectFields(text: string) {
  const regex = /\[(.*?)\]/g;
  const matches_array = text.match(regex);

  if (!matches_array) return [];

  const filtered_matches = matches_array.filter((x) => {
    return !["[patient_name]", "[doctor_name]", "[onboarding_link]"].includes(
      x
    );
  });

  return filtered_matches;
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function capitalizeFirstLetter(st: string) {
  let words = st.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i] === "" || words[i] === undefined || words[i] === null) {
      continue;
    }
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
  }

  return words.join(" ");
}
