import axios from "axios";

const RoutableAPI = {
  routable_create_company_and_send_initial_invoice: ({
    token,
    contact_first_name,
    contact_last_name,
    contact_email,
    contact_phone_number,
    is_sandbox,
  }) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/routable/routable_create_company_and_send_initial_invoice`,
      {
        contact_first_name,
        contact_last_name,
        contact_email,
        contact_phone_number,
        is_sandbox,
      },
      {
        headers: { Authorization: token },
      }
    );
  },
  site_has_routable_company: ({ token }) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/routable/site_has_routable_company`,
      {},
      {
        headers: { Authorization: token },
      }
    );
  },
};

export default RoutableAPI;
