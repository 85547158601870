type ValidFieldsType = {
  ok: boolean;
  message: string;
};

function userIdValid(user_id) {
  return Number.isInteger(user_id);
}

function dobValid(dob) {
  return dob !== null;
}

function validString(text) {
  return text && text.length > 0;
}

function validICD10Code(text) {
  var sanitized = text.replace(".", "").toUpperCase();
  const regex = /[A-Z][0-9][0-9A-Z]{1,5}/g;

  var found = sanitized.match(regex);
  console.log(found);
  if (!found) {
    return false;
  }
  return true;
}

function validPhoneNumber(text) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return text.match(regex) !== null;
}

function genderValid(text) {
  return ["Male", "Female"].includes(text);
}

function validBoolean(bool) {
  return [true, false].includes(bool);
}

function firstTestValid(firstTest) {
  return ["BLOOD_PRESSURE", "WEIGHT", "GLUCOSE", "PULSE_OXIMETRY"].includes(
    firstTest
  );
}

function isValidNumber(num) {
  try {
    const numeric = parseInt(num);
    return Number.isInteger(numeric) && numeric !== -1;
  } catch {
    return false;
  }
}

export const validateFields = (
  user_id: number,
  firstName: string,
  lastName: string,
  dob: string,
  phoneNumber: string,
  address: string,
  insuranceCompany: string,
  insuranceNumber: string,
  bloodPressureTest: boolean,
  weightTest: boolean,
  glucoseTest: boolean,
  isWeightBlind: boolean,
  doctorName: string,
  gender: string,
  condition1: string,
  condition2: string,
  firstTest: string,
  prescriberId: number
): ValidFieldsType => {
  if (!isValidNumber(prescriberId)) {
    return { ok: false, message: "Invalid prescriber selected" };
  }
  if (!userIdValid(user_id)) {
    return { ok: false, message: "Invalid user id" };
  }
  if (!validString(firstName)) {
    return { ok: false, message: "Invalid first name" };
  }
  if (!validString(lastName)) {
    return { ok: false, message: "Invalid last name" };
  }
  if (!dobValid(dob)) {
    return { ok: false, message: "Invalid date of birth" };
  }
  if (!validPhoneNumber(phoneNumber)) {
    return { ok: false, message: "Invalid phone number" };
  }
  if (!validString(address)) {
    return { ok: false, message: "Invalid address" };
  }
  if (!validString(insuranceCompany)) {
    return { ok: false, message: "Invalid insurance company" };
  }
  if (!validString(insuranceNumber)) {
    return { ok: false, message: "Invalid insurance number" };
  }
  if (!validBoolean(bloodPressureTest)) {
    return { ok: false, message: "Invalid blood pressure test" };
  }
  if (!validBoolean(weightTest)) {
    return { ok: false, message: "Invalid weight test" };
  }
  if (!validBoolean(glucoseTest)) {
    return { ok: false, message: "Invalid glucose test" };
  }
  if (!validBoolean(isWeightBlind)) {
    return { ok: false, message: "Invalid weight blind" };
  }
  if (!validString(doctorName)) {
    return { ok: false, message: "Invalid doctor name" };
  }
  if (!genderValid(gender)) {
    return { ok: false, message: "Invalid gender" };
  }
  if (!validICD10Code(condition1)) {
    return { ok: false, message: "Invalid ICD 10 code provided" };
  }
  if (!firstTestValid(firstTest)) {
    return { ok: false, message: "Invalid first test" };
  }

  return { ok: true, message: "OK" };
};
