import styles from "./Settings.module.css";

import { useEffect, useState } from "react";
import Switch from "react-switch";
import { useSelector } from "react-redux";
import React from "react";
import { ThresholdSetter } from "./ThresholdSetter";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import sitesAPI from "../home/sitesAPI";
import patientsAPI from "../patients/patientsAPI";
import { trackEvent } from "../../utils/tracking";
import Form from "react-bootstrap/Form";

type PropsType = {};

interface AlertingConditions {
  systolic_low: number | typeof NaN;
  systolic_high: number | typeof NaN;
  diastolic_low: number | typeof NaN;
  diastolic_high: number | typeof NaN;
}
export function SettingsThreshold(props: PropsType) {
  const site_id = useSelector((state) => state.user.currSiteId);
  const authToken = useSelector((state) => state.user.token);

  const [fetchedAlertingConditions, setFetchedAlertingConditions] = useState(
    false
  );
  const [enableNotificationsCheck, setEnableNotificationsCheck] = useState(
    false
  );
  const [sendToPrescriberCheck, setSendToPrescriberCheck] = useState(false);

  const [alertingConditions, setAlertingConditions] = useState({
    fetchedAlertingConditions,
  });

  const [
    selectedPatientForThreshold,
    setSelectedPatientForThreshold,
  ] = useState();

  const [
    perPatientAlertingConditions,
    setPerPatientAlertingConditions,
  ] = useState<AlertingConditions>({
    systolic_low: NaN,
    systolic_high: NaN,
    diastolic_high: NaN,
    diastolic_low: NaN,
  });

  const [email, setEmail] = useState("");
  const onEmailChanged = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
    if (alertingConditions["extra_alerting_emails"]) {
      setIsDuplicateEmail(
        alertingConditions["extra_alerting_emails"].includes(e.target.value)
      );
    }
  };

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);

  const validateEmail = (email: string) => {
    const res = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return res !== null;
  };

  const handleNewEmail = () => {
    if (!isDuplicateEmail) {
      let updatedAlertingConditions =
        alertingConditions["extra_alerting_emails"];

      if (updatedAlertingConditions) {
        updatedAlertingConditions = alertingConditions[
          "extra_alerting_emails"
        ].push(email);
      } else {
        alertingConditions["extra_alerting_emails"] = [email];
        updatedAlertingConditions = alertingConditions["extra_alerting_emails"];
      }

      setAlertingConditions({
        ...alertingConditions,
        updatedAlertingConditions,
      });
      updateEmailSettings();
    } else {
      console.log("Did not add new email, duplicate email found.");
    }
    trackEvent("click", "add_email_to_out_of_range_alerts");
  };

  if (!fetchedAlertingConditions && site_id && authToken) {
    sitesAPI.getAlertingConditions(site_id, authToken).then((res) => {
      setAlertingConditions(res.data);
      setEnableNotificationsCheck(Object.keys(res.data).length > 0);
      setSendToPrescriberCheck(
        !res.data["skip_sending_to_prescriber"] || false
      );
    });
    setFetchedAlertingConditions(true);
  }

  const [patientsWithCustomLimits, setPatientsWithCustomLimits] = useState([]);

  const refreshPatientsWithCustomLimits = () => {
    sitesAPI
      .getAllPatientsWithCustomLimits(site_id, authToken, "BLOOD_PRESSURE")
      .then((res) => {
        setPatientsWithCustomLimits(res.data);
        console.log("Setting patients with custom limits");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    refreshPatientsWithCustomLimits();
  }, [perPatientAlertingConditions]);

  useEffect(() => {
    if (selectedPatientForThreshold && site_id && authToken) {
      patientsAPI
        .getPerPatientAlertingConditions(
          site_id,
          authToken,
          selectedPatientForThreshold,
          "bp"
        )
        .then((res) => {
          const { patient_id, ...data } = res.data;
          setPerPatientAlertingConditions(data);
        })
        .catch((e) => {
          // when the user has no associated data
          const tempObj = {
            systolic_low: NaN,
            systolic_high: NaN,
            diastolic_low: NaN,
            diastolic_high: NaN,
          };
          setPerPatientAlertingConditions(tempObj);
          console.log(e.response);
        });
    }
  }, [selectedPatientForThreshold, site_id]);

  const disableNotifications = (testType: string) => {
    var conditions: any = alertingConditions;

    let testTypeToAlertingFlagEnabled: { [testType: string]: string } = {
      BLOOD_PRESSURE: "is_bp_alerting_enabled",
    };

    if (testType in testTypeToAlertingFlagEnabled) {
      conditions[testTypeToAlertingFlagEnabled[testType]] = false;
    }

    setAlertingConditions(conditions);
  };

  const enableNotifications = (testType: string) => {
    var conditions: any = alertingConditions;

    let testTypeToAlertingFlagEnabled: { [testType: string]: string } = {
      BLOOD_PRESSURE: "is_bp_alerting_enabled",
    };

    conditions[testTypeToAlertingFlagEnabled[testType]] = true;

    setAlertingConditions(conditions);
  };

  const updateAlertingConditions = (
    attribute: string,
    value: number | boolean
  ) => {
    var conditions: any = alertingConditions;

    // update attribute in condition to this value
    conditions[attribute] = value;

    setAlertingConditions(conditions);
    return value;
  };

  const updatePerPatientAlertingConditions = (
    attribute: string,
    value: number | boolean
  ) => {
    setPerPatientAlertingConditions({
      ...perPatientAlertingConditions,
      [attribute]: value,
    });
    return value;
  };

  const getAlertingCondition = (attribute: string) => {
    var conditions: any = alertingConditions;

    if (!Object.keys(conditions).includes(attribute)) {
      return undefined;
    }

    var attribute_value = conditions[attribute];
    if (attribute_value.length === 0) {
      return null;
    }
    return parseFloat(attribute_value);
  };

  const hasCondition = (testType: string) => {
    var conditions: any = alertingConditions;

    let testTypeToAlertingFlagEnabled: { [testType: string]: string } = {
      BLOOD_PRESSURE: "is_bp_alerting_enabled",
    };

    return (
      testType in testTypeToAlertingFlagEnabled &&
      conditions[testTypeToAlertingFlagEnabled[testType]]
    );
  };

  const updateNotificationsSettings = () => {
    let updatedAlertingConditions = enableNotificationsCheck
      ? alertingConditions
      : {};
    sitesAPI
      .setAlertingConditions(site_id, authToken, updatedAlertingConditions)
      .then((res) => {
        alert("Your threshold alerting settings have been saved.");
        trackEvent("click", "updated_notification_settings");
        console.log(res);
      })
      .catch((e) => {
        alert(
          "There was an error while saving your alerting notification settings: " +
            e.response.data
        );
        console.log(e.response);
      });

    if (selectedPatientForThreshold !== "default") {
      patientsAPI
        .setPerPatientAlertingConditions(
          site_id,
          authToken,
          selectedPatientForThreshold,
          perPatientAlertingConditions,
          "bp"
        )
        .then((res) => {
          console.log("Per patient information has been set.");
          trackEvent("click", "updated_per_patient_alerting_conditions");
          refreshPatientsWithCustomLimits(); // has to be inside then, to ensure it only runs after the conditions are updated - nice
        })
        .catch((e) => {
          console.log(
            "Patient alerting conditions have not been set. Error has occured."
          );
        });
    }
  };

  const updateEmailSettings = () => {
    let updatedAlertingConditions = enableNotificationsCheck
      ? alertingConditions
      : {};
    sitesAPI
      .setAlertingConditions(site_id, authToken, updatedAlertingConditions)
      .then((res) => {
        alert("New email has been added to notfication list.");
        trackEvent("click", "updated_notification_settings");
        console.log(res);
      })
      .catch((e) => {
        alert("There was an error adding a new email" + e.response.data);
        console.log(e.response);
      });
  };

  const handleNotificationsCheckHandler = (checked: boolean) => {
    setEnableNotificationsCheck(checked);

    if (!checked) {
      Object.keys(alertingConditions).map((key: string) => {
        disableNotifications(key);
        return null;
      });
    } else {
      setAlertingConditions({
        is_bp_alerting_enabled: true,
        alert_when_systolic_lower_than: 80,
        alert_when_systolic_higher_than: 180,
        alert_when_diastolic_lower_than: 0,
        alert_when_diastolic_higher_than: 200,
      });
    }
  };

  const sendToPrescriberCheckHandler = (checked: boolean) => {
    setSendToPrescriberCheck(checked);

    updateAlertingConditions("skip_sending_to_prescriber", !checked);
  };

  return (
    <>
      <h4>
        <b>Out of Bounds Test Result Notifications</b>
        <br />
        <p style={{ fontSize: 12 }}>
          Enable these notifications to receive emails any time one of your
          patients tests outside of the thresholds you set for different remote
          tests.
        </p>
      </h4>
      <hr />
      <div className={styles.flexBox}>
        <Switch
          onChange={() => {
            handleNotificationsCheckHandler(!enableNotificationsCheck);
          }}
          checked={enableNotificationsCheck}
        />
        <p className={styles.checkLabel}>
          Receive email notifications for out of threshold test results.
        </p>
      </div>

      {enableNotificationsCheck && (
        <>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "80%",
              marginBottom: "10px",
            }}
          >
            <Form.Control
              id="email"
              placeholder="Add email to notification list"
              bsPrefix={styles.SearchBox}
              value={email}
              onChange={onEmailChanged}
              width={300}
            />

            <AthelasButton
              variant="primary"
              text="Add email"
              size="small"
              onClick={handleNewEmail}
              disabled={!isValidEmail}
            />
          </div>

          {!isValidEmail && email.length !== 0 && (
            <p className={styles.InvalidEmail}> Email is not valid</p>
          )}
          {isDuplicateEmail && email.length !== 0 && (
            <p className={styles.InvalidEmail}> Email already added.</p>
          )}

          <div className={styles.flexBox}>
            <Switch
              onChange={() => {
                sendToPrescriberCheckHandler(!sendToPrescriberCheck);
              }}
              checked={sendToPrescriberCheck}
            />
            <p className={styles.checkLabel}>
              Send alerts to the prescriber of this practice.
            </p>
          </div>

          <ThresholdSetter
            type="BLOOD_PRESSURE"
            values={["systolic", "diastolic"]}
            onChange={() => {}}
            updateAlertingConditions={updateAlertingConditions}
            disableNotifications={disableNotifications}
            enableNotifications={enableNotifications}
            getAlertingCondition={getAlertingCondition}
            hasCondition={hasCondition}
            perPatientAlertingConditions={perPatientAlertingConditions}
            setSelectedPatientForThreshold={setSelectedPatientForThreshold}
            updatePerPatientAlertingConditions={
              updatePerPatientAlertingConditions
            }
            patientsWithCustomLimits={patientsWithCustomLimits}
            setPatientsWithCustomLimits={setPatientsWithCustomLimits}
          />
        </>
      )}

      <div style={{ width: 300, marginLeft: 48 }}>
        <AthelasButton
          text="Save Notification Settings"
          size="small"
          onClick={updateNotificationsSettings}
          disabled={
            selectedPatientForThreshold &&
            (isNaN(perPatientAlertingConditions["systolic_low"]) ||
              isNaN(perPatientAlertingConditions["systolic_high"]) ||
              isNaN(perPatientAlertingConditions["diastolic_low"]) ||
              isNaN(perPatientAlertingConditions["diastolic_high"]))
          }
        />
      </div>
    </>
  );
}
