import moment from "moment";
import "moment-timezone";

const monthNames = {};
monthNames["01"] = "Jan";
monthNames["02"] = "Feb";
monthNames["03"] = "Mar";
monthNames["04"] = "Apr";
monthNames["05"] = "May";
monthNames["06"] = "Jun";
monthNames["07"] = "Jul";
monthNames["08"] = "Aug";
monthNames["09"] = "Sep";
monthNames["10"] = "Oct";
monthNames["11"] = "Nov";
monthNames["12"] = "Dec";

const fullMonthNames = {};
fullMonthNames["01"] = "January";
fullMonthNames["02"] = "February";
fullMonthNames["03"] = "March";
fullMonthNames["04"] = "April";
fullMonthNames["05"] = "May";
fullMonthNames["06"] = "June";
fullMonthNames["07"] = "July";
fullMonthNames["08"] = "August";
fullMonthNames["09"] = "September";
fullMonthNames["10"] = "October";
fullMonthNames["11"] = "November";
fullMonthNames["12"] = "December";

export const IsoDateToFullMonthYYYY = (dateStr) => {
  const [year, month] = dateStr.slice(0, 10).split("-");
  return fullMonthNames[month] + " " + year;
};

export const IsoDateToMMMYYYY = (dateStr) => {
  const [year, month] = dateStr.slice(0, 10).split("-");
  return monthNames[month] + " " + year;
};

export const IsoDateToDDMMMYYYY = (dateStr) => {
  const [year, month, day] = dateStr.slice(0, 10).split("-");
  return day + " " + monthNames[month] + " " + year;
};

export const IsoDatetimeToHistoryTableFormat = (datetimeStr) => {
  return moment(datetimeStr).format("MMM D, YYYY - h:mm A");
};

// respect the test time timezone, do not use utc
export const PythonDatetimeStrToDDMMMYYYY = (dateStr) => {
  return dateStr === "No Result"
    ? dateStr
    : moment(dateStr).format("MMM D, YYYY - h:mm A");
};
