import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column, Table, AutoSizer } from "react-virtualized";
import { useProdVar } from "../../utils/prodVars";
import { Header } from "../header/Header";
import { statusThunk } from "../user/userSlice";
import {
  approveReferralAuthorizationThunk,
  fetchPendingEnrollmentPatients,
  updateOnePatient,
  selectAllPendingEnrollmentPatients,
  fetchSitePrescribersThunk,
  fetchPendingEnrollmentPatientsInsuranceNames,
} from "./enrollmentSlice";
import enrollmentAPI from "./enrollmentAPI";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "./enrollment.module.css";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ReactComponent as SearchSvg } from "../../app/assets/search.svg";
import { ReactComponent as EditPatientIcon } from "../../app/assets/font_awesome/user-pen-solid.svg";
import { IconButton } from "@mui/material";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";
import { ExportEnrollmentPatientListAsCSVFile } from "../../utils/csv";
import { trackEvent } from "../../utils/tracking";
import patientsAPI from "../../features/patients/patientsAPI";
export const EnrollmentList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.enrollment.status);
  // patient fetching error
  const error = useSelector((state) => state.enrollment.error);
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentPatientRow, setCurrentPatientRow] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const clinical_protocol_link = useProdVar("athelas_clinical_protocol_link");

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      // fetching first site for now
      dispatch(fetchPendingEnrollmentPatients({ site_id, token }));
      trackEvent("view", "viewed_enrollment_list");
    }
  });

  const patients = useSelector(selectAllPendingEnrollmentPatients);

  const prescribers = useSelector((state) => state.enrollment.prescribers);

  if (prescribers.length === 0) {
    dispatch(fetchSitePrescribersThunk({ site_id, token }));
  }
  const [selectedPatientIds, setSelectedPatientIds] = useState(new Set());
  const prescriberOptions = prescribers.map((prescriber) => {
    return (
      <option value={prescriber.id} key={prescriber.id}>
        {prescriber.name}
      </option>
    );
  });

  const insurance_names = useSelector(
    (state) => state.enrollment.insurance_names
  );
  const insuranceNameOptions = insurance_names.map((insurance_name) => {
    return (
      <option value={insurance_name} key={insurance_name}>
        {insurance_name}
      </option>
    );
  });

  if (insurance_names.length === 0) {
    dispatch(fetchPendingEnrollmentPatientsInsuranceNames({ site_id, token }));
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [prescriberSelected, setPrescriberSelected] = useState("");
  const [insuranceNameSelected, setInsuranceNameSelected] = useState("");
  const [actionSelected, setActionSelected] = useState("");

  const onSearchTermChanged = (e) => {
    setSearchTerm(e.target.value);
    setSelectedPatientIds(new Set());
  };

  const onInsuranceNameChanges = (e) => {
    setInsuranceNameSelected(e.target.value);
    setSelectedPatientIds(new Set());
  };

  const onPrescriberSelectedChanged = (e) => {
    setPrescriberSelected(e.target.value);
    setSelectedPatientIds(new Set());
  };
  const onActionSelectedChanged = (e) => {
    setActionSelected(e.target.value);
    setSelectedPatientIds(new Set());
  };

  const patientFilterFunc = (patient) => {
    if (searchTerm !== "") {
      const f = patient.first_name.toLowerCase();
      const l = patient.last_name.toLowerCase();
      const s = searchTerm.toLowerCase();

      if (!(f.includes(s) || l.includes(s))) {
        return false;
      }
    }

    if (prescriberSelected !== "") {
      if (patient.prescriber_id !== parseInt(prescriberSelected)) return false;
    }

    if (insuranceNameSelected !== "") {
      if (insuranceNameSelected !== patient.insurance_name) return false;
    }

    if (actionSelected !== "") {
      if (actionSelected !== patient.enrollment_action) return false;
    }

    return true;
  };

  const filteredPendingPatients = patients.filter(patientFilterFunc);

  const getUniquePrescriberNamesFromSelectedPatients = () => {
    const selectedFilteredPatients = filteredPendingPatients.filter((patient) =>
      selectedPatientIds.has(patient.id)
    );
    const prescriberNames = selectedFilteredPatients
      .map((patient) => patient.prescriber_name)
      .filter((x, i, a) => a.indexOf(x) === i);
    return prescriberNames;
  };
  const uniquePrescriberNames = getUniquePrescriberNamesFromSelectedPatients();
  let content;

  const referCheckboxCell = ({ rowData }) => {
    const handleCheckChange = (e) => {
      setSelectedPatientIds((prevSelectedIds) => {
        const updatedSelectedIds = new Set(prevSelectedIds);
        if (e.target.checked) {
          updatedSelectedIds.add(rowData.id);
        } else {
          updatedSelectedIds.delete(rowData.id);
        }
        return updatedSelectedIds;
      });
    };
    return (
      <Form.Check
        type="checkbox"
        checked={selectedPatientIds.has(rowData.id)}
        onChange={handleCheckChange}
      />
    );
  };

  const selectAllPatients = () => {
    const newSelectedIds = new Set(filteredPendingPatients.map((p) => p.id));
    setSelectedPatientIds(newSelectedIds);
  };

  const clearAllPatients = () => {
    setSelectedPatientIds(new Set());
  };

  const [signature, setSignature] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Authorize");

  const onSignatureChange = (e) => {
    const v = e.target.value;
    if (v === "") {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }

    setSignature(v);
  };

  const handleAuthorizeClick = async () => {
    setButtonDisabled(true);
    setButtonText("Authorizing...");

    const patientIdsArray = Array.from(selectedPatientIds);

    // ignore error when authorizing
    await dispatch(
      approveReferralAuthorizationThunk({
        token: token,
        site_id: site_id,
        signature: signature,
        patient_ids: patientIdsArray,
      })
    );
    // refresh the list
    await dispatch(fetchPendingEnrollmentPatients({ site_id, token }));
    setShowModal(false);
    setButtonText("Authorize");
    setButtonDisabled(false);
    setSelectedPatientIds(new Set());
  };

  const [showModal, setShowModal] = useState(false);
  const handleApprovalClick = () => {
    setShowModal(true);
  };

  function getTextWidth(text) {
    if (text === "") {
      return "300px";
    } else {
      return "fit-content";
    }
  }
  const getPatientUpdateLink = async (e, patient_id) => {
    try {
      const res = await patientsAPI.getPatientUpdateLink(
        patient_id,
        site_id,
        token
      );
      window.open(res.data.link, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFilteredPatientsCSV = async (e) => {
    setIsDownloading(true);
    ExportEnrollmentPatientListAsCSVFile(
      filteredPendingPatients,
      "enrollment_patients_data.csv"
    );
    setIsDownloading(false);
  };

  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <div className={styles.TableCardContainer}>
        <div className={styles.SelectionActions}>
          <div className={styles.ToggleSelectContainer}>
            <ToggleSelect
              text={`${selectedPatientIds.size} patients selected`}
              enabled={false}
            />
          </div>
          <AthelasButton
            variant="basic"
            text={"Select All"}
            size="medium"
            onClick={selectAllPatients}
          />
          <AthelasButton
            variant="basic"
            text={"Clear All"}
            size="medium"
            onClick={clearAllPatients}
          />
        </div>

        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height - 100}
              headerHeight={60}
              rowHeight={60}
              rowCount={filteredPendingPatients.length}
              onRowMouseOver={({ _, index }) => {
                setCurrentPatientRow(index);
              }}
              rowGetter={({ index }) => filteredPendingPatients[index]}
              rowClassName={({ index }) =>
                index !== -1 ? styles.TableRow : styles.HeaderRow
              }
            >
              <Column dataKey="" cellRenderer={referCheckboxCell} width={25} />
              <Column
                dataKey="edit_icon"
                width={50}
                cellRenderer={({ rowData, rowIndex }) => {
                  return (
                    <>
                      {rowIndex === currentPatientRow ? (
                        <IconButton>
                          <EditPatientIcon
                            style={{ width: 23, height: 23 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              getPatientUpdateLink(e, rowData["id"]);
                            }}
                          />
                        </IconButton>
                      ) : null}
                    </>
                  );
                }}
              />
              <Column
                label="Patient Name"
                dataKey="patient_name"
                width={((width - 20) / 12) * 2}
              />
              <Column
                label="DOB"
                dataKey="date_of_birth"
                width={((width - 20) / 12) * 1.5}
              />
              <Column
                label="Insurance Name"
                dataKey="insurance_name"
                width={((width - 20) / 12) * 2.5}
              />
              <Column
                label="Devices"
                dataKey="devices"
                width={((width - 20) / 12) * 2}
              />
              <Column
                label="ICD10 code"
                dataKey="icd10_code"
                width={((width - 10) / 12) * 1.5}
              />
              <Column
                label="Date Prescribed"
                dataKey="date_prescribed"
                width={((width - 20) / 12) * 2}
              />
              <Column
                label="Referral Statement"
                dataKey="referral_statement"
                width={((width - 20) / 12) * 3}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  } else if (status === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Patients"></Header>

      {/* pop up modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>RPM Authorization</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.Modalbody}>
          <p>
            As a Physician or Certified Healthcare professional with the ability
            to order relevant RPM services, I confirm Remote Patient Monitoring
            orders for the following patients with the following devices
            pursuant to the current Athelas{" "}
            <a
              href={
                "https://assets.website-files.com/606afb6ff04916f7d332ee53/61283a1b68ba9d99e66b61b5_Athelas%20Terms%20of%20Agreement%20(Effective%208-26-21).pdf"
              }
              target="_blank"
              rel="noreferrer"
            >
              Terms of Agreement
            </a>
            . These patients have relevant conditions that necessitate
            monitoring.
          </p>
          <p>
            By clicking ‘Authorize’, I authorize Athelas to provide remote
            monitoring services on my behalf in a manner consistent with the
            clinical protocol set forth{" "}
            <a
              href={clinical_protocol_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . I further understand that I may, in my sole professional judgment,
            change or substitute the default clinical protocol by notifying
            Athelas.
          </p>
          <p>
            Type your name below and click Authorize to enroll/recertify
            selected patients in the RPM program.
          </p>
          {uniquePrescriberNames.length > 1 ? (
            <p>
              <b>
                NOTICE: You are attempting to order/recertify RPM for patients
                of {uniquePrescriberNames.join(", ")}. If you do not intend to
                approve for multiple doctors, please hit “cancel.” If you
                intended to approve for multiple doctors, please click
                "Authorize" to acknowledge and attest that the listed doctors
                have ordered care management outside of the Athelas system, to
                be administered by Athelas, for all of the listed patients.
              </b>
            </p>
          ) : null}
          <Form.Control
            type="text"
            value={signature}
            placeholder="Type full name here to sign"
            onChange={onSignatureChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            size="lg"
            block
            disabled={buttonDisabled}
            onClick={handleAuthorizeClick}
          >
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* main content */}
      <div className={styles.EnrollmentContainer}>
        <div className={styles.ActionSection}>
          <div className={styles.SearchSection}>
            <SearchSvg className={styles.SearchIcon} />
            <Form.Control
              placeholder="Search patient by name"
              bsPrefix={styles.SearchBox}
              value={searchTerm}
              onChange={onSearchTermChanged}
            />
          </div>
          <div className={styles.ApproveSection}>
            <AthelasButton
              text="Approve Patients"
              variant="success"
              onClick={handleApprovalClick}
              disabled={selectedPatientIds.size == 0}
            />
            <AthelasButton
              variant="basic"
              text={isDownloading ? "Downloading..." : "Download as CSV"}
              size="medium"
              disabled={isDownloading}
              // className={styles.ReferralButton}
              onClick={downloadFilteredPatientsCSV}
            />
          </div>
        </div>
        <div>
          <Form inline style={{ width: "800px" }}>
            <Form.Group className="select-form-group">
              <Form.Control
                as="select"
                bsPrefix={
                  insuranceNameSelected === ""
                    ? "SelectPlaceHolder"
                    : "SelectInput"
                }
                id="insuranceNameSelected"
                value={insuranceNameSelected}
                onChange={onInsuranceNameChanges}
                style={{
                  width: getTextWidth(insuranceNameSelected),
                  paddingRight: "20px",
                }}
              >
                <option value="" key="emptySelect" selected>
                  Filter by Insurance
                </option>
                {insuranceNameOptions}
              </Form.Control>
            </Form.Group>
            <Form.Group className="select-form-group">
              <Form.Control
                as="select"
                bsPrefix={
                  prescriberSelected === ""
                    ? "SelectPlaceHolder"
                    : "SelectInput"
                }
                id="prescriberSelect"
                value={prescriberSelected}
                onChange={onPrescriberSelectedChanged}
                style={{ width: "210px" }}
              >
                <option value="" key="emptySelect" selected>
                  Filter by Prescriber
                </option>
                {prescriberOptions}
              </Form.Control>
            </Form.Group>

            <Form.Group className="select-form-group">
              <Form.Control
                as="select"
                bsPrefix={
                  actionSelected === "" ? "SelectPlaceHolder" : "SelectInput"
                }
                id="actionSelect"
                value={actionSelected}
                onChange={onActionSelectedChanged}
                style={{ width: "210px" }}
              >
                <option value="" key="emptySelect" selected>
                  Filter by Action
                </option>
                <option value="Initial Enrollment" key="initial_enrollment">
                  Initial Enrollment
                </option>
                <option value="Recertify" key="recertify">
                  Recertify
                </option>
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        {content}
      </div>
    </>
  );
};
