import Calendar from "react-calendar";
import "./Calendar.css";
import moment from "moment";

export type PropsType = {
  dates: any;
};

export const AtomCalendar = (props: PropsType) => {
  const mark = props.dates || [];

  return (
    <Calendar
      onChange={() => {}}
      tileClassName={({ date, view }: any) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        if (mark.find((x: any) => x === formattedDate)) {
          return "highlight";
        }
      }}
    />
  );
};
