import { Header } from "../header/Header";
import styles from "../medications/ComingSoon.module.css";
import Loader from "react-spinners/RingLoader";

const ComingSoon = () => {
  return (
    <>
      <Header title="Medications"></Header>
      <div className={styles.Body}>
        <h2>Coming soon!</h2>
        <h3>A dashboard to track all your patients' medication adherence</h3>
        <Loader color="#fc5378" size={70} speedMultiplier={1} />
      </div>
    </>
  );
};

export default ComingSoon;
