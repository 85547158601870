import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";

import "./cardiacCareSummary.css";
import { LineChart, AthelasChat } from "../../app/Atoms";
import moment from "moment";
import { fetchCardiacCarePatientSummary } from "../patients/patientsSlice";

type PropsType = {
  patientId: number;
};

export function CardiacCarePatientsSummary(props: PropsType) {
  const [fetchedPatientSummary, setFetchedPatientSummary] = useState(false);
  const token = useSelector((state: any) => state.user.token);
  const cardiacCareSummaries = useSelector(
    (state: any) => state.patients.cardiacCareSummary
  );
  const cardiacCareSummaryStatus = useSelector(
    (state: any) => state.patients.cardiacCareStatus
  );
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const cardiacCareSummary = cardiacCareSummaries[patientId];

  if (!fetchedPatientSummary && !cardiacCareSummary) {
    dispatch(fetchCardiacCarePatientSummary({ patientId, token }));
    setFetchedPatientSummary(true);
  }

  if (cardiacCareSummaryStatus !== "fulfilled") {
    return (
      <div className="cardiac-care-result-container" style={{ padding: 24 }}>
        <div style={{ margin: "0% auto", width: 70, height: 70 }}>
          <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
        </div>
      </div>
    );
  }

  const conversation = cardiacCareSummary["conversation"].map(
    (conversation: any) => conversation
  );
  conversation.reverse();
  const onboarding = cardiacCareSummary["onboarding"];
  const results = cardiacCareSummary["results"];

  const dates = results.map((result: any) =>
    moment(result[0]).format("MM/DD/YYYY")
  );
  const weights = results.map((result: any) => Math.round(result[1] * 10) / 10);

  return (
    <div className="flex-box-container">
      <div className="left-container">
        <div className="cardiac-care-result-container">
          <h2>Weight Measurements</h2>
          <p className="explanation">Your patient's weight measurements</p>
          <hr></hr>
          <LineChart
            categories={dates}
            series={weights}
            tickAmount={6}
            yTitle={"Weight"}
            xTitle={"Date"}
          />
        </div>
        <div className="cardiac-care-result-container">
          <h2>Onboarding Responses</h2>
          <p className="explanation">
            These are the responses your patient put in when enrolling for the
            Total Care program.
          </p>

          <div className="flex-box">
            <div className="stat-container">
              <p className="cardiac-care-label">Start Weight</p>
              <p className="cardiac-care-statistic">
                {onboarding?.start_weight} lb
              </p>
            </div>
            <div className="stat-container">
              <p className="cardiac-care-label">Goal Weight</p>
              <p className="cardiac-care-statistic">
                {onboarding?.goal_weight} lb
              </p>
            </div>
            <div className="stat-container">
              <p className="cardiac-care-label">Height</p>
              <p className="cardiac-care-statistic">
                {onboarding?.height} inches
              </p>
            </div>
          </div>

          <div style={{ paddingTop: "16px", textAlign: "center" }}>
            <p className="cardiac-care-label">Dietary Info</p>
            <p className="cardiac-care-statistic">{onboarding?.dietary_info}</p>
          </div>

          <div style={{ paddingTop: "16px", textAlign: "center" }}>
            <p className="cardiac-care-label">Exercise Info</p>
            <p className="cardiac-care-statistic">
              {onboarding?.exercise_info}
            </p>
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="cardiac-care-result-container">
          <h2>Nutritionist Transcript</h2>
          <p className="explanation">
            Patients-nutritionist conversation transcript ordered from newest to
            oldest messages.
          </p>
          <hr></hr>
          <div style={{ overflowY: "scroll", maxHeight: "800px" }}>
            <AthelasChat conversation={conversation} sender="patient" />
          </div>
        </div>
      </div>
    </div>
  );
}
