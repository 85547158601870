import axios from "axios";

const enrollmentAPI = {
  approveReferralAuthorizations: (token, site_id, signature, patient_ids) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/referral_authorizations`,
      {
        site_id,
        signature,
        patient_ids,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPendingEnrollmentPatients: (token, site_id) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/enrollment/pending_patients`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPendingEnrollmentPatientsInsuranceNames: (token, site_id) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/enrollment/patient_insurance_names`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default enrollmentAPI;
