import { ChatBubble } from "./ChatBubble";

type PropsType = {
  conversation: Array<{ person: string; text: string }>;
  sender: string;
};
export function AthelasChat(props: PropsType) {
  return (
    <>
      {props.conversation.map(({ person, text }) => {
        if (person === props.sender) {
          return (
            <div key={person + text + "div"}>
              <ChatBubble
                key={person + text}
                text={text}
                backgroundColor={"#03a9fc"}
                color={"white"}
                align="left"
                sender={person}
              />
            </div>
          );
        } else {
          return (
            <div key={person + text + "div"}>
              <ChatBubble
                key={person + text}
                text={text}
                backgroundColor={"#ebebeb"}
                color={"black"}
                align="right"
                sender={person}
              />
            </div>
          );
        }
      })}
    </>
  );
}
