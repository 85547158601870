import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column, Table, AutoSizer } from "react-virtualized";

import { Header } from "../header/Header";
import {
  fetchCardiacCarePatients,
  fetchPatients,
} from "../patients/patientsSlice";
import { statusThunk } from "../user/userSlice";
import { trackEvent } from "../../utils/tracking";
import Form from "react-bootstrap/Form";
import styles from "../patients/PatientsList.module.css";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ReactComponent as SearchSvg } from "../patients/SearchIcon.svg";
import moment from "moment";

export const CardiacCareList = () => {
  const sites = useSelector((state: any) => state.user.sites);
  const site_id = useSelector((state: any) => state.user.currSiteId);
  const token = useSelector((state: any) => state.user.token);
  const status = useSelector((state: any) => state.patients.status);
  const error = useSelector((state: any) => state.patients.error);
  const cardiacCarePatients = useSelector(
    (state: any) => state.patients.cardiacCarePatientsList
  );
  const cardiacCarePatientsStatus = useSelector(
    (state: any) => state.patients.cardiacCarePatientsListStatus
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");

  var [fetchedCardiacCarePatients, setFetchedCardiacCarePatients] = useState(
    false
  );

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchPatients({ site_id, token }));
      trackEvent("view", "viewed_cardiac_care");
    }
  });

  if (!fetchedCardiacCarePatients && !cardiacCarePatients) {
    dispatch(fetchCardiacCarePatients({ site_id, token }));
    setFetchedCardiacCarePatients(true);
  }

  const onSearchTermChanged = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const patientFilterFunc = (patient: any) => {
    if (searchTerm !== "") {
      const f = patient.first_name.toLowerCase();
      const l = patient.last_name.toLowerCase();
      const s = searchTerm.toLowerCase();

      if (!(f.includes(s) || l.includes(s))) {
        return false;
      }
    }

    return true;
  };

  const filteredPatients = cardiacCarePatients?.filter(patientFilterFunc) || [];

  function WeightRenderer({ cellData }) {
    return <div>{cellData} lbs</div>;
  }

  function DeltaRenderer({ cellData }) {
    return (
      <>
        <div className={styles.OnboardingStatusText + " " + styles[cellData]}>
          {Math.round((cellData - 1) * 1000) / 10}%
        </div>
      </>
    );
  }

  function ResultDate({ cellData }) {
    if (cellData && cellData !== "No Result") {
      return <div>{moment(cellData).format("MMM DD, YYYY")}</div>;
    } else {
      return cellData;
    }
  }

  let content;

  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <div className={styles.TableCardContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={60}
              rowHeight={68.35}
              rowCount={filteredPatients.length}
              onRowClick={({ rowData }) =>
                history.push("/patients/" + rowData.patient_id)
              }
              rowGetter={({ index }) => filteredPatients[index]}
              rowClassName={({ index }) =>
                index !== -1 ? styles.TableRow : styles.HeaderRow
              }
            >
              <Column
                label="First name"
                dataKey="first_name"
                width={(width - 100) / 7}
              />
              <Column
                label="Last name"
                dataKey="last_name"
                width={(width - 100) / 7}
              />
              <Column
                label="Start Weight"
                dataKey="first_weight"
                width={(width - 100) / 7}
                cellRenderer={WeightRenderer}
              />
              <Column
                label="Current Weight"
                dataKey="last_weight"
                width={(width - 100) / 7}
                cellRenderer={WeightRenderer}
              />
              <Column
                label="Goal"
                dataKey="goal_weight"
                width={(width - 100) / 7}
                cellRenderer={WeightRenderer}
              />
              <Column
                label="% Δ all-time"
                dataKey="delta"
                width={(width - 100) / 7}
                cellRenderer={DeltaRenderer}
              />
              <Column
                label={"Last Result Date"}
                dataKey="last_date"
                className={styles.LastResultCell}
                width={(width - 100) / 7}
                cellRenderer={ResultDate}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  } else if (status === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Total Care Program"></Header>
      <div className={styles.PatientsTableContainer}>
        <div className={styles.SearchSection}>
          <SearchSvg className={styles.SearchIcon} />
          <Form.Control
            placeholder="Search patients enrolled in Total Care by name"
            bsPrefix={styles.SearchBox}
            value={searchTerm}
            onChange={onSearchTermChanged}
          />
        </div>
        {cardiacCarePatientsStatus === "fulfilled" ? (
          content
        ) : (
          <div className={styles.SummaryCard}>
            <div style={{ margin: "5% auto" }}>
              <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
