export const PAGE_SIZE = 100;

export const TEST_TYPE_NAMES = {
  GLUCOSE: "Glucose",
  BLOOD_PRESSURE: "Blood Pressure",
  WEIGHT: "Weight",
  HEART_RATE: "Heart Rate",
  CARDIAC_CARE: "Cardiac Care",
  PULSE_OXIMETRY: "Pulse Oximetry",
  UNKNOWN: "Unknown",
};
