export var INSURANCE_LIST = [
  "MISSISSIPPI BLUE SHIELD",
  "NEW YORK BLUE SHIELD OF ROCHESTER",
  "1199 NATIONAL BENEFIT",
  "1199 NATIONAL BENEFIT FUND",
  "1199 NATIONAL BENEFIT FUNDS",
  "2936 E GARNET CIR",
  "AAG GALLAGHER BENEFITS",
  "AARP",
  "AARP MCR COMPLETE",
  "AARP MEDICARE",
  "AARP MEDICARE COMPLETE",
  "AARP MEDICARE SUPPLEMENT PLANS",
  "ABSOLUTE TOTAL CARE",
  "ACCESS BEHAVIORAL CARE",
  "ACCESS MEDICAL GROUP",
  "ADMINISTRATIVE CONCEPTS",
  "ADMINISTRATIVE SERVICES INC.",
  "ADVANTAGE BY BUCKEYE",
  "ADVANTAGE U MEDICARE",
  "ADVANZEON SOLUTIONS",
  "ADVENTIST HEALTH PLAN",
  "ADVENTIST HEALTHCARE",
  "ADVOCATE HEALTH CENTERS",
  "ADVOCATE HEALTH PARTNERS",
  "ADVOCATE LUTHERAN GENERAL PARTNERS",
  "AETNA",
  "AETNA (ALL AETNA PLANS)",
  "AETNA (MEDICARE REPLACEMENT/ADVANTAGE - PPO)",
  "AETNA (PPO)",
  "AETNA - CHOICE POS II",
  "AETNA - ELECT CHOICE (EC)",
  "AETNA - EPO",
  "AETNA - GOLDEN CHOICE (MEDICARE ADVANTAGE PLAN)",
  "AETNA - GOLDEN MEDICARE (MEDICARE ADVANTAGE PLAN)",
  "AETNA - HMO",
  "AETNA - INDEMNITY",
  "AETNA - MANAGED CHOICE (MC)",
  "AETNA - OPEN ACCESS HMO",
  "AETNA - OPEN CHOICE (OC)",
  "AETNA - PPO",
  "AETNA - QPOS",
  "AETNA - TRADITIONAL CHOICE (TC)",
  "AETNA ASA/GEHA",
  "AETNA BETTER HEALTH - IL MEDICAID",
  "AETNA BETTER HEALTH KIDS",
  "AETNA BETTER HEALTH NEW JERSEY",
  "AETNA BETTER HEALTH OF CALIFORNIA",
  "AETNA BETTER HEALTH OF FLORIDA",
  "AETNA BETTER HEALTH OF ILLINOIS",
  "AETNA BETTER HEALTH OF ILLINOIS - MMAI",
  "AETNA BETTER HEALTH OF KENTUCKEY",
  "AETNA BETTER HEALTH OF KENTUCKY",
  "AETNA BETTER HEALTH OF LOUISIANA",
  "AETNA BETTER HEALTH OF MARYLAND",
  "AETNA BETTER HEALTH OF MIGHIGAN",
  "AETNA BETTER HEALTH OF NEW JERSEY",
  "AETNA BETTER HEALTH OF VIRGINIA",
  "AETNA BETTER HEALTH PLAN",
  "AETNA CHOICE POS11",
  "AETNA GOLDEN MEDICARE PLAN",
  "AETNA HEALTHCARE",
  "AETNA LIFE AND CASUALTY",
  "AETNA MEDICARE ADVANTAGE PLAN",
  "AETNA/AETNA PPO/POS",
  "AFFILIATED DOCTORS OF ORANGE COUNTY (ADOC)",
  "AFFINITY HEALTH PLAN",
  "AKAMAI ADVANTAGE (MEDICARE ADVANTAGE PLAN)",
  "ALABAMA BLUE SHIELD",
  "ALABAMA MEDICAID",
  "ALABAMA MEDICARE",
  "ALAMEDA ALLIANCE",
  "ALAMEDA ALLIANCE COMPLETE CARE",
  "ALAMEDA ALLIANCE FOR HEALTH",
  "ALASKA MEDICARE",
  "ALIGNMENT HEALTH CARE",
  "ALL SAVERS",
  "ALL SAVERS ALTERNATE FUNDING",
  "ALL SAVERS FUNDING",
  "ALL SAVERS INSURANCE",
  "ALL SAVERS PLANS",
  "ALLEGIANCE BENEFIT PLAN",
  "ALLEGIANCE BENEFIT PLAN MANAGEMENT",
  "ALLEGIANCE BENEFIT PLAN MANAGEMENT INC.",
  "ALLEGIANCE HEALTH MANAGEMENT",
  "ALLIANT HEALTH PLANS OF GEORGIA",
  "ALLIED BENEFIT SYSTEM",
  "ALLIED BENEFIT SYSTEMS",
  "ALLIED PHYSICIANS MEDICAL GROUP",
  "ALLWAYS HEALTH PARTNERS",
  "ALLWELL FROM ABSOLUTE TOTAL CARE",
  "ALLWELL FROM ARKANSAS HEALTH AND WELLNESS",
  "ALLWELL FROM BUCKEYE HEALTH PLAN",
  "ALLWELL FROM HEALTH NET",
  "ALLWELL FROM LOUISIANA HEALTHCARE CONNECTIONS",
  "ALLWELL FROM MANAGED HEALTH SERVICES HEALTH WI",
  "ALLWELL FROM PA HEALTH AND WELLNESS",
  "ALLWELL FROM PEACH STATE HEALTH PLAN",
  "ALLWELL FROM SUNFLOWER HEALTH PLAN",
  "ALLWELL FROM SUPERIOR HEALTHPLAN",
  "ALLWELL FROM WESTERN SKY COMMUNITY CARE",
  "ALTIUS",
  "AM BETTER SUPERIOR HEALTH PLAN",
  "AMBETTER",
  "AMBETTER FROM BUCKEYE COMMUNITY HEALTH PLAN",
  "AMBETTER FROM HEALTH NET",
  "AMBETTER FROM MAGNOLIA HEALTH",
  "AMBETTER FROM MANAGED HEALTH SERVICES",
  "AMBETTER FROM MERIDIAN",
  "AMBETTER FROM PEACH STATE HEALTH PLAN",
  "AMBETTER FROM PENNSYLVANIA HEALTH AND WELLNESS",
  "AMBETTER FROM SUNSHINE HEALTH",
  "AMBETTER FROM SUPERIOR HEALT",
  "AMBETTER FROM SUPERIOR HEALTH",
  "AMBETTER ILLINICARE HEALTH",
  "AMBETTER NEW HAMPSHIRE HEALTHY FAMILIES",
  "AMBETTER OF ARKANSAS",
  "AMBETTER OF SUPERIOR HEALTH",
  "AMBETTER OF TENNESSEE",
  "AMERIBEN SOLUTIONS, INC.",
  "AMERICAN POSTAL WORKERS UNION",
  "AMERICAN PUBLIC LIFE (APL)",
  "AMERICAN SPECIALTY HEALTH",
  "AMERICHOICE OF NJ (MEDICAID NJ)",
  "AMERIGROUP",
  "AMERIGROUP COMMUNITY CARE",
  "AMERIGROUP CORPORATION",
  "AMERIGROUP CORPORATION (FT WORTH)",
  "AMERIGROUP CORPORATION (HOUSTON)",
  "AMERIGROUP IL",
  "AMERIGROUP NETWORK",
  "AMERIGROUP NJ",
  "AMERIGROUP OF KANSAS",
  "AMERIHEALTH ADMINISTRATORS, INC.",
  "AMERIHEALTH CARITAS DISTRICT OF COLUMBIA",
  "AMERIHEALTH CARITAS LOUISIANA",
  "AMERIHEALTH CARITAS NEW HAMPSHIRE",
  "AMERIHEALTH CARITAS PA CHC",
  "AMERIHEALTH CARITAS PENNSYLVANIA",
  "AMERIHEALTH CARITAS VIP CARE PLUS MICHIGAN",
  "AMERIHEALTH NEW JERSEY/DELAWARE - HMO",
  "AMERIVANTAGE (AMERIGROUP)",
  "ANTHEM - CENTRAL, NORTHERN, SOUTHERN",
  "ANTHEM BC PARTNERSHIP",
  "ANTHEM BLUE CROSS",
  "ANTHEM BLUE CROSS - CALIFORNIA",
  "ANTHEM BLUE CROSS - CALIFORNIA - FEP",
  "ANTHEM BLUE CROSS AND BLUE SHIELD - KENTUCKY",
  "ANTHEM BLUE CROSS BLUE SHIELD",
  "ANTHEM BLUE CROSS CALIFORNIA",
  "ANTHEM BLUE CROSS(BC) OF CALIFORNIA (CA)",
  "ANTHEM BLUE PREFERRED",
  "ANTHEM BLUE PREFERRED PLUS",
  "ANTHEM HEALTHKEEPERS",
  "ANTHEM HEALTHKEEPERS PLUS",
  "APPLE HEALTH AKA WASHINGTON MEDICAID",
  "APPLECARE MEDICAL GROUP",
  "APT 20E",
  "APWU 62308",
  "APWU HEALTH PLAN (ALL STATES EXCEPT USVI)",
  "ARCHBOLD ED UNINSURED",
  "ARIZONA BLUE SHIELD",
  "ARIZONA BLUE SHIELD FEP",
  "ARIZONA COMPLETE HEALTH",
  "ARIZONA COMPLETE HEALTH - DOS AFTER 10/1/18",
  "ARIZONA MEDICAID",
  "ARIZONA MEDICARE",
  "ARIZONA PHYSICIANS IPA",
  "ARKANSAS BLUE SHIELD",
  "ARKANSAS MEDICAID",
  "ARKANSAS MEDICARE",
  "ASCENSION COMPLETE",
  "ASPIRUS MEDICARE ADVANTAGE",
  "ASR PHYSICIANS CARE",
  "ASSURANT HEALTH SELF FUNDED",
  "ASURIS NORTHWEST",
  "ASURIS NORTHWEST REGENCE",
  "AULTRA ADMINISTRATIVE SERVICES",
  "AVALON - CAPITAL BLUE CROSS",
  "AVERA HEALTH PLANS",
  "AVMED, INC.",
  "BANNER MEDICARE ADVANTAGE PLUS PPO (BMAP)",
  "BANNER MEDICARE ADVANTAGE PRIME HMO (BMAH)",
  "BANNER UNIVERSITY FAMILY CARE (UNIVER OF AZ HLTH)",
  "BAYCARE PLUS MEDICARE ADVANTAGE",
  "BCBS",
  "BCBS COMPLETE",
  "BCBS FEDERAL",
  "BCBS ILLINOIS",
  "BCBS MICHIGAN MEDICARE PLUS BLUE PPO - JVHL",
  "BCBS OF MD (CAREFIRST)",
  "BCBS OF NEBRASKA",
  "BCBS-CA BLUE SHIELD OF CALIFORNIA (PPO)",
  "BCBS-PA INDEPENDENCE BLUE CROSS - PERSONAL CHOICE (PPO)",
  "BEACON HEALTH",
  "BEACON HEALTH OPTIONS FKA VALUE OPTIONS",
  "BEACON HEALTH STRATEGIES",
  "BEHAVIORAL HEALTH SYSTEMS",
  "BEHAVIORAL HEALTHCARE",
  "BENEFIT PLANNERS, INC.",
  "BIND",
  "BLUE BENEFIT ADMINISTRATORS OF MA",
  "BLUE BENEFIT ADMINISTRATORS OF MASSACHUSETTS",
  "BLUE CARE NETWORK",
  "BLUE CARE NETWORK HMO",
  "BLUE CHIP",
  "BLUE CROSS AND BLUE SHIELD OF TEXAS",
  "BLUE CROSS BLUE SHIELD",
  "BLUE CROSS BLUE SHIELD ARIZONA ADVANTAGE",
  "BLUE CROSS BLUE SHIELD OF GEORGIA",
  "BLUE CROSS BLUE SHIELD OF MASSACHUSETTS",
  "BLUE CROSS BLUE SHIELD OF TEXAS",
  "BLUE CROSS BLUE SHIELD OF TEXAS MEDICAID",
  "BLUE CROSS COMMUNITY FAMILY HEALTH PLAN",
  "BLUE CROSS COMMUNITY HEALTH PLANS",
  "BLUE CROSS COMPLETE OF MI (MIBCN)",
  "BLUE CROSS COMPLETE OF MICHIGAN",
  "BLUE CROSS MEDICARE ADVANTAGE",
  "BLUE CROSS MEDICARE ADVANTAGE PPO/HMO",
  "BLUE CROSS OF ID",
  "BLUE CROSS OF IDAHO",
  "BLUE CROSS WA/AK PREMERA BLUE CROSS",
  "BLUE CROSS/BLX SR HMO ST VINCENT IPA",
  "BLUE FOCUS POS",
  "BLUE MEDICARE ADVANTAGE",
  "BLUE PREFERRED PPO",
  "BLUE SHIELD - CALIFORNIA / BLUE SHIELD (FEP)",
  "BLUE SHIELD - CALIFORNIA / BLUE SHIELD(FEP)",
  "BLUE SHIELD CA",
  "BLUE SHIELD OF CA",
  "BLUE SHIELD OF CALIFORNIA",
  "BLUE SHIELD OF CALIFORNIA PROMISE HEALTH PLAN",
  "BLUE SHIELD OF NATIONAL CAPITOL AREA - CAREFIRST",
  "BLUE SHIELD/BLS HMO ST VINCENT IPA",
  "BLUE SHIELD/BLS PPO/EPO",
  "BLUECARE - BCBSTN",
  "BLUECHOICE SOUTH CAROLINA MEDICAID",
  "BLUECROSS BLUESHIELD AZ - BCBS FEDERAL",
  "BMC HEALTHNET PLAN",
  "BOON CHAPMAN ADMIN",
  "BOON CHAPMAN ADMINISTRATORS",
  "BOON GROUP (THE)",
  "BOON- CHAPMAN",
  "BOSTON MEDICAL CENTER HEALTH PLAN",
  "BOSTON MEDICAL CENTER HEALTHNET PLAN",
  "BRAND NEW DAY",
  "BRAND NEW DAY - EASY ACCESS IPA",
  "BRAVEN",
  "BRAVEN HEALTH",
  "BRENDA NELSON",
  "BRIGHT HEALTH",
  "BRIGHT HEALTH MEDICARE ADVANTAGE DOS AFTER 123121",
  "BRIGHT HEALTH PLAN",
  "BROWN & TOLAND MEDICAL GROUP",
  "BROWN AND TOLAND MEDICAL GROUP",
  "BRYAN INDEPENDENT SCHOOL",
  "BUCKEYE COMMUNITY HEALTH - OHIO",
  "CALIFORNIA ANTHEM BLUE CROSS",
  "CALIFORNIA BLUE CROSS",
  "CALIFORNIA BLUE SHIELD",
  "CALIFORNIA BLUE SHIELD - HMO ENCOUNTERS",
  "CALIFORNIA CHILDRENS SERVICES",
  "CALIFORNIA IPA",
  "CALIFORNIA MEDI-CAL",
  "CANOPY HEALTH",
  "CAPITAL BLUE CROSS",
  "CAPITAL BLUE CROSS - FACILITY CONTRACTED PROVIDERS",
  "CAPITAL DISTRICT PHYSICIAN'S HEALTH PLAN",
  "CAPITAL DISTRICT PHYSICIANS HEALTH PLAN (CDPHP)",
  "CARE 1ST HEALTH PLAN OF ARIZONA",
  "CARE 1ST PARTNER PLAN LLC",
  "CARE AND CARE",
  "CARE CHOICES HMO/PREFERRED CHOICES OF MICHIGAN PPO",
  "CARE IMPROVEMENT PLUS",
  "CARE IMPROVMENT PLUS",
  "CARE N CARE INSURANCE CO",
  "CARE NETWORK",
  "CARECENTRIX FLORIDA BLUE",
  "CARECORE OXFORD RADIOLOGY",
  "CAREMORE HEALTH PLAN",
  "CAREPLUS HEALTH PLANS",
  "CAREPLUS HEALTH PLANS, INC.",
  "CARESOURCE ADVANTAGE",
  "CARESOURCE OF GEORGIA",
  "CARESOURCE OF IN",
  "CARESOURCE OF INDIANA",
  "CARESOURCE OF KENTUCKY",
  "CARESOURCE OF OHIO",
  "CARESOURCE OF WEST VIRGINIA",
  "CARESOURCE THE COMMUNITY TRANSITION PLAN",
  "CAROLINA COMPLETE HEALTH CARE",
  "CARPENTERS HEALTH AND WELFARE FUND",
  "CDPHP",
  "CENPATICO BEHAVIORAL HEALTH - AZ DOS BEFORE 10/18",
  "CENPATICO BEHAVIORAL HEALTH - FLORIDA",
  "CENPATICO BEHAVIORAL HEALTH - KANSAS",
  "CENPATICO BEHAVIORAL HEALTH - MISSISSIPPI",
  "CENPATICO BEHAVIORAL HEALTH - NEW HAMPSHIRE",
  "CENPATICO BEHAVIORAL HEALTH - OHIO",
  "CENPATICO BEHAVIORAL HEALTH - WISCONSIN",
  "CENTRAL RESERVE LIFE INS CO (MEDICARE SUPPLEMENT)",
  "CENTRAL STATES HEALTH & WELFARE FUNDS",
  "CGLIC, MILWAUKEE",
  "CHAMP VA",
  "CHAMPUS VA",
  "CHAMPVA",
  "CHAMPVA - HAC",
  "CHAMPVA-HAC",
  "CHESAPEAKE LIFE INSURANCE CO-STUDENT INSURANCE",
  "CHRISTIAN BROTHERS SERVICES",
  "CIGNA ENCOUNTERS",
  "CIGNA HEALTH PLAN - CALIFORNIA",
  "CIGNA HEALTH PLAN - CHARLOTTE",
  "CIGNA HEALTH PLAN - CHICAGO",
  "CIGNA HEALTH PLAN - CIGNA PREMIER PLUS",
  "CIGNA HEALTH PLAN - CLEVELAND",
  "CIGNA HEALTH PLAN - CONNECTICUT",
  "CIGNA HEALTH PLAN - DALLAS",
  "CIGNA HEALTH PLAN - DENVER",
  "CIGNA HEALTH PLAN - GEORGIA",
  "CIGNA HEALTH PLAN - MASSACHUSETTS",
  "CIGNA HEALTH PLAN - MEMPHIS",
  "CIGNA HEALTH PLAN - NEW YORK",
  "CIGNA HEALTH PLAN - NORTHERN NEW JERSEY",
  "CIGNA HEALTH PLAN - ORLANDO",
  "CIGNA HEALTH PLAN - PENNSYLVANIA",
  "CIGNA HEALTH PLAN - PHOENIX",
  "CIGNA HEALTH PLAN - SALT LAKE CITY",
  "CIGNA HEALTH PLAN - ST LOUIS",
  "CIGNA HEALTH PLANS",
  "CIGNA HEALTHSPRING BRAVO HEALTH TEXAS",
  "CIGNA HEALTHSPRING BRAVO OF TN, AL",
  "CIGNA HEALTHSPRING MARYLAND/PENNSYLNAVIA",
  "CIGNA HEALTHSPRING STAR+PLUS",
  "CIGNA HEALTHSPRING TENNESSEE ALABAMA",
  "CIGNA MEDICARE",
  "CIGNA MEDICARE ACCESS (PFFS)",
  "CIGNA MEDICARE SELECT HMO",
  "CIGNA PPO",
  "CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)",
  "CITRUS VALLEY PHYSICIANS GROUP IPA",
  "CITY OF AMARILLO",
  "CLAIMS MANAGEMENT SERVICES, INC.",
  "CLEAR HEALTH ALLIANCE",
  "CLOVER HEALTH",
  "COBRA- MI",
  "COLORADO ACCESS",
  "COLORADO BLUE CROSS",
  "COLORADO BLUE SHIELD",
  "COLORADO MEDICARE",
  "COLUMBIA PACIFIC CCO",
  "COMMONWEALTH CARE ALLIANCE",
  "COMMUNITY CARE IPA",
  "COMMUNITY CARE MANAGED HEALTHCARE PLANS OF OK",
  "COMMUNITY FIRST HEALTH PLAN",
  "COMMUNITY FIRST HEALTH PLANS - COMMUNITY FIRST HEALTH PLANS",
  "COMMUNITY HEALTH CHOICE",
  "COMMUNITY HEALTH GROUP",
  "COMMUNITY HEALTH GROUP OF SD CAP",
  "COMMUNITY HEALTH PLAN OF WA (CHPWA)",
  "COMMUNITY HEALTH PLAN OF WASHINGTON (CHPWA)",
  "COMPCARE (WISCONSIN BADGERCARE ONLY)",
  "CONNECT BLUE EPO",
  "CONNECTED SENIOR CARE ADVANTAGE",
  "CONNECTICARE VIP MEDICARE ADVANTAGE",
  "CONNECTICARE, INC.",
  "CONNECTICUT BLUE SHIELD",
  "CONNECTICUT BLUECARE FAMILY PLAN",
  "CONNECTICUT MEDICAID",
  "CONNECTICUT MEDICARE",
  "CONNECTICUT MEDICARE BLUE(MEDICARE RISK)",
  "CONSOLIDATED BENEFITS INC.",
  "CONTINENTAL GENERAL INS CO (MEDICARE SUPPLEMENT)",
  "CONVERSION PLAN - APWU",
  "COORDINATED CARE",
  "COUNTYCARE HEALTH PLAN",
  "COVENTRY HEALTH CARE FEDERAL",
  "COVENTRY HEALTH CARE OF LOUISIANA, INC",
  "COVENTRY HEALTH CARE OF MISSOURI",
  "COX HEALTH PLAN",
  "CULINARY HEALTH & WELFARE FUND (LAS VEGAS, NV)",
  "DEFINITY HEALTH",
  "DELAWARE BLUE SHIELD - HIGHMARK",
  "DELAWARE MEDICAID",
  "DELAWARE MEDICARE",
  "DELTA HEALTH SYSTEMS",
  "DENVER HEALTH MEDICAL PLAN",
  "DESERET MUTUAL",
  "DESERET MUTUAL BENEFIT ASSOCIATES",
  "DESERET MUTUAL BENEFIT ASSOCIATES (DMBA)",
  "DEVOTED HEALTH",
  "DFW CONNECTED CARE",
  "DISTRICT OF COLUMBIA MEDICAID",
  "DISTRICT OF COLUMBIA METROPOLITAN AREA MEDICARE",
  "DIVA",
  "DRISCOLL CHILDRENS HEALTH PLAN",
  "EBMS HEALTHEWEB",
  "EDUCATORS MUTUAL INSURANCE ASSOCIATION",
  "EHC",
  "ELIZABETH COY",
  "EMBLEM HEALTH/HEALTH INSURANCE PLAN OF GREATER NY",
  "EMBLEMHEALTH",
  "EMBLEMHEALTH INSURANCE COMPANY",
  "EMBLEMHEALTH MANAGED LTC",
  "EMBLEMHEALTH PLAN INC HMO",
  "EMBLEMHEALTH PLAN INC MCR",
  "EMI",
  "EMI HEALTH",
  "EMPIRE BLUE CROSS AND BLUE SHIELD OF NEW YORK",
  "EMPIRE BLUE CROSS BLUE SHIELD",
  "EMPIRE HEALTHCHOICE BC BS OF NY",
  "EMPIRE HEALTHCHOICE HMO NY",
  "EMPLOYEE BENEFIT MANAGEMENT SERV (EBMS)",
  "EMPLOYEE BENEFIT MANAGEMENT SERV (EBMS) HEALTHEWEB",
  "EMPLOYEE BENEFIT SERVICES DBA KEY BENEFIT ADMIN",
  "EMPLOYEE BENEFIT SERVICES OF LOUISIANA, INC. (EBS)",
  "EMPLOYERS LIFE INSURANCE",
  "EMPOWER HEALTHCARE SOLUTIONS",
  "EVOLUTIONS HEALTHCARE SYSTEMS, INC.",
  "FALLON 365",
  "FALLON COMMUNITY HEALTH PLAN",
  "FALLON TOTAL CARE",
  "FARM FAMILY",
  "FEDERAL EMPLOYEE HEALTH BENEFITS PROGRAM (FEP)",
  "FEDERAL EMPLOYEES HEALTH BENEFIT PROGRAM (FEHB)",
  "FIDELIS CARE",
  "FIDELIS CARE AT HOME",
  "FIDELIS CARE OF NEW YORK",
  "FIDELIS MEDICAID ADVANTAGE PLUS",
  "FIRST CHOICE BY SELECT HEALTH OF SOUTH CAROLINA",
  "FIRST CHOICE HEALTH NETWORK",
  "FIRST HEALTH NETWORK",
  "FIRSTCARE",
  "FIRSTCARE CHIP",
  "FLORIDA BLUE MEDICARE",
  "FLORIDA BLUE SHIELD",
  "FLORIDA MEDICAID",
  "FLORIDA MEDICARE",
  "FREEDOM HEALTH",
  "FRIDAY",
  "FRIDAY HEALTH PLANS",
  "FRINGE BENEFIT GROUP",
  "GEHA",
  "GEHA ASA",
  "GEHA HIGH DEDUCTIBLE HEALTH PLAN (HDHP)",
  "GEHA HIGH OPTION",
  "GEHA STANDARD OPTION",
  "GEHA-ASA",
  "GEISINGER HEALTH PLAN",
  "GEORGIA BANKERS",
  "GEORGIA BLUE CHOICE",
  "GEORGIA BLUE SHIELD",
  "GEORGIA MEDICAID",
  "GEORGIA MEDICARE",
  "GIC INDEMNITY PLAN",
  "GOLD COAST HEALTH PLAN",
  "GOLDEN RULE",
  "GOLDEN RULE INSURANCE",
  "GOLDEN RULE INSURANCE COMPANY",
  "GOVERNMENT EMPLOYEE HEALTH ASSOCIATION (GEHA)",
  "GRANITE STATE HEALTH PLAN",
  "GREATER TRI-CITIES IPA",
  "GROUP AND PENSION ADMINISTRATORS",
  "GROUP HEALTH INSURANCE",
  "HAP/AHL/CURANET",
  "HARRINGTON BENEFIT SERVICES, INC.",
  "HARVARD PILGRIM",
  "HARVARD PILGRIM HEALTH",
  "HARVARD PILGRIM HEALTH CARE",
  "HAWAII HMSA",
  "HAWAII MEDICARE",
  "HEALTH ALLIANCE PLAN (CAPITATED CONTRACTS) - JVHL",
  "HEALTH ALLIANCE PLAN OF MICHIGAN",
  "HEALTH CARE LA",
  "HEALTH CARE LA IPA",
  "HEALTH CARE USA",
  "HEALTH CHOICE ARIZONA",
  "HEALTH CHOICE PATHWAY",
  "HEALTH CHOICE UTAH",
  "HEALTH COST SOLUTIONS",
  "HEALTH EXCHANGE (THE) (CERNER CORPORATION)",
  "HEALTH FIRST COLORADO FKA COLORADO MEDICAID",
  "HEALTH FIRST HEALTH PLAN",
  "HEALTH KEEPERS, INC.",
  "HEALTH NET COMMUNITY SOLUTIONS/CALVIVA HEALTH",
  "HEALTH NET OF AZ, CA, OR - DOS >12/31/17",
  "HEALTH NET OF CA, OR",
  "HEALTH NET OF CALIFORNIA - CLAIMS",
  "HEALTH NET OF CALIFORNIA - ENCOUNTERS",
  "HEALTH NET OF CALIFORNIA AND OREGON (CLAIMS)",
  "HEALTH NEW ENGLAND",
  "HEALTH PARTNERS",
  "HEALTH PARTNERS OF PHILADELPHIA",
  "HEALTH PARTNERS OF PHILADELPHIA INC",
  "HEALTH PARTNERS PLANS OF PENNSYLVANIA",
  "HEALTH PARTNERS, PA",
  "HEALTH PLAN (THE)",
  "HEALTH PLAN OF SAN JOAQUIN",
  "HEALTH PLANS, INC.",
  "HEALTH SAFETY NET",
  "HEALTH SHARE OF OREGON CCO - KAISER PERMANENTE NW",
  "HEALTH SOURCE MSO",
  "HEALTHAMERICA ONE",
  "HEALTHCARE MANAGEMENT ADMINISTRATORS",
  "HEALTHCARE MGMT ADMINISTRATORS",
  "HEALTHCARE MGMT ADMINISTRATORS - WESTERN REGION",
  "HEALTHCARE PARTNERS IPA HERITAGE NY MEDICAL GROUP",
  "HEALTHCHOICE OKLAHOMA",
  "HEALTHEASE KIDS FLORIDA",
  "HEALTHFIRST FAMILY HEALTH PLUS (FHP)",
  "HEALTHFIRST HEALTH PLAN OF NEW JJERSEY",
  "HEALTHFIRST NEW YORK",
  "HEALTHFIRST NEW YORK (COMMERCIAL)",
  "HEALTHFIRST OF NEW YORK",
  "HEALTHFIRST PHSP",
  "HEALTHFIRST, INC. (NEW YORK)",
  "HEALTHKEEPERS INC.",
  "HEALTHLINK HMO",
  "HEALTHNOW NEW YORK BLUE SHIELD",
  "HEALTHPARTNERS OF MINNESOTA",
  "HEALTHPARTNERS UNITYPOINT HEALTH",
  "HEALTHSCOPE",
  "HEALTHSCOPE BENEFITS",
  "HEALTHSCOPE BENEFITS, INC.",
  "HEALTHSMART BENEFIT SOLUTIONS",
  "HEALTHSPRING INC.",
  "HEALTHSPRING OF ALABAMA",
  "HEALTHTEAM ADVANTAGE",
  "HEALTHY ADVANTAGE",
  "HEALTHY BLUE DUAL ADVANTAGE LOUISIANA",
  "HEALTHY BLUE LOUISIANA",
  "HEALTHY BLUE MISSOURI",
  "HEALTHY BLUE NEBRASKA",
  "HEALTHY BLUE NORTH CAROLINA",
  "HEATLHCARE PARTNERS, IPA",
  "HIGHMARK BCBS DELAWARE HEALTH OPTIONS",
  "HILL PHYSICIANS BLUE CROSS PPO",
  "HILL PHYSICIANS BLUE SHIELD PPO",
  "HILL PHYSICIANS CIGNA PPO",
  "HILL PHYSICIANS HEALTH NET PPO",
  "HILL PHYSICIANS MEDICAL GROUP",
  "HILL PHYSICIANS UNITED HEALTHCARE PPO",
  "HIP-NY STATE",
  "HISPANIC PHYSICIANS IPA",
  "HMA",
  "HMA - HEALTHCARE MANAGEMENT ADMINISTRATORS",
  "HMA HAWAII",
  "HMO BLUE",
  "HOLMES, DIGGS & SADLER",
  "HOME",
  "HOMETOWN HEALTH",
  "HOOSIER CARE CONNECT",
  "HOPKINS HEALTH ADVANTAGE",
  "HORIZON BLUE CROSS OF NEW JERSEY",
  "HORIZON BLUE SHIELD NEW JERSEY",
  "HORIZON BLUE SHIELD OF NEW JERSEY",
  "HORIZON BLUE SHIELD OF NJ FEP",
  "HOUSE",
  "HUMANA",
  "HUMANA (PPO)",
  "HUMANA CARE PLAN",
  "HUMANA CHOICE CARE",
  "HUMANA CHOICE CARE HMO",
  "HUMANA CHOICE CARE PPO",
  "HUMANA CLAIMS OFFICE",
  "HUMANA GOLD",
  "HUMANA GOLD PLAN",
  "HUMANA GOLD PLUS",
  "HUMANA GW",
  "HUMANA HEALTH",
  "HUMANA HEALTH PLAN - HMO",
  "HUMANA HEALTHY HORIZONS",
  "HUMANA INC",
  "HUMANA INSURANCE CO",
  "HUMANA MEDICAL PLAN - CA",
  "I E SHAFFER",
  "IBA SELF FUNDED GROUP",
  "IBEW 640 AZ NECA",
  "ICARE, INDEPENDENT CARE HEALTH",
  "IDAHO BLUE CROSS",
  "IDAHO BLUE CROSS BOISE",
  "IDAHO BLUE SHIELD",
  "IDAHO BLUE SHIELD REGENCE",
  "IDAHO MEDICAID",
  "IDAHO MEDICARE",
  "IDAHO REGENCE BLUE SHIELD",
  "ILLINOIS BLUE CROSS",
  "ILLINOIS BLUE SHIELD",
  "ILLINOIS MEDICAID",
  "ILLINOIS MEDICARE",
  "IMAGINE HEALTH",
  "IMPERIAL HEALTH HOLDINGS MEDICAL GROUP",
  "IN - MEDICARE (MAC J8)",
  "INDEPENDENCE ADMINISTRATORS",
  "INDIANA BLUE SHIELD",
  "INDIANA MEDICAID",
  "INDIANA MEDICARE",
  "INLAND EMPIRE HEALTH PLAN - DOS AFTER 3/31/18",
  "INTEGRATED MENTAL HEALTH SERVICES",
  "IOWA BLUE SHIELD",
  "IOWA MEDICARE",
  "IOWA TOTAL CARE",
  "JOHNS HOPKINS HEALTHCARE",
  "JOHNS HOPKINS USFHP",
  "KAISER (NORTHERN CALIFORNIA ONLY) PERMANENTE",
  "KAISER ADDED CHOICE",
  "KAISER FOUNDATION HEALTH PLAN OF COLORADO",
  "KAISER FOUNDATION HEALTH PLAN OF WASHINGTON",
  "KAISER PERMANENTE",
  "KAISER PERMANENTE OF COLORADO",
  "KAISER PERMANENTE OF N CA REGION",
  "KAISER PERMANENTE OF S CA REGION",
  "KANSAS BLUE SHIELD",
  "KANSAS BLUE SHIELD - TOPEKA",
  "KANSAS MEDICARE",
  "KENTUCKY BLUE SHIELD",
  "KENTUCKY MEDICARE",
  "KEY SOLUTIONS",
  "KEYSTONE EAST HMO",
  "KEYSTONE FIRST",
  "KEYSTONE FIRST COMMUNITY HEALTHCHOICES",
  "KEYSTONE FIRST VIP CHOICE",
  "KLAIS AND COMPANY",
  "LA BLUE ADVANTAGE",
  "LA CARE HEALTH PLAN",
  "LA UHC DUAL COMPLETE",
  "LAKESIDE MEDICAL GROUP",
  "LAKESIDE MEDICAL GROUP (REGAL LAKESIDE)",
  "LEWISON FARM",
  "LIFEPRINT",
  "LIFEWISE HEALTHPLAN OF OREGON",
  "LIFEWISE OF WASHINGTON",
  "LINCOLN NATIONAL - PHOENIX, AZ",
  "LOOMIS COMPANY (THE)",
  "LOUISIANA BLUE SHIELD",
  "LOUISIANA HEALTH CONNECTIONS",
  "LOUISIANA HEALTHCARE CONNECTIONS",
  "LOUISIANA HEALTHCARE CONNNECTIONS",
  "LOUISIANA MEDICAID",
  "LOUISIANA MEDICAID - DME",
  "LOUISIANA MEDICARE",
  "LOUISIANA UNITED HEALTHCARE DUAL COMPLETE",
  "LOVELACE MEDICARE ADVANTAGE",
  "LOVELACE MEDICARE ADVANTAGE OK",
  "LUCENT HEALTH",
  "MAGELLAN COMPLETE CARE",
  "MAGELLAN COMPLETE CARE OF VIRGINIA MEDICARE",
  "MAGELLAN HEALTH SERVICES (MAGELLAN BEHAVIORAL HLTH",
  "MAGELLAN HEALTHCARE",
  "MAGNACARE",
  "MAGNOLIA HEALTH PLAN - MISSISSIPPI",
  "MANAGED HEALTH NETWORK",
  "MANAGED HEALTH SERVICES - INDIANA",
  "MARTINS POINT HEALTH CARE USFHP/GENERATIONS ADV",
  "MARYLAND BLUE SHIELD",
  "MARYLAND BLUE SHIELD - CAREFIRST",
  "MARYLAND MEDICARE",
  "MARYLAND PHYSICIANS CARE",
  "MASSACHUSETTS BLUE SHIELD",
  "MASSACHUSETTS MEDICAID",
  "MASSACHUSETTS MEDICAID (FORMAT 9)",
  "MASSACHUSETTS MEDICARE",
  "MCARE",
  "MCLAREN ABW - NORTHERN HEALTH PLAN NE",
  "MCLAREN HEALTH ADVANTAGE",
  "MCLAREN HEALTH MEDICAID",
  "MCLAREN HEALTH PLAN MEDICAID",
  "MCLAREN HEALTH PLANS",
  "MCLAREN MEDICAID",
  "MEDCOST",
  "MEDCOST, INC.",
  "MEDICA GOVERNMENT PROGRAMS",
  "MEDICA HEALTH PLAN",
  "MEDICA HEALTH PLANS (ACCESSABILITY SOLUTION)",
  "MEDICA HEALTLH PLAN",
  "MEDICAID MONTANA (MT)",
  "MEDICAID OF ILLINOIS",
  "MEDICAID OF MONTANA",
  "MEDICAL BENEFITS ADMINISTATORS, INC. (NEWARK, OH)",
  "MEDICAL BENEFITS MUTUAL (NEWARK, OH)",
  "MEDICAL CLAIMS MANAGEMENT CORPORATION",
  "MEDICAL MUTUAL OF OHIO",
  "MEDICAL MUTUAL OF OHIO - PROLINK",
  "MEDICAL MUTUAL OHIO",
  "MEDICAL MUTUL OF OHIO",
  "MEDICARE",
  "MEDICARE - CALIFORNIA (NORTH)",
  "MEDICARE COLORADO",
  "MEDICARE DME MAC JURISDICTION B",
  "MEDICARE ILLINOIS",
  "MEDICARE KANSAS",
  "MEDICARE MICHIGAN",
  "MEDICARE OF CALIFORNIA SOUTHERN",
  "MEDICARE OF IDAHO",
  "MEDICARE OF NEW YORK DOWNSTATE",
  "MEDICARE OF TEXAS",
  "MEDICARE PART B",
  "MEDICARE PART B OF FLORIDA",
  "MEDICARE PART B OF NEBRASKA",
  "MEDICARE PLUS BLUE - MEDICARE ADVANTAGE PLAN",
  "MEDICARE TEXAS",
  "MEDICARE-CA SOUTHERN (MEDICARE)",
  "MEDICO INSURANCE COMPANY",
  "MEDIGOLD HMO",
  "MEDIGOLD PPO",
  "MEDSTAR FAMILY CHOICE",
  "MEDSTAR MEDICARE CHOICE",
  "MEMORIAL HERMANN",
  "MEMORIAL HERMANN ADVANTAG",
  "MEMORIAL HERMANN HEALTH SOLUTIONS",
  "MERCY CARE",
  "MERCY CARE PLAN",
  "MERCYCARE",
  "MERCYCARE HMO",
  "MERIDIAN ADVANTAGE PLAN OF ILLINOIS",
  "MERIDIAN HEALTH PLAN",
  "MERIDIAN HEALTH PLAN OF ILLINOIS DOS < 7/1/21",
  "MERIDIAN HEALTH PLAN OF MICHIGAN",
  "MERIDIAN HEALTH PLAN OF MICHIGAN, INC.",
  "MERIDIANCOMPLETE ILLINOIS",
  "MERIDIANCOMPLETE MICHIGAN",
  "MERITAGE MEDICAL NETWORK",
  "MERITAGE MEDICAL(BLUE SHIELD ANTHEM CA)",
  "MERITAIN HEALTH",
  "MERITAIN HEALTH (NORTH AMERICAN HEALTH PLAN)",
  "MERITAIN HEALTH / AGENCY SERVICES",
  "MERITAIN HEALTH MINNEAPOLIS",
  "METRO PLUS HEALTH PLAN",
  "METROPOLITAN HEALTH PLUS",
  "MHN MANAGED HEALTH NETWOR",
  "MHN MANAGED HEALTH NETWORK",
  "MHS HEALTH WISCONSIN",
  "MHSA",
  "MICHIGAN BLUE SHIELD",
  "MICHIGAN BLUE SHIELD FEP",
  "MICHIGAN MEDICAID",
  "MICHIGAN MEDICARE",
  "MIDWEST HEALTH PARTNERS",
  "MINNESOTA BLUE CROSS AND BLUE SHIELD",
  "MINNESOTA BLUE CROSS/BLUE SHIELD HMO",
  "MINNESOTA BLUE PLUS",
  "MINNESOTA BLUE SHIELD",
  "MINNESOTA MEDICARE",
  "MISSION COMMUNITY IPA MEDICAL GROUP",
  "MISSISSIPPI MEDICAID",
  "MISSISSIPPI MEDICARE",
  "MISSOURI BLUE SHIELD",
  "MISSOURI BLUE SHIELD - ST LOUIS MO",
  "MISSOURI BLUE SHIELD OF KANSAS CITY",
  "MISSOURI MEDICAID",
  "MISSOURI MEDICARE EASTERN",
  "MISSOURI MEDICARE SELECT",
  "MODA HEALTH",
  "MOLINA COMPLETE CARE (MCC)",
  "MOLINA HEALTHCARE OF IDAHO",
  "MOLINA HEALTHCARE OF MISSISSIPPI",
  "MOLINA HEALTHCARE OF OHIO",
  "MOLINA HEALTHCARE OF CALIFORNIA",
  "MOLINA HEALTHCARE OF ILLINOIS",
  "MOLINA HEALTHCARE OF MICHIGAN",
  "MOLINA HEALTHCARE OF NEW MEXICO",
  "MOLINA HEALTHCARE OF NEW YORK",
  "MOLINA HEALTHCARE OF SOUTH CAROLINA",
  "MOLINA HEALTHCARE OF TEXA",
  "MOLINA HEALTHCARE OF TEXAS",
  "MOLINA HEALTHCARE OF UTAH",
  "MOLINA HEALTHCARE OF WASHINGTON",
  "MOLINA HEALTHCARE TEXAS",
  "MONTANA BLUE SHIELD",
  "MONTANA MEDICAID",
  "MONTANA MEDICARE",
  "MOTION PICTURE INDUSTRY PENSIONS AND HEALTH PLAN",
  "MOTIVHEALTH INSURANCE COMPANY",
  "MOUNTAIN HEALTH CO-OP",
  "MOUNTAIN STATE BLUE SHIELD (WEST VIRGINIA)",
  "MT. CARMEL",
  "MUNICIPAL HEALTH BENEFIT FUND",
  "MUTUAL HEALTH SERVICES",
  "MUTUAL OF OMAHA",
  "MUTUAL OF OMAHA MEDICARE ADVANTAGE",
  "MVP HEALTH CARE",
  "MVP MEDICAID MANAGED CARE",
  "NALC/AFFORDABLE",
  "NATIONAL ASSOCIATION OF LETTER CARRIERS",
  "NATIONAL GENERAL",
  "NATIONAL GOVERNMENT SERVICES/MEDICAID MASSHEALTH",
  "NATIONAL LETTER CARRIERS ASSOCIATION",
  "NEBRASKA BLUE SHIELD",
  "NEBRASKA MEDICAID",
  "NEBRASKA MEDICARE",
  "NEBRASKA TOTAL CARE",
  "NEIGHBORHOOD HEALTH PLAN OF RHODE ISLAND",
  "NETWORK HEALTH INSURANCE CORP MEDICARE",
  "NETWORK HEALTH OF MASSACHUSETTS",
  "NEVADA BLUE CROSS",
  "NEVADA BLUE SHIELD",
  "NEVADA MEDICAID",
  "NEVADA MEDICARE",
  "NEW YORK EMPIRE MEDICARE",
  "NEW ERA LIFE INSURANCE COMPANY",
  "NEW HAMPSHIRE BLUE SHIELD",
  "NEW HAMPSHIRE MEDICAID",
  "NEW HAMPSHIRE MEDICARE",
  "NEW JERSEY BLUE CHOICE",
  "NEW JERSEY MANUFACTURERS (NJM) AUTO",
  "NEW JERSEY MEDICAID",
  "NEW JERSEY MEDICARE",
  "NEW MEXICO BLUE SHIELD",
  "NEW MEXICO MEDICAID",
  "NEW MEXICO MEDICARE",
  "NEW YORK BLUE SHIELD OF NORTHEASTERN NEW YORK",
  "NEW YORK BLUE SHIELD WESTERN",
  "NEW YORK COMMUNITY BLUE HMO",
  "NEW YORK EMPIRE BLUE CROSS",
  "NEW YORK EMPIRE BLUE SHIELD",
  "NEW YORK EMPIRE BLUE SHIELD - HEALTHNET",
  "NEW YORK MEDICAID",
  "NEW YORK MEDICAID - PHASE II",
  "NEW YORK MEDICARE (UPSTATE)",
  "NEW YORK MEDICARE DOWNSTATE",
  "NEW YORK NETWORK MANAGEMENT (NYNM)",
  "NEW YORKE EMPIRE BLUE SHIELD",
  "NEX MEXICO MEDICARE",
  "NEY YORK EMPIRE MEDICARE",
  "NIPPON LIFE INSURANCE COMPANY OF AMERICA",
  "NIVANO PHYSICIANS IPA",
  "NORTH CAROLINA BLUE SHIELD",
  "NORTH CAROLINA MEDICAID",
  "NORTH CAROLINA MEDICARE",
  "NORTHEAST BEHAVIORAL HEALTH PARTNERSHIP",
  "NORTHERN CALIFORNIA MEDICARE",
  "NORTHWEST ADMINISTRATORS, INC.",
  "NORTHWEST COMMUNITY HEALTH PARTNERS",
  "NOVA HEALTHCARE ADMINISTRATORS, INC. (GRAND ISLAND",
  "NOVITAS MEDICARE",
  "OHANA HEALTH PLAN HAWAII",
  "OHIO BLIE SHIELD",
  "OHIO BLUE CROSS",
  "OHIO BLUE SHIELD",
  "OHIO MEDICAID",
  "OHIO MEDICARE",
  "OKLAHOMA BLUE SHIELD",
  "OKLAHOMA MEDICAID",
  "OKLAHOMA MEDICARE",
  "OMNIA HEALTH PLANS",
  "OPTIMA HEALTH PLANS",
  "OPTIMA INSURANCE COMPANY",
  "OPTUM MARYLAND BEHAVIOR HEALTH",
  "OPTUM MEDICAL GROUP",
  "OPTUM MEDICAL NETWORK",
  "OPTUM PHYSICAL HEALTH",
  "OPTUMHEALTH BEHAVIORAL SOLUTIONS",
  "OPTUMHEALTH CARE SOLUTIONS",
  "OREGON BLUE SHIELD REGENCE",
  "OREGON MEDICAID",
  "OREGON MEDICARE",
  "OSCAR",
  "OSCAR HEALTH",
  "OSCAR HEALTH PLAN OF NEW YORK - DOS <1/1/17",
  "OXFORD",
  "OXFORD HEALTH PLANS",
  "PACIFICSOURCE",
  "PACIFICSOURCE ADMINISTRATORS (PSA)",
  "PACIFICSOURCE CCO",
  "PACIFICSOURCE HEALTH PLANS",
  "PACIFICSOURCE MEDICARE",
  "PAN AMERICAN LIFE INSURANCE GROUP",
  "PARAMOUNT",
  "PASSPORT ADVANTAGE",
  "PASSPORT HEALTH PLAN BY MOLINA HEALTHCARE",
  "PEACH STATE HEALTH PLAN",
  "PEHP",
  "PENINSULA HEALTH CARE",
  "PENNSLYVANIA BLUE SHIELD",
  "PENNSYLVANIA BLUE SHIELD",
  "PENNSYLVANIA FREEDOM BLUE MEDICARE ADVANTAGE",
  "PENNSYLVANIA INDEPENDENCE AMERIHEALTH DE PPO",
  "PENNSYLVANIA INDEPENDENCE AMERIHEALTH NJ PPO",
  "PENNSYLVANIA INDEPENDENCE BLUE CROSS",
  "PENNSYLVANIA MEDICAID",
  "PENNSYLVANIA MEDICARE",
  "PEOPLES HEALTH NETWORK",
  "PHYSICIAN HEALTH PARTNERS MEDICARE",
  "PHYSICIAN HEALTH PLAN OF NORTHERN INDIANA - PHP",
  "PHYSICIANS HEALTH PLAN MID-MICHIGAN / PHP",
  "PHYSICIANS MUTUAL INSURANCE COMPANY",
  "PLANNED ADMINISTRATORS",
  "PRAIRIE STATES ENTERPRISES, INC.",
  "PRE-EXISTING CONDITION INSURANCE PLAN (PCIP)",
  "PREFERRED ADMINISTRATORS",
  "PREFERRED BLUE",
  "PREFERRED IPA",
  "PREFERRED ONE",
  "PREFERRED ONE OF MINNESOTA",
  "PREMERA BLUE CARD",
  "PREMIER HEALTHCARE EXCHANGE (PHX)",
  "PREMIER PHYSICIANS NETWORK (MEDPOINT MANAGEMENT)",
  "PRESBYTERIAN HEALTH PLAN",
  "PRIMARY CARE ASSOCIATES OF CALIFORNIA",
  "PRIMARY HEALTH NETWORK",
  "PRIME BENEFIT SYSTEMS, INC",
  "PRIME HEALTH CHOICE",
  "PRIME HEALTH MANAGEMENT SERVICES",
  "PRIORITY HEALTH CARE",
  "PRIORITY HEALTH OF MICHIGAN",
  "PROMINENCE HEALTH PLAN PPO/HMO",
  "PROVIDENCE HEALTH PLAN",
  "PUBLIC EMPLOYEE HEALTH PLAN (PEHP)",
  "QUALCHOICE OF OHIO",
  "RAILROAD MEDICARE",
  "REBECCA ALLRED",
  "REGENCE BLUE SHIELD OF IDAHO",
  "REGENCE GROUP ADMINISTRATORS",
  "RELIANCE HMO - JVHL",
  "RESERVE NATIONAL INSURANCE",
  "RETIRED RAILROAD MEDICARE",
  "RHA VESTACARE",
  "RHODE ISLAND BLUE CROSS",
  "RHODE ISLAND BLUE SHIELD",
  "RHODE ISLAND MEDICARE",
  "SABATIA FLOWER FARM",
  "SACRAMENTO PPO",
  "SAINT JOHNS HEALTH CLINIC",
  "SAMERA HEALTH",
  "SANFORD HEALTH PLAN",
  "SC BCBS PREFERRED HEALTH SYSTEMS - HMO BLUE",
  "SCAN",
  "SCAN HEALTH PLAN",
  "SCOTT AND WHITE",
  "SCOTT AND WHITE HEALTH PLAN",
  "SECURE HORIZONS - TUFTS HEALTH PLAN FOR SENIORS",
  "SELECT HEALTH",
  "SELECT HEALTH OF SOUTH CAROLINA",
  "SELECTHEALTH",
  "SELECTHEALTH COMMUNITY CARE",
  "SELF",
  "SELF FUNDED PLANS (IL, PA, OH)",
  "SELF INSURED PLANS LLC",
  "SELMAN TRICARE SUPPLEMENT",
  "SENIOR WHOLE HEALTH OF MASSACHUSETTS",
  "SIERRA HEALTH SERVICES, INC. (CLAIMS)",
  "SILVER 87 COMMUNITY CARE HMO",
  "SILVER CROSS HEALTH CONNECTION",
  "SIMPLY HEALTHCARE PLANS, INC.",
  "SISCO",
  "SMITH ADMINISTRATORS",
  "SMITH MINER FUNERAL HOME",
  "SOLIDARITY HEALTHSHARE",
  "SOUTH CAROLINA BLUE CROSS",
  "SOUTH CAROLINA BLUE SHIELD",
  "SOUTH CAROLINA BLUECHOICE HEALTH PLAN",
  "SOUTH CAROLINA MEDICAID",
  "SOUTH CAROLINA MEDICARE",
  "SOUTH DAKOTA BLUE SHIELD",
  "SOUTHEASTERN INDIANA HEALTH ORGANIZATION - SIHO",
  "SOUTHERN CALIFORNIA MEDICARE",
  "SPECIAL AGENT MUTUAL BENEFIT ASSOCIATION",
  "SPECTERA",
  "SPINA BIFIDA - VA HAC",
  "ST VINCENT IPA",
  "ST. JOSEPH HERITAGE",
  "STATE EMPLOYEES GROUP BENEFITS PROGRAM",
  "STEWARD HEALTH CHOICE",
  "STONES RIVER IPA FORTN BLUE CROSS BLUE SHIELD ONLY",
  "STUDENT INSURANCE-MEGA LIFE & HEALTH INSURANCE CO",
  "SUMMIT COMMUNITY CARE",
  "SUMMIT COMMUNITY CARE PASSE",
  "SUNFLOWER STATE",
  "SUNSHINE HEALTH",
  "SUNSHINE STATE HEALTH PLAN",
  "SUPERIOR AMBETTER COMMERCIAL",
  "SUPERIOR HEALTH PLAN - TEXAS",
  "SUPERIOR MEDICARE ADVANTAGE PLAN",
  "SUPERIOR SELECT HEALTH PLAN",
  "TEAM CHOICE UMC",
  "TENNCARE SELECT",
  "TENNESSEE BLUE SHIELD",
  "TENNESSEE MEDICARE",
  "TENNESSEE UNITEDHEALTHCARE DUAL COMPLETE",
  "TEXAS BLUE SHIELD",
  "TEXAS BLUE SHIELD BLUE CROSS",
  "TEXAS CHILDRENS HEALTH PLAN",
  "TEXAS CHILDRENS HEALTH PLAN (MEDICAID)",
  "TEXAS FIRST HEALTH PLAN",
  "TEXAS MEDICAID",
  "TEXAS MEDICARE",
  "TGI",
  "THE LOOMIS COMPANY",
  "TODAYS OPTIONS",
  "TRICARE - EAST REGION",
  "TRICARE - WEST REGION",
  "TRICARE - WPS- WEST REGION (CHAMPUS)",
  "TRICARE EAST",
  "TRICARE FOR LIFE",
  "TRICARE OVERSEAS PROGRAM",
  "TRICARE WEST",
  "TRIHEALTH PHYSICIAN SERVICES",
  "TRIWEST REGION 4 CCN",
  "TRUSTMARK HEALTH (CORESOURCE AZ IL IN MD MN NC PA)",
  "TRUSTMARK HEALTH BENEFITS",
  "TRUSTMARK HEALTH BENEFITS (CORESOURCE DETROIT)",
  "TRUSTMARK HEALTH BENEFITS (CORESOURCE INTERNAL)",
  "TRUSTMARK HEALTH BENEFITS (CORESOURCE OH)",
  "TRUSTMARK SMALL BUSINESS BENEFITS",
  "TUFTS ASSOCIATED HEALTH PLAN",
  "TUFTS HEALTH PLAN",
  "TUFTS HEALTH PLANS",
  "TUFTS HEALTH PUBLIC PLANS",
  "TUFTS HEALTH TOGETHER",
  "TUFTS MEDICARE PREFERRED MEDICARE ADVANTAGE",
  "UBH - UNITED BEHAVIORAL HEALTH",
  "UCARE MINNESOTACARE (MNCARE)",
  "UCARE PLANS",
  "UHC",
  "UMP PLUS",
  "UMR",
  "UMR - USHEALTH GROUP (PPO)",
  "UMR CINCINNATI REPRICING VIA HEALTHSPAN",
  "UMR FKA UMR WAUSAU",
  "UMR FKA WASAU",
  "UMR ORLANDO HEALTH NETWORK",
  "UMWA HEALTH AND RETIREMENT FUNDS",
  "UNICARE",
  "UNICARE (TX, MA, KS, WV, RI, IL)",
  "UNINSURED",
  "UNITE HEALTH SHARE MINISTRIES (UHSM)",
  "UNITED AARP MED COMPL OXFORD NETWORK",
  "UNITED AARP MEDICARE COMPLETE",
  "UNITED CARE MEDICAL GROUP",
  "UNITED HEALTHCARE",
  "UNITED HEALTHCARE (HMO)",
  "UNITED HEALTHCARE (MEDICARE REPLACEMENT/ADVANTAGE - PPO)",
  "UNITED HEALTHCARE CHOICE",
  "UNITED HEALTHCARE CHOICE+",
  "UNITED HEALTHCARE COMMUNITY PLAN",
  "UNITED HEALTHCARE COMMUNITY PLAN - TENNCARE ONLY",
  "UNITED HEALTHCARE COMMUNITY PLAN OF KANSAS",
  "UNITED HEALTHCARE COMMUNITY PLAN OF KENTUCKY",
  "UNITED HEALTHCARE DUAL COMPLETE",
  "UNITED HEALTHCARE MC ADV",
  "UNITED HEALTHCARE MCR SOLUTIONS",
  "UNITED HEALTHCARE OF LA",
  "UNITED HEALTHCARE OF LOUISIANA",
  "UNITED HEALTHCARE OF THE MID-ATLANTIC",
  "UNITED HEALTHCARE/UHC SR HMO ST VINCENT IPA",
  "UNITED HEALTHCARE/UHC SR PPO",
  "UNITED MEDICAL RESOURCES",
  "UNITEDHEALTHCARE",
  "UNITEDHEALTHCARE COMMUNITY PLAN FKA APIPA",
  "UNITEDHEALTHCARE COMMUNITY PLAN LOUISIANA",
  "UNITEDHEALTHCARE COMMUNITY PLAN NEBRASKA",
  "UNITEDHEALTHCARE COMMUNITY PLAN NEW MEXICO",
  "UNITEDHEALTHCARE COMMUNITY PLAN NEW YORK",
  "UNITEDHEALTHCARE COMMUNITY PLAN OF MASSACHUSETTS",
  "UNITEDHEALTHCARE COMMUNITY PLAN OF MICHIGAN",
  "UNITEDHEALTHCARE COMMUNITY PLAN OF MISSOURI",
  "UNITEDHEALTHCARE COMMUNITY PLAN OF NY MEDICAID",
  "UNITEDHEALTHCARE DEFINITY HEALTH PLAN",
  "UNITEDHEALTHCARE DUAL COMPLETE",
  "UNITEDHEALTHCARE DUAL COMPLETE CHOICE",
  "UNITEDHEALTHCARE GROUP MEDICARE ADVANTAGE",
  "UNITEDHEALTHCARE MEDICARE COMPLETE",
  "UNITEDHEALTHCARE MEDICARE GOLD",
  "UNITEDHEALTHCARE MEDICARE SOLUTIONS FKA SECUREHORI",
  "UNITEDHEALTHCARE MEDICARECOMPLETE OPEN PREMIER",
  "UNIVERA ENY",
  "UNIVERSITY OF UTAH",
  "UNIVERSITY OF UTAH HEALTH PLAN",
  "UNIVERSITY OF UTAH HEALTHY U",
  "UPMC FOR LIFE",
  "UPMC HEALTH PLAN",
  "US HEALTH AND LIFE",
  "USAA",
  "UST HEALTHPROOF",
  "UST HEALTHPROOF/UHC",
  "UTAH BLUE SHIELD",
  "UTAH BLUE SHIELD - FEP",
  "UTAH BLUE SHIELD FEDERAL",
  "UTAH MEDICAID",
  "UTAH MEDICARE",
  "UTAH REGENCE BLUE CROSS BLUE SHIELD",
  "UTAH REGENCE BLUE SHIELD",
  "UTAH UNIVERSITY HEALTH PLAN",
  "VALUE OPTIONS",
  "VALUE OPTIONS - BEHAVIORAL HEALTH",
  "VALUE OPTIONS MBHP",
  "VANTAGE HEALTH PLAN",
  "VARIPRO",
  "VENCOR",
  "VERMONT BLUE SHIELD",
  "VETERANS ADMINISTRATION FEE BASIS PROGRAMS",
  "VETERANS AFFAIRS COMMUNITY CARE NETWORK RGN 1 2 3",
  "VIBRA HEALTH PLAN",
  "VIRGINIA BLUE SHIELD",
  "VIRGINIA MEDICARE",
  "VIVA HEALTH",
  "VIVA HEALTH CARE AND VIVA MEDICARE",
  "VNSNY CHOICE",
  "WASHINGTON BLUE SHIELD REGENCE",
  "WASHINGTON MEDICAID",
  "WASHINGTON MEDICARE",
  "WASHINGTON REGENCE BLUE SHIELD",
  "WASHINGTON WORKERS COMPENSATION",
  "WEA TRUST",
  "WEBTPA",
  "WELLCARE",
  "WELLCARE HEALTH PLAN",
  "WELLCARE HEALTH PLANS",
  "WELLCARE HEALTH PLANS, INC.",
  "WELLCARE NORTH CAROLINA, INC",
  "WELLCARE OF CT, FL, GA, LA, NJ, NY, OH, TX, SC",
  "WELLCARE OF KENTUCKY",
  "WELLCARE OF SC",
  "WELLCARE PFFS",
  "WELLMED",
  "WELLMED DUAL/SOUTHWESTEN HEALTH RESOURCE",
  "WELLMED ENCOUNTERS",
  "WELLMED MEDICAL",
  "WELLMED MEDICAL MANAGEMENT INC.",
  "WELLMED MEDICAL MANAGEMENT, INC.",
  "WEST VIRGINIA FREEDOM BLUE MEDICARE ADVANTAGE",
  "WEST VIRGINIA MEDICAID",
  "WEST VIRGINIA MEDICARE",
  "WESTERN HEALTH ADVANTAGE",
  "WESTERN SKY COMMUNITY CARE",
  "WESTERN SOUTHERN LIFE GROUP",
  "WISCONSIN BLUE SHIELD",
  "WISCONSIN EDUCATORS ASSOCIATION (WEA)",
  "WISCONSIN MEDICAID",
  "WISCONSIN MEDICARE",
  "WORLD TRADE CENTER HEALTH PROGRAM/FDNY",
  "WPS COMERCIAL / REGULAR BUSINESS",
  "WPS HEALTH PLAN",
  "WRITERS GUILD INDUSTRY HEALTH PLAN",
  "WYOMING BLUE SHIELD",
];
