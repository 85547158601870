// imported libs
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { useHistory, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";

// react-boostrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

// logo/styles
import { ReactComponent as BackArrowIcon } from "./back.svg";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as LogoSVG } from "../../app/assets/logo.svg";
import styles from "./Header.module.css";

// slice logic
import {
  statusThunk,
  impersonateThunk,
  stopImpersonateThunk,
  resetPasswordThunk,
  setCurrSiteId,
} from "../user/userSlice";
import { reset as resetPatientsState } from "../patients/patientsSlice";
import { reset as resetReportsState } from "../reports/reportsSlice";
import {
  reset as resetHomeState,
  submitReferrals,
  submitRCMRequestForDemo,
} from "../home/homeSlice";
import { toggleNav, hideNav } from "../header/headerSlice";
import {
  Referral,
  ValidateReferrals,
  IsReferralEmpty,
} from "../onboard/OnboardPage";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { trackEvent } from "../../utils/tracking";
import { SiteDropdown } from "./SiteDropdown";

import { RCMReferralModal } from "./RCMReferralModal";

import { RPM_SITE_ROLES } from "../../app/apiConstants";

export const Header = ({
  title,
  showBackButton = false,
  iframeView = false,
}) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const email = useSelector((state) => state.user.email);
  const token = useSelector((state) => state.user.token);
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const rpm_role = useSelector((state) => state.user.rpm_site_role);
  const showNav = useSelector((state) => state.header.showNav);
  const impersonatorUserId = useSelector(
    (state) => state.user.impersonatorUserId
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const [impersonateTarget, setImpersonateTarget] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  // auto hide nav
  if (width > 1024) {
    if (showNav) {
      // reduce action noise in dev tools
      dispatch(hideNav());
    }
  }

  const onImpersonateTargetChanged = (e) =>
    setImpersonateTarget(e.target.value);

  const onImpersonateClick = async () => {
    try {
      const resultAction = await dispatch(
        impersonateThunk({ impersonateTarget, token })
      );
      const impersonateResponse = unwrapResult(resultAction);
      const targetToken = impersonateResponse.auth_token;
      // load target status and send to home
      await dispatch(setCurrSiteId({ site_id: null })); // allow current site to be updated
      await dispatch(statusThunk({ token: targetToken }));
      // reset states
      dispatch(resetHomeState());
      dispatch(resetPatientsState());
      dispatch(resetReportsState());

      history.push("/home");

      window.location.reload();
    } catch (err) {
      console.error("Failed to Impersonate: ", err);
    }
  };

  const onStopImpersonateClick = async () => {
    try {
      const resultAction = await dispatch(stopImpersonateThunk({ token }));
      const stopImpersonateResponse = unwrapResult(resultAction);

      // reload target status and send to home
      await dispatch(setCurrSiteId({ site_id: null })); // allow current site to be updated
      await dispatch(
        statusThunk({ token: stopImpersonateResponse.auth_token })
      );
      dispatch(resetHomeState());
      dispatch(resetPatientsState());
      dispatch(resetReportsState());

      history.push("/home");

      window.location.reload();
    } catch (err) {
      console.error("Failed to stop Impersonate: ", err);
    }
  };

  const onBackButtonClick = () => {
    history.goBack();
  };

  let formContent;

  if (impersonatorUserId === null) {
    formContent = (
      <Form className={styles.HeaderImpersonate}>
        <Form.Row>
          <Col>
            <Form.Control
              type="email"
              placeholder="Enter impersonate target email"
              value={impersonateTarget}
              onChange={onImpersonateTargetChanged}
            />
          </Col>
          <Col>
            <Button variant="primary" onClick={onImpersonateClick}>
              Impersonate
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  } else {
    formContent = (
      <Form className={styles.HeaderImpersonate}>
        <Button variant="warning" onClick={onStopImpersonateClick}>
          Stop Impersonate As {email}
        </Button>
      </Form>
    );
  }

  const backButton = (
    <div className={styles.BackButtonWrapper} onClick={onBackButtonClick}>
      <BackArrowIcon />
    </div>
  );

  const forwardButton = (
    <div className={styles.ForwardButtonWrapper} onClick={onBackButtonClick}>
      <BackArrowIcon />
    </div>
  );

  function onHamburgerButtonClick() {
    dispatch(toggleNav());
  }

  const hamburgerButton = (
    <div
      className={styles.HamburgerButtonWrapper}
      onClick={onHamburgerButtonClick}
    >
      <MenuIcon />
    </div>
  );

  const headerTitle = (
    <div>
      <h1 className={styles.HeaderTitle}>{title}</h1>
      {rpm_role === RPM_SITE_ROLES.RPM_ORG_OWNER &&
        site_id &&
        Object.keys(sites).length > 0 && <SiteDropdown />}
    </div>
  );
  const headerLogo = (
    <div>
      <LogoSVG />
      {rpm_role === RPM_SITE_ROLES.RPM_ORG_OWNER &&
        site_id &&
        Object.keys(sites).length > 0 && <SiteDropdown />}
    </div>
  );

  const iframeHeaderTitle = (
    <h1 className={styles.iFrameHeaderTitle}>{title}</h1>
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  const [show, setShow] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showRCMReferralModal, setShowRCMReferralModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseReferralModal = () => setShowReferralModal(false);
  const handleCloseRCMReferralModal = () => setShowRCMReferralModal(false);

  const handleShowReferralModal = () => {
    trackEvent("click", "clicked_header_referral_promotion");
    setShowReferralModal(true);
  };

  const handleShowRCMReferralModal = () => {
    trackEvent("click", "clicked_header_rcm_request_demo");
    setShowRCMReferralModal(true);
  };

  const [referralNumbers, setReferralNumbers] = useState(["", "", "", ""]);
  const [referralNames, setReferralNames] = useState(["", "", "", ""]);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [modalError, setModalError] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);

  const onOldPasswordChanged = (e) => setOldPassword(e.target.value);
  const onNewPasswordChanged = (e) => setNewPassword(e.target.value);
  const onNewPasswordConfirmChanged = (e) => {
    setNewPasswordConfirm(e.target.value);
    if (e.target.value === newPassword) {
      setModalError("");
    }
  };

  const handleRCMRequestDemo = async () => {
    try {
      const resultAction = await dispatch(
        submitRCMRequestForDemo({
          site_id,
          token,
        })
      );
      const resetResponse = unwrapResult(resultAction);
      if (resetResponse.status !== 200) {
        alert(resetResponse.data);
      } else {
        setShowRCMReferralModal(false);
        alert(
          "Thanks for your interest, we will get in touch with you as soon as possible!"
        );
      }
    } catch {
      alert("Server error occured, request again");
    }
  };

  const handleReferralSubmit = async () => {
    let failed = ValidateReferrals(referralNumbers, referralNames);
    let empty = IsReferralEmpty(referralNumbers, referralNames);
    if (empty) {
      setShowReferralModal(false);
    }
    if (failed >= 0) {
      alert(
        `Please enter both a name and phone number for Referral ${failed + 1}`
      );
    } else {
      try {
        if (!empty) {
          await dispatch(
            submitReferrals({
              site_id,
              token,
              numbers: referralNumbers,
              names: referralNames,
            })
          );
          setShowReferralModal(false);
          setReferralNumbers(["", "", "", ""]);
          setReferralNames(["", "", "", ""]);
        }
      } catch {
        alert("Server error occured, please contact us at help@getathelas.com");
      }
    }
  };

  const handleConfirmUpdate = async () => {
    setFormDisabled(true);
    if (oldPassword === "") {
      setModalError("Please provide old password");
      setFormDisabled(false);
      return;
    }
    if (newPassword === "") {
      setModalError("Please provide new password");
      setFormDisabled(false);
      return;
    }
    if (newPassword !== newPasswordConfirm) {
      setModalError(
        "The confirmed new password does not match the new password."
      );
      setFormDisabled(false);
      return;
    }

    const resultAction = await dispatch(
      resetPasswordThunk({
        token: token,
        email: email,
        old_password: oldPassword,
        new_password: newPassword,
      })
    );
    const resetResponse = unwrapResult(resultAction);
    if (resetResponse.status !== 200) {
      setModalError(resetResponse.message);
    } else {
      alert("Password updated successfully");
      handleClose();
      setOldPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
      setModalError("");
    }

    setFormDisabled(false);
  };

  let userIcon = (
    <div
      style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
    >
      <div style={{ marginRight: 16, marginTop: 2 }}>
        <AthelasButton
          variant="primary"
          text="Get AI-powered medical billing"
          size="small"
          className={styles.ReferralButton}
          onClick={handleShowRCMReferralModal}
        />
      </div>
      <div style={{ marginRight: 16, marginTop: 2 }}>
        <AthelasButton
          variant="danger"
          text="Refer a Colleague"
          size="small"
          className={styles.ReferralButton}
          onClick={handleShowReferralModal}
        />
      </div>
      <Dropdown className={styles.HeaderDropdown}>
        <Dropdown.Toggle as={CustomToggle}>
          <div className={styles.Avatar}>
            {email[0] ? email[0].toUpperCase() : ""}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={handleShow}>
            Update Password
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const openMainDoctorApp = () => {
    window.open("https://app.athelas.com", "_blank");
  };

  let goToAthelas = (
    <div className={styles.GoToAthelasWrapper} onClick={openMainDoctorApp}>
      <p style={{ margin: "0 0 0 8px" }}>Open the Athelas Doctor App</p>
      {forwardButton}
    </div>
  );

  return (
    <div className={styles.HeaderWrapper}>
      {/* header title */}
      <div
        className={
          showBackButton ? styles.HeaderLeftWithBackButton : styles.HeaderLeft
        }
      >
        {/* hamburger */}
        {!iframeView && width < 1024 ? hamburgerButton : <></>}
        {showBackButton ? backButton : <></>}
        {(width < 1024 && location.pathname === "/home") || iframeView
          ? headerLogo
          : headerTitle}
        {iframeView ? iframeHeaderTitle : <></>}
      </div>

      {/* admin feature */}
      {!iframeView && (isAdmin || impersonatorUserId) ? formContent : <></>}

      {/* referral modal */}
      <Modal
        dialogClassName={styles.ReferralModal}
        show={showReferralModal}
        onHide={handleCloseReferralModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Refer Providers to Athelas RPM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Referral
            referralNumbers={referralNumbers}
            setReferralNumbers={setReferralNumbers}
            referralNames={referralNames}
            setReferralNames={setReferralNames}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseReferralModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleReferralSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <RCMReferralModal
        showRCMReferralModal={showRCMReferralModal}
        handleCloseRCMReferralModal={handleCloseRCMReferralModal}
        handleRCMRequestDemo={handleRCMRequestDemo}
      />

      {/* change password modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old password"
                value={oldPassword}
                onChange={onOldPasswordChanged}
                disabled={formDisabled}
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={onNewPasswordChanged}
                disabled={formDisabled}
              />
            </Form.Group>
            <Form.Group controlId="newPasswordConfirm">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Type new password again"
                value={newPasswordConfirm}
                onChange={onNewPasswordConfirmChanged}
                disabled={formDisabled}
              />
            </Form.Group>
            {modalError !== "" && <Alert variant="danger">{modalError}</Alert>}
            <Form.Text className="text-muted">
              Passwords must have:
              <ul>
                <li>A number</li>
                <li>A special character</li>
                <li>Uppercase character</li>
                <li>At least 10 characters</li>
              </ul>
            </Form.Text>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={formDisabled}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmUpdate}
            disabled={formDisabled}
          >
            {formDisabled ? "Updating…" : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* right side of header */}
      {!iframeView ? userIcon : goToAthelas}
    </div>
  );
};
