import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column, Table, AutoSizer } from "react-virtualized";
import fileDownload from "js-file-download";
import { Header } from "../header/Header";
import {
  fetchSitePrescribersThunk,
  fetchPatientsPaginated,
  selectAllPatients,
  setCurrentPage,
  setPrescriberSelected,
  setonboardingStatusSelected,
  setSearchTerm,
  setNoTestThisMonthSelected,
  setInsuranceNameSelected,
  fetchInsuranceNames,
} from "./patientsSlice";

import patientsAPI from "./patientsAPI";
import { statusThunk } from "../user/userSlice";
import { IsoDateToDDMMMYYYY } from "../../utils/date";

import Form from "react-bootstrap/Form";
import styles from "./PatientsList.module.css";
import { PAGE_SIZE } from "./consts";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ReactComponent as SearchSvg } from "./SearchIcon.svg";
import { Pagination } from "../../app/Atoms/Pagination/Pagination";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { AddPatientModal } from "./AddPatientModal";
import { trackEvent } from "../../utils/tracking";
import Select from "react-select";
import { ExportPatientListAsCSVFile } from "../../utils/csv";
import { ReactComponent as EditPatientIcon } from "../../app/assets/font_awesome/user-pen-solid.svg";
import { IconButton } from "@mui/material";

const OnboardingStatuses = [
  "Pending",
  "Confirmed Enrollment",
  "Unbillable",
  "Billing Check Approved",
  "Billing Check Skipped",
  "Billing Check in Progress",
  "Shipping Label Created",
  "Shipped",
  "Dropped Out",
  "Declined",
  "Tested",
  "Paused Texts",
];

export const PatientsList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.patients.status);
  const error = useSelector((state) => state.patients.error);
  const [isDownloading, setIsDownloading] = useState(false);
  const [prescriberForAddingPatient, setPrescriberForAddingPatient] =
    useState("");
  const [currentPatientRow, setCurrentPatientRow] = useState(null);

  const patients = useSelector(selectAllPatients);
  const dispatch = useDispatch();
  const history = useHistory();

  const currentPage = useSelector((state) => state.patients.currentPage);
  const totalNumberOfPages = useSelector(
    (state) => state.patients.totalNumberOfPages
  );

  const prescribers = useSelector((state) => state.patients.prescribers);
  const prescriberOptions = prescribers.map((prescriber) => {
    return { value: prescriber.id, label: prescriber.name, key: prescriber.id };
  });
  const onboardingStatusOptions = OnboardingStatuses.map((status) => {
    return { value: status, label: status };
  });

  // const insuranceNames = patients.map(item => item.insurance_name).filter((value, index, self) => self.indexOf(value) === index)
  const insuranceNames = useSelector((state) => state.patients.insuranceNames);
  const insuranceNameOptions = insuranceNames.map((name) => {
    return { value: name, label: name };
  });

  const [showAddPatientModal, setShowAddPatientModal] = useState(false);

  const prescriberSelected = useSelector(
    (state) => state.patients.prescriberSelected
  );

  const onboardingStatusSelected = useSelector(
    (state) => state.patients.statusSelected
  );

  const noTestThisMonthSelected = useSelector(
    (state) => state.patients.noTestThisMonthSelected
  );

  const insuranceNameSelected = useSelector(
    (state) => state.patients.insuranceNameSelected
  );

  const onInsuranceNameSelected = (e) => {
    let newInsurance = "";
    if (e) {
      newInsurance = e.value;
    }

    dispatch(
      setInsuranceNameSelected({
        insuranceNameSelected: newInsurance,
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: 0,
        search_term: searchTerm,
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: newInsurance,
      })
    );
  };

  const onPrescriberSelectedChanged = (e) => {
    let newPrescriber = [];
    if (e && e.length > 0) {
      newPrescriber = e.map((prescriber) => prescriber.value);
    }

    dispatch(
      setPrescriberSelected({
        prescriberId: newPrescriber,
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: newPrescriber,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: 0,
        search_term: searchTerm,
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  const selectPrescriberForPatientAdd = (e) => {
    let newPrescriber = "";
    if (e) {
      newPrescriber = e.value;
    }
    setPrescriberForAddingPatient(newPrescriber);
  };

  const getPatientUpdateLink = async (e, patient_id) => {
    try {
      const res = await patientsAPI.getPatientUpdateLink(
        patient_id,
        site_id,
        token
      );
      window.open(res.data.link, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const getEnrollmentLinkForPrescriber = async (e) => {
    try {
      const res = await patientsAPI.getPatientEnrollmentLink(
        prescriberForAddingPatient,
        site_id,
        token
      );
      window.open(res.data.link, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnboardingStatusSelectedChanged = (e) => {
    let newStatus = "";
    if (e && e.length > 0) {
      newStatus = e.map((u) => u.value).join(",");
    }

    dispatch(
      setonboardingStatusSelected({
        statusSelected: newStatus,
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: newStatus,
        page_size: PAGE_SIZE,
        offset: 0,
        search_term: searchTerm,
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  const downloadPatientsCSV = async (e) => {
    setIsDownloading(true);
    const res = await patientsAPI.getPatientsCSV(
      site_id,
      token,
      prescriberSelected,
      onboardingStatusSelected,
      searchTerm,
      noTestThisMonthSelected
    );
    fileDownload(res.data, "patients_data.csv");
    setIsDownloading(false);
  };

  const onNoTestThisMonthFilterClick = (e) => {
    dispatch(
      setNoTestThisMonthSelected({
        noTestThisMonthSelected: !noTestThisMonthSelected,
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: 0,
        search_term: searchTerm,
        no_test_this_month: !noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  const searchTerm = useSelector((state) => state.patients.searchTerm);
  const onSearchTermChanged = (e) => {
    dispatch(
      setSearchTerm({
        searchTerm: e.target.value,
      })
    );
  };

  const searchByNameClicked = (e) => {
    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: 0,
        search_term: searchTerm,
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  const clearSearch = (e) => {
    dispatch(
      setSearchTerm({
        searchTerm: "",
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: 0,
        search_term: "",
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  const totalCount = useSelector((state) => state.patients.totalCount);

  const previousPageFunc = () => {
    if (currentPage === 1) {
      return;
    }
    dispatch(
      setCurrentPage({
        targetPage: currentPage - 1,
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: (currentPage - 2) * PAGE_SIZE,
        search_term: searchTerm,
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  const nextPageFunc = () => {
    if (currentPage === totalNumberOfPages) {
      return;
    }

    dispatch(
      setCurrentPage({
        targetPage: currentPage + 1,
      })
    );

    dispatch(
      fetchPatientsPaginated({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        status: onboardingStatusSelected,
        page_size: PAGE_SIZE,
        offset: currentPage * PAGE_SIZE,
        search_term: searchTerm,
        no_test_this_month: noTestThisMonthSelected,
        insurance_company: insuranceNameSelected,
      })
    );
  };

  // TODO: can add this in to eliminate queries each time a different keystroke happens-
  // however, searchTerm is null right now when doSearch is called
  // window.onload=function(){
  //     var input = document.getElementById("searchLink");
  //     input.addEventListener("keydown", function (e) {
  //       if (e.key === "Enter") {
  //         doSearch();
  //       }});
  // }

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchSitePrescribersThunk({ site_id, token }));
      dispatch(
        fetchPatientsPaginated({
          site_id,
          token,
          prescriber_id: prescriberSelected,
          status: onboardingStatusSelected,
          page_size: PAGE_SIZE,
          offset: 0,
          search_term: searchTerm,
          no_test_this_month: noTestThisMonthSelected,
          insurance_company: insuranceNameSelected,
        })
      );
      trackEvent("view", "viewed_patients_list");
    }
  });

  if (insuranceNames.length === 0) {
    dispatch(fetchInsuranceNames({ site_id, token }));
  }

  function OnBoardCell({ cellData }) {
    return (
      <>
        <div
          className={
            styles.OnboardingStatusText +
            " " +
            styles[cellData.replace(" ", "_")]
          }
        >
          {cellData}
        </div>
      </>
    );
  }

  function ResultDate({ cellData }) {
    if (cellData && cellData !== "No Result") {
      return IsoDateToDDMMMYYYY(cellData);
    } else {
      return cellData;
    }
  }

  let content;
  if (status === "succeeded" || status === "loading") {
    const patientTable = (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={60}
            rowHeight={68.35}
            rowCount={patients.length}
            onRowMouseOver={({ _, index }) => {
              setCurrentPatientRow(index);
            }}
            // rowRenderer={rowWrapper}
            onRowClick={({ rowData }) =>
              history.push("/patients/" + rowData.id)
            }
            rowGetter={({ index }) => patients[index]}
            rowClassName={({ index }) =>
              index !== -1 ? styles.TableRow : styles.HeaderRow
            }
          >
            <Column
              dataKey="edit_icon"
              width={50}
              cellRenderer={({ rowData, rowIndex }) => {
                return (
                  <>
                    {rowIndex === currentPatientRow ? (
                      <IconButton>
                        <EditPatientIcon
                          style={{ width: 23, height: 23 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            getPatientUpdateLink(e, rowData["id"]);
                          }}
                        />
                      </IconButton>
                    ) : null}
                  </>
                );
              }}
            />
            <Column
              label="Patient ID"
              dataKey="id"
              width={((width - 20) / 12) * 2}
            />
            <Column
              label="Name"
              dataKey="patient_name"
              width={((width - 20) / 12) * 2}
            />
            <Column
              label="Date of Birth"
              dataKey="date_of_birth"
              width={((width - 20) / 12) * 2}
            />
            <Column
              label="Insurance"
              dataKey="insurance_name"
              width={((width - 20) / 12) * 2}
            />
            <Column
              label="Prescriber"
              dataKey="prescriber_name"
              width={((width - 20) / 12) * 2}
            />
            <Column
              label="Device Type"
              dataKey="devices"
              width={((width - 20) / 12) * 2}
            />
            <Column
              label="Status"
              dataKey="onboarding_status"
              cellRenderer={OnBoardCell}
              width={((width - 20) / 12) * 2}
            />
            <Column
              label={"Last Result Date"}
              dataKey="last_result_datetime"
              className={styles.LastResultCell}
              cellRenderer={ResultDate}
              width={((width - 20) / 12) * 2}
            />
            {/* <Column label={"More >"} dataKey="" width={100} /> */}
          </Table>
        )}
      </AutoSizer>
    );

    if (status === "loading") {
      content = (
        <div className={styles.LoaderContainer} height={70}>
          <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
        </div>
      );
    } else if (status === "succeeded") {
      content = patientTable;
    }
  } else if (status === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Patients"></Header>
      <div className={styles.PatientsTableContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.SearchSection}>
            <div>
              <SearchSvg className={styles.SearchIcon} />
              <Form.Control
                id="searchLink"
                placeholder="Search patient by name or id"
                bsPrefix={styles.SearchBox}
                value={searchTerm}
                onChange={onSearchTermChanged}
              />
            </div>
            <div style={{ paddingLeft: "12px", paddingTop: "24px" }}>
              <AthelasButton
                variant="search"
                text="Search"
                onClick={searchByNameClicked}
                radius="10"
                size="small"
              />
            </div>
            <div style={{ paddingLeft: "12px", paddingTop: "24px" }}>
              <AthelasButton
                variant="search"
                text="Clear Search"
                onClick={clearSearch}
                radius="10"
                size="small"
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <Select
              id="prescriberSelect"
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              onChange={selectPrescriberForPatientAdd}
              options={prescriberOptions}
              placeholder={"Select Prescriber for Adding Patient"}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "350px",
                  marginTop: "5px",
                }),
              }}
            />

            <div style={{ paddingLeft: "10px" }}>
              <AthelasButton
                variant="success"
                text="Add Patient"
                size="medium"
                disabled={prescriberForAddingPatient === ""}
                disabledToolTipText="Please select a prescriber first"
                onClick={getEnrollmentLinkForPrescriber}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Select
            id="insuranceNameSelected"
            className="basic-single"
            classNamePrefix="select"
            placeholder={"Filter by Insurance"}
            onChange={onInsuranceNameSelected}
            isClearable={true}
            options={insuranceNameOptions}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "240px",
                paddingRight: "20px",
                marginTop: "10px",
              }),
            }}
          />

          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            id="prescriberSelect"
            onChange={onPrescriberSelectedChanged}
            style={{ width: "220px" }}
            placeholder={"Filter by Prescriber"}
            isClearable={true}
            options={prescriberOptions}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "230px",
                paddingRight: "20px",
                marginTop: "10px",
              }),
            }}
          />

          <Select
            isMulti
            id="onboardingSelect"
            name="colors"
            placeholder={"Filter by Status"}
            className="basic-multi-select"
            classNamePrefix="select"
            options={onboardingStatusOptions}
            onChange={handleOnboardingStatusSelectedChanged}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "200px",
                marginTop: "10px",
              }),
            }}
          />

          <ToggleSelect
            enabled={noTestThisMonthSelected}
            onClick={onNoTestThisMonthFilterClick}
            text={"No test last 30 days"}
          />
          <div style={{ flexDirection: "row", justifyContent: "end" }}>
            <AthelasButton
              variant="basic"
              text={isDownloading ? "Downloading..." : "Download as CSV"}
              size="medium"
              disabled={isDownloading}
              onClick={downloadPatientsCSV}
            />
          </div>
        </div>

        <div className={styles.TableCardContainer}>{content}</div>

        <AddPatientModal
          show={showAddPatientModal}
          onHide={() => {
            setShowAddPatientModal(false);
          }}
          onAdd={() => {
            setShowAddPatientModal(false);
          }}
        />
        <div className={styles.PaginationSection}>
          <div className={styles.PaginationLeft}>
            {PAGE_SIZE} patients per page, {totalCount} total patients
          </div>

          <div className={styles.PaginationRight}>
            <Pagination
              previousPageFunc={previousPageFunc}
              nextPageFunc={nextPageFunc}
              currentPageVal={currentPage}
              totalNumberOfPages={totalNumberOfPages}
            />
          </div>
        </div>
      </div>
    </>
  );
};
