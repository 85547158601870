import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Header } from "../header/Header";
import {
  fetchReports,
  selectReportIds,
  selectReportById,
} from "./reportsSlice";

import { statusThunk } from "../user/userSlice";
import styles from "./ReportsList.module.css";
import { IsoDateToMMMYYYY } from "../../utils/date";

import { ReactComponent as ProfileIcon } from "./profile.svg";
import { ReactComponent as ProfileLightIcon } from "./profile-light.svg";
import { ReactComponent as RightIcon } from "./right.svg";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { trackEvent } from "../../utils/tracking";

const ReportAttestStatus = ({ reportId }) => {
  const report = useSelector((state) => selectReportById(state, reportId));

  let content;

  if (report.data.attested) {
    content = (
      <div className={styles.attestStatus + " " + styles.attestReviewed}>
        Reviewed
      </div>
    );
  } else {
    content = (
      <div className={styles.attestStatus + " " + styles.attestNotReviewed}>
        Not Reviewed
      </div>
    );
  }

  const today = new Date();
  const curentMonth = today.getMonth();
  const curentYear = today.getFullYear();
  const reportMonth = parseInt(report.month.slice(5, 7)) - 1;
  const reportYear = parseInt(report.month.slice(0, 4));

  // current month not complete yet
  if (curentMonth === reportMonth && curentYear === reportYear) {
    content = (
      <div className={styles.attestStatus + " " + styles.attestReviewNextMonth}>
        In Progress
      </div>
    );
  }

  return content;
};

const ReportCard = ({ reportId }) => {
  const history = useHistory();
  const report = useSelector((state) => selectReportById(state, reportId));
  const site_id = useSelector((state) => state.user.currSiteId);
  const monthStr = report.month;

  const testedCount = report.data.tested_patient_count;
  const totalPatientsCount = report.data.total_patient_count;

  const series = [(testedCount / totalPatientsCount) * 100, 100];
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontFamily: "Montserrat-Regular",
            offsetY: 7,
          },
          value: {
            fontFamily: "Montserrat-Regular",
            formatter: function (val) {
              return Math.round((val / 100) * totalPatientsCount);
            },
          },
          total: {
            show: true,
            label: testedCount + "/" + totalPatientsCount,
            formatter: function (w) {
              return "";
            },
            color: "#373d3f",
            fontSize: "18px",
            fontFamily: "Montserrat-Regular",
            fontWeight: "bold",
          },
        },
        track: {
          background: "transparent",
        },
      },
    },
    labels: ["Tested", "Total"],
    colors: ["#42A1F8", "#B8D2FF"],
    stroke: {
      lineCap: "round",
      width: 6,
    },
  };

  function onCardClick() {
    history.push("/reports/" + reportId);
  }

  return (
    <div className={styles.ReportCard} onClick={onCardClick}>
      <div className={styles.ReportCardChart}>
        <Chart
          options={options}
          series={series}
          type="radialBar"
          height="220"
          width="220"
          className={styles.ChartDiv}
        />
      </div>

      <div className={styles.ReportCardMonth}>{IsoDateToMMMYYYY(monthStr)}</div>

      <div className={styles.ReportCardDetail}>
        <Link to={"/reports/" + reportId}>
          <span>Details</span>
          <span>
            <RightIcon />
          </span>
        </Link>
        <div>
          <ReportAttestStatus reportId={reportId} />
        </div>
      </div>

      <div className={styles.ReportCardData}>
        <div className={styles.ReportCardDataContent}>
          <div className={styles.FirstDataDiv}>
            <span className={styles.RevenueText}>
              {site_id === 617
                ? "$" + (report.data.tested_patient_count * 35).toFixed(2)
                : "N/A"}
            </span>
            <span className={styles.EarnedText}>Earned</span>
          </div>
          <div>
            <span>
              <ProfileIcon />
            </span>
            <span className={styles.PatientsCountText}>{testedCount}</span>
            <span className={styles.PatientsCountCategoryText}>
              Patients Tested
            </span>
          </div>
          <div>
            <span>
              <ProfileLightIcon />
            </span>
            <span className={styles.PatientsCountText}>
              {totalPatientsCount}
            </span>
            <span className={styles.PatientsCountCategoryText}>
              Total Patients
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReportsList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.reports.status);
  const error = useSelector((state) => state.reports.error);
  const reportIds = useSelector(selectReportIds);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchReports({ site_id, token }));
      trackEvent("view", "viewed_reports_list");
    }
  }, [status, sites, site_id, token, dispatch, history]);

  let content;

  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    const cards = reportIds.map((reportId) => (
      <ReportCard key={reportId} reportId={reportId} />
    ));
    content = <div className={styles.ReportsContainer}>{cards}</div>;
  } else if (status === "error") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Reports"></Header>
      {content}
    </>
  );
};
