import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import sitesAPI from "./sitesAPI";

const initialState = {
  status: "idle",
  error: null,
  test_results: [],
  all_test_results_per_patient: {},
  siteSummary: {},
  siteSummaryStatus: "idle",
  has_cardiac_care_patients: false,
  has_medication_patients: false,
};

export const fetchSiteSummary = createAsyncThunk(
  "sites/fetchSiteSummary",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getSiteSummary(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchLatestTestResults = createAsyncThunk(
  "sites/fetchLatestTestResults",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getLatestTestResults(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const submitReferrals = createAsyncThunk(
  "sites/submitReferrals",
  async ({ site_id, token, numbers, names }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.submitReferrals(
        site_id,
        token,
        numbers,
        names
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitRCMRequestForDemo = createAsyncThunk(
  "sites/submitRCMRequestForDemo",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.submitRCMRequestForDemo(site_id, token);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// duplicated logic, extract later
function transformTestResult(test_result) {
  switch (test_result.test_type) {
    case "GLUCOSE":
      test_result.display_test_value = test_result.glucose_value + " g/dL";
      break;
    case "BLOOD_PRESSURE":
      test_result.display_test_value =
        test_result.systolic + "/" + test_result.diastolic + " mm/Hg";
      break;
    case "WEIGHT":
      test_result.display_test_value = test_result.weight + " pounds";
      break;
    case "CARDIAC_CARE":
      test_result.display_test_value = test_result.weight + " pounds";
      break;
    case "WBC":
      test_result.display_test_value =
        test_result.lymphocytes + "/" + test_result.neutrophils;
      break;
    case "HEART_RATE":
      test_result.display_test_value = test_result.bpm + " bpm";
      break;
    case "PULSE_OXIMETRY":
      test_result.display_test_value = test_result.spo2 + "% SpO2";
      break;
    default:
      test_result.display_test_value = test_result.value;
  }
  return test_result;
}

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchLatestTestResults.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchLatestTestResults.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        const transformedTestResults = action.payload.test_results.map(
          transformTestResult
        );
        state.test_results = transformedTestResults;

        state.status = "succeeded";
      }
    },
    [fetchLatestTestResults.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchSiteSummary.fulfilled]: (state, action) => {
      state.siteSummaryStatus = "succeeded";

      const summary = action.payload;
      state.siteSummary = summary;
      state.has_cardiac_care_patients = summary.has_cardiac_care_patients;
      state.has_medication_patients = summary.has_medication_patients;
    },
    [fetchSiteSummary.rejected]: (state, action) => {
      state.siteSummaryStatus = "failed";
    },
    [fetchSiteSummary.pending]: (state, action) => {
      state.siteSummaryStatus = "pending";
    },
  },
});

export const { reset } = homeSlice.actions;

export default homeSlice.reducer;
