import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import moment from "moment";
import sitesAPI from "../home/sitesAPI";
import { capitalizeFirstLetter } from "../../utils/utils";

import claimsOverviewAPI from "./claimsOverviewAPI";

const claimsOverviewAdapter = createEntityAdapter({
  selectId: (claim) => claim.id,
  sortComparer: (a, b) =>
    new Date(a.date_of_submission) < new Date(b.data_of_submission) ? 1 : -1,
});

const initialState = claimsOverviewAdapter.getInitialState({
  allTimeStatsStatus: "idle",
  filteredClaimsAndStatsStatus: "idle",
  singleClaimStatus: "idle",
  allTimeStatsError: null,
  filteredClaimsAndStatsError: null,
  total_insurance_reimbursements: 0,
  average_reimbursement_per_claim: 0,
  percentage_claims_approved: 0,
  filtered_total_insurance_reimbursements: 0,
  filtered_average_reimbursement_per_claim: 0,
  filtered_percent_claims_approved: 0,
  filtered_total_submissions: 0,
  from_date_filter: moment().subtract(180, "days").format("MM/DD/YYYY"),
  to_date_filter: moment().format("MM/DD/YYYY"),
  claim_status_filter: "",
  patient_first_name_filter: "",
  patient_last_name_filter: "",
  insurance_name_filter: "",
  provider_first_name_filter: "",
  provider_last_name_filter: "",
  prescribers: [],
  insurance_names: [],
});

export const fetchAllTimeStats = createAsyncThunk(
  "claimsOverview/fetchAllTimeStats",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await claimsOverviewAPI.get_all_time_claims_metrics(
        site_id,
        token
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchFilteredClaimsAndStats = createAsyncThunk(
  "claimsOverview/fetchFilteredClaimsAndStats",
  async (
    {
      site_id,
      token,
      to_date,
      from_date,
      claim_status,
      patient_first_name,
      patient_last_name,
      insurance_name,
      provider_first_name,
      provider_last_name,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await claimsOverviewAPI.get_filtered_site_claims(
        site_id,
        token,
        to_date,
        from_date,
        claim_status,
        patient_first_name,
        patient_last_name,
        insurance_name,
        provider_first_name,
        provider_last_name
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSitePrescribersThunk = createAsyncThunk(
  "site/fetchPrescribers",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getSitePrescribers(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchInsuranceNames = createAsyncThunk(
  "site/fetchInsuranceNames",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await claimsOverviewAPI.get_insurance_names(
        site_id,
        token
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchSingleClaimInfo = createAsyncThunk(
  "claimsOverview/fetchSingleClaimInfo",
  async ({ site_id, token, claim_id }, { rejectWithValue }) => {
    try {
      const response = await claimsOverviewAPI.get_single_claim_info(
        site_id,
        token,
        claim_id
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateClaimStatus = createAsyncThunk(
  "claimsOverview/updateClaimStatus",
  async (
    { site_id, token, claim_id, status_to_update },
    { rejectWithValue }
  ) => {
    try {
      const response = await claimsOverviewAPI.update_claim_status_for_single_claim(
        site_id,
        token,
        claim_id,
        status_to_update
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const claimsOverviewSlice = createSlice({
  name: "claimsOverview",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setDateFilters: (state, action) => {
      state.from_date_filter = action.payload.from_date;
      state.to_date_filter = action.payload.to_date;
      return state;
    },
    setPatientNameFilters: (state, action) => {
      state.patient_first_name_filter = action.payload.first_name;
      state.patient_last_name_filter = action.payload.last_name;
      return state;
    },
    setProviderNameFilters: (state, action) => {
      state.provider_first_name_filter = action.payload.first_name;
      state.provider_last_name_filter = action.payload.last_name;
      return state;
    },
    setClaimStatusFilter: (state, action) => {
      state.claim_status_filter = action.payload.status;
      return state;
    },
    setInsuranceNameFilter: (state, action) => {
      state.insurance_name_filter = action.payload.insurance_name;
      return state;
    },
  },
  extraReducers: {
    [fetchAllTimeStats.pending]: (state, action) => {
      state.allTimeStatsStatus = "loading";
    },
    [fetchAllTimeStats.fulfilled]: (state, action) => {
      if (state.allTimeStatsStatus === "loading") {
        state.total_insurance_reimbursements =
          action.payload.total_insurance_reimbursements;
        state.average_reimbursement_per_claim =
          action.payload.average_reimbursement_per_claim;
        state.percentage_claims_approved =
          action.payload.percentage_claims_approved;
        state.allTimeStatsStatus = "succeeded";
      }
    },
    [fetchAllTimeStats.rejected]: (state, action) => {
      if (state.allTimeStatsStatus === "loading") {
        state.allTimeStatsStatus = "failed";
        state.allTimeStatsError = action.payload;
      }
    },
    [fetchFilteredClaimsAndStats.pending]: (state, action) => {
      state.filteredClaimsAndStatsStatus = "loading";
    },
    [fetchFilteredClaimsAndStats.fulfilled]: (state, action) => {
      if (state.filteredClaimsAndStatsStatus === "loading") {
        claimsOverviewAdapter.removeAll(state);
        state.filtered_total_insurance_reimbursements =
          action.payload.total_insurance_reimbursements;
        state.filtered_average_reimbursement_per_claim =
          action.payload.average_reimbursement_per_claim;
        state.filtered_percent_claims_approved =
          action.payload.get_percent_claims_approved;
        state.filtered_total_submissions = action.payload.get_total_submissions;
        claimsOverviewAdapter.upsertMany(
          state,
          action.payload.filtered_claims.map((x) => {
            x["insurance_name"] = capitalizeFirstLetter(x["insurance_name"]);
            if (
              x["status"] === "Submitted" ||
              x["status"] === "Rejected" ||
              x["status"] === "PROVIDER_APPROVAL_PENDING" ||
              x["status"] === "PROVIDER_APPROVED" ||
              x["status"] === "PROVIDER_REJECTED"
            ) {
              x["insurance_payout"] = -1;
            }
            return x;
          })
        );
        state.filteredClaimsAndStatsStatus = "succeeded";
      }
    },
    [fetchFilteredClaimsAndStats.rejected]: (state, action) => {
      if (state.filteredClaimsAndStatsStatus === "loading") {
        state.filteredClaimsAndStatsStatus = "failed";
        state.filteredClaimsAndStatsError = action.payload;
      }
    },
    [fetchSitePrescribersThunk.fulfilled]: (state, action) => {
      state.prescribers = action.payload.prescribers;
    },
    [fetchInsuranceNames.fulfilled]: (state, action) => {
      state.insurance_names = action.payload.map((x) =>
        capitalizeFirstLetter(x)
      );
    },
    [fetchSingleClaimInfo.fulfilled]: (state, action) => {
      if (state.singleClaimStatus === "loading") {
        action.payload["insurance_name"] = capitalizeFirstLetter(
          action.payload["insurance_name"]
        );
        if (
          action.payload["status"] === "Submitted" ||
          action.payload["status"] === "Rejected" ||
          action.payload["status"] === "PROVIDER_APPROVAL_PENDING" ||
          action.payload["status"] === "PROVIDER_APPROVED" ||
          action.payload["status"] === "PROVIDER_REJECTED"
        ) {
          action.payload["insurance_payout"] = -1;
        }
        claimsOverviewAdapter.upsertOne(state, action.payload);
        state.singleClaimStatus = "succeeded";
      }
    },
    [fetchSingleClaimInfo.pending]: (state, action) => {
      state.singleClaimStatus = "loading";
    },
    [fetchSingleClaimInfo.rejected]: (state, action) => {
      if (state.singleClaimStatus === "loading") {
        state.singleClaimStatus = "failed";
      }
    },
  },
});

export const {
  reset,
  setDateFilters,
  setPatientNameFilters,
  setProviderNameFilters,
  setClaimStatusFilter,
  setInsuranceNameFilter,
} = claimsOverviewSlice.actions;

export const {
  selectAll: selectAllClaims,
  selectById: selectClaimById,
  selectIds: selectClaimIds,
} = claimsOverviewAdapter.getSelectors((state) => state.claimsOverview);

export default claimsOverviewSlice.reducer;
