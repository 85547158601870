// lib
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useBeforeUnload } from "react-use";
import Form from "react-bootstrap/Form";

// styles & svg
import styles from "./AuthPage.module.css";
import { ReactComponent as DoctorsSvg } from "./doctors.svg";
import { ReactComponent as LogoSvg } from "../../app/assets/logo.svg";

// slice
import { logout, loginThunk, statusThunk, verifyMFAThunk } from "./userSlice";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";

export const AuthPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [OTP, setOTP] = useState("");

  const errorMsg = useSelector((state) => state.user.errorMsg);
  const requires_mfa = useSelector((state) => state.user.requires_mfa);

  const dispatch = useDispatch();
  const history = useHistory();

  const onEmailChanged = (e) => setEmail(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const onOTPChanged = (e) => setOTP(e.target.value);

  const forScribble = props.forScribble || false;

  // reset state when refresh
  // so don't stuck on OTP page
  useBeforeUnload(() => dispatch(logout()));

  const onLoginClicked = async () => {
    if (email && password) {
      try {
        const resultAction = await dispatch(loginThunk({ email, password }));
        const loginResponse = unwrapResult(resultAction);

        // no OTP required nor activated
        if ("auth_token" in loginResponse) {
          const token = loginResponse.auth_token;
          dispatch(statusThunk({ token }));
          if (forScribble) {
            history.push("/scribble");
          } else {
            history.push("/home");
          }
        }
      } catch (err) {
        console.error("Failed to login: ", err);
      }
    }
  };

  const onVerifyOTPClicked = async () => {
    if (email && OTP) {
      try {
        const resultAction = await dispatch(verifyMFAThunk({ email, OTP }));
        const loginResponse = unwrapResult(resultAction);
        const token = loginResponse.auth_token;
        dispatch(statusThunk({ token }));
        if (forScribble) {
          history.push("/scribble");
        } else {
          history.push("/home");
        }
      } catch (err) {
        console.log("Failed to verify OTP");
      }
    }
  };

  const passwordFieldKeyDown = (event) => {
    if (event.key === "Enter") {
      // prevent form submission
      event.preventDefault();
      onLoginClicked();
    }
  };

  const OTPFieldKeyDown = (event) => {
    if (event.key === "Enter") {
      // prevent form submission
      event.preventDefault();
      onVerifyOTPClicked();
    }
  };

  const loginForm = (
    <>
      <Form.Group controlId="formBasicEmail" className={styles.loginFormGroup}>
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="Username"
          placeholder="Enter username"
          value={email}
          onChange={onEmailChanged}
        />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={onPasswordChanged}
          onKeyDown={passwordFieldKeyDown}
        />
      </Form.Group>

      <Form.Group>
        <Form.Text className="text-danger">
          <b>{errorMsg}</b>
        </Form.Text>
      </Form.Group>

      <div style={{ width: "50%" }}>
        <AthelasButton text="Sign In" size="small" onClick={onLoginClicked} />
      </div>

      <p style={{ marginTop: "16px" }}>
        <a href="password_reset">Forgot Password?</a>
      </p>
    </>
  );

  const optForm = (
    <>
      <Form.Group controlId="formBasicOTP" className={styles.loginFormGroup}>
        <Form.Label>Enter your OTP below</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter OTP"
          value={OTP}
          onChange={onOTPChanged}
          onKeyDown={OTPFieldKeyDown}
        />
      </Form.Group>

      <Form.Group>
        <Form.Text className="text-danger">
          <b>{errorMsg}</b>
        </Form.Text>
      </Form.Group>
      <div style={{ width: "50%" }}>
        <AthelasButton
          size="small"
          onClick={onVerifyOTPClicked}
          text="Verify OTP"
        />
      </div>
    </>
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.LeftImageContainer}>
        <DoctorsSvg />
      </div>
      <div className={styles.RightLoginContainer}>
        <div>
          <LogoSvg className={styles.LogoSVG} />
          <h3 className={styles.SignInHeader}>Sign In</h3>
          <Form>{requires_mfa ? optForm : loginForm}</Form>
        </div>
      </div>
    </div>
  );
};
