import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import testResultsAPI from "./testResultsAPI";
import sitesAPI from "../home/sitesAPI";
import { PAGE_SIZE } from "./consts";

const initialState = {
  status: "idle",
  error: null,
  test_results: [],
  last_thousand_test_results: [],
  totalCount: null,
  totalNumberOfPages: null,
  outOfRangeSelected: false,
  largeChangeSelected: false,
  currentPage: 1,
  prescribers: [],
  prescriberSelected: null,
};

export const fetchSitePrescribersThunk = createAsyncThunk(
  "site/fetchPrescribers",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getSitePrescribers(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchTestResultSlice = createAsyncThunk(
  "testResults/fetchTestResultSlice",
  async (
    {
      site_id,
      token,
      prescriber_id,
      out_of_range,
      large_change,
      page_size,
      offset,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await testResultsAPI.getTestResultPageSlice(
        site_id,
        token,
        prescriber_id,
        out_of_range,
        large_change,
        page_size,
        offset
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchLastThousandTestResultSlice = createAsyncThunk(
  "testResults/fetchLastThousandTestResultSlice",
  async (
    {
      site_id,
      token,
      prescriber_id,
      out_of_range,
      large_change,
      page_size,
      offset,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await testResultsAPI.getLastThousandTestResultSlice(
        site_id,
        token,
        prescriber_id,
        out_of_range,
        large_change,
        page_size,
        offset
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

// transform test result
// adding unified display vlaue with unit
export function transformTestResult(test_result) {
  switch (test_result.test_type) {
    case "GLUCOSE":
      test_result.display_test_value = test_result.glucose_value + " g/dL";
      break;
    case "BLOOD_PRESSURE":
      test_result.display_test_value =
        test_result.systolic + "/" + test_result.diastolic + " mm/Hg";
      break;
    case "WEIGHT":
      test_result.display_test_value = test_result.weight + " pounds";
      break;
    case "WBC":
      test_result.display_test_value =
        test_result.lymphocytes + "/" + test_result.neutrophils;
      break;
    case "HEART_RATE":
      test_result.display_test_value = test_result.bpm + " bpm";
      break;
    case "CARDIAC_CARE":
      test_result.display_test_value = test_result.weight + " pounds";
      break;
    case "PULSE_OXIMETRY":
      test_result.display_test_value = test_result.spo2 + "% SpO2";
      break;
    default:
      test_result.display_test_value = test_result.value;
  }

  test_result.cpt_codes = "99454, 99457";

  return test_result;
}

const testResultsSlice = createSlice({
  name: "testResults",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setOutOfRangeSelected: (state) => {
      state.outOfRangeSelected = !state.outOfRangeSelected;
      state.currentPage = 1;
      return state;
    },
    setLargeChangeSelected: (state) => {
      state.largeChangeSelected = !state.largeChangeSelected;
      state.currentPage = 1;
      return state;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.targetPage;
      return state;
    },
    setPrescriberSelected: (state, action) => {
      state.prescriberSelected = action.payload.prescriberId;
      state.currentPage = 1;
      return state;
    },
  },
  extraReducers: {
    [fetchSitePrescribersThunk.fulfilled]: (state, action) => {
      state.prescribers = action.payload.prescribers;
    },
    [fetchTestResultSlice.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchTestResultSlice.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        const transformedTestResults = action.payload.test_results.map(
          transformTestResult
        );
        state.test_results = transformedTestResults;
        state.totalCount = action.payload.test_results_query_total_count;
        state.totalNumberOfPages = Math.ceil(
          action.payload.test_results_query_total_count / PAGE_SIZE
        );

        state.status = "succeeded";
      }
    },
    [fetchTestResultSlice.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchLastThousandTestResultSlice.fulfilled]: (state, action) => {
      const transformedLastThousandTestResults = action.payload.test_results.map(
        transformTestResult
      );
      state.last_thousand_test_results = transformedLastThousandTestResults;
    },
    [fetchLastThousandTestResultSlice.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
  },
});

export const {
  reset,
  setOutOfRangeSelected,
  setLargeChangeSelected,
  setCurrentPage,
  setPrescriberSelected,
} = testResultsSlice.actions;

export default testResultsSlice.reducer;
