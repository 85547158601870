import styles from "./ResetPasswordPage.module.css";
import { useWindowSize } from "../../utils/useWindowResize";
import { useEffect, useState } from "react";
import userAPI from "./userAPI";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import AthelasLogo from "../../app/assets/logo.svg";
import PasswordResetImage from "./assets/password_reset.svg";
import SuccessImage from "./assets/success.svg";
import { PillButton } from "../../app/Atoms/PillButton/PillButton";
import { Form } from "react-bootstrap";

type PropsType = {};

export const ResetPasswordPage = (props: PropsType) => {
  const size = useWindowSize();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [mode, setMode] = useState("requestReset"); // requestReset, checkEmail, changePassword, success
  const [passwordError, setPasswordError] = useState("");
  const history = useHistory();

  const value = queryString.parse(window.location.search);
  useEffect(() => {
    const token = value["token"];
    if (token && token !== "" && mode !== "success") {
      setMode("changePassword");
    }
    setToken(token);
  }, [value, mode]);

  const sendEmail = () => {
    userAPI.sendPasswordResetEmail(email);
    setMode("checkEmail");
  };

  const resetPassword = () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    userAPI.resetPasswordViaToken(token, password).then((res) => {
      const data = res["data"];
      const error = data["error"];

      if (error) {
        setPasswordError(error);
      } else {
        setMode("success");
      }
    });
  };

  const requestReset = () => {
    return (
      <>
        <img src={AthelasLogo} className={styles["logo"]} alt="Athelas logo" />
        <img
          src={PasswordResetImage}
          className={styles["password-reset-image"]}
          alt="Password reset"
        />
        <h4>
          <b>Reset Password</b>
        </h4>
        <p>
          Enter the email associated with your account and we'll send you
          intructions to reset your password.
        </p>
        <p className={styles["label"]}>ACCOUNT EMAIL</p>
        <input
          placeholder="cynthia@athelas.com"
          className={styles["email_input"]}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <PillButton
          text="Send Password Reset Email"
          variant="info"
          onClick={sendEmail}
        />
      </>
    );
  };

  const checkEmail = () => {
    return (
      <>
        <img src={AthelasLogo} className={styles["logo"]} alt="Athelas logo" />
        <h4>
          <b>Reset Password</b>
        </h4>
        <p>
          In a few minutes or less, you will receive a special password reset
          link.<br></br>
          <br></br> Do <b>not</b> share this link with anyone else.
        </p>
        <hr></hr>
        <p>
          Didn't receive an email from us? Check your Spam folder or,{" "}
          <u>
            <span style={{ cursor: "pointer" }} onClick={sendEmail}>
              try sending another email.{" "}
            </span>
          </u>
        </p>
      </>
    );
  };

  const changePassword = () => {
    return (
      <>
        <img src={AthelasLogo} className={styles["logo"]} alt="Athelas logo" />
        <h4>
          <b>Change Password</b>
        </h4>
        <p>Go ahead and enter your new password below.</p>

        <Form.Text className="text-muted" style={{ marginTop: "16px" }}>
          Passwords must have:
          <ul>
            <li>A number</li>
            <li>A special character</li>
            <li>Uppercase character</li>
            <li>At least 10 characters</li>
          </ul>
        </Form.Text>

        <p className={styles["label"]}>NEW PASSWORD</p>
        <input
          type="password"
          placeholder="***********"
          className={styles["email_input"]}
          onChange={(e) => {
            setPasswordError("");
            setPassword(e.target.value);
          }}
        />
        <p className={styles["label"]}>CONFIRM NEW PASSWORD</p>
        <input
          type="password"
          placeholder="***********"
          className={styles["email_input"]}
          onChange={(e) => {
            setPasswordError("");
            setConfirmPassword(e.target.value);
          }}
        />
        <p className={styles["errorMessage"]}>{passwordError}</p>
        <PillButton
          variant="info"
          text="Reset Password"
          onClick={resetPassword}
          disabled={confirmPassword !== password || password.length < 8}
        />
      </>
    );
  };

  const success = () => {
    return (
      <>
        <img src={AthelasLogo} className={styles["logo"]} alt="Athelas logo" />
        <h4>
          <b>Reset Success</b>
        </h4>
        <img
          src={SuccessImage}
          className={styles["password-reset-image"]}
          alt="success"
        />
        <p>
          Great! Your password has successfully been reset. Go ahead and try
          logging back in by visiting the link below.
        </p>
        <p className={styles["errorMessage"]}>{passwordError}</p>
        <PillButton
          variant="info"
          text="Login"
          onClick={() => {
            history.push("/");
          }}
        />
      </>
    );
  };

  const stepsMap: any = {
    requestReset: requestReset(),
    checkEmail: checkEmail(),
    changePassword: changePassword(),
    success: success(),
  };

  const content = stepsMap[mode];

  return (
    <div style={{ width: size.width, height: size.height }}>
      <div className={styles["container"]}>{content}</div>
    </div>
  );
};
