import React from "react";
import { DownloadNurseNotes } from "./DownloadNurseNotes";
import { DownloadResults } from "./DownloadResults";
import { DownloadPatientReport } from "./DownloadPatientReport";

type PropsType = {
  patientId: number;
  testResults: any;
  name: Text;
};

export const DownloadTab = (props: PropsType) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        overflowX: "auto",
      }}
    >
      <div style={{ flex: "1", margin: "0 10px", minWidth: "300px" }}>
        <DownloadNurseNotes patientId={props.patientId} name={props.name} />
      </div>
      <div style={{ flex: "1", margin: "0 10px", minWidth: "300px" }}>
        <DownloadResults
          patientId={props.patientId}
          testResults={props.testResults}
          name={props.name}
        />
      </div>
      <div style={{ flex: "1", margin: "0 10px", minWidth: "300px" }}>
        <DownloadPatientReport patientId={props.patientId} name={props.name} />
      </div>
    </div>
  );
};
