import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";

import styles from "./Scribble.module.css";
import { createNote, fetchNotes, selectAllNotes } from "./ScribbleSlice";
import logo from "./logo_white.svg";

const RecordingSummary = (props) => {
  let name = props.name;
  let status = props.status;
  let date = props.date;
  return (
    <div onClick={props.onClick} className={styles.RecordingSummary}>
      <div>
        <div className={styles.RecordingTitle}>{name}</div>
        <div className={styles.RecordingStatus}>
          Status: {status.toUpperCase()}
        </div>
      </div>
      <div className={styles.RecordingDate}>{date}</div>
    </div>
  );
};

export const Scribble = () => {
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.notes.status);

  const dispatch = useDispatch();

  let mediaRecorder = useRef(null);

  const [stopped, setStopped] = useState(true);
  const [screen, setScreen] = useState("main");
  const [activeTab, setActiveTab] = useState("Summary");
  const [currentNote, setCurrentNote] = useState(null);

  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const notes = useSelector(selectAllNotes);

  useEffect(() => {
    if (Object.keys(sites).length !== 0 && site_id) {
      if (status === "idle") {
        dispatch(fetchNotes({ site_id, token }));
      }
    }
  });

  const handleAudioRecording = (stream) => {
    //const options = { mimeType: "audio/webm" };
    mediaRecorder.current = new MediaRecorder(stream, {});

    mediaRecorder.current.addEventListener("dataavailable", function (e) {
      let defaultRecordingName = `Recording ${notes.length + 1}`;
      let recordingName = prompt(
        "Please enter a name for the recording",
        defaultRecordingName
      );

      dispatch(
        createNote({
          token,
          site_id: site_id,
          name: recordingName,
          blob: e.data,
        })
      );
    });

    mediaRecorder.current.addEventListener("stop", function () {
      console.log("stopped recording");
      setStopped(true);
    });

    mediaRecorder.current.start();
  };

  const toggleRecording = (e) => {
    if (stopped) {
      console.log("started recording");
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(handleAudioRecording);
      setStopped(false); // we are now playing
    } else {
      console.log("stopping recording");
      mediaRecorder.current.stop();
    }
  };

  const handleRecordingClick = (n) => {
    return () => {
      setScreen("details");
      setCurrentNote(n);
    };
  };

  const getContent = () => {
    let content = null;
    let recordButtonColor = stopped ? "red" : "darkred";
    if (screen === "main") {
      content = (
        <div className={styles.Recordings}>
          <div className={styles.RecordingHeader}> All Recordings </div>
          {notes.map((n) => (
            <RecordingSummary
              onClick={handleRecordingClick(n)}
              date={n.date}
              key={n.id}
              name={n.name}
              status={n.status}
            />
          ))}
          <div className={styles.RecordingFooter}>
            <div
              style={{ background: recordButtonColor }}
              className={styles.RecordButton}
              onClick={toggleRecording}
            >
              {" "}
            </div>
            <div className={styles.RecordLogo}>
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      );
    } else if (screen === "details" && currentNote) {
      let currentSummary = currentNote.summary;
      if (currentSummary === null) {
        currentSummary = "Summary is pending!";
      }

      let currentTranscript = currentNote.transcript;
      if (currentTranscript === null) {
        currentTranscript = "Transcript is pending!";
      }

      content = (
        <div>
          <div className={styles.DetailsHeader}>
            <button onClick={() => setScreen("main")}>Back</button>
            <h4 className={styles.DetailsTitle}>{currentNote.name}</h4>
          </div>
          <div
            className={
              activeTab !== "Summary" ? styles.InactiveTab : styles.ActiveTab
            }
          >
            <p>{currentSummary}</p>
          </div>
          <div
            className={
              activeTab !== "Transcription"
                ? styles.InactiveTab
                : styles.ActiveTab
            }
          >
            <p>{currentTranscript}</p>
          </div>
          <div className={styles.TabButtons}>
            <button
              className={activeTab !== "Summary" ? "" : styles.ActiveButton}
              onClick={() => setActiveTab("Summary")}
            >
              Summary
            </button>
            <button
              className={
                activeTab !== "Transcription" ? "" : styles.ActiveButton
              }
              onClick={() => setActiveTab("Transcription")}
            >
              Transcription
            </button>
          </div>
        </div>
      );
    }

    return content;
  };

  return <div>{getContent()}</div>;
};
