export function track(
  action: "view" | "click",
  user_email: string,
  tag: string,
  info: any,
  persistent_cookie: string
) {
  const payload = {
    action: action,
    data: {
      user_email: user_email,
      persistent_cookie: persistent_cookie,
      info: info,
    },
    tag: tag,
    application: "doctor_application",
  };

  return fetch(`${process.env.REACT_APP_HOST}/tracking/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    console.log("tracked.");
    console.log(res);
  });
}

export const trackEvent = (action: "click" | "view", tag: string) => {
  const user_email = localStorage.getItem("user_email") || "";
  const impersonationActive = localStorage.getItem("impersonate");

  const persistent_cookie = localStorage.getItem("persistent_cookie") || "";
  track(
    action,
    user_email,
    tag,
    {
      impersonation_active: impersonationActive,
    },
    persistent_cookie
  );
};
