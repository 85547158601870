import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";

// import fonts
import "./fonts/Montserrat-Black.ttf";
import "./fonts/Montserrat-BlackItalic.ttf";
import "./fonts/Montserrat-Bold.ttf";
import "./fonts/Montserrat-BoldItalic.ttf";
import "./fonts/Montserrat-ExtraBold.ttf";
import "./fonts/Montserrat-ExtraBoldItalic.ttf";
import "./fonts/Montserrat-ExtraLight.ttf";
import "./fonts/Montserrat-ExtraLightItalic.ttf";
import "./fonts/Montserrat-Italic.ttf";
import "./fonts/Montserrat-Light.ttf";
import "./fonts/Montserrat-LightItalic.ttf";
import "./fonts/Montserrat-Medium.ttf";
import "./fonts/Montserrat-MediumItalic.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-SemiBoldItalic.ttf";
import "./fonts/Montserrat-Thin.ttf";
import "./fonts/Montserrat-ThinItalic.ttf";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_50ohomqEWgZJ7GE6TKaTaboC00gmRhGJRH");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
