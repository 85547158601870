import axios from "axios";

const reportsAPI = {
  get: (site_id, token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/sites/${site_id}/reports`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  attest: (site_id, token, data) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/report_attestations`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default reportsAPI;
