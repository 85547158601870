import React from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Table } from "react-bootstrap";
import moment from "moment";
import AthelasLogo from "./assets/athelasLogo.png";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { trackEvent } from "../../utils/tracking";
import { ExportTestResultsSinglePatientAsCSVFile } from "../../utils/csv";

type PropsType = {
  patientId: number;
  testResults: any;
  name: Text;
};

const RESULT_ATTRIBUTE_MAP = {
  WEIGHT: ["weight"],
  HEART_RATE: ["bpm"],
  BLOOD_PRESSURE: ["systolic", "diastolic"],
  CARDIAC_CARE: ["cardiac_care_weight_lbs"],
  GLUCOSE: ["glucose_value"],
  PULSE_OXIMETRY: ["spo2"],
};

export function DownloadResults(props: PropsType) {
  const contentArea = React.createRef();

  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      fileName: `RPM_${props.name}_Results`.replace(" ", "_"),
      paperSize: "A4",
    });
    trackEvent("click", "download_test_results");
  };

  const resultsMap = {};
  props.testResults.map((result) => {
    if (!Object.keys(resultsMap).includes(result.test_type)) {
      resultsMap[result.test_type] = [];
    }
    if (result.test_type !== "UNKNOWN") {
      resultsMap[result.test_type].push(result);
    }
    return null;
  });
  Object.keys(resultsMap).forEach((key) => {
    resultsMap[key] = resultsMap[key].reverse();
  });

  const downloadTestResultCSV = (testResultsConcat) => {
    ExportTestResultsSinglePatientAsCSVFile(
      testResultsConcat,
      `RPM_${props.name}_Results`.replace(" ", "_")
    );
  };

  const handleCSVExport = () => {
    const testResultsConcat = [];
    let tests = Object.keys(resultsMap);
    tests.forEach((test_type) => {
      if (test_type !== "UNKNOWN") {
        testResultsConcat.push(...resultsMap[test_type]);
      }
    });
    downloadTestResultCSV(testResultsConcat);
  };

  const getDataUnits = (testType) => {
    let value = "";
    switch (testType) {
      case "GLUCOSE":
        value = `g/dL`;
        break;
      case "BLOOD_PRESSURE":
        value = ``;
        break;
      case "WEIGHT":
        value = `pounds`;
        break;
      case "WBC":
        value = latest.wbc_count;
        break;
      case "HEART_RATE":
        value = `bpm`;
        break;
      case "PULSE_OXIMETRY":
        value = `SpO2`;
        break;
      default:
        value = "";
    }
    return value;
  };

  const generateResultsTable = (test_type, data) => {
    if (test_type === "UNKNOWN") {
      return null;
    }
    return (
      <>
        <h2 style={{ marginLeft: -2, fontSize: 16 }}>
          {test_type.replaceAll("_", " ").toUpperCase()}
        </h2>
        <p>
          Test results for the {test_type.replaceAll("_", " ").toUpperCase()}{" "}
          RPM program in reverse chronological order by test time.
        </p>
        <Table
          style={{ width: "100%", fontSize: "10px", border: "solid 1px black" }}
        >
          <thead>
            <th style={{ textAlign: "left" }}>Test Type</th>
            <th style={{ textAlign: "left" }}>Date</th>
            {RESULT_ATTRIBUTE_MAP[test_type].map((attribute) => {
              return (
                <th style={{ textAlign: "left" }}>
                  {attribute.toUpperCase().replaceAll("_", " ")}
                </th>
              );
            })}
          </thead>
          <tbody>
            {resultsMap[test_type].map((data) => {
              return (
                <tr>
                  <td>{test_type.replaceAll("_", " ").toUpperCase()}</td>
                  <td>{moment(data["test_time"]).format("MMM D, YYYY")}</td>
                  {RESULT_ATTRIBUTE_MAP[test_type].map((attribute) => {
                    return (
                      <td>
                        {data[attribute]} {getDataUnits(test_type)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <hr />
      </>
    );
  };
  return (
    <div>
      <PDFExport ref={null} paperSize="A4">
        <div
          style={{
            background: "white",
            padding: 32,
            borderRadius: 16,
            boxShadow: "0px 0px 10px #dedede",
            minWidth: 400,
          }}
        >
          <h5 style={{ marginLeft: "-8px" }}>Download Test Results</h5>
          <p>
            Export file contains segmented test results for{" "}
            {Object.keys(resultsMap).length} different RPM programs. There are a
            total of {props.testResults.length} different test results in this
            file.
          </p>
          <p>This file can be attached to the EHR entries for this patient.</p>

          <div style={{ width: 300, marginTop: 16, margin: "0% auto" }}>
            <AthelasButton
              variant="info"
              size="small"
              text="Download Patient Test Results (PDF)"
              onClick={handleExportWithFunction}
            />
          </div>

          <div style={{ width: 300, margin: "5% auto" }}>
            <AthelasButton
              variant="info"
              size="small"
              text="Download Patient Test Results (CSV)"
              onClick={handleCSVExport}
            />
          </div>
        </div>
        {/* PDF Content for Test Results */}
        <div style={{ opacity: 0, height: 0 }}>
          <div
            style={{
              padding: 32,
              fontSize: 10,
            }}
            ref={contentArea}
          >
            <hr></hr>
            <img src={AthelasLogo} style={{ width: 130 }} alt="Athelas Logo" />
            <h2 style={{ marginLeft: -2, fontSize: 18 }}>
              Results for {props.name}
            </h2>
            <p>
              This document contains Athelas Remote Patient Monitoring test
              results for {props.name}.
            </p>
            <p>
              This document was exported on{" "}
              {moment(Date.now()).format("MMMM D, YYYY")} and contains segmented
              test results for {Object.keys(resultsMap).length} different RPM
              programs. There are a total of {props.testResults.length}{" "}
              different test results in this file.
            </p>
            <hr />
            <br></br>
            {Object.keys(resultsMap).map((test_type) =>
              generateResultsTable(test_type, resultsMap[test_type])
            )}
          </div>
        </div>
      </PDFExport>
    </div>
  );
}
