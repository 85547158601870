import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import patientsAPI from "./patientsAPI";
import sitesAPI from "../home/sitesAPI";
import { PAGE_SIZE } from "./consts";
import moment from "moment";
import "moment-timezone";

const patientsAdapter = createEntityAdapter({
  selectId: (patient) => patient.id,
  sortComparer: (a, b) =>
    new Date(a.last_result_datetime) < new Date(b.last_result_datetime)
      ? 1
      : -1,
});

const initialState = patientsAdapter.getInitialState({
  status: "idle",
  singlePatientStatus: "idle",
  error: null,
  cardiacCareStatus: "idle",
  cardiacCareSummary: {},
  cardiacCarePatientsListStatus: "idle",
  totalCount: null,
  totalNumberOfPages: null,
  currentPage: 1,
  prescribers: [],
  prescriberSelected: null,
  statusSelected: null,
  searchTerm: "",
  noTestThisMonthSelected: false,
  insuranceNames: [],
  insuranceNameSelected: "",
});

export function getOnboardingStatus(backend_rpm_onboard_status) {
  switch (backend_rpm_onboard_status) {
    case "PENDING":
    case "SENT_ONBOARDING_TEXT":
    case "SENT_ONBOARDING_TEXT_WITH_REMINDER":
    case "SENT_PATIENT_CONNECT":
    case "ATTEMPTED_CONTACT":
    case "ATTEMPTED_CONTACT_2":
    case "ATTEMPTED_CONTACT_3":
      return "Pending";
    case "ATTEMPTED_COMPLIANCE_CALL":
    case "CONFIRMED_ENROLLMENT":
      return "Confirmed Enrollment";
    case "BILLING_CHECK_REJECTED":
      return "Unbillable";
    case "BILLING_CHECK_APPROVED":
      return "Billing Check Approved";
    case "BILLING_CHECK_SKIPPED":
      return "Billing Check Skipped";
    case "BILLING_CHECK_IN_PROGRESS":
      return "Billing Check in Progress";
    case "SHIPPING_LABEL_CREATED":
      return "Shipping Label Created";
    case "SHIPPED_KIT":
      return "Shipped";
    case "DROPPED_OUT":
      return "Dropped Out";
    case "DECLINED_WITHOUT_ENROLLMENT":
      return "Declined";
    case "TESTED":
      return "Tested";
    case "PAUSED_NOTIFY":
      return "Paused Texts";
    default:
      return "Pending";
  }
}

// transfrom backend patient data to for easy displaying
function transformPatient(patient) {
  patient.test_results = patient.test_results.map((test_result) => {
    test_result.test_time = moment
      .utc(test_result.test_time)
      .tz(patient.patient_timezone)
      .format();
    return test_result;
  });

  // set last result date
  if (patient.test_results.length === 0) {
    patient.last_result_date = "No Result";
  } else {
    const last_test = patient.test_results[patient.test_results.length - 1];
    patient.last_result_date = last_test.test_time;
  }

  patient.onboarding_status = getOnboardingStatus(patient.rpm_onboard_status);

  // link
  patient.detail_link = "/patients/" + patient.id;

  return patient;
}

const TEST_TYPE_TO_DEVICE_MAP = {
  WBC: "Athelas One",
  GLUCOSE: "Glucometer",
  WEIGHT: "Weight Scale",
  BLOOD_PRESSURE: "Blood Pressure Monitor",
  HEART_RATE: "Blood Pressure Monitor",
  PULSE_OXIMETRY: "Pulse Oximeter",
  PLATELET: "Athelas One",
  HEMOGLOBIN: "Athelas One",
  TEMPERATURE: "Thermometer",
  EKG: "EKG",
};

function generateReferralStatement(patient_name, prescriber_name, devices) {
  return `${patient_name} was prescribed remote patient monitoring by ${prescriber_name}. The patient requires ongoing monitoring by ${devices} device(s), to assist with side-effect management as well as managing chronic disease risk created by the patient's underlying condition(s).`;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function transformLitePatient(patient) {
  patient.patient_name = `${patient.first_name} ${patient.last_name}`;
  patient.onboarding_status = getOnboardingStatus(patient.rpm_onboard_status);
  patient.devices = patient.test_types
    ? patient.test_types
        .map((test_type) => TEST_TYPE_TO_DEVICE_MAP[test_type])
        .filter(onlyUnique)
        .join(", ")
    : [];

  patient.referral_statement = generateReferralStatement(
    patient?.first_name + " " + patient?.last_name,
    patient.prescriber_name,
    patient.devices
  );

  return patient;
}

export const fetchSitePrescribersThunk = createAsyncThunk(
  "site/fetchPrescribers",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getSitePrescribers(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchInsuranceNames = createAsyncThunk(
  "patients/fetchInsuranceNames",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await patientsAPI.getInsuranceNames(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchPatients = createAsyncThunk(
  "patients/fetchPatients",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await patientsAPI.get(site_id, token);
      const filteredPatients = response.data.patients.filter(
        (p) => p.test_types !== null
      );
      const transformedPatients = filteredPatients.map(transformLitePatient);
      return transformedPatients;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchPatientsPaginated = createAsyncThunk(
  "patients/fetchPatientsPaginated",
  async (
    {
      site_id,
      token,
      prescriber_id,
      status,
      page_size,
      offset,
      search_term,
      no_test_this_month,
      insurance_company,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await patientsAPI.getAllPatientsPaginated(
        site_id,
        token,
        prescriber_id,
        status,
        page_size,
        offset,
        search_term,
        no_test_this_month,
        insurance_company
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchCardiacCarePatients = createAsyncThunk(
  "patients/fetchCardiacCarePatients",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await patientsAPI.getCardiacCarePatients(site_id, token);
      const data = response.data;

      const patients = Object.keys(data).map((key) => data[key]);
      return patients;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchPatient = createAsyncThunk(
  "patients/fetchPatient",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await patientsAPI.getPatient(id, token);

      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchCardiacCarePatientSummary = createAsyncThunk(
  "patients/fetchPatientCardiacCareSummary",
  async ({ patientId, token }, { rejectWithValue }) => {
    try {
      const response = await patientsAPI.getCardiacCarePatientSummary(
        patientId,
        token
      );

      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.targetPage;
      return state;
    },
    setPrescriberSelected: (state, action) => {
      state.prescriberSelected = action.payload.prescriberId;
      state.currentPage = 1;
      return state;
    },
    setonboardingStatusSelected: (state, action) => {
      state.statusSelected = action.payload.statusSelected;
      state.currentPage = 1;
      return state;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload.searchTerm;
      state.currentPage = 1;
      return state;
    },
    setNoTestThisMonthSelected: (state, action) => {
      state.noTestThisMonthSelected = action.payload.noTestThisMonthSelected;
      state.currentPage = 1;
      return state;
    },
    updateOnePatient: (state, action) => {
      patientsAdapter.updateOne(state, action.payload);
    },
    setInsuranceNameSelected: (state, action) => {
      state.insuranceNameSelected = action.payload.insuranceNameSelected;
      state.currentPage = 1;
      return state;
    },
  },
  extraReducers: {
    [fetchSitePrescribersThunk.fulfilled]: (state, action) => {
      state.prescribers = action.payload.prescribers;
    },
    [fetchPatients.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchPatients.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        patientsAdapter.upsertMany(state, action.payload);
        state.status = "succeeded";
      }
    },
    [fetchPatients.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchInsuranceNames.fulfilled]: (state, action) => {
      state.insuranceNames = action.payload.insurance_names;
    },
    [fetchPatientsPaginated.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchPatientsPaginated.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        patientsAdapter.removeAll(state);
        const returnedPatients = action.payload.patients.map(
          transformLitePatient
        );
        patientsAdapter.addMany(state, returnedPatients);
        state.totalCount = action.payload.patient_total_count;
        state.totalNumberOfPages = Math.ceil(
          action.payload.patient_total_count / PAGE_SIZE
        );
        state.status = "succeeded";
      }
    },
    [fetchPatientsPaginated.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchPatient.pending]: (state, action) => {
      state.singlePatientStatus = "loading";
      state.error = null;
    },
    [fetchPatient.fulfilled]: (state, action) => {
      const transformedPatient = transformPatient(action.payload);
      patientsAdapter.upsertOne(state, transformedPatient);
      state.singlePatientStatus = "succeeded";
    },
    [fetchPatient.rejected]: (state, action) => {
      if (state.singlePatientStatus === "loading") {
        state.singlePatientStatus = "failed";
        state.error = action.payload;
      }
    },
    [fetchCardiacCarePatientSummary.pending]: (state, action) => {
      state.cardiacCareStatus = "pending";
    },
    [fetchCardiacCarePatientSummary.fulfilled]: (state, action) => {
      state.cardiacCareStatus = "fulfilled";

      const patientId = action.meta.arg["patientId"];
      state.cardiacCareSummary[patientId] = action.payload;
    },
    [fetchCardiacCarePatientSummary.rejected]: (state, action) => {
      state.cardiacCareStatus = "rejected";
    },
    [fetchCardiacCarePatients.pending]: (state, action) => {
      state.cardiacCarePatientsListStatus = "pending";
    },
    [fetchCardiacCarePatients.fulfilled]: (state, action) => {
      state.cardiacCarePatientsListStatus = "fulfilled";
      state.cardiacCarePatientsList = action.payload;
    },
    [fetchCardiacCarePatients.rejected]: (state, action) => {
      state.cardiacCarePatientsListStatus = "rejected";
    },
  },
});

export const {
  reset,
  updateOnePatient,
  setCurrentPage,
  setPrescriberSelected,
  setonboardingStatusSelected,
  setSearchTerm,
  setNoTestThisMonthSelected,
  setInsuranceNameSelected,
} = patientsSlice.actions;

export const {
  selectAll: selectAllPatients,
  selectById: selectPatientById,
  selectIds: selectPatientIds,
  selectEntities: selectPatientEntities,
} = patientsAdapter.getSelectors((state) => state.patients);

export default patientsSlice.reducer;
