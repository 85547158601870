import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showNav: false,
};

const headerSlice = createSlice({
  name: "header",
  initialState: initialState,
  reducers: {
    toggleNav: (state) => {
      state.showNav = !state.showNav;
    },
    hideNav: (state) => {
      state.showNav = false;
    },
  },
});

export const { toggleNav, hideNav } = headerSlice.actions;

export default headerSlice.reducer;
