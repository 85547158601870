import axios from "axios";

const scribbleAPI = {
  create: (token, site_id, name, blob) => {
    const data = new FormData();
    data.append("file", blob);
    data.append("name", name);
    return axios.post(
      `${process.env.REACT_APP_HOST}/notes/${site_id}/create`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  get: (site_id, token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/notes/${site_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export default scribbleAPI;
