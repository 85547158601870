import moment from "moment";
import { LineChart } from "../../app/Atoms";
import { PiChart } from "../../app/Atoms/PiChart/PiChart";
import { PillButton } from "../../app/Atoms/PillButton/PillButton";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Shimmer from "react-shimmer-effect";

import styles from "./HomePage.module.css";

import WeightImg from "./assets/weight.png";
import GlucoseImg from "./assets/glucose.png";
import HeartRateImg from "./assets/heartrate.png";
import PulseOxImg from "./assets/pulseox.png";
import BlankImg from "./assets/blank.png";
import IsMobile from "../../utils/isMobile";
import { useProdVar } from "../../utils/prodVars";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { useState } from "react";
import { useFeatureFlag } from "../../utils/feature_flag";
import { AddPatientModal } from "../patients/AddPatientModal";
import Form from "react-bootstrap/Form";
import patientsAPI from "../patients/patientsAPI";

type SiteSummary = {
  nurse_notes_summary: { num_escalations: number; num_nurse_notes: number };
  rpm_statuses: { tested: number; total: number };
  test_types_breakdown_summary: any;
  tests_summary: { series: Array<any>; total_tests: number };
  thirty_day_actives_summary: {
    active_patients_percentage: number;
    patient_count: number;
    thirty_day_active_patients: number;
  };
};

type PropsType = {
  siteSummary: SiteSummary;
  siteSummaryStatus: "complete" | "failed" | "pending" | "idle";
  site_id: number;
  prescribers: [];
  token: string;
};

export const PracticeOverview = (props: PropsType) => {
  const history = useHistory();
  const siteSummary = props.siteSummary;
  const isMobile = IsMobile();
  const practiceOverviewHeader = useProdVar("practiceOverviewHeader");
  const [prescriberForAddingPatient, setPrescriberForAddingPatient] = useState(
    ""
  );
  const prescribers = props.prescribers;
  const prescriberOptions = prescribers.map((prescriber) => {
    return (
      <option value={prescriber.id} key={prescriber.id}>
        {prescriber.name}
      </option>
    );
  });
  const token = props.token;
  const site_id = props.site_id;

  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const addPatientEnabled = useFeatureFlag(
    "add_patient_from_provider_portal",
    props.site_id
  );

  if (props.siteSummaryStatus === "failed") {
    return null;
  }

  if (
    props.siteSummaryStatus === "pending" ||
    props.siteSummaryStatus === "idle"
  ) {
    return (
      <div style={{ marginBottom: "32px" }}>
        <Shimmer>
          <div style={{ width: 200, height: 30, marginBottom: 16 }}></div>
        </Shimmer>
        <div
          className={styles.Card}
          style={{ maxHeight: "500px", maxWidth: "1200px", marginBottom: 32 }}
        >
          <div style={{ margin: "24px auto" }}>
            <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
          </div>
        </div>
      </div>
    );
  }

  const completedTests = () => {
    return (
      <>
        <p style={{ fontSize: "90%" }}>Total # of Tests Completed</p>
        <p style={{ fontWeight: "bold", fontSize: "150%" }}>
          {siteSummary.tests_summary.total_tests}
        </p>
        <LineChart
          categories={siteSummary.tests_summary.series.map((data: any) =>
            moment(data["date"]).format("YYYY-MM-DD")
          )}
          series={siteSummary.tests_summary.series.map(
            (data: any) => data["count"]
          )}
          hideToolbar={false}
          tickAmount={5}
          xTitle={"Date"}
          yTitle={"# of Tests"}
        />
      </>
    );
  };

  const verticalLine = () => {
    return (
      <div
        style={{
          height: "70%",
          margin: 40,
          width: "2px",
          borderRadius: 16,
          backgroundColor: "#ddd",
        }}
      ></div>
    );
  };

  const statisticModule = (
    stat: string,
    title: string,
    subtitle: string,
    variant: "danger" | "info",
    buttonText: string,
    onClick: () => void,
    hidePillButton?: boolean
  ) => {
    return (
      <div
        style={{
          textAlign: "center",
          width: !isMobile ? "30%" : "100%",
          paddingTop: 32,
          paddingBottom: 32,
        }}
      >
        <p style={{ fontWeight: "bold" }}>{title}</p>
        <p style={{ fontWeight: "bold", fontSize: "350%" }}>{stat}</p>
        <p style={{ fontSize: "80%", minHeight: "60px" }}>{subtitle}</p>
        {!hidePillButton && (
          <PillButton text={buttonText} variant={variant} onClick={onClick} />
        )}
      </div>
    );
  };

  const selectPrescriberForPatientAdd = (e) => {
    setPrescriberForAddingPatient(e.target.value);
  };

  const getEnrollmentLinkForPresriber = async (e) => {
    try {
      const res = await patientsAPI.getPatientEnrollmentLink(
        prescriberForAddingPatient,
        site_id,
        token
      );
      window.open(res.data.link, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginBottom: "32px" }}>
      <div style={{ marginBottom: "16px" }}>
        <h4 style={{ float: "left" }}>
          <b>{practiceOverviewHeader}</b>
        </h4>
        <div style={{ display: "flex", flexDirection: "row", float: "right" }}>
          {addPatientEnabled && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Form.Group className="select-form-group">
                <Form.Control
                  as="select"
                  bsPrefix={
                    prescriberForAddingPatient === ""
                      ? "SelectPlaceHolder"
                      : "SelectInput"
                  }
                  id="prescriberSelect"
                  value={prescriberForAddingPatient}
                  onChange={selectPrescriberForPatientAdd}
                  style={{ width: "350px" }}
                >
                  <option value="" key="emptySelect" selected>
                    Select Prescriber for Adding Patient
                  </option>
                  {prescriberOptions}
                </Form.Control>
              </Form.Group>
              <AthelasButton
                variant="success"
                text="Add Patient"
                size="medium"
                disabled={prescriberForAddingPatient === ""}
                disabledToolTipText="Please select a prescriber first"
                onClick={getEnrollmentLinkForPresriber}
              />
            </div>
          )}
        </div>
        <br style={{ clear: "both" }} />
      </div>
      <div
        className={styles.Card}
        style={{ maxWidth: "1200px", marginBottom: 32 }}
      >
        <div
          style={{
            flexDirection: "row",
            display: !isMobile ? "flex" : "",
            maxWidth: "1100px",
          }}
        >
          <div style={{ width: !isMobile ? "40%" : "100%" }}>
            {completedTests()}
          </div>
          {!isMobile && verticalLine()}

          {statisticModule(
            Math.round(
              siteSummary.thirty_day_actives_summary
                .active_patients_percentage * 100
            ) + "%",
            "30 Day Compliant",
            "Total percentage of patients who have used Athelas RPM in the last 30 days.",
            "info",
            "View Patients",
            () => {
              history.push("patients");
            }
          )}

          {!isMobile && verticalLine()}

          {statisticModule(
            siteSummary.thirty_day_actives_summary.thirty_day_active_patients +
              "",
            "30 Day Active Patients",
            "Patients who have actively taken a RPM test in the past month.",
            "info",
            "View Patients",
            () => {
              history.push("patients");
            }
          )}
        </div>
      </div>

      <h4 style={{ marginBottom: "16px" }}>
        <b>RPM 30 Day Overview</b>
      </h4>
      <div
        className={styles.Card}
        style={{ maxWidth: "1200px", marginBottom: 32 }}
      >
        <div
          style={{
            flexDirection: "row",
            display: !isMobile ? "flex" : "",
            maxWidth: "1100px",
          }}
        >
          {statisticModule(
            siteSummary.nurse_notes_summary.num_escalations + "",
            "# of Priority Notes",
            "Total number of notes marked as 'Priority' by Athelas nurses.",
            "danger",
            "View Notes",
            () => {
              history.push("nurseNotes?onlyPriority=true");
            }
          )}

          {!isMobile && verticalLine()}

          {statisticModule(
            siteSummary.nurse_notes_summary.num_nurse_notes + "",
            "# of Nurse Notes",
            "Total number of reviews left by Athelas nurses on your patients.",
            "info",
            "Review Notes",
            () => {
              history.push("nurseNotes");
            }
          )}

          {!isMobile && verticalLine()}

          {/* TODO(Aakash) unhide the nudge patients pill button */}
          {statisticModule(
            Math.round(
              (1000 * siteSummary.rpm_statuses.tested) /
                siteSummary.rpm_statuses.total
            ) /
              10 +
              "%",
            "Percent Activated",
            "Total percentage of patients who have tested via Athelas RPM at least once",
            "danger",
            "Nudge Patients",
            () => {
              history.push("patients");
            },
            true
          )}
        </div>
      </div>

      <div
        className={styles.Card}
        style={{ maxWidth: "1200px", marginBottom: 32 }}
      >
        <div
          style={{
            flexDirection: "row",
            display: !isMobile ? "flex" : "",
            maxWidth: "1100px",
          }}
        >
          <div style={{ width: !isMobile ? "40%" : "100%", paddingTop: 16 }}>
            <p style={{ fontWeight: "bold" }}>
              <b>Test Types Breakdown</b>
            </p>
            <p style={{ fontSize: "80%" }}>
              # of tests (by type) in your practice
            </p>
            <PiChart data={siteSummary.test_types_breakdown_summary} />
          </div>

          {!isMobile && verticalLine()}

          <div
            style={{
              width: !isMobile ? "55%" : "100%",
              paddingTop: !isMobile ? 16 : 32,
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ width: "50%" }}>
                <p>Total # of RPM Tests Shipped Out</p>
                <p style={{ fontSize: "180%" }}>
                  <b>
                    {Object.keys(siteSummary.test_types_breakdown_summary)
                      .map(
                        (key: any) =>
                          siteSummary.test_types_breakdown_summary[key]
                      )
                      .reduce((a, b) => a + b, 0)}
                  </b>
                </p>
              </div>
              <div style={{ width: "50%" }}>
                <PillButton
                  text="Send more Devices"
                  variant="info"
                  onClick={() => {
                    history.push("patients");
                  }}
                />
              </div>
            </div>

            {!isMobile && <hr></hr>}

            <div
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                marginTop: !isMobile ? 0 : 16,
              }}
            >
              {Object.keys(siteSummary.test_types_breakdown_summary).map(
                (key: string) => {
                  const imgMap: any = {
                    CARDIAC_CARE: WeightImg,
                    WEIGHT: WeightImg,
                    HEART_RATE: HeartRateImg,
                    BLOOD_PRESSURE: HeartRateImg,
                    GLUCOSE: GlucoseImg,
                    PULSE_OXIMETRY: PulseOxImg,
                  };
                  let img: any = BlankImg;
                  if (Object.keys(imgMap).includes(key)) {
                    img = imgMap[key];
                  }

                  const count = siteSummary.test_types_breakdown_summary[key];
                  const content = (
                    <div
                      style={{
                        width: "120px",
                        marginRight: 24,
                        marginBottom: 16,
                      }}
                    >
                      <img src={img} style={{ width: "100%" }} alt="Device" />
                      <p
                        style={{
                          width: "100%",
                          fontSize: "60%",
                          marginTop: "8px",
                        }}
                      >
                        {key.replace("_", " ")}
                      </p>
                      <p>x {count}</p>
                    </div>
                  );

                  return content;
                }
              )}
            </div>
          </div>
        </div>
      </div>

      <AddPatientModal
        show={showAddPatientModal}
        onHide={() => {
          setShowAddPatientModal(false);
        }}
        onAdd={() => {
          setShowAddPatientModal(false);
        }}
      />
    </div>
  );
};
