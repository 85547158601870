import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Loader from "react-loader-spinner";

import Nav from "react-bootstrap/Nav";
import styles from "./Onboard.module.css";

import { ReactComponent as CheckedRedDot } from "./CheckedRedDot.svg";
import { ReactComponent as BackArrowSVG } from "./BackArrow.svg";
import { Contract } from "./Contract";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { Modal } from "react-bootstrap";
import { validateFields } from "./NewRoutableCompanyValidator";

import Form from "react-bootstrap/Form";

import {
  previousStep,
  nextStep,
  setLoadingState,
  fetchProgress,
  AgreeOnTC,
  SetNewPassword,
  SubmitReferrals,
  SubmitPracticeInformation,
  CheckRoutableCompanyExists,
  CreateRoutableCompanyAndSendInitialInvoice,
} from "./onboardSlice";

import { loginThunk, statusThunk } from "../user/userSlice";

export const CreditCardAndBankAccount = ({ activeTab, setActiveTab }) => {
  const SelectBA = () => setActiveTab("bank_account");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.onboard.token);
  const is_sandbox = false;
  const apiCallStatus = useSelector((state) => state.onboard.apiCallStatus);
  const routable_company_exists = useSelector(
    (state) => state.onboard.routable_company_exists
  );

  const [contactFirstNameRoutable, setContactFirstNameRoutable] = useState("");
  const onContactFirstNameRoutableChanged = (e) => {
    setContactFirstNameRoutable(e.target.value);
  };

  const [contactLastNameRoutable, setContactLastNameRoutable] = useState("");
  const onContactLastNameRoutableChanged = (e) => {
    setContactLastNameRoutable(e.target.value);
  };

  const [contactEmailRoutable, setContactEmailRoutable] = useState("");
  const onContactEmailRoutableChanged = (e) => {
    setContactEmailRoutable(e.target.value);
  };

  const [contactPhoneNbRoutable, setContactPhoneNbRoutable] = useState("");
  const onContactPhoneNbRoutableChanged = (e) => {
    setContactPhoneNbRoutable(e.target.value);
  };

  const [showModal, setShowModal] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const RoutableLink = ({
    contactFirstNameRoutable,
    contactLastNameRoutable,
    contactEmailRoutable,
    contactPhoneNbRoutable,
  }) => {
    const handleClickLinkAccount = () => {
      const fieldsValid = validateFields(
        contactFirstNameRoutable,
        contactLastNameRoutable,
        contactEmailRoutable,
        contactPhoneNbRoutable
      );
      setInputErrorMessage("");
      if (!fieldsValid.ok) {
        setInputErrorMessage("Error: " + fieldsValid.message);
      } else {
        setShowModal(true);
      }
    };
    return (
      <button className={styles.StepButton} onClick={handleClickLinkAccount}>
        Create Account
      </button>
    );
  };

  useEffect(() => {});

  window.zESettings = {
    webWidget: {
      chat: {
        suppress: true,
      },
      contactForm: {
        suppress: true,
      },
      helpCenter: {
        suppress: true,
      },
      talk: {
        suppress: true,
      },
      answerBot: {
        suppress: true,
      },
    },
  };

  const sendRoutableLinkFromModal = async () => {
    const resp = await dispatch(
      CreateRoutableCompanyAndSendInitialInvoice({
        token,
        contactFirstNameRoutable,
        contactLastNameRoutable,
        contactEmailRoutable,
        contactPhoneNbRoutable,
        is_sandbox,
      })
    );
    const response = unwrapResult(resp);

    if (response.status === 409) {
      setShowModal(false);
      alert(
        "A Routable account already exists for this site. An invoice was sent to " +
          response.message
      );
    } else if (response.status !== 200) {
      setShowModal(false);
      setShowErrorModal(true);
    } else {
      setShowModal(false);
      alert("Email sent to " + contactEmailRoutable);
    }
  };

  let content;
  let innerContent;
  if (routable_company_exists) {
    innerContent = (
      <div className={styles.CreditCardBody}>
        You have successfully set up your Routable account. An invoice has been
        sent to your site's email.
      </div>
    );
  } else if (apiCallStatus === "loading") {
    innerContent = (
      <div className={styles.CreditCardBody}>
        Fetching your onboarding process...
      </div>
    );
  } else {
    innerContent = (
      <>
        <p className={styles.CreditCardBody}>
          By linking, we will send the RPM Implementation Fee invoice through
          our partners,{" "}
          <a href="https://routable.com/about" target="_blank" rel="noreferrer">
            Routable.
          </a>{" "}
          This allows us to securely charge your account so we can collect
          payments for your Athelas services automatically. This process is
          secure and trusted by many organizations around the world.
        </p>
        <p></p>
        <p className={styles.CreditCardBody}>
          <b>
            This contact email will receive all Athelas invoices. Please provide
            information for the person at your practice responsible for billing.
          </b>
        </p>

        <>
          <div>
            <Form.Group
              controlId="formFirstName"
              className={styles.loginFormGroup}
            >
              <div style={{ padding: "12px" }}>
                <Form.Label>Contact First Name For Payables</Form.Label>
                <Form.Control
                  type="Username"
                  placeholder="John"
                  value={contactFirstNameRoutable}
                  onChange={onContactFirstNameRoutableChanged}
                />
              </div>
              <div style={{ padding: "12px" }}>
                <Form.Label>Contact Last Name For Payables</Form.Label>
                <Form.Control
                  type="Username"
                  placeholder="Smith"
                  value={contactLastNameRoutable}
                  onChange={onContactLastNameRoutableChanged}
                />
              </div>
              <div style={{ padding: "12px" }}>
                <Form.Label>Contact Email For Payables</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@gmail.com"
                  value={contactEmailRoutable}
                  onChange={onContactEmailRoutableChanged}
                />
              </div>
              <div style={{ padding: "12px 12px 6px 12px" }}>
                <Form.Label>Contact Phone Number For Payables</Form.Label>
                <div style={{ display: "flex", "flex-direction": "row" }}>
                  <div style={{ padding: "10px 10px 0px 0px" }}>
                    <h5>+1</h5>
                  </div>
                  <Form.Control
                    type="Username"
                    placeholder="4088213918"
                    value={contactPhoneNbRoutable}
                    onChange={onContactPhoneNbRoutableChanged}
                  />
                </div>
              </div>
            </Form.Group>
            {inputErrorMessage.length > 0 && (
              <p
                style={{
                  color: "#eb4034",
                  margin: 8,
                  backgroundColor: "#fad9d7",
                  borderRadius: 4,
                  padding: 8,
                }}
              >
                {inputErrorMessage}
              </p>
            )}
            <RoutableLink
              contactFirstNameRoutable={contactFirstNameRoutable}
              contactLastNameRoutable={contactLastNameRoutable}
              contactEmailRoutable={contactEmailRoutable}
              contactPhoneNbRoutable={contactPhoneNbRoutable}
            />
          </div>
        </>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Implementation Fee Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "text-align": "center" }}>
            Clicking “Send Invoice” below will send the invoice for your RPM
            Service Agreement Implementation Fee to {contactEmailRoutable}. In
            order to complete onboarding, please follow the emailed link to
            input ACH information and pay your invoice. RPM services will launch
            for your site once payment is complete.
            <div style={{ margin: "10px" }}>
              <AthelasButton
                variant="success"
                size="regular"
                text="Send Invoice"
                onClick={sendRoutableLinkFromModal}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Error occured while processing your request.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            An error occured while processing your request, please contact us at
            help@getathelas.com
          </Modal.Body>
        </Modal>
      </>
    );
  }

  content = (
    <div className={styles.BankAccountWrapper}>
      <div
        className={styles.CreditCardTitle}
        style={{ marginBottom: "16px", marginTop: "4px" }}
      >
        Create Routable Company
      </div>

      {innerContent}
    </div>
  );

  return (
    <>
      <Nav className={styles.PaymentTabs}>
        <Nav.Item>
          <Nav.Link
            active={activeTab === "bank_account"}
            onClick={SelectBA}
            bsPrefix={
              activeTab === "bank_account"
                ? styles.ActiveTab
                : styles.InactiveTab
            }
          >
            Bank Account
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {content}
    </>
  );
};

export const SmallRedDot = () => {
  return <div className={styles.SmallRedDot}></div>;
};

export const SmallGreenDot = () => {
  return <div className={styles.SmallGreenDot}></div>;
};

function hasNumber(str) {
  return /\d/.test(str);
}

function hasUpperCase(str) {
  return /[A-Z]/.test(str);
}

export const Referral = ({
  referralNumbers,
  setReferralNumbers,
  referralNames,
  setReferralNames,
}) => {
  const referralNumbersMap = [0, 1, 2, 3];
  const OnReferralNumberChange = (refNum) => {
    return (e) => {
      var matches = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !matches[2]
        ? matches[1]
        : "(" +
          matches[1] +
          ") " +
          matches[2] +
          (matches[3] ? "-" + matches[3] : "");
      let newReferralNumbers = [...referralNumbers];
      newReferralNumbers[refNum] = e.target.value;
      setReferralNumbers(newReferralNumbers);
    };
  };

  const OnReferralNameChange = (refNum) => {
    return (e) => {
      let newReferralNames = [...referralNames];
      newReferralNames[refNum] = e.target.value;
      setReferralNames(newReferralNames);
    };
  };

  return (
    <div>
      <div className={styles.ReferralHeader}>
        <div className={styles.ReferralHeaderTitle}>
          {" "}
          Get $1000 by referring a practice!{" "}
        </div>
        <p>
          {" "}
          For every doctor you sign up, you will both earn a $1000 visa gift
          card. Enter up to 4 phone numbers below.
        </p>
      </div>
      {referralNumbersMap.map((refNumber) => (
        <div key={refNumber} className={styles.ReferralChild}>
          <label className={styles.ReferralLabel}>
            Referral {refNumber + 1}
          </label>
          <input
            style={{ marginRight: "10px" }}
            type="text"
            className={styles.ReferralInput}
            placeholder="Name"
            onChange={OnReferralNameChange(refNumber)}
          />
          <input
            type="text"
            className={styles.ReferralInput}
            placeholder="Phone Number"
            onChange={OnReferralNumberChange(refNumber)}
          />
        </div>
      ))}
    </div>
  );
};

export const Password = ({
  email,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const onPasswordChange = (e) => setPassword(e.target.value);
  const onConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  return (
    <div className={styles.PasswordContent}>
      <div className={styles.LeftTop}>
        <label className={styles.CCInputLabel}>Username</label>
        <br />
        <input
          type="text"
          placeholder="Password"
          className={styles.CCInput}
          value={email}
          disabled
        />
      </div>
      <div className={styles.RightTop}></div>
      <div className={styles.LeftBottom}>
        <div>
          <label className={styles.CCInputLabel}>Password</label>
          <br />
          <input
            type="password"
            placeholder="Password"
            className={styles.CCInput}
            value={password}
            onChange={onPasswordChange}
          />
        </div>
        <div>
          <label className={styles.CCInputLabel}>Confirm password</label>
          <br />
          <input
            type="password"
            placeholder="Password"
            className={styles.CCInput}
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
          />
        </div>
      </div>
      <div className={styles.RightBottom}>
        <div className={styles.RightBottomWrapper}>
          <div className={styles.PasswordStrengthText}>Password Strength</div>
          <div className={styles.CheckListItem}>
            {password.length < 10 ? <SmallRedDot /> : <SmallGreenDot />}
            At least 10 characters
          </div>
          <div className={styles.CheckListItem}>
            {!hasNumber(password) ? <SmallRedDot /> : <SmallGreenDot />}
            At least 1 number
          </div>
          <div className={styles.CheckListItem}>
            {!hasUpperCase(password) ? <SmallRedDot /> : <SmallGreenDot />}
            At least 1 uppercase character
          </div>
          <div className={styles.CheckListItem}>
            {!"!@#$%^*".split("").some(function (v) {
              return password.indexOf(v) >= 0;
            }) ? (
              <SmallRedDot />
            ) : (
              <SmallGreenDot />
            )}
            At least 1 special character ( !@#$%^* )
          </div>
        </div>
      </div>
    </div>
  );
};

export const PracticeInformation = ({
  setPTAN,
  ptan,
  setTaxID,
  taxID,
  setNPI,
  npi,
}) => {
  return (
    <div>
      <div className={styles.ReferralHeader}>
        <div className={styles.ReferralHeaderTitle}>Practice Information</div>
      </div>
      <div>
        <label className={styles.providerInformationLabel}>Tax ID </label>
        <input
          type="text"
          className={styles.providerInformationInput}
          placeholder="Tax ID"
          value={taxID}
          onChange={(e) => setTaxID(e.target.value)}
        />
      </div>
      <div>
        <label className={styles.providerInformationLabel}>Site NPI</label>
        <input
          type="text"
          className={styles.providerInformationInput}
          placeholder="Site NPI"
          value={npi}
          onChange={(e) => setNPI(e.target.value)}
        />
      </div>
      <div>
        <label className={styles.providerInformationLabel}>
          Provider Transaction Access Number (Optional)
        </label>
        <input
          type="text"
          className={styles.providerInformationInput}
          placeholder="Provider Transaction Access Number"
          value={ptan}
          onChange={(e) => setPTAN(e.target.value)}
        />
      </div>
    </div>
  );
};

export const DashLine = () => {
  return <div className={styles.DashLine}></div>;
};

export const WhiteCircle = () => {
  return <div className={styles.WhiteCircle}></div>;
};

export const RedDot = () => {
  return <div className={styles.RedDot}></div>;
};

export const StepDot = ({ currentStep, PositionStep }) => {
  // special case for last step, directly move to red checked dot for all dots
  if (currentStep === 5) {
    return <CheckedRedDot />;
  }

  if (currentStep === PositionStep) {
    return <RedDot />;
  } else if (currentStep > PositionStep) {
    return <CheckedRedDot />;
  } else {
    return <WhiteCircle />;
  }
};

export const SolidLine = ({ step, currentStep }) => {
  return (
    <div
      className={
        currentStep > step ? styles.PinkSolidLine : styles.BlackSolidLine
      }
    ></div>
  );
};

export const IsReferralEmpty = (referralNumbers, referralNames) => {
  for (let i = 0; i < referralNumbers.length; i += 1) {
    let name = referralNames[i];
    let number = referralNumbers[i];
    if (name !== "" || number !== "") {
      return false;
    }
  }
  return true;
};

export const ValidateReferrals = (referralNumbers, referralNames) => {
  let failed = -1;
  for (let i = 0; i < referralNumbers.length; i += 1) {
    let name = referralNames[i];
    let number = referralNumbers[i];
    if (name === "" && number !== "") {
      failed = i;
    } else if (number === "" && name !== "") {
      failed = i;
    }
  }
  return failed;
};

export const OnboardPage = () => {
  const step = useSelector((state) => state.onboard.step);
  const email = useSelector((state) => state.onboard.email);
  const apiCallStatus = useSelector((state) => state.onboard.apiCallStatus);
  const deal_type = useSelector((state) => state.onboard.deal_type);
  const contract = useSelector((state) => state.onboard.contract);

  const history = useHistory();
  const dispatch = useDispatch();

  const [nameVal, setNameVal] = useState("");
  const [referralNumbers, setReferralNumbers] = useState(["", "", "", ""]);
  const [referralNames, setReferralNames] = useState(["", "", "", ""]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [ptan, setPTAN] = useState(useSelector((state) => state.onboard.ptan));
  const [taxID, setTaxID] = useState(
    useSelector((state) => state.onboard.taxID)
  );
  const [npi, setNPI] = useState(useSelector((state) => state.onboard.npi));

  const token = useSelector((state) => state.onboard.token);

  useEffect(() => {
    if (apiCallStatus === "idle") {
      dispatch(fetchProgress({ token }));
    }
  });

  function NextButtonText() {
    if (apiCallStatus === "loading") {
      return "Loading...";
    } else if (step === 1) {
      return "Submit";
    } else if (step === 2) {
      return "Next";
    } else if (step === 3) {
      return "Confirm";
    } else if (step === 4) {
      return "Continue";
    } else {
      return "Finish";
    }
  }

  function HandleClickBackButton() {
    dispatch(previousStep());
  }

  async function HandleClickNextStep() {
    // start loading state
    dispatch(
      setLoadingState({
        status: "loading",
      })
    );

    if (step === 1) {
      try {
        const resultAction = await dispatch(AgreeOnTC({ token }));
        unwrapResult(resultAction);
        dispatch(nextStep());
      } catch {
        alert("Server error occured, please contact us at help@getathelas.com");
      }
    }

    if (step === 2) {
      const resp = await dispatch(
        CheckRoutableCompanyExists({
          token,
        })
      );

      const response = unwrapResult(resp);
      if (response.status === 400) {
        alert("Server error occured, please contact us at help@getathelas.com");
      }
      if (response.status === 200) {
        dispatch(nextStep());
      } else {
        alert(
          "Please fill out the form below and create a Routable Company account."
        );
      }
    }

    if (step === 3) {
      if (password === "") {
        alert("Please provide a password");
      } else if (confirmPassword === "") {
        alert("Please confirm new password");
      } else if (password !== confirmPassword) {
        alert("Passwords doesn't match.");
      } else {
        try {
          const resultAction = await dispatch(
            SetNewPassword({
              token,
              newPassword: password,
            })
          );
          const resetResponse = unwrapResult(resultAction);
          if (resetResponse.status !== 200) {
            alert(resetResponse.message);
          } else {
            dispatch(nextStep());
          }
        } catch {
          alert(
            "Server error occured, please contact us at help@getathelas.com"
          );
        }
      }
    }

    if (step === 4) {
      if (taxID === "") {
        alert("Please provide a valid Tax ID");
      } else if (npi === "") {
        alert("Please provide a valid NPI");
      } else {
        try {
          const resultAction = await dispatch(
            SubmitPracticeInformation({ token, ptan, taxID, npi })
          );
          const resetResponse = unwrapResult(resultAction);
          if (resetResponse === "OK") {
            dispatch(nextStep());
          }
        } catch {
          alert(
            "Server error occured, please contact us at help@getathelas.com"
          );
        }
      }
    }

    if (step === 5) {
      let failed = ValidateReferrals(referralNumbers, referralNames);
      let empty = IsReferralEmpty(referralNumbers, referralNames);
      if (failed >= 0) {
        alert(
          `Please enter both a name and phone number for Referral ${failed + 1}`
        );
      } else {
        try {
          if (!empty) {
            await dispatch(
              SubmitReferrals({
                token,
                numbers: referralNumbers,
                names: referralNames,
              })
            );
          }
          setReferralNumbers(["", "", "", ""]);
          setReferralNames(["", "", "", ""]);
          dispatch(nextStep());
        } catch {
          alert(
            "Server error occured, please contact us at help@getathelas.com"
          );
        }
      }

      try {
        const resultAction = await dispatch(loginThunk({ email, password }));
        const loginResponse = unwrapResult(resultAction);

        const token = loginResponse.auth_token;
        dispatch(statusThunk({ token }));

        history.push("/home");
      } catch {
        alert("Server error occured, please contact us at help@getathelas.com");
      }
    }
    // out of loading state
    dispatch(
      setLoadingState({
        status: "complete",
      })
    );
  }

  function InstructionText() {
    if (step === 2) {
      return "Provide Your Billing Contact Information";
    } else if (step === 3) {
      return "Confirm your login information";
    } else if (step === 4) {
      return "Tell Us About Your Practice";
    } else {
      return "Refer Providers to Athelas RPM";
    }
  }

  const [activeTab, setActiveTab] = useState("bank_account");

  let stepContent;
  if (step === 1) {
    if (deal_type === "") {
      stepContent = (
        <div className={styles.LoaderWrapper}>
          <Loader type="TailSpin" color="#42A1F8" />
        </div>
      );
    } else if (contract !== {}) {
      stepContent = <Contract />;
    } else {
      stepContent = (
        <div>
          Server error occured, please contact us at help@getathelas.com
        </div>
      );
    }
  }
  if (step === 2) {
    stepContent = (
      <CreditCardAndBankAccount
        nameVal={nameVal}
        setNameVal={setNameVal}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    );
  }
  if (step === 3) {
    stepContent = (
      <Password
        email={email}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
      />
    );
  }
  if (step === 4) {
    stepContent = (
      <PracticeInformation
        setPTAN={setPTAN}
        ptan={ptan}
        setTaxID={setTaxID}
        taxID={taxID}
        setNPI={setNPI}
        npi={npi}
      />
    );
  }
  if (step === 5) {
    stepContent = (
      <Referral
        referralNumbers={referralNumbers}
        setReferralNumbers={setReferralNumbers}
        referralNames={referralNames}
        setReferralNames={setReferralNames}
      />
    );
  }

  let previousSection;

  const [readCheckbox, setReadCheckbox] = useState(false);

  const onReadCheckBoxChange = (e) => {
    setReadCheckbox(!readCheckbox);
  };

  if (step > 1) {
    previousSection = (
      <div className={styles.BackStepButton} onClick={HandleClickBackButton}>
        <BackArrowSVG />
        Back
      </div>
    );
  } else {
    previousSection = (
      <Form.Group
        controlId="formBasicCheckbox"
        style={{ "user-select": "none" }}
      >
        <Form.Check
          type="checkbox"
          label="By clicking “submit,” you acknowledge that you have read, understand, and agree on behalf of your organization to purchase the Services indicated above according to the applicable terms and conditions incorporated by reference into this agreement."
          checked={readCheckbox}
          onChange={onReadCheckBoxChange}
        />
      </Form.Group>
    );
  }

  const nextButtonDisabled = () => {
    if (step === 1) {
      return !readCheckbox;
    } else {
      return apiCallStatus === "loading";
    }
  };

  const stepState = {
    done: "done",
    current: "current",
    future: "future",
  };

  const getStepState = (step, currentStep) => {
    if (currentStep === step) {
      return "current";
    } else if (currentStep < step) {
      return "future";
    } else {
      return "done";
    }
  };

  return (
    <div
      className={step > 1 ? styles.CommonContainer : styles.AgreementContainer}
    >
      <div className={styles.StepsHeaderContainer}>
        <div></div>
        <div className={styles.StepsGrid}>
          <div></div>
          <div>
            <p className={styles[stepState[getStepState(1, step)]]}>
              Terms of Service
            </p>
          </div>
          <div></div>
          <div>
            <p className={styles[stepState[getStepState(2, step)]]}>
              Payment Information
            </p>
          </div>
          <div></div>
          <div>
            <p className={styles[stepState[getStepState(3, step)]]}>
              Login Information
            </p>
          </div>
          <div></div>
          <div>
            <p className={styles[stepState[getStepState(4, step)]]}>
              Practice Information
            </p>
          </div>
          <div></div>
          <div>
            <p className={styles[stepState[getStepState(5, step)]]}>
              Refer Colleagues
            </p>
          </div>
          <div></div>
        </div>
        <div className={styles.StepIconGrid}>
          <div>
            <DashLine />
          </div>
          <div>
            <StepDot currentStep={step} PositionStep={1} />
          </div>
          <div>
            <SolidLine step={1} currentStep={step} />
          </div>
          <div>
            <StepDot currentStep={step} PositionStep={2} />
          </div>
          <div>
            <SolidLine step={2} currentStep={step} />
          </div>
          <div>
            <StepDot currentStep={step} PositionStep={3} />
          </div>
          <div>
            <SolidLine step={3} currentStep={step} />
          </div>
          <div>
            <StepDot currentStep={step} PositionStep={4} />
          </div>
          <div>
            <SolidLine step={4} currentStep={step} />
          </div>
          <div>
            <StepDot currentStep={step} PositionStep={5} />
          </div>
          <div>
            <DashLine />
          </div>
        </div>
        <div></div>
      </div>
      {step > 1 && (
        <div className={styles.MiddleInstructionSection}>
          {InstructionText()}
        </div>
      )}
      <div
        className={
          step === 1 ? styles.AgreementMainContainer : styles.MainContainer
        }
      >
        <div className={step === 1 ? styles.AgreementMain : styles.Main}>
          {stepContent}
        </div>
        <div className={styles.ContentFooter}>
          <div className={styles.ContentFooterLeft}>{previousSection}</div>
          <div className={styles.ContentFooterRight}>
            <button
              className={
                nextButtonDisabled()
                  ? styles.DisabledStepButton
                  : styles.StepButton
              }
              onClick={HandleClickNextStep}
              disabled={nextButtonDisabled()}
            >
              {NextButtonText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
