import styles from "./NurseNotes.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";

import patientsAPI from "../patients/patientsAPI";
import { NurseNoteThumbnail } from "./NurseNoteThumbnail";
import { NurseNotesType } from "./NurseNoteThumbnail";
import { NurseNotesDetails } from "./NurseNotesDetails";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

type PropsType = {
  patientId: number;
};

export const NurseNotesClient = (props: PropsType) => {
  const [fetchedNurseNotes, setFetchedNurseNotes] = useState(false);
  const [nurseNotesList, setNurseNotesList] = useState([]);
  const token = useSelector((state: any) => state.user.token);
  const [noteDetailsIndex, setNoteDetailsIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");

  const [level1Selected, setLevel1Selected] = useState(false);
  const onLevel1ToggleClick = () => {
    setLevel1Selected(!level1Selected);
  };

  const [level2Selected, setLevel2Selected] = useState(false);
  const onLevel2ToggleClick = () => {
    setLevel2Selected(!level2Selected);
  };

  const [level3Selected, setLevel3Selected] = useState(false);
  const onLevel3ToggleClick = () => {
    setLevel3Selected(!level3Selected);
  };

  const [allEscalationsSelected, setAllEscalationsSelected] = useState(false);
  const onAllEscalationsToggleClick = () => {
    setAllEscalationsSelected(!allEscalationsSelected);
  };

  const [notAcknowledgedSelected, setNotAcknowledgedSelected] = useState(false);
  const onNotAcknowledgedToggleClick = () => {
    setNotAcknowledgedSelected(!notAcknowledgedSelected);
  };

  if (!fetchedNurseNotes) {
    patientsAPI.getPatientNurseNotes(props.patientId, token).then((res) => {
      if (res?.data?.nurse_notes) {
        let notes = res?.data?.nurse_notes;
        setNurseNotesList(notes);

        if (notes.length > 0) {
          setNoteDetailsIndex(0);
        }
      }
    });
    return setFetchedNurseNotes(true);
  }

  const viewNoteDetails = (index: number) => {
    let true_index = index;
    if (index < 0) {
      true_index = 0;
    }
    if (index > nurseNotesList.length) {
      true_index = nurseNotesList.length - 1;
    }
    setNoteDetailsIndex(true_index);
  };

  const setAckTime = (index: number, ack_time: Date) => {
    setNurseNotesList((prevNurseNotesList) => [
      ...prevNurseNotesList.slice(0, index),
      {
        ...prevNurseNotesList[index],
        escalation_ack_date: ack_time,
      },
      ...prevNurseNotesList.slice(index + 1),
    ]);
  };

  const nurseNote: any =
    noteDetailsIndex === -1 ? {} : nurseNotesList[noteDetailsIndex];

  return (
    <div className={styles["nurse-notes-client-container"]}>
      <div className={styles["left-navigation-bar"]}>
        <input
          className={styles["search-bar"]}
          type="text"
          placeholder="Search"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        ></input>
        <div className={styles["notes-scroll-list"]}>
          {nurseNotesList?.map((res: NurseNotesType, index: number) => {
            if (!JSON.stringify(res).includes(searchQuery)) {
              return null;
            }

            if (
              notAcknowledgedSelected &&
              (res.escalation_ack_date !== null ||
                res.escalation_note === null ||
                res.escalation_note === "")
            ) {
              return null;
            }

            if (
              allEscalationsSelected ||
              level1Selected ||
              level2Selected ||
              level3Selected
            ) {
              if (
                allEscalationsSelected &&
                res.escalation_note !== null &&
                res.escalation_note !== ""
              ) {
                return (
                  <NurseNoteThumbnail
                    nurse_note={res}
                    index={index}
                    onClick={viewNoteDetails}
                    activeIndex={noteDetailsIndex}
                  />
                );
              } else if (
                (level1Selected && res.escalation_level === 1) ||
                (level2Selected && res.escalation_level === 2) ||
                (level3Selected && res.escalation_level === 3)
              ) {
                return (
                  <NurseNoteThumbnail
                    nurse_note={res}
                    index={index}
                    onClick={viewNoteDetails}
                    activeIndex={noteDetailsIndex}
                  />
                );
              } else {
                return null;
              }
            }

            return (
              <NurseNoteThumbnail
                nurse_note={res}
                index={index}
                onClick={viewNoteDetails}
                activeIndex={noteDetailsIndex}
              />
            );
          })}
        </div>
      </div>

      <div className={styles["notes-details"]}>
        <Tooltip
          title={
            <Box style={{ fontSize: "14px" }}>
              Only notes created after March 30, 2023 have escalation levels
            </Box>
          }
          arrow
        >
          <div
            style={{
              minWidth: "95%",
              marginLeft: "5%",
              marginTop: "16px",
              overflowX: "auto",
            }}
            className={styles.NurseNotesTableFilters}
          >
            <ToggleSelect
              enabled={level1Selected}
              onClick={onLevel1ToggleClick}
              text={"Level 1 Escalation (Standard)"}
            />
            <ToggleSelect
              enabled={level2Selected}
              onClick={onLevel2ToggleClick}
              text={"Level 2 Escalation (Major)"}
            />
            <ToggleSelect
              enabled={level3Selected}
              onClick={onLevel3ToggleClick}
              text={"Level 3 Escalation (Emergency)"}
            />
            <ToggleSelect
              enabled={allEscalationsSelected}
              onClick={onAllEscalationsToggleClick}
              text={"All Escalations"}
            />
            <ToggleSelect
              enabled={notAcknowledgedSelected}
              onClick={onNotAcknowledgedToggleClick}
              text={"Unacknowledged Escalations"}
            />
          </div>
        </Tooltip>
        <hr style={{ width: "90%", marginLeft: "5%" }}></hr>
        <div
          style={{
            overflowY: "scroll",
            height: "80%",
          }}
        >
          {noteDetailsIndex === -1 ? null : (
            <NurseNotesDetails
              nurse_note={nurseNote}
              index={noteDetailsIndex}
              on_escalation_ack={setAckTime}
            />
          )}
        </div>
      </div>
    </div>
  );
};
