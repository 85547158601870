import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Column, Table, AutoSizer } from "react-virtualized";

import { Header } from "../header/Header";
import {
  fetchNurseNotesThunk,
  fetchSitePrescribersThunk,
} from "./nurseNotesSlice";
import { statusThunk } from "../user/userSlice";

import styles from "./NurseNotes.module.css";
import { IsoDateToDDMMMYYYY } from "../../utils/date";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";
import { trackEvent } from "../../utils/tracking";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { ExportNurseNoteListAsCSVFile } from "../../utils/csv";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const NurseNotesList = () => {
  let query = useQuery();
  const onlyPriorityQuery = query.get("onlyPriority");

  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.nurseNotes.status);
  const nurseNotes = useSelector((state) => state.nurseNotes.nurseNotes);
  let totalCount = useSelector((state) => state.nurseNotes.totalCount);

  const dispatch = useDispatch();
  const history = useHistory();

  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedPrescriber, setSelectedPrescriber] = useState(null);
  const prescribers = useSelector((state) => state.nurseNotes.prescribers);
  const prescriberOptions = prescribers.map((prescriber) => {
    return (
      <option value={prescriber.name} key={prescriber.id}>
        {prescriber.name}
      </option>
    );
  });

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchSitePrescribersThunk({ site_id, token }));
      dispatch(fetchNurseNotesThunk({ site_id, token }));
      trackEvent("view", "viewed_nurse_notes_list");
    }
  });

  const [level1Selected, setLevel1Selected] = useState(false);
  const onLevel1ToggleClick = () => {
    setLevel1Selected(!level1Selected);
  };

  const [level2Selected, setLevel2Selected] = useState(false);
  const onLevel2ToggleClick = () => {
    setLevel2Selected(!level2Selected);
  };

  const [level3Selected, setLevel3Selected] = useState(false);
  const onLevel3ToggleClick = () => {
    setLevel3Selected(!level3Selected);
  };

  const [allEscalationsSelected, setAllEscalationsSelected] = useState(
    onlyPriorityQuery === "true"
  );
  const onAllEscalationsToggleClick = () => {
    setAllEscalationsSelected(!allEscalationsSelected);
  };

  const [notAcknowledgedSelected, setNotAcknowledgedSelected] = useState(false);
  const onNotAcknowledgedToggleClick = () => {
    setNotAcknowledgedSelected(!notAcknowledgedSelected);
  };

  const onPrescriberSelectedChanged = (e) => {
    setSelectedPrescriber(e.target.value);
  };

  let filteredNurseNotes = nurseNotes;
  if (allEscalationsSelected) {
    filteredNurseNotes = nurseNotes.filter(
      (note) =>
        note.escalation_notes !== null &&
        note.escalation_notes !== "" &&
        note.escalation_notes.trim().length > 0
    );
    totalCount = filteredNurseNotes.length;
  } else if (level1Selected || level2Selected || level3Selected) {
    filteredNurseNotes = nurseNotes.filter(
      (note) =>
        (level1Selected && note.escalation_level === 1) ||
        (level2Selected && note.escalation_level === 2) ||
        (level3Selected && note.escalation_level === 3)
    );
    totalCount = filteredNurseNotes.length;
  }

  if (notAcknowledgedSelected) {
    filteredNurseNotes = filteredNurseNotes.filter(
      (note) =>
        note.escalation_notes !== null &&
        note.escalation_notes !== "" &&
        note.escalation_notes.trim().length > 0 &&
        note.escalation_ack_date === null
    );
    totalCount = filteredNurseNotes.length;
  }

  if (selectedPrescriber) {
    filteredNurseNotes = nurseNotes.filter(
      (note) => note.prescriber_name === selectedPrescriber
    );
    totalCount = filteredNurseNotes.length;
  }

  function ReviewDateRender({ cellData }) {
    return IsoDateToDDMMMYYYY(cellData);
  }

  const nameRenderer = ({ rowData }) => {
    return rowData.patient_first_name + " " + rowData.patient_last_name;
  };

  function AcknowledgedOnRender({ rowData }) {
    if (rowData.escalation_ack_date !== null) {
      return IsoDateToDDMMMYYYY(rowData.escalation_ack_date);
    } else {
      if (
        rowData.escalation_notes !== null &&
        rowData.escalation_notes !== "" &&
        rowData.escalation_notes.trim().length > 0
      ) {
        return "Not acknowledged";
      } else {
        return "";
      }
    }
  }

  const downloadNurseNotesListCSV = async (e) => {
    setIsDownloading(true);
    if (
      allEscalationsSelected ||
      level1Selected ||
      level2Selected ||
      level3Selected ||
      selectedPrescriber
    ) {
      ExportNurseNoteListAsCSVFile(filteredNurseNotes, "patient_nurse_notes");
    } else {
      ExportNurseNoteListAsCSVFile(nurseNotes, "patient_nurse_notes");
    }
    setIsDownloading(false);
  };

  let content;

  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <div className={styles.TableCardContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={60}
              rowHeight={68}
              rowCount={filteredNurseNotes.length}
              onRowClick={({ rowData }) =>
                history.push(
                  "/patients/" + rowData.patient_id + "?tab=nurse_notes"
                )
              }
              rowGetter={({ index }) => filteredNurseNotes[index]}
              rowClassName={({ index }) =>
                index !== -1 ? styles.TableRow : styles.HeaderRow
              }
            >
              <Column
                label="Review Date"
                dataKey="created_at"
                width={(width / 12) * 2}
                cellRenderer={ReviewDateRender}
              />
              <Column
                label="Patient Name"
                dataKey="patient_first_name"
                width={(width / 12) * 2}
                cellRenderer={nameRenderer}
              />
              <Column
                label="Prescriber Name"
                dataKey="prescriber_name"
                width={(width / 12) * 2}
              />
              <Column label="Note" dataKey="notes" width={(width / 12) * 4} />
              <Column
                label="Escalation Notes"
                dataKey="escalation_notes"
                width={(width / 12) * 4}
              />
              <Column
                label="Level"
                dataKey="escalation_level"
                width={width / 12}
              />
              <Column
                label="Acknowledgement"
                dataKey="escalation_ack_date"
                width={(width / 12) * 2}
                cellRenderer={AcknowledgedOnRender}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  } else if (status === "failed") {
    content = <div>An error occurred</div>;
  }

  return (
    <>
      <Header title="Nurse Notes"></Header>
      <div className={styles.NurseNotesTableContainer}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <div style={{ padding: "16px" }}>
            <AthelasButton
              variant="info"
              size="small"
              text={isDownloading ? "Downloading..." : "Download as CSV"}
              disabled={isDownloading}
              onClick={downloadNurseNotesListCSV}
            />
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Tooltip
            title={
              <Box style={{ fontSize: "14px" }}>
                Only notes created after March 30, 2023 have escalation levels
              </Box>
            }
            arrow
          >
            <div className={styles.NurseNotesTableFilters}>
              <Form inline>
                <Form.Group className="select-form-group">
                  <Form.Control
                    as="select"
                    bsPrefix={
                      selectedPrescriber === null
                        ? "SelectPlaceHolder"
                        : "SelectInput"
                    }
                    id="prescriberSelect"
                    value={selectedPrescriber || ""}
                    onChange={onPrescriberSelectedChanged}
                    style={{ width: "220px" }}
                  >
                    <option value="">Filter by Prescriber</option>
                    {prescriberOptions}
                  </Form.Control>
                </Form.Group>
              </Form>

              <ToggleSelect
                enabled={level1Selected}
                onClick={onLevel1ToggleClick}
                text={"Level 1 Escalation (Standard)"}
              />
              <ToggleSelect
                enabled={level2Selected}
                onClick={onLevel2ToggleClick}
                text={"Level 2 Escalation (Major)"}
              />
              <ToggleSelect
                enabled={level3Selected}
                onClick={onLevel3ToggleClick}
                text={"Level 3 Escalation (Emergency)"}
              />
              <ToggleSelect
                enabled={allEscalationsSelected}
                onClick={onAllEscalationsToggleClick}
                text={"All Escalations"}
              />
              <ToggleSelect
                enabled={notAcknowledgedSelected}
                onClick={onNotAcknowledgedToggleClick}
                text={"Unacknowledged Escalations"}
              />
            </div>
          </Tooltip>
        </div>

        {content}
        <div className={styles.PaginationSection}>
          <div className={styles.PaginationLeft}>
            {totalCount} total results
          </div>
        </div>
      </div>
    </>
  );
};
