import axios from "axios";
import qs from "qs";

const patientsAPI = {
  get: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/patients/lite`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPatientsCSV: (
    site_id,
    token,
    prescriber_id,
    rpm_onboard_status,
    search_term,
    no_test_this_month
  ) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/patients_as_file/lite`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          prescriber_id: prescriber_id,
          rpm_onboard_status: rpm_onboard_status,
          search_term: search_term,
          no_test_this_month: no_test_this_month,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
  },
  getAllPatientsPaginated: (
    site_id,
    token,
    prescriber_id,
    rpm_onboard_status,
    page_size,
    offset,
    search_term,
    no_test_this_month,
    insurance_company
  ) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/patients/lite/paginated`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          prescriber_id: prescriber_id,
          rpm_onboard_status: rpm_onboard_status,
          page_size: page_size,
          offset: offset,
          search_term: search_term,
          no_test_this_month: no_test_this_month,
          insurance_company: insurance_company,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
  },
  getPatient: (id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/${id}/`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getCardiacCarePatients: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/cardiac_care_patients`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getEscalations: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/escalations`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getMedicationPatients: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/medication_patients`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getCardiacCarePatientSummary: (id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/${id}/cardiac_care_summary`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getPatientDevicesSummary: (id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/${id}/device_summary`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getPatientDevicesStatusList: (id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/${id}/device_ping_summary`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getPatientNurseNotes: (id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/${id}/nurse_notes`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getPatientReportForSelectedDateRange: (
    id,
    token,
    fromDate,
    toDate,
    includeDeviceDiagnosticData
  ) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/${id}/get_patient_data_for_selected_date_range`;
    return axios.post(
      endpoint,
      {
        from_date: fromDate,
        to_date: toDate,
        include_device_diagnostic_data: includeDeviceDiagnosticData,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  addNewPatient: (
    site_id,
    token,
    user_id,
    firstName,
    lastName,
    dob,
    phoneNumber,
    address,
    insuranceCompany,
    insuranceNumber,
    bloodPressureTest,
    weightTest,
    glucoseTest,
    isWeightBlind,
    doctorName,
    gender,
    condition1,
    condition2,
    firstTest,
    prescriberId
  ) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${site_id}/add_patient`;
    return axios.post(
      endpoint,
      {
        prescriber_id: parseInt(prescriberId),
        first_name: firstName,
        last_name: lastName,
        dob: dob.format("YYYY-MM-DD"),
        phone_number: phoneNumber,
        address: address,
        insurance_company: insuranceCompany,
        insurance_number: insuranceNumber,
        blood_pressure_test: bloodPressureTest,
        weight_test: weightTest,
        glucose_test: glucoseTest,
        is_weight_blind: isWeightBlind,
        doctor_name: doctorName,
        gender: gender,
        condition_1: condition1,
        condition_2: condition2,
        user_id: user_id,
        first_test: firstTest,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  changePatientStatus: (patient_id, to_status, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/patients/change_status_from_doctor_app`;
    return axios.post(
      endpoint,
      {
        patient_id: patient_id,
        to_status: to_status,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  getInsuranceNames: (site_id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${site_id}/patients/insurance_names`;
    return axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  getPatientUpdateLink: (patient_id, site_id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${site_id}/generate_update_patient_link`;
    return axios.post(
      endpoint,
      {
        patient_id: patient_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPatientEnrollmentLink: (prescriber_id, site_id, token) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${site_id}/generate_add_patient_link_for_prescriber`;
    return axios.post(
      endpoint,
      {
        prescriber_id: prescriber_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPerPatientAlertingConditions: (siteID, token, patient_id, test_type) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${siteID}/get_alerting_on_per_patient_basis`;
    return axios.post(
      endpoint,
      {
        patient_id: patient_id,
        test_type: test_type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  setPerPatientAlertingConditions: (
    siteID,
    token,
    patient_id,
    alerting_conditions,
    test_type
  ) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${siteID}/set_alerting_on_per_patient_basis`;
    return axios.post(
      endpoint,
      {
        patient_id: patient_id,
        test_type: test_type,
        alerting_conditions: alerting_conditions,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  deletePatientTestResultLimit: (siteID, token, test_result_limit_id) => {
    const endpoint = `${process.env.REACT_APP_HOST}/sites/${siteID}/delete_test_result_limit`;
    return axios.post(
      endpoint,
      {
        test_result_limit_id: test_result_limit_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default patientsAPI;
