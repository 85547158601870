import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import claimsAPI from "./claimsAPI";

const claimsAdapter = createEntityAdapter({
  selectId: (claim) => `${claim.claim_id}-${claim.cpt_code}`,
});

const initialState = claimsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchAllClaims = createAsyncThunk(
  "claims/fetchAllForSite",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await claimsAPI.getAllClaimsOfSite(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

// CPT code to estimated value
const ESTIMATED_MAP = {
  99453: 20.76,
  99454: 69.93,
  99457: 56.27,
};

const transfromClaim = (claim) => {
  claim.submitDateTimeMil = Date.parse(claim.submitted_at);
  claim.estimated_value = ESTIMATED_MAP[claim.cpt_code];
  return claim;
};

const claimsSlice = createSlice({
  name: "claims",
  initialState,
  extraReducers: {
    [fetchAllClaims.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchAllClaims.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        claimsAdapter.upsertMany(state, action.payload.map(transfromClaim));
        state.status = "succeeded";
      }
    },
    [fetchAllClaims.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
  },
});

export const {
  selectAll: selectAllClaims,
  selectById: selectClaimById,
} = claimsAdapter.getSelectors((state) => state.claims);

export default claimsSlice.reducer;
