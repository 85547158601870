type PropsType = {
  backgroundColor: string;
  color: string;
  text: string;
  align: "left" | "right";
  sender: string;
};

export function ChatBubble(props: PropsType) {
  return (
    <div>
      <div style={{ fontSize: "80%", color: "#AAA", textAlign: props.align }}>
        {props.sender?.toUpperCase()}
      </div>
      <div
        style={{
          backgroundColor: props.backgroundColor,
          color: props.color,
          borderRadius: 16,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          maxWidth: "75%",
          marginLeft: props.align === "right" ? "25%" : "0%",
          marginRight: props.align === "left" ? "25%" : "0%",
          marginBottom: 16,
        }}
      >
        {props.text}
      </div>
    </div>
  );
}
