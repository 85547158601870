import axios from "axios";

const drChronoAPI = {
  getPatientId: (token, params) => {
    const endpoint = `${process.env.REACT_APP_HOST}/drchrono/patient`;
    return axios.get(endpoint, {
      // headers: { Authorization: `Bearer ${token}` },
      params: params,
    });
  },
};

export default drChronoAPI;
