import React, { useState } from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import AthelasLogo from "./assets/athelasLogo.png";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { useSelector } from "react-redux";
import patientsAPI from "../patients/patientsAPI";
import moment from "moment";
import { trackEvent } from "../../utils/tracking";

type PropsType = {
  patientId: number;
  name: Text;
};

export function DownloadNurseNotes(props: PropsType) {
  const contentArea = React.createRef();
  const token = useSelector((state: any) => state.user.token);

  const [nurseNotesList, setNurseNotesList] = useState([]);
  const [fetchedNurseNotes, setFetchedNurseNotes] = useState(false);
  console.log(nurseNotesList);

  if (!fetchedNurseNotes) {
    patientsAPI.getPatientNurseNotes(props.patientId, token).then((res) => {
      if (res?.data?.nurse_notes) {
        let notes = res?.data?.nurse_notes;
        setNurseNotesList(notes);
      }
    });
    return setFetchedNurseNotes(true);
  }

  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      fileName: `RPM_${props.name}_Nurse_Notes`.replace(" ", "_"),
      paperSize: "A4",
    });
    trackEvent("click", "download_nurse_notes");
  };

  return (
    <div>
      <PDFExport ref={null} paperSize="A4">
        <div
          style={{
            background: "white",
            padding: 32,
            borderRadius: 16,
            boxShadow: "0px 0px 10px #dedede",
            minWidth: 400,
          }}
        >
          <h5 style={{ marginLeft: "-8px" }}>Download Nurse Notes</h5>
          <p>
            Export file contains all Nurse Notes, written by Athelas Clinical
            Staff, for {props.name}.
          </p>
          <p>This file can be attached to the EHR entries for this patient.</p>

          <div
            style={{
              width: 300,
              marginTop: 16,
              margin: "0% auto",
              zIndex: 100,
            }}
          >
            <AthelasButton
              variant="info"
              size="small"
              text="Download Nurse Notes"
              onClick={handleExportWithFunction}
            />
          </div>
        </div>

        {/* PDF Content for Test Results */}
        <div style={{ opacity: 0, height: 0 }}>
          <div
            style={{
              padding: 32,
              fontSize: 10,
            }}
            ref={contentArea}
          >
            <hr></hr>
            <img src={AthelasLogo} style={{ width: 130 }} alt="Athelas Logo" />
            <h2 style={{ marginLeft: -2, fontSize: 18 }}>
              Nurse Notes for {props.name}
            </h2>
            <p>
              This document contains all nurse notes written by Athelas Clinical
              staff for {props.name}.
            </p>
            <hr></hr>
            <table>
              {nurseNotesList.map((nurseNote) => {
                return (
                  <tr>
                    <td
                      style={{
                        width: "40%",
                        padding: 16,
                        border: "solid 1px #888",
                      }}
                    >
                      <p style={{ margin: 0, fontWeight: "bold" }}>
                        Written on:
                      </p>
                      <p style={{ margin: 0 }}>
                        {moment(nurseNote.created_at).format(
                          "MMM D, YYYY - MM:HH A"
                        )}
                      </p>
                      <br />
                      <p style={{ margin: 0, fontWeight: "bold" }}>
                        Review Time:
                      </p>
                      <p style={{ margin: 0 }}>
                        {nurseNote.review_time} minutes
                      </p>
                    </td>
                    <td
                      style={{
                        width: "60%",
                        padding: 16,
                        border: "solid 1px #888",
                      }}
                    >
                      <p style={{ margin: 0, fontWeight: "bold" }}>
                        Priority Notes:
                      </p>
                      <p style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                        {" "}
                        {nurseNote.escalation_note}
                      </p>
                      <p style={{ margin: 0, fontWeight: "bold" }}>
                        Priority Level:
                      </p>
                      <br />
                      <p style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                        {nurseNote.escalation_level}
                      </p>
                      <p style={{ margin: 0, fontWeight: "bold" }}>Note:</p>
                      <p style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                        {nurseNote.notes}
                      </p>
                      <br />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </PDFExport>
    </div>
  );
}
