import moment from "moment";
import "./deviceSummary.css";

import BatteryIcon from "./assets/battery.png";
import LowBattery from "./assets/low-battery.png";
import WiFi from "./assets/wifi.png";

type PropsType = {
  batteryVoltage: number;
  lastUpdated: Date;
  signalStrength: number;
  simDeviceId: string;
  testType: string;
};

//TODO fix Pulse Oximetry image
const DeviceImageMap: any = {
  BLOOD_PRESSURE:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cdb296c12c2a1560ae95_3d_illustrations_white_pressure_shadow%201.png",
  GLUCOSE:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cdb183993b3fb4576688_3d_illustrations_glucometer_shadow%201.png",
  HEART_RATE:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cdb296c12c2a1560ae95_3d_illustrations_white_pressure_shadow%201.png",
  PLATELET:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cded2235a1d4e3df378d_3d_illustrations__athleas_shadow%201%20(1).png",
  WBC:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cded2235a1d4e3df378d_3d_illustrations__athleas_shadow%201%20(1).png",
  WEIGHT:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cdb2ce12115e597aef7c_3d_illustrations_weight_shadow%201.png",
  CARDIAC_CARE:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cdb2ce12115e597aef7c_3d_illustrations_weight_shadow%201.png",
  PULSE_OXIMETRY:
    "https://assets.website-files.com/606afb6ff04916f7d332ee53/6076cdb183993b3fb4576688_3d_illustrations_glucometer_shadow%201.png",
};

export const DeviceSummaryBlock = (props: PropsType) => {
  const testType = props.testType
    .replace("TestType.", "")
    .replace("_", " ")
    .toUpperCase();
  const batteryPercentage =
    props.batteryVoltage < 101
      ? props.batteryVoltage
      : Math.round(Math.min(1, props.batteryVoltage / 6200) * 1000) / 10;
  const signalStrength = props.signalStrength;

  const batteryIcon = batteryPercentage > 30 ? BatteryIcon : LowBattery;

  if (signalStrength === -1) {
    return null;
  }

  const imageKey = props.testType.replace("TestType.", "").toUpperCase();
  const deviceImage = Object.keys(DeviceImageMap).includes(imageKey)
    ? DeviceImageMap[imageKey]
    : "";

  return (
    <div className="device-summary-container">
      <div style={{ width: "150px", margin: "0% auto" }}>
        <img
          src={deviceImage}
          alt={"SIM Sensor Device"}
          className="device-summary-image"
        />
      </div>
      <p className="test-type-title">{testType}</p>
      <p className="sim-device-id-subtitle">{props.simDeviceId}</p>

      <div className="flex-box">
        <div className="flex-box-partition">
          <img
            src={batteryIcon}
            alt={"Battery Logo"}
            style={{ width: "20%", marginLeft: "40%", marginBottom: "16px" }}
          />
          <p className="statistic">{batteryPercentage}%</p>
          <p className="statistic-title">BATTERY LEVEL</p>
        </div>
        <div className="flex-box-partition">
          <img
            src={WiFi}
            alt={"Wifi Logo"}
            style={{ width: "20%", marginLeft: "40%", marginBottom: "16px" }}
          />
          <p className="statistic">{signalStrength}%</p>
          <p className="statistic-title">SIGNAL STRENGTH</p>
        </div>
      </div>

      <div>
        <p className="statistic">
          {moment(props.lastUpdated).format("MMM D, YYYY")}
        </p>
        <p className="statistic-title">LAST UPDATED</p>
      </div>
    </div>
  );
};
