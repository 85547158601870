import styles from "../../../features/patients/PatientsList.module.css";

export type PropsType = {
  leftStat: string;
  rightStat: string;
};

export const HorizontalStats = (props: PropsType) => {
  return (
    <div className={styles.CardValueSimple} style={{ marginBottom: "24px" }}>
      <div
        style={{
          width: "100%",
          marginLeft: "8px",
          marginRight: "8px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {props.leftStat}
      </div>
      <div
        style={{
          height: "100%",
          width: "2px",
          backgroundColor: "#888",
        }}
      >
        <br></br>
      </div>
      <div
        style={{
          width: "70%",
          marginLeft: "8px",
          marginRight: "8px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {props.rightStat}
      </div>
    </div>
  );
};
