// react
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// my content
import { Header } from "../header/Header";
import styles from "./TestResults.module.css";

import { statusThunk } from "../user/userSlice";
import {
  setOutOfRangeSelected,
  setLargeChangeSelected,
  setCurrentPage,
  fetchSitePrescribersThunk,
  fetchTestResultSlice,
  setPrescriberSelected,
  fetchLastThousandTestResultSlice,
} from "./testResultsSlice";
import { ReactComponent as CrossSvg } from "./cross.svg";
import { EscalationsTable } from "../nurseNotes/EscalationsTable";
import { Pagination } from "../../app/Atoms/Pagination/Pagination";

// utils
import { IsoDateToDDMMMYYYY } from "../../utils/date";

// third party
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Column, Table, AutoSizer } from "react-virtualized";
import Form from "react-bootstrap/Form";

// react-boostrap stuff
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToggleSelect } from "../../app/Atoms/ToggleSelect/ToggleSelect";
import { trackEvent } from "../../utils/tracking";
import { PAGE_SIZE, TEST_TYPE_NAMES } from "./consts";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { ExportTestResultListAsCSVFile } from "../../utils/csv";
import Modal from "react-bootstrap/Modal";

export const TestResultsList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.testResults.status);
  const error = useSelector((state) => state.testResults.error);
  const [
    thirtyDayEscalationsEnabled,
    setThirtyDayEscalationsEnabled,
  ] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const testResults = useSelector((state) => state.testResults.test_results);
  const lastThousandTestResults = useSelector(
    (state) => state.testResults.last_thousand_test_results
  );
  const outOfRangeSelected = useSelector(
    (state) => state.testResults.outOfRangeSelected
  );
  const largeChangeSelected = useSelector(
    (state) => state.testResults.largeChangeSelected
  );
  const prescribers = useSelector((state) => state.testResults.prescribers);

  const prescriberOptions = prescribers.map((prescriber) => {
    return (
      <option value={prescriber.id} key={prescriber.id}>
        {prescriber.name}
      </option>
    );
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [isDownloading, setIsDownloading] = useState(false);

  const prescriberSelected = useSelector(
    (state) => state.testResults.prescriberSelected
  );
  const onPrescriberSelectedChanged = (e) => {
    dispatch(
      setPrescriberSelected({
        prescriberId: e.target.value,
      })
    );

    dispatch(
      fetchTestResultSlice({
        site_id,
        token,
        prescriber_id: e.target.value,
        out_of_range: outOfRangeSelected,
        large_change: largeChangeSelected,
        page_size: PAGE_SIZE,
        offset: 0,
      })
    );
    dispatch(
      fetchLastThousandTestResultSlice({
        site_id,
        token,
        prescriber_id: e.target.value,
        out_of_range: outOfRangeSelected,
        large_change: largeChangeSelected,
        page_size: 5000,
        offset: 0,
      })
    );
  };

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else if (status === "idle") {
      dispatch(fetchSitePrescribersThunk({ site_id, token }));
      dispatch(
        fetchTestResultSlice({
          site_id,
          token,
          prescriber_id: prescriberSelected,
          out_of_range: outOfRangeSelected,
          large_change: largeChangeSelected,
          page_size: PAGE_SIZE,
          offset: 0,
        })
      );
      dispatch(
        fetchLastThousandTestResultSlice({
          site_id,
          token,
          prescriber_id: prescriberSelected,
          out_of_range: outOfRangeSelected,
          large_change: largeChangeSelected,
          page_size: 5000,
          offset: 0,
        })
      );

      trackEvent("view", "viewed_test_results");
    }
  });

  const downloadLastThousandTestResultsListCSV = async (e) => {
    setIsDownloading(true);
    ExportTestResultListAsCSVFile(
      lastThousandTestResults,
      "all_patient_test_results"
    );
    setIsDownloading(false);
  };

  const downloadTestResultsListCSV = async (e) => {
    setIsDownloading(true);
    ExportTestResultListAsCSVFile(testResults, "patient_test_results");
    setIsDownloading(false);
  };

  function ResultDate({ cellData }) {
    return IsoDateToDDMMMYYYY(cellData);
  }

  function PercentileChangeCell({ cellData }) {
    if (cellData === null) {
      return "N/A";
    } else {
      return cellData + " %";
    }
  }

  function DefaultRenderer({ cellData }) {
    return cellData;
  }

  function TestTypeName({ cellData }) {
    return TEST_TYPE_NAMES[cellData];
  }

  const currentPage = useSelector((state) => state.testResults.currentPage);
  const totalNumberOfPages = useSelector(
    (state) => state.testResults.totalNumberOfPages
  );
  const totalCount = useSelector((state) => state.testResults.totalCount);

  const handleDownloadClick = () => {
    setShowModal(true);
  };

  const previousPageFunc = () => {
    if (currentPage === 1) {
      return;
    }
    dispatch(
      setCurrentPage({
        targetPage: currentPage - 1,
      })
    );

    dispatch(
      fetchTestResultSlice({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        out_of_range: outOfRangeSelected,
        large_change: largeChangeSelected,
        page_size: PAGE_SIZE,
        offset: (currentPage - 2) * PAGE_SIZE,
      })
    );
  };

  const nextPageFunc = () => {
    if (currentPage === totalNumberOfPages) {
      return;
    }

    dispatch(
      setCurrentPage({
        targetPage: currentPage + 1,
      })
    );

    dispatch(
      fetchTestResultSlice({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        out_of_range: outOfRangeSelected,
        large_change: largeChangeSelected,
        page_size: PAGE_SIZE,
        offset: currentPage * PAGE_SIZE,
      })
    );
  };

  const onOutOfRangeFilterClick = () => {
    dispatch(setOutOfRangeSelected());
    dispatch(
      fetchTestResultSlice({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        out_of_range: !outOfRangeSelected,
        large_change: largeChangeSelected,
        page_size: PAGE_SIZE,
        offset: 0,
      })
    );

    dispatch(
      fetchLastThousandTestResultSlice({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        out_of_range: !outOfRangeSelected,
        large_change: largeChangeSelected,
        page_size: 5000,
        offset: 0,
      })
    );
  };

  const onLargeChangeFilterClick = () => {
    dispatch(setLargeChangeSelected());
    dispatch(
      fetchTestResultSlice({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        out_of_range: outOfRangeSelected,
        large_change: !largeChangeSelected,
        page_size: PAGE_SIZE,
        offset: 0,
      })
    );
    dispatch(
      fetchLastThousandTestResultSlice({
        site_id,
        token,
        prescriber_id: prescriberSelected,
        out_of_range: outOfRangeSelected,
        large_change: !largeChangeSelected,
        page_size: 5000,
        offset: 0,
      })
    );
  };

  const columnInfo = [
    {
      label: "Patient ID",
      dataKey: "patient_id",
      cellRendererVal: DefaultRenderer,
    },

    {
      label: "Patient Name",
      dataKey: "patient",
      cellRendererVal: DefaultRenderer,
    },
    {
      label: "Date of Birth",
      dataKey: "patient_date_of_birth",
      cellRendererVal: DefaultRenderer,
    },
    {
      label: "Prescriber Name",
      dataKey: "prescriber_full_name",
      cellRendererVal: DefaultRenderer,
    },
    { label: "Test Type", dataKey: "test_type", cellRendererVal: TestTypeName },
    {
      label: "Test Value",
      dataKey: "display_test_value",
      cellRendererVal: DefaultRenderer,
    },
    {
      label: "% Change",
      dataKey: "percentile_change",
      cellRendererVal: PercentileChangeCell,
    },
    { label: "Test Time", dataKey: "test_time", cellRendererVal: ResultDate },
  ];
  let content;
  if (status === "succeeded" || status === "loading") {
    const testResultsTable = (
      <div className={styles.RecentTestTableContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={60}
              rowHeight={68}
              rowCount={testResults.length}
              onRowClick={({ rowData }) =>
                history.push("/patients/" + rowData.patient_id)
              }
              rowGetter={({ index }) => testResults[index]}
              rowClassName={({ index }) =>
                index !== -1 ? styles.TableRow : styles.HeaderRow
              }
            >
              {columnInfo.map((c) => {
                return (
                  <Column
                    label={c["label"]}
                    dataKey={c["dataKey"]}
                    cellRenderer={c["cellRendererVal"]}
                    width={width / columnInfo.length}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
    content = (
      <>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <div style={{ padding: "16px" }}>
            <AthelasButton
              variant="info"
              size="small"
              text={isDownloading ? "Downloading..." : "Download as CSV"}
              disabled={isDownloading}
              onClick={handleDownloadClick}
            />
          </div>
        </div>
        <div className={styles.TableCardContainer}>
          <div className={styles.TableHeader}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Form inline>
                <Form.Group className="select-form-group">
                  <Form.Control
                    as="select"
                    bsPrefix={
                      prescriberSelected === null
                        ? "SelectPlaceHolder"
                        : "SelectInput"
                    }
                    id="prescriberSelect"
                    value={prescriberSelected}
                    onChange={onPrescriberSelectedChanged}
                    style={{ width: "220px" }}
                  >
                    <option value="selected">Filter by Prescriber</option>
                    {prescriberOptions}
                  </Form.Control>
                </Form.Group>
              </Form>
              <div style={{ marginTop: 10 }}>
                <ToggleSelect
                  text="30-Day Priority Notes"
                  enabled={thirtyDayEscalationsEnabled}
                  onClick={() => {
                    setThirtyDayEscalationsEnabled(
                      !thirtyDayEscalationsEnabled
                    );
                  }}
                />
              </div>
            </div>
            <div className={styles.TableFilterContainer}>
              <div className={styles.TableFilters}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Glucose >= 250 <br />
                      Systolic &gt; 150 or &lt; 100 <br />
                      Diastolic &gt; 100 or &lt; 50 <br />
                      BPM &gt; 110 or &lt; 55
                    </Tooltip>
                  }
                >
                  <div
                    className={
                      outOfRangeSelected ? "FilterSelected" : "FilterUnselected"
                    }
                    onClick={onOutOfRangeFilterClick}
                  >
                    <CrossSvg />
                    <span> Out of Range </span>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>>= 20%</Tooltip>}
                >
                  <div
                    className={
                      largeChangeSelected
                        ? "FilterSelected"
                        : "FilterUnselected"
                    }
                    onClick={onLargeChangeFilterClick}
                  >
                    <CrossSvg />
                    <span> Large Change </span>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          {status === "loading" ? (
            <div className={styles.LoaderContainer}>
              <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
            </div>
          ) : (
            <>
              {" "}
              {thirtyDayEscalationsEnabled ? (
                <EscalationsTable />
              ) : (
                testResultsTable
              )}
            </>
          )}
        </div>
        <div className={styles.PaginationSection}>
          <div className={styles.PaginationLeft}>
            {PAGE_SIZE} results per page, {totalCount} total results
          </div>

          <div className={styles.PaginationRight}>
            <Pagination
              previousPageFunc={previousPageFunc}
              nextPageFunc={nextPageFunc}
              currentPageVal={currentPage}
              totalNumberOfPages={totalNumberOfPages}
            />
          </div>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Download Test Results as CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.Modalbody}>
            Would you like to download the current page or all test results?
            <div style={{ padding: "10px 0px 10px 0px" }}>
              <AthelasButton
                variant="info"
                size="regular"
                text="Download Current Page"
                onClick={downloadTestResultsListCSV}
              />
            </div>
            <div>
              <AthelasButton
                variant="success"
                size="regular"
                text="Download All Test Results (Max 5000)"
                onClick={downloadLastThousandTestResultsListCSV}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } else if (status === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <>
      <Header title="Test Results"></Header>

      <div className={styles.ContentContainer}>
        <div className={styles.RecentTestContainer}>{content}</div>
      </div>
    </>
  );
};
