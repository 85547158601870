import { useState } from "react";
import { useSelector } from "react-redux";
import patientsAPI from "../patients/patientsAPI";
import { useHistory } from "react-router-dom";

import styles from "../patients/PatientsList.module.css";
import { Column, Table, AutoSizer } from "react-virtualized";
import moment from "moment";

type PropsType = {};
export const EscalationsTable = (props: PropsType) => {
  const token = useSelector((state: any) => state.user.token);
  const site_id = useSelector((state: any) => state.user.currSiteId);
  const history = useHistory();

  const [escalations, setEscalations] = useState([]);
  const [fetchedEscalations, setFetchedEscations] = useState(false);

  if (!fetchedEscalations) {
    patientsAPI.getEscalations(site_id, token).then((res) => {
      setEscalations(res?.data);
    });
    setFetchedEscations(true);
  }

  const reviewTimeRenderer = ({ cellData }) => {
    return <div>{cellData} minutes</div>;
  };

  const nameRenderer = (data) => {
    return (
      <div>
        {data?.rowData?.first_name} {data?.rowData?.last_name}
      </div>
    );
  };

  const dateRenderer = ({ cellData }) => {
    return <div>{moment(cellData).format("MMM D, YYYY")}</div>;
  };

  return (
    <div style={{ height: "85%" }}>
      <AutoSizer>
        {(data: any) => (
          <Table
            width={data?.width}
            height={data?.height}
            headerHeight={60}
            rowHeight={68.35}
            rowCount={escalations.length}
            onRowClick={(data: any) =>
              history.push("/patients/" + data?.rowData?.patient_id)
            }
            rowGetter={(data: any) => escalations[data?.index]}
            rowClassName={(data: any) =>
              data?.index !== -1 ? styles.TableRow : styles.HeaderRow
            }
          >
            <Column
              label="Patient Name"
              dataKey="first_name"
              width={((data?.width - 100) / 8) * 2}
              cellRenderer={nameRenderer}
            />
            <Column
              label="Note"
              dataKey="notes"
              width={(data?.width - 100) / 8}
            />
            <Column
              label="Escalation Note"
              dataKey="escalation_notes"
              width={((data?.width - 100) / 8) * 3}
            />
            <Column
              label="Escalation Level"
              dataKey="escalation_level"
              width={(data?.width - 100) / 8}
            />
            <Column
              label="Review Time"
              dataKey="review_time"
              width={(data?.width - 100) / 8}
              cellRenderer={reviewTimeRenderer}
            />
            <Column
              label="Created On"
              dataKey="created_at"
              width={(data?.width - 100) / 8}
              cellRenderer={dateRenderer}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};
