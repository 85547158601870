import { useState } from "react";
import patientsAPI from "../patients/patientsAPI";
import { useSelector } from "react-redux";

import { getOnboardingStatus } from "../patients/patientsSlice";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

import styles from "./enrollmentStatus.module.css";
import sitesAPI from "../home/sitesAPI";

type PropsType = {
  patientId: number;
};
export function EnrollmentStatus(props: PropsType) {
  const token = useSelector((state: any) => state.user.token);
  const [frontendStatus, setFrontEndStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newBackendStatus, setNewBackendStatus] = useState("");
  const [newFrontendStatus, setNewFrontendStatus] = useState("");
  const [patientFullName, setPatientFullName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [patientInsurance, setPatientInsurance] = useState("");

  const site_id = useSelector((state: any) => state.user.currSiteId);

  const [siteStatus, setSiteStatus] = useState({
    on_athelas_billing: true,
    ship_gating_enabled: true,
  });

  if (frontendStatus === "") {
    patientsAPI.getPatient(props.patientId, token).then((res) => {
      const frontEndStatus = getOnboardingStatus(res?.data.rpm_onboard_status);
      setFrontEndStatus(frontEndStatus);
      setPatientInsurance(res?.data.insurance_company);
      const name = res?.data.first_name + " " + res?.data.last_name;
      setPatientFullName(name);
    });
  }

  sitesAPI.getSiteEligibility(site_id, token).then((res) => {
    setSiteStatus(res.data);
  }, []);

  const changeStatusFromModal = async () => {
    if (
      newBackendStatus === "DECLINED_WITHOUT_ENROLLMENT" ||
      newBackendStatus === "DROPPED_OUT"
    ) {
      //set patient status to declined
      setShowLoader(true);
      patientsAPI
        .changePatientStatus(props.patientId, newBackendStatus, token)
        .then((res) => {
          setShowModal(false);
          setShowLoader(false);
          setFrontEndStatus(newFrontendStatus);
        })
        .catch(() => {
          setShowLoader(false);
          setShowErrorModal(true);
        });
    } else {
      //send them to onboarding link returned from endpoint
      setShowLoader(true);
      patientsAPI
        .changePatientStatus(props.patientId, newBackendStatus, token)
        .then((res) => {
          setShowModal(false);
          setShowLoader(false);
          const link = res.data?.link;
          window.open(link, "_blank");
        })
        .catch(() => {
          setShowLoader(false);
          setShowErrorModal(true);
        });
    }
  };

  const changeStatus = async (
    backendStatus: string,
    frontEndStatus: string
  ) => {
    setShowModal(true);
    setNewBackendStatus(backendStatus);
    setNewFrontendStatus(frontEndStatus);
  };

  const showButtons =
    frontendStatus === "Pending" ? (
      <div className={styles.statusContainer}>
        <h4>Change Status To</h4>
        <div style={{ marginTop: 12 }}>
          <AthelasButton
            variant="danger"
            size="regular"
            text="DECLINED"
            onClick={() =>
              changeStatus("DECLINED_WITHOUT_ENROLLMENT", "Declined")
            }
          />
        </div>
        {siteStatus.on_athelas_billing && !patientInsurance ? (
          <div
            style={{ marginTop: 12, display: "flex", justifyContent: "center" }}
          >
            <p>Cannot enroll: patient does not have insurance information</p>
          </div>
        ) : (
          <div style={{ marginTop: 12 }}>
            <AthelasButton
              variant="success"
              size="regular"
              text="ENROLLED"
              onClick={() => changeStatus("CONFIRMED_ENROLLMENT", "Enrolled")}
            />
          </div>
        )}
      </div>
    ) : frontendStatus !== "Dropped Out" &&
      frontendStatus !== "Declined" &&
      frontendStatus !== "" ? (
      <div className={styles.statusContainer}>
        <h4>Change Status To</h4>
        <div style={{ marginTop: 12 }}>
          <AthelasButton
            variant="danger"
            size="regular"
            text="UNENROLL"
            onClick={() => changeStatus("DROPPED_OUT", "Declined")}
          />
        </div>
      </div>
    ) : (
      <div></div>
    );

  const modalButtonColor =
    newFrontendStatus === "Declined" ? "danger" : "success";
  const modalButtonText =
    frontendStatus === "Enrolled"
      ? "Unenroll"
      : newFrontendStatus === "Declined"
      ? "Decline"
      : "Enroll";

  const modalQuestion =
    newFrontendStatus === "Declined" ? (
      <p>Are you sure you want to drop {patientFullName} from the program?</p>
    ) : (
      <p>
        Are you sure you want to enroll {patientFullName} in the program? Click
        Enroll below to go the enrollment website.
      </p>
    );

  if (showModal) {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Enrollment</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.Modalbody}>
          {modalQuestion}
          <AthelasButton
            variant={modalButtonColor}
            size="regular"
            text={modalButtonText}
            onClick={changeStatusFromModal}
          />
        </Modal.Body>
      </Modal>
    );
  }
  if (showErrorModal) {
    return (
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>There was an error processing your request.</Modal.Title>
        </Modal.Header>
      </Modal>
    );
  }
  if (showLoader) {
    return (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  }
  return (
    <div>
      <div className={styles.statusContainer}>
        <h4>Current Status</h4>
        <div className="status">{frontendStatus}</div>
      </div>
      {showButtons}
    </div>
  );
}
