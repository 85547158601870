import axios from "axios";

const chronicCareAPI = {
  getIntakeFormsAndCarePlans: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/patient_intake_form/${site_id}/get_intake_and_care_plans`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getPatientIntakeForm: (intake_form_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/patient_intake_form/get_intake_form/${intake_form_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getUnapprovedCarePlan: (care_plan_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/patient_intake_form/get_unapproved_care_plan/${care_plan_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getApprovedCarePlan: (care_plan_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/patient_intake_form/get_approved_care_plan/${care_plan_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getCarePlanSignedUrl: (bucket_url, token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/patient_intake_form/get_care_plan_signed_url`,
      { bucket_url },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  approveCarePlan: (care_plan_id, approver_prescriber_id, token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/patient_intake_form/approve_care_plan`,
      { care_plan_id, approver_prescriber_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default chronicCareAPI;
