import { useState } from "react";
import patientsAPI from "../patients/patientsAPI";
import { useSelector } from "react-redux";
import { Column, Table, AutoSizer } from "react-virtualized";
import styles from "../patients/PatientsList.module.css";

import "./deviceSummary.css";
import { DeviceSummaryBlock } from "./deviceSummaryBlock";
type PropsType = {
  patientId: number;
};

type DeviceSummaryType = {
  battery_voltage: number;
  last_updated: Date;
  signal_strength: number;
  sim_device_id: string;
  test_type: string;
  device_type: string;
};

export function DeviceSummary(props: PropsType) {
  const [fetchedPatientSummary, setFetchedPatientSummary] = useState(false);
  const [deviceSummaries, setDeviceSummaries] = useState([]);

  const [deviceStatusList, setDeviceStatusList] = useState([]);
  const token = useSelector((state: any) => state.user.token);

  if (!fetchedPatientSummary) {
    patientsAPI.getPatientDevicesSummary(props.patientId, token).then((res) => {
      if (res?.data?.device_summaries) {
        setDeviceSummaries(res?.data?.device_summaries);
      }
    });

    patientsAPI
      .getPatientDevicesStatusList(props.patientId, token)
      .then((res) => {
        const devicesList = res?.data?.payload;
        setDeviceStatusList(devicesList);
      });

    return setFetchedPatientSummary(true);
  }

  function BatteryRenderer({ cellData }) {
    const num =
      cellData < 101 ? cellData : Math.round((cellData / 6200) * 1000) / 10;
    return (
      <>
        <div className={styles[cellData]}>{num}%</div>
      </>
    );
  }

  function SignalRenderer({ cellData }) {
    const num = cellData;
    return (
      <>
        <div className={styles[cellData]}>🌐 {num}%</div>
      </>
    );
  }

  function MeasurementRenderer({ cellData }) {
    const emoji = cellData <= 0 || !cellData ? "🔴" : "🟢";
    return (
      <>
        <div className={styles[cellData]}>
          {emoji} ( {cellData || 0} tests )
        </div>
      </>
    );
  }

  function DeviceTypeRenderer({ cellData }) {
    const deviceType =
      cellData?.replace("TestType.", " ").replace("_", " ") || "NONE";
    return (
      <>
        <div>{deviceType}</div>
      </>
    );
  }

  return (
    <div>
      <div className="flex-box-container">
        {deviceSummaries.map((res: DeviceSummaryType) => {
          return (
            <DeviceSummaryBlock
              batteryVoltage={res.battery_voltage}
              lastUpdated={res.last_updated}
              signalStrength={res.signal_strength}
              simDeviceId={res.sim_device_id}
              testType={res.test_type}
            />
          );
        })}
      </div>

      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={60}
            rowHeight={68.35}
            rowCount={deviceStatusList.length}
            rowGetter={(data: any) => deviceStatusList[data?.index]}
            rowClassName={(data: any) =>
              data?.index !== -1 ? styles.TableRow : styles.HeaderRow
            }
          >
            <Column
              label="Device Type"
              dataKey="device_type"
              width={(width - 100) / 6}
              cellRenderer={DeviceTypeRenderer}
            />
            <Column
              label="Device ID"
              dataKey="device_id"
              width={(width - 100) / 6}
            />
            <Column
              label="Date"
              dataKey="created_at"
              width={(width - 100) / 6}
            />
            <Column
              label="Signal Strength"
              dataKey="signal_strength"
              width={(width - 100) / 6}
              cellRenderer={SignalRenderer}
            />
            <Column
              label="Battery Level"
              dataKey="battery_voltage"
              width={(width - 100) / 6}
              cellRenderer={BatteryRenderer}
            />
            <Column
              label="# of Tests"
              dataKey="occurrences"
              width={(width - 100) / 6}
              cellRenderer={MeasurementRenderer}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
}
