import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllTimeStats,
  fetchFilteredClaimsAndStats,
  selectAllClaims,
  setDateFilters,
  setPatientNameFilters,
  setProviderNameFilters,
  setClaimStatusFilter,
  setInsuranceNameFilter,
  fetchSitePrescribersThunk,
  fetchInsuranceNames,
} from "./claimsOverviewSlice";
import { statusThunk } from "../user/userSlice";
import { useHistory } from "react-router-dom";
import { Header } from "../header/Header";
import styles from "./ClaimsOverview.module.css";
import { Column, Table, AutoSizer } from "react-virtualized";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ReactComponent as TotalReimbursementIcon } from "./assets/refund_red.svg";
import { ReactComponent as AverageReimbursementIcon } from "./assets/avg_reimbursement_blue.svg";
import { ReactComponent as ApprovalRateIcon } from "./assets/approval_rate_green.svg";
import { useState } from "react";
import fileDownload from "js-file-download";
import claimsOverviewAPI from "./claimsOverviewAPI";
import { trackEvent } from "../../utils/tracking";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";

export const ClaimsOverview = () => {
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);
  const sites = useSelector((state) => state.user.sites);
  const token = useSelector((state) => state.user.token);
  const allTimeStatsStatus = useSelector(
    (state) => state.claimsOverview.allTimeStatsStatus
  );
  const totalInsuranceReimbursement = useSelector(
    (state) => state.claimsOverview.total_insurance_reimbursements
  );
  const averageReimbursementPerClaim = useSelector(
    (state) => state.claimsOverview.average_reimbursement_per_claim
  );
  const percentageClaimsApproved = useSelector(
    (state) => state.claimsOverview.percentage_claims_approved
  );
  const filteredClaimsAndStatsStatus = useSelector(
    (state) => state.claimsOverview.filteredClaimsAndStatsStatus
  );
  const filteredTotalInsuranceReimbursements = useSelector(
    (state) => state.claimsOverview.filtered_total_insurance_reimbursements
  );
  const filteredAverageReimbursementPerClaim = useSelector(
    (state) => state.claimsOverview.filtered_average_reimbursement_per_claim
  );
  const filteredPercentClaimsApproved = useSelector(
    (state) => state.claimsOverview.filtered_percent_claims_approved
  );
  const filteredTotalSubmissions = useSelector(
    (state) => state.claimsOverview.filtered_total_submissions
  );
  const filteredClaims = useSelector(selectAllClaims);

  const from_date_filter = useSelector(
    (state) => state.claimsOverview.from_date_filter
  );
  const to_date_filter = useSelector(
    (state) => state.claimsOverview.to_date_filter
  );
  const patient_first_name_filter = useSelector(
    (state) => state.claimsOverview.patient_first_name_filter
  );
  const patient_last_name_filter = useSelector(
    (state) => state.claimsOverview.patient_last_name_filter
  );
  const insurance_name_filter = useSelector(
    (state) => state.claimsOverview.insurance_name_filter
  );
  const provider_first_name_filter = useSelector(
    (state) => state.claimsOverview.provider_first_name_filter
  );
  const provider_last_name_filter = useSelector(
    (state) => state.claimsOverview.provider_last_name_filter
  );
  const claim_status_filter = useSelector(
    (state) => state.claimsOverview.claim_status_filter
  );
  const prescribers = useSelector((state) => state.claimsOverview.prescribers);
  const site_id = useSelector((state) => state.user.currSiteId);

  const prescriberOptions = prescribers.map((prescriber) => {
    return (
      <option value={prescriber.name} key={prescriber.name}>
        {prescriber.name}
      </option>
    );
  });

  const insuranceNames = useSelector(
    (state) => state.claimsOverview.insurance_names
  );

  const insuranceNameOptions = insuranceNames.map((insurance_name) => {
    return (
      <option value={insurance_name} key={insurance_name}>
        {insurance_name}
      </option>
    );
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const updateClaimsTable = useCallback(
    (filters) => {
      let to_date = "to_date" in filters ? filters["to_date"] : to_date_filter;
      let from_date =
        "from_date" in filters ? filters["from_date"] : from_date_filter;
      let claim_status =
        "claim_status" in filters
          ? filters["claim_status"]
          : claim_status_filter;
      let patient_first_name =
        "patient_first_name" in filters
          ? filters["patient_first_name"]
          : patient_first_name_filter;
      let patient_last_name =
        "patient_last_name" in filters
          ? filters["patient_last_name"]
          : patient_last_name_filter;
      let insurance_name =
        "insurance_name" in filters
          ? filters["insurance_name"]
          : insurance_name_filter;
      let provider_first_name =
        "provider_first_name" in filters
          ? filters["provider_first_name"]
          : provider_first_name_filter;
      let provider_last_name =
        "provider_last_name" in filters
          ? filters["provider_last_name"]
          : provider_last_name_filter;

      dispatch(
        fetchFilteredClaimsAndStats({
          site_id: site_id,
          token: token,
          to_date: to_date,
          from_date: from_date,
          claim_status: claim_status,
          patient_first_name: patient_first_name,
          patient_last_name: patient_last_name,
          insurance_name: insurance_name,
          provider_first_name: provider_first_name,
          provider_last_name: provider_last_name,
        })
      );
    },
    [
      dispatch,
      token,
      to_date_filter,
      from_date_filter,
      claim_status_filter,
      patient_first_name_filter,
      patient_last_name_filter,
      insurance_name_filter,
      provider_first_name_filter,
      provider_last_name_filter,
      site_id,
    ]
  );

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else {
      if (allTimeStatsStatus === "idle") {
        dispatch(fetchAllTimeStats({ site_id, token }));
      }

      if (filteredClaimsAndStatsStatus === "idle") {
        updateClaimsTable({});
      }
      dispatch(fetchSitePrescribersThunk({ site_id, token }));
      dispatch(fetchInsuranceNames({ site_id, token }));
    }
  }, [
    sites,
    site_id,
    token,
    dispatch,
    history,
    allTimeStatsStatus,
    filteredClaimsAndStatsStatus,
    updateClaimsTable,
  ]);

  const applyDateFilter = (e, picker) => {
    const start_date = picker.startDate.format("MM/DD/YYYY");
    const end_date = picker.endDate.format("MM/DD/YYYY");

    dispatch(
      setDateFilters({
        from_date: start_date,
        to_date: end_date,
      })
    );

    updateClaimsTable({ to_date: end_date, from_date: start_date });
  };

  const applyPatientNameFilter = (e) => {
    const patient_name_split = e.target.value.split(" ");
    const patient_first_name =
      patient_name_split.length === 0 ? "" : patient_name_split[0];
    const patient_last_name =
      patient_name_split.length <= 1 ? "" : patient_name_split[1];

    dispatch(
      setPatientNameFilters({
        first_name: patient_first_name,
        last_name: patient_last_name,
      })
    );

    updateClaimsTable({
      patient_first_name: patient_first_name,
      patient_last_name: patient_last_name,
    });
  };

  const applyPrescriberNameFilter = (e) => {
    const provider_name_split = e.target.value.split(" ");
    const provider_first_name =
      provider_name_split.length === 0 ? "" : provider_name_split[0];
    const provider_last_name =
      provider_name_split.length <= 1 ? "" : provider_name_split[1];

    dispatch(
      setProviderNameFilters({
        first_name: provider_first_name,
        last_name: provider_last_name,
      })
    );

    updateClaimsTable({
      provider_first_name: provider_first_name,
      provider_last_name: provider_last_name,
    });
  };

  const applyClaimStatusFilter = (e) => {
    const claim_status = e.target.value;

    if (
      claim_status === "PAYER_APPROVED" ||
      claim_status === "PAYER_REJECTED" ||
      claim_status === "SUBMITTED" ||
      claim_status === "PROVIDER_APPROVED" ||
      claim_status === "PROVIDER_APPROVAL_PENDING" ||
      claim_status === "PROVIDER_REJECTED" ||
      claim_status === ""
    ) {
      dispatch(setClaimStatusFilter({ status: claim_status }));
      updateClaimsTable({ claim_status: claim_status });
    }
  };

  const applyInsuranceNameFilter = (e) => {
    const insurance_name = e.target.value;

    dispatch(setInsuranceNameFilter({ insurance_name: insurance_name }));

    updateClaimsTable({ insurance_name: insurance_name });
  };

  const downloadFilteredClaimsCSV = async (e) => {
    setIsCSVDownloading(true);
    let to_date = to_date_filter;
    let from_date = from_date_filter;
    let claim_status = claim_status_filter;
    let patient_first_name = patient_first_name_filter;
    let patient_last_name = patient_last_name_filter;
    let insurance_name = insurance_name_filter;
    let provider_first_name = provider_first_name_filter;
    let provider_last_name = provider_last_name_filter;
    const res = await claimsOverviewAPI.get_filtered_claims_as_csv(
      site_id,
      token,
      to_date,
      from_date,
      claim_status,
      patient_first_name,
      patient_last_name,
      insurance_name,
      provider_first_name,
      provider_last_name
    );
    fileDownload(res.data, "claims_data.csv");
    trackEvent("click", "claims_dashboard_downloaded_a_csv");
    setIsCSVDownloading(false);
  };

  function removeSpaces(cellData) {
    return cellData.replace(/\s+/g, "");
  }

  function StatusCell({ cellData }) {
    const formattedClassName = removeSpaces(cellData);
    return (
      <>
        <div
          className={styles.ClaimStatusText + " " + styles[formattedClassName]}
        >
          {cellData}
        </div>
      </>
    );
  }

  function DollarAmountCell({ cellData }) {
    return (
      <div style={{ fontWeight: "bold" }}>
        {" "}
        ${cellData === -1 ? " -" : cellData}{" "}
      </div>
    );
  }

  const AllTimeStatTextBox = ({ statName, stateValue, color }) => {
    return (
      <div className={styles.SingleStatTextBox}>
        <div className={styles.SingleStatDataText}>{statName}</div>
        <div className={styles.SingleStatDataValue} style={{ color: color }}>
          {statName === "Approval Rate" ? stateValue + "%" : "$" + stateValue}
        </div>
      </div>
    );
  };

  let allTimeContent;
  if (allTimeStatsStatus === "loading") {
    allTimeContent = (
      <div className={styles.AllTimeStatsHeader}>
        <div className={styles.SingleStatDataPill}>
          {/* <div className={styles.LoaderContainer} height={45}>
            <Loader type="TailSpin" color="#42A1F8" height={45} width={45} />
          </div> */}
        </div>
        <div className={styles.SingleStatDataPill}>
          {/* <div className={styles.LoaderContainer} height={45}>
            <Loader type="TailSpin" color="#42A1F8" height={45} width={45} />
          </div> */}
        </div>
        <div className={styles.SingleStatDataPill}>
          {/* <div className={styles.LoaderContainer} height={45}>
            <Loader type="TailSpin" color="#42A1F8" height={45} width={45} />
          </div> */}
        </div>
      </div>
    );
  } else if (allTimeStatsStatus === "succeeded") {
    allTimeContent = (
      <div className={styles.AllTimeStatsHeader}>
        <div className={styles.SingleStatDataPill}>
          <TotalReimbursementIcon />
          <AllTimeStatTextBox
            statName={"Total Reimbursements"}
            stateValue={totalInsuranceReimbursement}
            color={"#eb717c"}
          />
        </div>
        <div className={styles.SingleStatDataPill}>
          <AverageReimbursementIcon />
          <AllTimeStatTextBox
            statName={"Average Reimbursement"}
            stateValue={averageReimbursementPerClaim}
            color={"#4B93C8"}
          />
        </div>
        <div className={styles.SingleStatDataPill}>
          <ApprovalRateIcon />
          <AllTimeStatTextBox
            statName={"Approval Rate"}
            stateValue={percentageClaimsApproved}
            color={"#76BEA4"}
          />
        </div>
      </div>
    );
  } else if (allTimeStatsStatus === "failed") {
    allTimeContent = <></>;
  } else if (allTimeStatsStatus === "idle") {
    allTimeContent = <></>;
  }

  let filteredContent;
  if (filteredClaimsAndStatsStatus === "loading") {
    filteredContent = (
      <div className={styles.TableCardContainer}>
        <div>
          <div className={styles.FilteredStatsHeader}>
            <div className={styles.FilterStatDataPill}>
              <div className={styles.LoaderContainer} height={45}>
                <Loader
                  type="TailSpin"
                  color="#42A1F8"
                  height={45}
                  width={45}
                />
              </div>
            </div>
            <div className={styles.FilterStatDataPill}>
              <div className={styles.LoaderContainer} height={45}>
                <Loader
                  type="TailSpin"
                  color="#42A1F8"
                  height={45}
                  width={45}
                />
              </div>
            </div>
            <div className={styles.FilterStatDataPill}>
              <div className={styles.LoaderContainer} height={45}>
                <Loader
                  type="TailSpin"
                  color="#42A1F8"
                  height={45}
                  width={45}
                />
              </div>
            </div>
            <div className={styles.FilterStatDataPill}>
              <div className={styles.LoaderContainer} height={45}>
                <Loader
                  type="TailSpin"
                  color="#42A1F8"
                  height={45}
                  width={45}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.TableContainer}>
          <div className={styles.LoaderContainer} height={70}>
            <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
          </div>
        </div>
      </div>
    );
  } else if (filteredClaimsAndStatsStatus === "succeeded") {
    filteredContent = (
      <div className={styles.TableCardContainer}>
        <div>
          <div className={styles.FilteredStatsHeader}>
            <div className={styles.FilterStatDataPill}>
              <span className={styles.FilterStatDataText}>
                Total Reimbursements for Time Range
              </span>
              <span className={styles.FilterStatDataValue}>
                $
                {[
                  "SUBMITTED",
                  "PAYER_REJECTED",
                  "PROVIDER_APPROVAL_PENDING",
                  "PROVIDER_APPROVED",
                  "PROVIDER_REJECTED",
                ].includes(claim_status_filter)
                  ? "-"
                  : filteredTotalInsuranceReimbursements}
              </span>
            </div>
            {/* <div className={styles.divider}></div> */}
            <div className={styles.FilterStatDataPill}>
              <span className={styles.FilterStatDataText}>
                Average Reimbursement
              </span>
              <span className={styles.FilterStatDataValue}>
                $
                {[
                  "SUBMITTED",
                  "PAYER_REJECTED",
                  "PROVIDER_APPROVAL_PENDING",
                  "PROVIDER_APPROVED",
                  "PROVIDER_REJECTED",
                ].includes(claim_status_filter)
                  ? " -"
                  : filteredAverageReimbursementPerClaim}
              </span>
            </div>
            {/* <div className={styles.divider}></div> */}
            <div className={styles.FilterStatDataPill}>
              <span className={styles.FilterStatDataText}>Approval Rate</span>
              <span className={styles.FilterStatDataValue}>
                {claim_status_filter.length === 0
                  ? filteredPercentClaimsApproved
                  : "-"}
                %
              </span>{" "}
            </div>
            {/* <div className={styles.divider}></div> */}
            <div className={styles.FilterStatDataPill}>
              <span className={styles.FilterStatDataText}>
                Total Submissions
              </span>
              <span className={styles.FilterStatDataValue}>
                {filteredTotalSubmissions}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.TableContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                headerHeight={60}
                rowHeight={55.35}
                rowCount={filteredClaims.length}
                // rowRenderer={rowWrapper}
                rowGetter={({ index }) => filteredClaims[index]}
                rowClassName={({ index }) =>
                  index !== -1 ? styles.TableRow : styles.HeaderRow
                }
                onRowClick={({ rowData }) => {
                  history.push("/claimsOverview/" + rowData.id);
                  trackEvent("click", "claims_dashboard_clicked_on_claim");
                }}
              >
                <Column
                  label="Patient Name"
                  dataKey="patient_name"
                  width={(width - 100) / 6}
                />
                <Column
                  label="Prescriber Name"
                  dataKey="provider_name"
                  width={(width - 100) / 6}
                />
                <Column
                  label="Insurance Name"
                  dataKey="insurance_name"
                  width={(width - 100) / 3}
                />
                <Column
                  label="Submission Date"
                  dataKey="date_of_submission"
                  width={(width - 100) / 6}
                />
                <Column
                  label="Status"
                  dataKey="status"
                  width={(width - 100) / 3}
                  cellRenderer={StatusCell}
                />
                <Column
                  label="Reimbursement"
                  dataKey="insurance_payout"
                  width={(width - 100) / 6}
                  cellRenderer={DollarAmountCell}
                />
                {/* <Column label={"More >"} dataKey="" width={100} /> */}
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  } else if (filteredClaimsAndStatsStatus === "failed") {
    filteredContent = <></>;
  } else if (filteredClaimsAndStatsStatus === "idle") {
    filteredContent = <></>;
  }

  return (
    <>
      <Header title="Claims"></Header>
      <div className={styles.ClaimsOverviewContainer}>
        {allTimeContent}
        <div className={styles.FilterCard}>
          <div className={styles.FilterContainer}>
            <DateRangePicker
              initialSettings={{
                startDate: from_date_filter,
                endDate: to_date_filter,
              }}
              onApply={applyDateFilter}
            >
              <Form.Control
                as="select"
                bsPrefix={"ClaimsOverviewSelectInput"}
                style={{ width: "auto", appearance: "menulist" }}
              >
                <option selected disabled hidden>
                  {from_date_filter} - {to_date_filter}
                </option>
              </Form.Control>
            </DateRangePicker>
            <Form.Control
              type="text"
              bsPrefix={
                patient_first_name_filter + " " + patient_last_name_filter ===
                " "
                  ? "ClaimsOverviewSelectPlaceHolder"
                  : "ClaimsOverviewSelectInput"
              }
              placeholder="Patient Name"
              onChange={applyPatientNameFilter}
            />
            <Form.Control
              as="select"
              bsPrefix={
                provider_first_name_filter + " " + provider_last_name_filter ===
                " "
                  ? "ClaimsOverviewSelectPlaceHolder"
                  : "ClaimsOverviewSelectInput"
              }
              value={
                provider_first_name_filter + " " + provider_last_name_filter
              }
              onChange={applyPrescriberNameFilter}
              style={{ width: "auto", appearance: "menulist" }}
            >
              <option value="">Prescriber</option>
              {prescriberOptions}
            </Form.Control>
            <Form.Control
              as="select"
              bsPrefix={
                claim_status_filter === ""
                  ? "ClaimsOverviewSelectPlaceHolder"
                  : "ClaimsOverviewSelectInput"
              }
              value={claim_status_filter}
              onChange={applyClaimStatusFilter}
              style={{ width: "auto", appearance: "menulist" }}
            >
              <option value="">Claim Status</option>
              <option value="PAYER_APPROVED">Approved</option>
              <option value="PAYER_REJECTED">Rejected</option>
              <option value="SUBMITTED">Submitted</option>3
              <option value="PROVIDER_APPROVAL_PENDING">
                Provider Approval Pending
              </option>
              <option value="PROVIDER_APPROVED">Provider Approved</option>
              <option value="PROVIDER_REJECTED">Provider Rejected</option>
            </Form.Control>
            <Form.Control
              as="select"
              bsPrefix={
                insurance_name_filter === ""
                  ? "ClaimsOverviewSelectPlaceHolder"
                  : "ClaimsOverviewSelectInput"
              }
              value={insurance_name_filter}
              onChange={applyInsuranceNameFilter}
              style={{ width: "auto", appearance: "menulist" }}
            >
              <option value="">Insurance Name</option>
              {insuranceNameOptions}
            </Form.Control>
            <div style={{ marginTop: "6px" }}>
              <AthelasButton
                variant="basic"
                size="small"
                text="Download as CSV"
                radius="6"
                onClick={downloadFilteredClaimsCSV}
                disabled={isCSVDownloading}
              />
            </div>
          </div>
        </div>
        {filteredContent}
      </div>
    </>
  );
};
