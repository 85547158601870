import axios from "axios";

const claimsAPI = {
  getAllClaimsOfSite: (site_id, token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/sites/${site_id}/claims`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  downloadSingleClaimPDF: (site_id, token, patient_id, test_date) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/download_single_claim_pdf?patient_id=${patient_id}&test_date=${test_date}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      }
    );
  },
};

export default claimsAPI;
