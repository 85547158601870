import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import enrollmentAPI from "./enrollmentAPI";
import sitesAPI from "../home/sitesAPI";

const enrollmentAdapter = createEntityAdapter();

const initialState = enrollmentAdapter.getInitialState({
  status: "idle",
  error: null,
  prescribers: [],
  insurance_names: [],
});

const TEST_TYPE_TO_DEVICE_MAP = {
  WBC: "Athelas One",
  GLUCOSE: "Glucometer",
  WEIGHT: "Weight Scale",
  BLOOD_PRESSURE: "Blood Pressure Monitor",
  HEART_RATE: "Blood Pressure Monitor",
  PULSE_OXIMETRY: "Pulse Oximeter",
  PLATELET: "Athelas One",
  HEMOGLOBIN: "Athelas One",
  TEMPERATURE: "Thermometer",
  EKG: "EKG",
};

function generateReferralStatement(patient_name, prescriber_name, devices) {
  return `${patient_name} was prescribed remote patient monitoring by ${prescriber_name}. The patient requires ongoing monitoring by ${devices} device(s), to assist with side-effect management as well as managing chronic disease risk created by the patient's underlying condition(s).`;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const fetchSitePrescribersThunk = createAsyncThunk(
  "site/fetchPrescribers",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getSitePrescribers(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const approveReferralAuthorizationThunk = createAsyncThunk(
  "sites/approveReferralAuthorizations",
  async ({ token, site_id, signature, patient_ids }, { rejectWithValue }) => {
    try {
      await enrollmentAPI.approveReferralAuthorizations(
        token,
        site_id,
        signature,
        patient_ids
      );
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchPendingEnrollmentPatients = createAsyncThunk(
  "sites/getPendingEnrollmentPatients",
  async ({ token, site_id }, { rejectWithValue }) => {
    try {
      const response = await enrollmentAPI.getPendingEnrollmentPatients(
        token,
        site_id
      );
      const transformedPatients = response.data.map(transformLitePatient);
      return transformedPatients;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchPendingEnrollmentPatientsInsuranceNames = createAsyncThunk(
  "sites/getPendingEnrollmentPatientsInsuranceNames",
  async ({ token, site_id }, { rejectWithValue }) => {
    try {
      const response = await enrollmentAPI.getPendingEnrollmentPatientsInsuranceNames(
        token,
        site_id
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

function transformLitePatient(patient) {
  patient.patient_name = `${patient.first_name} ${patient.last_name}`;
  patient.devices = patient.test_types
    ? patient.test_types
        .map((test_type) => TEST_TYPE_TO_DEVICE_MAP[test_type])
        .filter(onlyUnique)
        .join(", ")
    : [];
  patient.enrollment_action = patient.enrollment_action =
    patient.enrollment_action === "INITIAL_ENROLLMENT"
      ? "Initial Enrollment"
      : "Recertify";
  patient.referral_statement = generateReferralStatement(
    patient?.first_name + " " + patient?.last_name,
    patient.prescriber_name,
    patient.devices
  );

  return patient;
}

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    reset: () => initialState,
    updateOnePatient: (state, action) => {
      enrollmentAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: {
    [fetchSitePrescribersThunk.fulfilled]: (state, action) => {
      state.prescribers = action.payload.prescribers;
    },
    [fetchPendingEnrollmentPatientsInsuranceNames.fulfilled]: (
      state,
      action
    ) => {
      state.insurance_names = action.payload.insurance_names;
    },
    [fetchPendingEnrollmentPatients.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchPendingEnrollmentPatients.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        enrollmentAdapter.removeAll(state);
        enrollmentAdapter.addMany(state, action.payload);
        state.status = "succeeded";
      }
    },
    [fetchPendingEnrollmentPatients.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
  },
});

export const { reset, updateOnePatient } = enrollmentSlice.actions;

export const {
  selectAll: selectAllPendingEnrollmentPatients,
  selectById: selectPatientById,
  selectIds: selectPatientIds,
  selectEntities: selectPatientEntities,
} = enrollmentAdapter.getSelectors((state) => state.enrollment);

export default enrollmentSlice.reducer;
