// react
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// react-bootstrap
import Dropdown from "react-bootstrap/Dropdown";

// slice logic
import { setCurrSiteId } from "../user/userSlice";

export const SiteDropdown = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const site_id_str = site_id.toString();

  const siteStrToDisplay = sites[site_id_str] + " (ID: " + site_id_str + ")";
  const formattedSiteStr =
    siteStrToDisplay.length < 24
      ? siteStrToDisplay
      : siteStrToDisplay.substring(0, 21) + "...";

  const dispatch = useDispatch();
  const history = useHistory();

  const onDropdownClick = (siteId) => {
    const numSiteId = parseInt(siteId);
    dispatch(setCurrSiteId({ site_id: numSiteId }));
    history.push("/home");
    window.location.reload();
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          style={{
            fontSize: "12px",
            fontWeight: "600",
            padding: "0.2rem 0.35rem",
          }}
        >
          {formattedSiteStr}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: "600px", overflowY: "scroll" }}>
          {Object.keys(sites).length > 0 &&
            Object.keys(sites).map((siteId) => (
              <Dropdown.Item onClick={() => onDropdownClick(siteId)}>
                <strong>{sites[siteId]}</strong> (ID: {siteId})
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
