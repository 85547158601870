import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { statusThunk } from "../user/userSlice";
import {
  fetchPendingEnrollmentPatients,
  selectAllPendingEnrollmentPatients,
} from "../enrollment/enrollmentSlice";

import { fetchReports } from "../reports/reportsSlice";

import styles from "./todos.module.css";

import IsMobile from "../../utils/isMobile";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";

const EnrollmentTodoCard = () => {
  const history = useHistory();
  const isMobile = IsMobile();

  return (
    <div
      className={styles.TodoCard}
      style={{
        display: isMobile ? "" : "grid",
      }}
      onClick={() => history.push("/enrollment")}
    >
      <div>
        <h4 className={styles.TodoCardTitle}>Review New Patient Onboardings</h4>
        <div className={styles.TodoCardSubtitle}>
          You have new patients pending your approval in the Enrollment tab.
        </div>
      </div>
      <div style={{ width: 180, marginTop: 6 }}>
        <AthelasButton
          text="Review Patients"
          variant="info"
          size="small"
          onClick={() => {
            history.push("/enrollment");
          }}
        />
      </div>
    </div>
  );
};

export const TodosList = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const enrollmentPatientStatus = useSelector(
    (state) => state.enrollment.status
  );
  const reportsStatus = useSelector((state) => state.reports.status);
  const patients = useSelector(selectAllPendingEnrollmentPatients);
  const pendingPatients = patients.filter((patient) => {
    return (
      patient.onboarding_status !== "Declined" &&
      patient.referral_authorization_id === null
    );
  });
  const [numItemsToLoad, setNumItemsToLoad] = useState(3);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else {
      if (enrollmentPatientStatus === "idle") {
        dispatch(fetchPendingEnrollmentPatients({ site_id, token }));
      }
      if (reportsStatus === "idle") {
        dispatch(fetchReports({ site_id, token }));
      }
    }
  });

  const hasTodos = pendingPatients.length > 0;
  const itemCount = pendingPatients.length > 0 ? 1 : 0;

  return (
    <div style={{ display: hasTodos ? "block" : "none" }}>
      <h4 className={styles.SectionTitle}>
        <b>Outstanding Items</b>
      </h4>
      {pendingPatients.length > 0 && <EnrollmentTodoCard />}

      {itemCount >= numItemsToLoad && (
        <div
          style={{ marginLeft: 16, cursor: "pointer" }}
          onClick={() => {
            setNumItemsToLoad(itemCount);
          }}
        >
          {numItemsToLoad + (pendingPatients.length > 0 ? 1 : 0)} out of{" "}
          {itemCount} outstanding items.{" "}
          <span>
            <u>Click to load all outstanding items.</u>
          </span>
          )
        </div>
      )}
      <br />
    </div>
  );
};
