import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import scribbleAPI from "./ScribbleAPI";

const notesAdapter = createEntityAdapter();

const initialState = notesAdapter.getInitialState({
  createStatus: "idle",
  status: "idle",
  error: null,
});

export const createNote = createAsyncThunk(
  "scribble/create_note",
  async ({ token, site_id, name, blob }, { rejectWithValue }) => {
    try {
      const response = await scribbleAPI.create(token, site_id, name, blob);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchNotes = createAsyncThunk(
  "scribble/fetch_notes",
  async ({ token, site_id }, { rejectWithValue }) => {
    try {
      const response = await scribbleAPI.get(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const noteSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchNotes.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchNotes.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        notesAdapter.upsertMany(state, action.payload.notes);
        state.status = "succeeded";
      }
    },
    [fetchNotes.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [createNote.pending]: (state, action) => {
      state.createStatus = "loading";
      state.error = null;
    },
    [createNote.fulfilled]: (state, action) => {
      if (state.createStatus === "loading") {
        notesAdapter.upsertOne(state, action.payload);
        state.createStatus = "succeeded";
      }
    },
    [createNote.rejected]: (state, action) => {
      if (state.createStatus === "loading") {
        state.createStatus = "failed";
        state.error = action.payload;
      }
    },
  },
});

export const {
  selectAll: selectAllNotes,
  selectById: selectNoteById,
  selectIds: selectNoteIds,
  selectEntities: selectNoteEntities,
} = notesAdapter.getSelectors((state) => state.notes);

export default noteSlice.reducer;
