import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// react bootstrap
import Loader from "react-loader-spinner";

import { Header } from "../header/Header";
import styles from "../chronicCare/ChronicCareList.module.css";

import {
  fetchApprovedCarePlan,
  fetchCarePlanSignedUrl,
} from "./chronicCareSlice";
import { isInIframe } from "../../utils/utils";

export const SingleApprovedCarePlanPage = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.chronicCare.singleEntryStatus);
  const info = useSelector((state) => state.chronicCare.currRecordInfo);
  const signedUrl = useSelector((state) => state.chronicCare.signedUrl);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApprovedCarePlan({ id, token }));
  }, []);

  useEffect(() => {
    if (status === "succeeded" && info) {
      dispatch(
        fetchCarePlanSignedUrl({ bucketUrl: info["bucket_url"], token })
      );
    }
  }, [info]);

  const CarePlanField = ({ fieldName, fieldValue, isUrl = false }) => {
    const normalValue = <div>{fieldValue}</div>;
    const urlValue = (
      <a href={fieldValue} target="_blank" rel="noreferrer">
        Care Plan PDF
      </a>
    );
    return (
      <div className={styles.FieldCardInfo}>
        <div className={styles.FieldCardInfoText}>{fieldName}</div>
        {isUrl ? urlValue : normalValue}
      </div>
    );
  };

  let content;
  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <>
        <Header
          title={info["first_name"] + " " + info["last_name"]}
          showBackButton={true && !isInIframe()}
          iframeView={isInIframe()}
        />

        <div className={styles.FormContainer}>
          <div className={styles.FormCard}>
            <div className={styles.FormCardTitle}>
              <span className={styles.FormCardTitleText}>
                Care Plan Details (ID: {info["id"]})
              </span>
            </div>
            <CarePlanField
              fieldName={"Patient ID"}
              fieldValue={info["patient_id"]}
            />
            <CarePlanField
              fieldName={"First Name"}
              fieldValue={info["first_name"]}
            />
            <CarePlanField
              fieldName={"Last Name"}
              fieldValue={info["last_name"]}
            />
            <CarePlanField
              fieldName={"Care Plan URL"}
              fieldValue={signedUrl}
              isUrl={true}
            />
            <br></br>
            <CarePlanField
              fieldName={"Approving Prescriber ID"}
              fieldValue={info["approver_prescriber_id"]}
            />
            <CarePlanField
              fieldName={"Prescriber Name"}
              fieldValue={
                info["approver_prescriber_first_name"] +
                " " +
                info["approver_prescriber_last_name"]
              }
            />
            <CarePlanField
              fieldName={"Prescriber NPI"}
              fieldValue={info["approver_prescriber_npi"]}
            />
            <CarePlanField
              fieldName={"Prescriber Email"}
              fieldValue={info["approver_prescriber_email"]}
            />
            <CarePlanField
              fieldName={"Date Approved"}
              fieldValue={info["date_approved"]}
            />
            <br></br>
            <CarePlanField
              fieldName={"Care Plan Submission Date"}
              fieldValue={info["submission_date"]}
            />
          </div>
        </div>
      </>
    );
  }
  return <>{content}</>;
};
