import { NavLink, useHistory } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Navbar.module.css";
import Logo from "./assets/logo.svg";
import { ReactComponent as PatientIcon } from "./assets/font_awesome/user-group-solid.svg";
import { ReactComponent as ReportsIcon } from "./assets/font_awesome/file-invoice-solid.svg";
import { ReactComponent as HomeIcon } from "./assets/font_awesome/house-solid.svg";
import { ReactComponent as ComplianceIcon } from "./assets/font_awesome/file-shield-solid.svg";
import { ReactComponent as ClaimsIcon } from "./assets/font_awesome/folder-open-regular.svg";
import { ReactComponent as CheckedIcon } from "./assets/font_awesome/user-plus-solid.svg";
import { ReactComponent as NurseIcon } from "./assets/font_awesome/notes-medical-solid.svg";
import { ReactComponent as ScribeIcon } from "./assets/font_awesome/microphone-lines-solid.svg";
import { ReactComponent as TestResultsIcon } from "./assets/font_awesome/heart-pulse-solid.svg";
import { ReactComponent as CCMIcon } from "./assets/font_awesome/clipboard-solid.svg";

import signout from "./assets/font_awesome/arrow-right-from-bracket-solid.svg";

// slices
import { logout } from "../features/user/userSlice";
import { hideNav } from "../features/header/headerSlice";
import { reset as resetPatientsState } from "../features/patients/patientsSlice";
import { reset as resetReportsState } from "../features/reports/reportsSlice";
import { reset as resetHomeState } from "../features/home/homeSlice";
import { reset as resetTestResultsState } from "../features/testResults/testResultsSlice";
import { trackEvent } from "../utils/tracking";

import { persistor } from "./store";
import { useFeatureFlag } from "../utils/feature_flag";

import { RPM_SITE_ROLES } from "./apiConstants";

const CLAIM_ACTIVE_SITE_IDS = [
  273,
  505,
  522,
  544,
  549,
  591,
  617,
  642,
  652,
  671,
  674,
  682,
  684,
  689,
  690,
  700,
  707,
  709,
  718,
  724,
  724,
  731,
  739,
  743,
  747,
  761,
  769,
  776,
  791,
  798,
  812,
  816,
  819,
  825,
  829,
  832,
  833,
  835,
  838,
  843,
  859,
  860,
  861,
  864,
  872,
  885,
  886,
  888,
  904,
  908,
  909,
  913,
  914,
  923,
  925,
  926,
  930,
  948,
];

export const Navbar = () => {
  const navState = useSelector((state) => state.header.showNav);
  const dispatch = useDispatch();
  const history = useHistory();
  const rpm_site_role = useSelector((state) => state.user.rpm_site_role);

  const onSignOutClicked = () => {
    localStorage.removeItem("user_email");

    dispatch(logout());
    dispatch(resetPatientsState());
    dispatch(resetReportsState());
    dispatch(resetHomeState());
    dispatch(resetTestResultsState());

    setTimeout(() => persistor.purge(), 200);
    window.localStorage.clear();
    history.push("/login");
  };

  function onNavLinkClick() {
    dispatch(hideNav());
  }

  function onMobileBackgroundClick() {
    dispatch(hideNav());
  }

  const site_id = useSelector((state) => state.user.currSiteId);

  const showClaimsTab = useFeatureFlag(
    "show_claims_tab_in_provider_portal",
    site_id
  );

  const showScribeTab = useFeatureFlag("scribe_provider_portal", site_id);

  let claims_nav = <></>;
  if (CLAIM_ACTIVE_SITE_IDS.includes(site_id) && showClaimsTab) {
    claims_nav = (
      <li>
        <NavLink to="/claims" activeClassName={styles.active}>
          <ClaimsIcon />
          <span> Claims </span>
        </NavLink>
      </li>
    );
  }
  let documentsNav = <></>;
  if (
    rpm_site_role === RPM_SITE_ROLES.RPM_SITE_OWNER ||
    rpm_site_role === RPM_SITE_ROLES.RPM_ORG_OWNER
  ) {
    documentsNav = (
      <li>
        <NavLink to="/Documents" activeClassName={styles.active}>
          <ComplianceIcon />
          <span> Documents </span>
        </NavLink>
      </li>
    );
  }

  let scribeNav = <></>;
  if (showScribeTab) {
    scribeNav = (
      <li>
        <NavLink
          to="/medical_scribe"
          activeClassName={styles.active}
          className={styles.navlink}
          onClick={(e) => {
            trackEvent("view", "medical_scribe_open");
          }}
        >
          <ScribeIcon />
          <span> Scribe (NEW) </span>
        </NavLink>
      </li>
    );
  }

  const showCCMTab = useFeatureFlag("show_ccm_tab", site_id);
  let ccmNav = <></>;
  if (showCCMTab) {
    ccmNav = (
      <li>
        <NavLink
          to="/chronic_care"
          activeClassName={styles.active}
          className={styles.navlink}
        >
          <CCMIcon />
          <span> Chronic Care </span>
        </NavLink>
      </li>
    );
  }

  return (
    <>
      <div
        className={
          navState
            ? styles.MobileNavBackground_Show
            : styles.MobileNavBackground_Hide
        }
        onClick={onMobileBackgroundClick}
      ></div>
      <nav
        className={
          navState
            ? styles.MobileNavContainerShow
            : styles.MobileNavContainerHide
        }
      >
        <section className={styles.navHeader}>
          <img src={Logo} alt="Athelas" />
          <hr />
        </section>

        <section>
          <div className={styles.navMain}>
            <div className={styles.navLinks}>
              <ul className={styles.navList}>
                <li>
                  <NavLink
                    to="/home"
                    activeClassName={styles.active}
                    onClick={onNavLinkClick}
                  >
                    <HomeIcon />
                    <span>Home</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/nurseNotes"
                    activeClassName={styles.active}
                    className={styles.navlink}
                  >
                    <NurseIcon />
                    <span> Nurse Notes </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/test_results"
                    activeClassName={styles.active}
                    onClick={onNavLinkClick}
                  >
                    <TestResultsIcon />
                    <span> Test Results </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/patients"
                    activeClassName={styles.active}
                    onClick={onNavLinkClick}
                  >
                    <PatientIcon />
                    <span>Patients</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/reports"
                    activeClassName={styles.active}
                    onClick={onNavLinkClick}
                  >
                    <ReportsIcon />
                    <span>Reports</span>
                  </NavLink>
                </li>
                {scribeNav}
                {documentsNav}
                {claims_nav}
                {ccmNav}
                <li>
                  <NavLink to="/enrollment" activeClassName={styles.active}>
                    <CheckedIcon />
                    <span> Enrollment </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className={styles.navFooter}>
          <div>
            <hr />
            <Nav.Item>
              <img src={signout} alt="signout" />
              <Nav.Link
                href="/login"
                onClick={onSignOutClicked}
                style={{ display: "inline" }}
              >
                Sign out
              </Nav.Link>
            </Nav.Item>
          </div>
        </section>
      </nav>
    </>
  );
};

export default Navbar;
