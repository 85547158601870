import { useEffect, useState } from "react";
import { Header } from "../header/Header";
import styles from "./PatientConnect.module.css";
import iPhoneImage from "./assets/iphone.jpg";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { useLocation, useHistory } from "react-router-dom";
import patientEnrollmentAPI from "./patientConnectAPI";
import React from "react";
import { Modal } from "react-bootstrap";
import {
  findInvalidPatientConnectFields,
  hasValidBrackets,
} from "../../utils/utils";

type PropsType = {};

const DEFAULT_TEXT =
  "Hi [patient_name], this is [doctor_name] with [site_name] reaching out to let you know I am prescribing a remote monitoring device as part of your ongoing care plan. Our partner Athelas will help set you up with a free device that you can use to test and transmit vitals from home. Please opt in here: [onboarding_link]\n\nThe Athelas team can help you with onboarding if you respond to this text, or you can go to [landing_page_link] for more information.";

export function PatientConnectComposer(props: PropsType) {
  const onMessageChanged = (text: string) => {
    const previewText = generatePreviewMessage(text);

    setMessage(text);
    setPreviewMessage(previewText);
  };

  const generatePreviewMessage = (text) => {
    const name = "Jane"; // sample name
    const doctor_name = "Dr. Allen";
    const onboarding_link = "athelas.com/onboard/ZJDEF";
    const previewText = text
      .replaceAll("[patient_name]", name)
      .replaceAll("[doctor_name]", doctor_name)
      .replaceAll("[onboarding_link]", onboarding_link)
      .replaceAll("\n", "<br/>");

    return previewText;
  };

  const [testTextMessageNote, setTestTextMessageNote] = useState("");
  const [showTestTextMessageButton, setShowTestTextMessageButton] =
    useState(true);
  const [doctorPhoneNumber, setDoctorPhoneNumber] = useState("");
  const [message, setMessage] = useState(DEFAULT_TEXT);
  const [showButton, setShowButton] = useState(true);
  const [previewMessage, setPreviewMessage] = useState(
    generatePreviewMessage(DEFAULT_TEXT)
  );
  const location = useLocation();
  const history = useHistory();

  const [fetchedInitialMessage, setFetchedInitialMessage] = useState(false);

  const patients = location.state?.patients;
  const site_id = location.state?.site_id;
  const token = location.state?.token;

  if (!fetchedInitialMessage) {
    patientEnrollmentAPI
      .getInitialPatientConnectMessage(site_id, token)
      .then((res) => res.data)
      .then((j) => {
        setPreviewMessage(generatePreviewMessage(j.message));
        setMessage(j.message);
      });

    setFetchedInitialMessage(true);
  }

  // Popup modal logic
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowButton(true);
  };
  const handleShow = () => {
    setShow(true);
    setShowButton(false);
  };
  const [validationError, setValidationError] = useState(null);

  const sendMessage = () => {
    history.push("/connectConfirm");

    patientEnrollmentAPI.send_enrollment_message(
      site_id,
      token,
      message,
      patients
    );
    handleClose();
  };

  const verifyMessage = (message: string) => {
    const invalidFields = findInvalidPatientConnectFields(message);
    if (!hasValidBrackets(message)) {
      setValidationError(
        "Error: Number of brackets do not match. Please check the Custom Fields."
      );
      setShowButton(false);
      return;
    } else if (invalidFields.length > 0) {
      setValidationError(
        "Found invalid custom fields: " + invalidFields.join(", ")
      );
      setShowButton(false);
      return;
    }

    setShowButton(true);
    setValidationError(null);
  };

  useEffect(() => {
    verifyMessage(previewMessage);
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Message Send</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm that you would like to send an enrollment message to{" "}
          {patients?.length} patients. We will schedule your message to be sent
          out at the optimal time to increase enrollment.
        </Modal.Body>

        <Modal.Body>
          <u>
            <b>EXAMPLE:</b>
          </u>{" "}
          Here is a sample message that would be sent to 'Jane' from a doctor
          who goes by 'Dr. Allen'
        </Modal.Body>
        <div
          style={{
            width: "320px",
            margin: "0% auto",
            marginBottom: "-24px",
            marginTop: "-30px",
          }}
        >
          <div className={styles.messageFloatyDiv}>
            <div className={styles.messageName}>Jane Doe</div>
            <div className={styles.messageBubble}>
              <div
                dangerouslySetInnerHTML={{
                  __html: previewMessage,
                }}
              />
            </div>
          </div>
          <img
            src={iPhoneImage}
            className={styles.iphoneMockup}
            alt="iphone mockup"
          />
        </div>
        <Modal.Footer>
          <div class="mr-auto">
            <AthelasButton
              variant="danger"
              onClick={handleClose}
              text="Cancel"
            />
          </div>
          <AthelasButton
            variant="success"
            onClick={sendMessage}
            text={`Send Message to ${patients?.length} Patients`}
          />
        </Modal.Footer>
      </Modal>

      <Header title="Patient Connect" showBackButton={true}></Header>
      <div className={styles.SuperContainer}>
        <div className={styles.Container}>
          <div className={styles.composerDiv}>
            <h3>
              <b>Message Patients to Enroll</b>
            </h3>
            <p style={{ fontSize: "90%" }}>
              Use our templates to boost adoption and drive additional revenue
              for your practice or customize the message however you’d like.
            </p>

            <hr />

            <div
              style={{ flexDirection: "row", display: "flex", marginTop: 24 }}
            >
              <div style={{ marginRight: 16, width: "30%" }}>
                <h6>
                  <b>Audience</b>
                </h6>
                <p style={{ fontSize: "100%", marginTop: 16 }}>
                  {patients?.length} Selected Patients
                </p>
              </div>

              <div style={{ width: "70%" }}>
                <h6>
                  <b>Click to insert Custom Fields</b>
                </h6>
                <div
                  style={{ width: "100%", flexWrap: "wrap", display: "flex" }}
                >
                  <div style={{ width: "45%", margin: 4 }}>
                    <AthelasButton
                      variant="danger"
                      size="small"
                      text="[patient_name]"
                      onClick={() => {
                        const newMessage = message + "[patient_name]";
                        setMessage(newMessage);
                        onMessageChanged(newMessage);
                      }}
                    />
                  </div>
                  <div style={{ width: "45%", margin: 4 }}>
                    <AthelasButton
                      variant="info"
                      size="small"
                      text="[doctor_name]"
                      onClick={() => {
                        const newMessage = message + "[doctor_name]";
                        setMessage(newMessage);
                        onMessageChanged(newMessage);
                      }}
                    />
                  </div>
                  <div style={{ width: "45%", margin: 4 }}>
                    <AthelasButton
                      variant="success"
                      size="small"
                      text="[onboarding_link]"
                      onClick={() => {
                        const newMessage = message + "[onboarding_link]";
                        setMessage(newMessage);
                        onMessageChanged(newMessage);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 24 }}>
              <h6>
                <b>Message Composer</b>
                <p style={{ fontSize: "85%", color: "#888", marginTop: 4 }}>
                  Type your message below and see how it looks for your patients
                  on the right
                </p>
              </h6>

              <textarea
                style={{
                  width: "100%",
                  minHeight: "240px",
                  padding: 16,
                  border: validationError ? "solid 2px red" : "",
                  backgroundColor: validationError ? "#fff4f2" : "",
                  outline: "none",
                }}
                value={message}
                onChange={(e) => {
                  const message = e.target.value;
                  verifyMessage(message);
                  onMessageChanged(message);
                }}
              ></textarea>
              <p style={{ fontSize: "80%", color: "red" }}>{validationError}</p>
            </div>

            <div style={{ marginTop: 24 }}>
              <h6>
                <b>Send Test Text Message (optional)</b>
                <p style={{ fontSize: "85%", color: "#888", marginTop: 4 }}>
                  Send a test text message to your phone. Patient name is 'Jane'
                  and sample doctor's name is 'Dr. Smith'.
                </p>
              </h6>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <input
                  placeholder="phone number"
                  style={{ paddingLeft: 16, paddingRight: 16, marginRight: 16 }}
                  onChange={(e) => setDoctorPhoneNumber(e.target.value)}
                ></input>
                <div
                  style={{
                    minWidth: 180,
                    display: !showTestTextMessageButton ? "none" : "",
                  }}
                >
                  <AthelasButton
                    size="small"
                    text="Send Test Message"
                    variant="danger"
                    onClick={() => {
                      if (doctorPhoneNumber.length < 10) {
                        setTestTextMessageNote("Invalid phone number.");
                        return;
                      }
                      patientEnrollmentAPI.send_test_enrollment_message(
                        message,
                        doctorPhoneNumber
                      );
                      setShowTestTextMessageButton(false);
                      setTestTextMessageNote(
                        `Sent test text message to ${doctorPhoneNumber}. You can send another message in 20 seconds.`
                      );
                      setTimeout(() => {
                        setShowTestTextMessageButton(true);
                        setTestTextMessageNote("");
                      }, 20000);
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  marginTop: 4,
                  color: "#1472c9",
                  maxWidth: 350,
                }}
              >
                {testTextMessageNote}
              </p>
            </div>
          </div>
          <div className={styles.PreviewContainer}>
            <h5
              style={{
                textAlign: "center",
                color: "#BBB",
                textTransform: "uppercase",
              }}
            >
              <b>Preview</b>
            </h5>
            <div style={{ width: "320px", margin: "0% auto" }}>
              <div className={styles.messageFloatyDiv}>
                <div className={styles.messageName}>Jane Doe</div>
                <div className={styles.messageBubble}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: previewMessage,
                    }}
                  />
                </div>
              </div>
              <img
                src={iPhoneImage}
                className={styles.iphoneMockup}
                alt="iphone mockup"
              />
            </div>
            <hr style={{ marginTop: "-24px" }}></hr>

            <h3>
              <b>Review & Send</b>
            </h3>
            <p style={{ fontSize: "90%" }}>
              After reviewing the message above, you are good to go! Pressing
              ‘Send Messages’ will send your message via text to all{" "}
              {patients?.length} of your unenrolled patients. We will also
              follow up with your patients twice on your behalf in case they
              miss the first message.
            </p>
            <p style={{ fontSize: "90%" }}>
              You will see enrollment numbers increase in the days that follow.
            </p>
            <div style={{ display: showButton ? "" : "none" }}>
              <AthelasButton
                text={`Review and Verify Message`}
                variant={"basic"}
                onClick={() => {
                  handleShow();
                  setShowButton(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
