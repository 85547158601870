import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import documentsAPI from "./documentsAPI";

const initialState = {
  status: "idle",
  agree_date: "",
  has_baa: false,
  has_service_agreement: false,
  has_contract: false,
  claim_collections: [],
};

export const agreementStatusThunk = createAsyncThunk(
  "sites/agreementStatusThunk",
  async ({ token, site_id }, { rejectWithValue }) => {
    try {
      const response = await documentsAPI.agreement_status(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchClaimCollectionsThunk = createAsyncThunk(
  "sites/fetchClaimCollectionsThunk",
  async ({ token, site_id }, { rejectWithValue }) => {
    try {
      const response = await documentsAPI.claim_collections(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState: initialState,
  extraReducers: {
    [agreementStatusThunk.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [agreementStatusThunk.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        const data = action.payload;
        state.agree_date = data.agree_date;
        state.has_baa = data.has_baa;
        state.has_service_agreement = data.has_service_agreement;
        state.has_contract = data.has_contract;

        state.status = "succeeded";
      }
    },
    [agreementStatusThunk.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchClaimCollectionsThunk.fulfilled]: (state, action) => {
      // sort so that last one on top
      action.payload.sort((a, b) => {
        return a.month > b.month ? -1 : 1;
      });
      state.claim_collections = action.payload;
    },
  },
});

export default documentsSlice.reducer;
