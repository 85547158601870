import React, { useState, useEffect } from "react";
import styles from "./Settings.module.css";
import Switch from "react-switch";
import { useSelector, useDispatch } from "react-redux";
import { fetchPatients } from "../patients/patientsSlice";
import TestResultLimtTable from "./TestResultLimitTable";

type PropsType = {
  type: string;
  values: Array<any>;
  onChange: () => void;
  updateAlertingConditions: (attribute: string, value: number) => number;
  getAlertingCondition: (attribute: string) => number | undefined;
  enableNotifications: (test_type: string) => void;
  disableNotifications: (test_type: string) => void;
  hasCondition: (test_type: string) => boolean;
  perPatientAlertingConditions: any;
  setSelectedPatientForThreshold: any;
  updatePerPatientAlertingConditions: (
    attribute: string,
    value: number
  ) => number;
  patientsWithCustomLimits: any;
  setPatientsWithCustomLimits: any;
};

export function ThresholdSetter(props: PropsType) {
  const [checked, setChecked] = useState(props.hasCondition(props.type));
  const [patientsForDropdown, setPatientsForDropdown] = useState(props.values);
  const formattedTitle = props.type.replace("_", " ").toLowerCase();
  const dispatch = useDispatch();

  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const allPatients = useSelector((state) => state.patients);

  useEffect(() => {
    dispatch(fetchPatients({ site_id, token }));
    const arrOfObj: any[] = Object.values(allPatients.entities);
    setPatientsForDropdown(arrOfObj);
  }, [allPatients.ids.length, site_id]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const onPatientInDropdownSelect = (event) => {
    props.setSelectedPatientForThreshold(event.target.value);
  };
  return (
    <>
      <div className={styles.thresholdSetterContainer}>
        <div className={styles.flexBox}>
          <Switch
            onChange={() => {
              setChecked(!checked);
              if (checked) {
                props.disableNotifications(props.type);
              } else {
                props.enableNotifications(props.type);
              }
            }}
            checked={checked}
          />
          <p className={styles.checkLabel}>
            Receive notifications for '{formattedTitle}' test results.
          </p>
        </div>

        {checked && (
          <>
            <p>
              Set the minimum and maximum range for which you'd like to receive
              notifications.
            </p>
            <hr />
            <>
              <p style={{ marginBottom: 0 }}>
                You will receive notifications if a patient's <b>systolic</b>{" "}
                data point is out of these bounds.
              </p>
              <div className={styles.flexBox}>
                <input
                  placeholder="lower threshold"
                  type="number"
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  defaultValue={props.getAlertingCondition(
                    "alert_when_systolic_lower_than"
                  )}
                  onChange={(e) => {
                    props.updateAlertingConditions(
                      "alert_when_systolic_lower_than",
                      parseFloat(e.target.value)
                    );
                  }}
                ></input>
                <input
                  style={{
                    marginLeft: "16px",
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  placeholder="upper threshold"
                  type="number"
                  defaultValue={props.getAlertingCondition(
                    "alert_when_systolic_higher_than"
                  )}
                  onChange={(e) => {
                    props.updateAlertingConditions(
                      "alert_when_systolic_higher_than",
                      parseFloat(e.target.value)
                    );
                  }}
                ></input>
              </div>

              <br />

              <p style={{ marginBottom: 0 }}>
                You will receive notifications if a patient's <b>diastolic</b>{" "}
                data point is out of these bounds.
              </p>
              <div className={styles.flexBox}>
                <input
                  placeholder="lower threshold"
                  type="number"
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  defaultValue={props.getAlertingCondition(
                    "alert_when_diastolic_lower_than"
                  )}
                  onChange={(e) => {
                    props.updateAlertingConditions(
                      "alert_when_diastolic_lower_than",
                      parseFloat(e.target.value)
                    );
                  }}
                ></input>
                <input
                  style={{
                    marginLeft: "16px",
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  placeholder="upper threshold"
                  type="number"
                  defaultValue={props.getAlertingCondition(
                    "alert_when_diastolic_higher_than"
                  )}
                  onChange={(e) => {
                    props.updateAlertingConditions(
                      "alert_when_diastolic_higher_than",
                      parseFloat(e.target.value)
                    );
                  }}
                ></input>
              </div>

              <div>
                <div style={{ marginTop: 60 }}>
                  Select a patient in the current site you'd like to set custom
                  bounds.
                </div>
                <select
                  name="drop1"
                  id="Select1"
                  className={styles.dropDown}
                  onChange={onPatientInDropdownSelect}
                  defaultValue={"default"}
                >
                  <option value="default" disabled>
                    Select patient here
                  </option>
                  {patientsForDropdown.map((patient) => {
                    return (
                      <option key={patient.id} value={patient.id}>
                        {" "}
                        {`${patient.first_name} ${patient.last_name} (${patient.date_of_birth})`}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* per patient settings */}
              <p>
                Set the minimum and maximum range for which you'd like to
                receive notifications.
              </p>
              <hr />
              <p style={{ marginBottom: 0 }}>
                You will receive notifications if a patient's <b>systolic</b>{" "}
                data point is out of these bounds.
              </p>
              <div className={styles.flexBox}>
                <input
                  placeholder="lower threshold"
                  type="number"
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  value={
                    !isNaN(props.perPatientAlertingConditions["systolic_low"])
                      ? props.perPatientAlertingConditions["systolic_low"]
                      : ""
                  }
                  onKeyDown={blockInvalidChar}
                  onChange={(event) => {
                    props.updatePerPatientAlertingConditions(
                      "systolic_low",
                      parseFloat(event.target.value)
                    );
                  }}
                ></input>
                <input
                  style={{
                    marginLeft: "16px",
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  placeholder="upper threshold"
                  type="number"
                  value={
                    !isNaN(props.perPatientAlertingConditions["systolic_high"])
                      ? props.perPatientAlertingConditions["systolic_high"]
                      : ""
                  }
                  onKeyDown={blockInvalidChar}
                  onChange={(event) => {
                    props.updatePerPatientAlertingConditions(
                      "systolic_high",
                      parseFloat(event.target.value)
                    );
                  }}
                ></input>
              </div>

              <br />

              <p style={{ marginBottom: 0 }}>
                You will receive notifications if a patient's <b>diastolic</b>{" "}
                data point is out of these bounds.
              </p>
              <div className={styles.flexBox}>
                <input
                  placeholder="lower threshold"
                  type="number"
                  style={{
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  value={
                    !isNaN(props.perPatientAlertingConditions["diastolic_low"])
                      ? props.perPatientAlertingConditions["diastolic_low"]
                      : ""
                  }
                  onKeyDown={blockInvalidChar}
                  onChange={(event) => {
                    props.updatePerPatientAlertingConditions(
                      "diastolic_low",
                      parseFloat(event.target.value)
                    );
                  }}
                ></input>
                <input
                  style={{
                    marginLeft: "16px",
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: "center",
                  }}
                  placeholder="upper threshold"
                  type="number"
                  value={
                    !isNaN(props.perPatientAlertingConditions["diastolic_high"])
                      ? props.perPatientAlertingConditions["diastolic_high"]
                      : ""
                  }
                  onKeyDown={blockInvalidChar}
                  onChange={(event) => {
                    props.updatePerPatientAlertingConditions(
                      "diastolic_high",
                      parseFloat(event.target.value)
                    );
                  }}
                ></input>
              </div>
              <TestResultLimtTable
                patientsWithCustomLimits={props.patientsWithCustomLimits}
                setPatientsWithCustomLimits={props.setPatientsWithCustomLimits}
              ></TestResultLimtTable>
              <br />
              <br />
            </>
          </>
        )}
      </div>
    </>
  );
}
