import { useHistory } from "react-router-dom";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";
import { Header } from "../header/Header";
import styles from "./PatientConnect.module.css";

type PropsType = {};

export function PatientConnectConfirm(props: PropsType) {
  const history = useHistory();
  return (
    <>
      <Header title="Patient Connect"></Header>

      <div className={styles.SuperContainer}>
        <div className={styles.Container}>
          <div className={styles.explanationDiv}>
            <h3>
              <b>Messages Queued ✅</b>
            </h3>
            <p>
              Your enrollment messages will be sent out to your patients in the
              next 10 minutes. Check back in a few days to see your enrollment
              percentage increase.
            </p>

            <div style={{ width: "75%" }}>
              <AthelasButton
                text={"Go to Dashboard"}
                onClick={() => {
                  history.push("/");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
