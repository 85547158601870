import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Column, Table, AutoSizer } from "react-virtualized";
import { useWindowSize } from "react-use";
import Loader from "react-loader-spinner";
import { Button, Form } from "react-bootstrap";

// app stuff
import { Header } from "../header/Header";
import {
  setLoadedReportId,
  selectReportById,
  attestReportThunk,
  fetchTestResultByIdsThunk,
} from "./reportsSlice";

// util
import { IsoDateToMMMYYYY } from "../../utils/date";
import { IsoDateToDDMMMYYYY } from "../../utils/date";
import { ExportTestResultsAsCSVFile } from "../../utils/csv";
// style
import styles from "./ReportsList.module.css";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";

import { RPM_SITE_ROLES } from "../../app/apiConstants";

const testTypeNames = {
  GLUCOSE: "Glucose",
  BLOOD_PRESSURE: "Blood Pressure",
  WEIGHT: "Weight",
  HEART_RATE: "Heart Rate",
  CARDIAC_CARE: "Cardiac Care",
  PULSE_OXIMETRY: "Pulse Oximetry",
  UNKNOWN: "Unknown",
};

const preText =
  "I attest to having reviewed, or my staff having reviewed the above patient data as part of the Remote Patient Monitoring Program. In combination, my staff, contractors, subcontractors, Athelas and myself spent a total of ";

const postText =
  "minutes, with the appropriate review period per patient, over the course of normal business & operations in the past month conducting services such as, but not limited to: reviewing patient data, reviewing clinical context of patients, reviewing related clinical history, reviewing patient medications, analyzing potential side effects arising from medications, collecting data, communicating with patients interactively, analyzing potential side effects arising from medications, collecting data, communicating with patients, assisting patients, supporting patients, and/or related activities as part of the remote patient monitoring program. After insurance billing, standard copay collection efforts will be undertaken, and I have access and will review appropriate EOBs. I attest that the relevant CPT codes of remote patient monitoring shown above have been performed for the given patients and authorize submission of claims to payers for the relevant RPM services.";

export const SingleReportPage = () => {
  const { reportId } = useParams();
  // report month is 0 based
  const reportMonth = parseInt(reportId.slice(5, 7)) - 1;
  const reportYear = parseInt(reportId.slice(0, 4));
  const report = useSelector((state) => selectReportById(state, reportId));

  const token = useSelector((state) => state.user.token);
  const site_id = useSelector((state) => state.user.currSiteId);

  const testResultsStatus = useSelector(
    (state) => state.reports.testResultStatus
  );
  const error = useSelector((state) => state.testResults.error);
  const user_site_role = useSelector((state) => state.user.rpm_site_role);

  const dispatch = useDispatch();
  const loadedReportID = useSelector((state) => state.reports.loadedReportID);

  let testResults = useSelector((state) => state.reports.testResults);
  if (loadedReportID !== reportId) {
    testResults = [];
  }

  useEffect(() => {
    if (testResultsStatus !== "loading" && loadedReportID !== reportId) {
      dispatch(setLoadedReportId({ reportId }));
      dispatch(
        fetchTestResultByIdsThunk({
          ids: report.data.test_result_ids,
          token: token,
        })
      );
    }
  });

  const window = useWindowSize();
  const windowWidth = window.width;
  const history = useHistory();

  const testedCount = report.data.tested_patient_count;
  const totalPatientsCount = report.data.total_patient_count;

  function ResultDate({ cellData }) {
    return IsoDateToDDMMMYYYY(cellData);
  }

  function TestTypeName({ cellData }) {
    return testTypeNames[cellData];
  }

  const fullTable = (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={60}
          rowHeight={68.35}
          rowCount={testResults.length}
          onRowClick={({ rowData }) =>
            history.push("/patients/" + rowData.patient_id)
          }
          rowGetter={({ index }) => testResults[index]}
          rowClassName={({ index }) =>
            index !== -1 ? styles.TableRow : styles.HeaderRow
          }
        >
          <Column label="Patient Name" dataKey="patient" width={width / 5} />
          <Column
            label="Test Type"
            dataKey="test_type"
            cellRenderer={TestTypeName}
            width={width / 5}
          />
          <Column
            label="Test Value"
            dataKey="display_test_value"
            width={width / 5}
          />
          <Column
            label="Test Time"
            dataKey="test_time"
            cellRenderer={ResultDate}
            width={width / 5}
          />
          <Column label="CPT Codes" dataKey="cpt_codes" width={width / 5} />
        </Table>
      )}
    </AutoSizer>
  );

  const mobileTable = (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={60}
          rowHeight={68.35}
          rowCount={testResults.length}
          onRowClick={({ rowData }) =>
            history.push("/patients/" + rowData.patient_id)
          }
          rowGetter={({ index }) => testResults[index]}
          rowClassName={({ index }) =>
            index !== -1 ? styles.TableRow : styles.HeaderRow
          }
        >
          <Column
            label="Patient Name"
            dataKey="patient"
            width={(width / 4) * 3}
          />

          <Column label="More >" dataKey="" width={width / 4} />
        </Table>
      )}
    </AutoSizer>
  );

  let tableContent;
  if (testResultsStatus === "loading") {
    tableContent = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (testResultsStatus === "succeeded" && reportId === loadedReportID) {
    tableContent = windowWidth > 1024 ? fullTable : mobileTable;
  } else if (testResultsStatus === "error") {
    tableContent = <div>{error}</div>;
  }

  const today = new Date();

  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  const [attestTextChecked, setAttestedCheckChange] = useState(false);
  const [attestTimeInMinutes, setAttestTimeInMinutes] = useState(
    testedCount * 20
  );
  const [signDate, setSignDate] = useState(`${yyyy}-${mm}-${dd}`);
  const [signature, setSignature] = useState("");

  const onSignatureChanged = (e) => setSignature(e.target.value);
  const onSigndateChanged = (e) => setSignDate(e.target.value);
  const onAttestedTimeChanged = (e) => setAttestTimeInMinutes(e.target.value);
  const onAttestedCheckChange = (e) => setAttestedCheckChange(e.target.checked);
  const onConfirmClicked = async () => {
    try {
      const attestText = `${preText} ${attestTimeInMinutes} ${postText}`;
      if (testedCount !== 0 && attestTimeInMinutes > testedCount * 20) {
        alert(`Upper limit for time spent is ${testedCount * 20} minutes`);
        return;
      }
      dispatch(
        attestReportThunk({
          token,
          site_id: site_id,
          attestData: {
            attest_content: attestText,
            sign_date: signDate,
            signature: signature,
            month: reportMonth + 1, // reportMonth is 0 based
            year: reportYear,
          },
        })
      );
      alert("Report successfully attested.");
    } catch (err) {
      console.error("Failed to attest report: ", err);
    }
  };

  const attestTextSpanPre = <span>{preText}</span>;
  const attestTextSpanPost = <span>{postText}</span>;
  const attestTimeInput = (
    <Form.Control
      inline
      type="number"
      value={attestTimeInMinutes}
      bsPrefix={styles.AttestTimeInput}
      onChange={onAttestedTimeChanged}
    />
  );

  const attestationContent = (
    <>
      <h2 className={styles.AttestationTitle}>
        Complete Monthly Attestation and Claims Review
      </h2>
      <div className={styles.ConfirmationContainer}>
        <Form>
          <Form.Group>
            <Form.Check type="checkbox" controlId="attest">
              <Form.Check.Input
                type="checkbox"
                Checked={attestTextChecked}
                onChange={onAttestedCheckChange}
              />
              <Form.Check.Label>
                {attestTextSpanPre}
                {attestTimeInput}
                {attestTextSpanPost}
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>

          <Form.Group controlId="date">
            <Form.Control
              type="date"
              value={signDate}
              onChange={onSigndateChanged}
            />
          </Form.Group>
          <Form.Group controlId="sign">
            <Form.Control
              type="text"
              placeholder="Please type your full name here to sign"
              value={signature}
              onChange={onSignatureChanged}
            />
          </Form.Group>
          <Button
            type="button"
            variant="primary"
            disabled={!attestTextChecked || signature === ""}
            onClick={onConfirmClicked}
          >
            Confirm
          </Button>
        </Form>
      </div>
    </>
  );

  function ShowAttestation() {
    const curentMonth = today.getMonth();
    const curentYear = today.getFullYear();

    // current month not complete yet
    if (curentMonth === reportMonth && curentYear === reportYear) {
      return false;
    }

    // attested already ?
    if (report.data.attested) {
      return false;
    }

    if (user_site_role === RPM_SITE_ROLES.RPM_SITE_PRESCRIBER) {
      return false;
    }

    return true;
  }

  const HandleDataExport = () => {
    ExportTestResultsAsCSVFile(testResults, reportId);
  };

  return (
    <>
      <Header
        title={IsoDateToMMMYYYY(report.month)}
        showBackButton={true}
      ></Header>
      <div>
        <div className={styles.SingleReportContainer}>
          <div className={styles.SingleReportDataHeader}>
            <div className={styles.SingleReportDataPill}>
              <span className={styles.SingleReportDataValue}>
                {testedCount}
              </span>
              <span className={styles.SingleReportDataText}>
                Patients Tested
              </span>
            </div>
            <div className={styles.SingleReportDataPill}>
              <span className={styles.SingleReportDataValue}>
                {totalPatientsCount}
              </span>
              <span className={styles.SingleReportDataText}>
                Total Patients
              </span>
            </div>
            <div>
              <AthelasButton
                variant="basic"
                text="Export Data to CSV"
                size="large"
                className={styles.ReferralButton}
                onClick={HandleDataExport}
              />
            </div>
          </div>
        </div>
        <div className={styles.CPTCodeSection}>
          <a href="/CPT.pdf" target="_blank" rel="noopener noreferrer">
            CPT Code Summaries and Descriptors
          </a>
        </div>

        <div className={styles.TableCardContainer}>{tableContent}</div>

        {ShowAttestation() ? attestationContent : <></>}
      </div>
    </>
  );
};
