import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// app stuff
import { Header } from "../header/Header";
import { selectClaimById } from "./claimsSlice";

import { ReactComponent as CoinsSVG } from "./Coins.svg";

import { IsoDateToDDMMMYYYY } from "../../utils/date";

import { ReactComponent as CalendarSVG } from "./Calendar.svg";
import { ReactComponent as ProfileSVG } from "./Profile.svg";
import { ReactComponent as MessageSVG } from "./Message.svg";
import { ReactComponent as ReportsSVG } from "../../app/assets/reports.svg";

// style
import styles from "./Claims.module.css";

export const SingleClaimPage = () => {
  const { claimID } = useParams();
  const claim = useSelector((state) => selectClaimById(state, claimID));

  let amount;
  if (claim.amount_in_usd_cents) {
    amount = claim.amount_in_usd_cents / 100;
  } else {
    amount = `${claim.estimated_value}*`;
  }

  let reimbursedGrid;
  if (claim.status === "Reimbursed") {
    reimbursedGrid = (
      <div className={styles.TimelineGrid}>
        <div className={styles.TimelineIcon}>
          <CoinsSVG />
        </div>
        <div>
          <div className={styles.TimelineStatusText}>Reimbursed</div>
          <div className={styles.TimelineTimeText}>
            {IsoDateToDDMMMYYYY(claim.last_update)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header title="Claim Detail" showBackButton={true}></Header>
      <div className={styles.SingleClaimContainer}>
        <div className={styles.Card}>
          <div className={styles.CardInnerHeader}>
            <CoinsSVG />
            <span className={styles.CardSubTitle}>Payment</span>
          </div>
          <div>
            <span className={styles.CurrencyValueText}>$ {amount}</span>
            <span className={styles.CurrencyTypeText}>USD</span>
            <span className={styles[claim.status]}> {claim.status} </span>
            <div className={styles.TabsContainer}>
              <div className={styles.Tab}>
                <CalendarSVG />
                {IsoDateToDDMMMYYYY(claim.last_update)}
              </div>
              <div className={styles.Tab}>
                <ReportsSVG />
                CPT : {claim.cpt_code}
              </div>
              <div className={styles.LastTab}>
                <ProfileSVG />
                {claim.patient_name}
              </div>
            </div>
          </div>
        </div>
        <h1 className={styles.CardTitle}>Timeline</h1>
        <div className={styles.Card}>
          <div className={styles.TimelineGrid}>
            <div className={styles.TimelineIcon}>
              <MessageSVG />
            </div>
            <div>
              <div className={styles.TimelineStatusText}>Claim Submitted</div>
              <div className={styles.TimelineTimeText}>
                {IsoDateToDDMMMYYYY(claim.submitted_at)}
              </div>
            </div>
          </div>
          {reimbursedGrid}
        </div>
      </div>
    </>
  );
};
