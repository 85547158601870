export const API_SERVER_URL = "https://api.athelas.com";

export const ASSEMBLY_AI_API_URL = "https://api.assemblyai.com/v2/";

export const ASSEMBLY_AUTH_HEADER = "bd43cd0fad47406e95e0739edbfa6aff";

export const RPM_SITE_ROLES = {
  RPM_SITE_OWNER: "RPM_SITE_OWNER",
  RPM_SITE_PRESCRIBER: "RPM_SITE_PRESCRIBER",
  RPM_ORG_OWNER: "RPM_ORG_OWNER",
};
