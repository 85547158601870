import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import styles from "./DrChrono.module.css";
import drChronoAPI from "./drChronoAPI";

import { ReactComponent as LogoSVG } from "../../app/assets/logo.svg";

export const DrChrono = () => {
  const [error, setError] = useState(false);
  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const token = useSelector((state) => state.user.token);
  const history = useHistory();

  const params = {
    doctor_id: queryParams.get("doctor_id"),
    patient_id: queryParams.get("patient_id"),
    practice_id: queryParams.get("practice_id"),
    user_id: queryParams.get("user_id"),
    jwt: queryParams.get("jwt"),
  };

  // redirect to patient page once we convert DrChrono ID to Athelas Patient ID
  drChronoAPI
    .getPatientId(token, params)
    .then((res) => {
      if (res.data.patient_id) {
        history.push("/patients/" + res.data.patient_id);
      } else {
        setError(true);
      }
    })
    .catch(() => {
      setError(true);
    });

  const loading = (
    <Loader type="TailSpin" color="#42A1F8" height={50} width={50} />
  );

  const errored = (
    <>
      <div className={styles.HeaderTitle}>
        This patient is not enrolled with Athelas.
      </div>
      <p>Please talk to your Athelas CSM for more information!</p>
    </>
  );

  return (
    <div className={styles.LogoLoadingWrapper}>
      <div style={{ textAlign: "center" }}>
        <LogoSVG width={200} height={"100%"} style={{ margin: "0 0 20 0" }} />
        {!error ? loading : errored}
      </div>
    </div>
  );
};
