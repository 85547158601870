type ValidFieldsType = {
  ok: boolean;
  message: string;
};

function validString(text) {
  return text && text.length > 0;
}

function validPhoneNumber(text) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\d{10}$/im;
  return text.match(regex) !== null;
}

function validEmail(text) {
  const re = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/im;
  return text.match(re) !== null;
}

export const validateFields = (
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
): ValidFieldsType => {
  if (!validString(firstName)) {
    return { ok: false, message: "Invalid First Name" };
  }
  if (!validString(lastName)) {
    return { ok: false, message: "Invalid Last Name" };
  }
  if (!validEmail(email)) {
    return { ok: false, message: "Invalid Email" };
  }
  if (!validPhoneNumber(phoneNumber)) {
    return {
      ok: false,
      message:
        "Invalid Phone Number. Please ensure the number is 10 digits long and do not include any non-numeric characters.",
    };
  }

  return { ok: true, message: "OK" };
};
