import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import nurseNotesAPI from "./nurseNotesAPI";
import sitesAPI from "../home/sitesAPI";

const initialState = {
  status: "idle",
  nurseNotes: [],
  totalCount: null,
  prescribers: [],
};

export const fetchNurseNotesThunk = createAsyncThunk(
  "sites/fetchNurseNotesThunk",
  async ({ token, site_id }, { rejectWithValue }) => {
    try {
      const response = await nurseNotesAPI.get(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchSitePrescribersThunk = createAsyncThunk(
  "site/fetchPrescribers",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await sitesAPI.getSitePrescribers(site_id, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const nurseNotesSlice = createSlice({
  name: "nursenotes",
  initialState: initialState,
  extraReducers: {
    [fetchNurseNotesThunk.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchNurseNotesThunk.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        state.nurseNotes = action.payload;
        state.totalCount = action.payload.length;
        state.status = "succeeded";
      }
    },
    [fetchNurseNotesThunk.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [fetchSitePrescribersThunk.fulfilled]: (state, action) => {
      state.prescribers = action.payload.prescribers;
    },
  },
});

export default nurseNotesSlice.reducer;
