import styles from "./Pagination.module.css";

export const Pagination = ({
  previousPageFunc,
  nextPageFunc,
  currentPageVal,
  totalNumberOfPages,
}) => {
  return (
    <div className={styles.PaginationContainer}>
      <span onClick={previousPageFunc} className={styles.PageButton}>
        &lt;
      </span>
      <span>{currentPageVal}</span>
      <span> of </span>
      <span> {totalNumberOfPages} </span>
      <span onClick={nextPageFunc} className={styles.PageButton}>
        &gt;
      </span>
    </div>
  );
};
