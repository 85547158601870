import React from "react";
import { MedicationSummary } from "./MedicationSummary/SingleMedicationSummary";
import { useSelector } from "react-redux";
import { selectMedicationPatientById } from "../medications/medicationSlice";

export const PatientMedicationsList = (props) => {
  const patientId = props.patientId;
  const patient = useSelector((state) =>
    selectMedicationPatientById(state, patientId)
  );

  if (!patient) return null;

  return patient["medications"].map((med) => {
    return (
      <MedicationSummary
        name={med.medication_name}
        lastTaken={med.last_taken}
        totalDays={med.dates.length}
        activated={med.activation_date}
        dates={med.dates}
        adherence={med.adherence}
        status={med.state}
      />
    );
  });
};
