import { useParams } from "react-router-dom";
import {
  selectClaimById,
  fetchSingleClaimInfo,
  updateClaimStatus,
} from "./claimsOverviewSlice";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../header/Header";
import styles from "./ClaimsOverview.module.css";
import Loader from "react-loader-spinner";
import moment from "moment";
import { statusThunk } from "../user/userSlice";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/utils";
import { AthelasButton } from "../../app/Atoms/AthelasButton/AthelasButton";

export const SingleClaim = () => {
  const { claim_id } = useParams();
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);
  const claim = useSelector((state) => selectClaimById(state, claim_id));
  const singleClaimStatus = useSelector(
    (state) => state.claimsOverview.singleClaimStatus
  );
  const sites = useSelector((state) => state.user.sites);
  const dispatch = useDispatch();
  const history = useHistory();

  if (!claim) {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // reload sites data (get the latest one upon reload)
      try {
        dispatch(statusThunk({ token }));
      } catch (err) {
        history.push("/login");
      }
    } else {
      dispatch(
        fetchSingleClaimInfo({
          site_id: site_id,
          token: token,
          claim_id: claim_id,
        })
      );
    }
  }

  const ClaimSummaryInfo = ({ infoName, infoValue, isStatus = false }) => {
    const normalValue = <div>{infoValue}</div>;
    const statusValue = (
      <div
        className={
          styles.ClaimStatusText +
          " " +
          styles[infoValue] +
          " " +
          styles.ClaimStatusTextDetail
        }
      >
        {" "}
        {infoValue}
      </div>
    );
    return (
      <div className={styles.SummaryCardInfo}>
        <div className={styles.SummaryCardInfoText}>{infoName} </div>
        {isStatus ? statusValue : normalValue}
      </div>
    );
  };

  const handleClaimStatusUpdateClick = async (status_to_update) => {
    await dispatch(
      updateClaimStatus({
        token: token,
        site_id: site_id,
        claim_id: claim.id,
        status_to_update: status_to_update,
      })
    );
    await dispatch(
      fetchSingleClaimInfo({
        site_id: site_id,
        token: token,
        claim_id: claim.id,
      })
    );
  };

  let content;
  if (claim || singleClaimStatus === "succeeded") {
    content = (
      <>
        <div className={styles.ApproveSection}>
          <AthelasButton
            text="Approve Claim for Submission"
            variant="success"
            size="medium"
            onClick={() => handleClaimStatusUpdateClick("Approved")}
            disabled={claim.status != "Provider Approval Pending"}
          />
          <AthelasButton
            text="Cancel Claim Submission"
            variant="danger"
            size="medium"
            onClick={() => handleClaimStatusUpdateClick("Declined")}
            disabled={claim.status != "Provider Approval Pending"}
          />
        </div>
        <div className={styles.ClaimSummaryContainer}>
          <div className={styles.SummaryCard}>
            <div className={styles.SummaryCardTitle}>
              <span className={styles.SummaryCardTitleText}>
                Service Summary
              </span>
            </div>
            <ClaimSummaryInfo
              infoName={"Patient Name"}
              infoValue={claim.patient_name}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Date of Birth"}
              infoValue={moment(claim.date_of_birth).format("MM/DD/YYYY")}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Diagnosis Code"}
              infoValue={
                claim.diagnosis_code !== null ? claim.diagnosis_code : ""
              }
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Prescriber Name"}
              infoValue={claim.provider_name}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Date of Service"}
              infoValue={claim.date_of_service}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"CPT Codes"}
              infoValue={claim.cpt_codes.join(", ")}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Billed Amount"}
              infoValue={claim.billed_amount}
            ></ClaimSummaryInfo>
            {claim.status === "Approved" ? (
              <ClaimSummaryInfo
                infoName={"Invoice ID"}
                infoValue={
                  claim.invoice_id !== undefined ? claim.invoice_id : "-"
                }
              ></ClaimSummaryInfo>
            ) : null}
          </div>
          <div className={styles.SummaryCard}>
            <div className={styles.SummaryCardTitle}>
              <span className={styles.SummaryCardTitleText}>Claim Summary</span>
            </div>
            <ClaimSummaryInfo
              infoName={"Date of Submission"}
              infoValue={claim.date_of_submission}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Billed Insurance"}
              infoValue={capitalizeFirstLetter(claim.insurance_name)}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Member ID"}
              infoValue={claim.member_id}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Status"}
              infoValue={claim.status}
            ></ClaimSummaryInfo>
            <ClaimSummaryInfo
              infoName={"Insurance Payout"}
              infoValue={
                claim.insurance_payout !== -1 ? (
                  <span>
                    <b>{"$" + parseFloat(claim.insurance_payout)}</b>
                  </span>
                ) : (
                  "-"
                )
              }
            ></ClaimSummaryInfo>
            {claim.status === "Approved" ? (
              <ClaimSummaryInfo
                infoName={"Date of Decision"}
                infoValue={
                  claim.date_of_decision !== undefined
                    ? claim.date_of_decision
                    : "-"
                }
              ></ClaimSummaryInfo>
            ) : null}
            <ClaimSummaryInfo
              infoName={"Athelas EOB Identifier"}
              infoValue={
                claim.patient_control_number !== null
                  ? claim.patient_control_number
                  : "-"
              }
            ></ClaimSummaryInfo>
            {claim.status === "Approved" ? (
              <ClaimSummaryInfo
                infoName={"Check Number"}
                infoValue={claim.check_number !== "" ? claim.check_number : "-"}
              ></ClaimSummaryInfo>
            ) : null}
            {claim.status === "Approved" ? (
              <ClaimSummaryInfo
                infoName={"Check Issue Date"}
                infoValue={
                  claim.check_issue_date !== "" ? claim.check_issue_date : "-"
                }
              ></ClaimSummaryInfo>
            ) : null}
          </div>
        </div>
      </>
    );
  } else if (!claim || singleClaimStatus === "loading") {
    content = (
      <>
        <div className={styles.LoaderContainer} height={60}>
          <Loader type="TailSpin" color="#42A1F8" height={60} width={60} />
        </div>
        <div className={styles.LoaderContainer} height={60}>
          <Loader type="TailSpin" color="#42A1F8" height={60} width={60} />
        </div>
      </>
    );
  } else if (singleClaimStatus === "failed") {
    content = <></>;
  }

  return (
    <>
      <Header title="Claim in Detail" showBackButton={true}></Header>
      <div>{content}</div>
    </>
  );
};
