// react
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// my content
import { Header } from "../header/Header";
import styles from "./HomePage.module.css";
import { fetchSiteSummary } from "./homeSlice";
import { fetchSitePrescribersThunk } from "../patients/patientsSlice";
import { statusThunk, logout } from "../user/userSlice";
import { TodosList } from "../todos/TodosList";

// third party
import { PracticeOverview } from "./PracticeOverview";
import { trackEvent } from "../../utils/tracking";
import { useFeatureFlag } from "../../utils/feature_flag";

// Establish limit for reloading homepage ONLY when certain user data is missing
const homeRefreshLimit = 5;

export const HomePage = () => {
  const sites = useSelector((state) => state.user.sites);
  const site_id = useSelector((state) => state.user.currSiteId);
  const token = useSelector((state) => state.user.token);

  const siteSummary = useSelector((state) => state.home.siteSummary);
  const siteSummaryStatus = useSelector(
    (state) => state.home.siteSummaryStatus
  );
  const siteRefreshAttempts = useSelector(
    (state) => state.user.site_refresh_attempts
  );
  const prescribers = useSelector((state) => state.patients.prescribers);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(sites).length === 0 || site_id == null) {
      // Exceeding the refresh limit for the homepage will result in a forced logout.
      if (siteRefreshAttempts < homeRefreshLimit) {
        try {
          dispatch(statusThunk({ token }));
        } catch (err) {
          history.push("/login");
        }
      } else if (siteRefreshAttempts === homeRefreshLimit) {
        alert(
          "Your user is not associated with any sites. Please contact Engineering Support to be added to a site."
        );
        console.error(
          "Error: User not associated with any sites. Halting database requests."
        );
        dispatch(logout());
      }
    } else if (siteSummaryStatus === "idle") {
      trackEvent("view", "viewed_dashboard");
      dispatch(fetchSiteSummary({ site_id, token }));
      dispatch(fetchSitePrescribersThunk({ site_id, token }));
    }
  });

  let title = "Home";

  const isTestFeatureConfigEnabled = useFeatureFlag(
    "test_feature_config",
    site_id
  );
  if (isTestFeatureConfigEnabled) {
    title = "Home 🏡";
  }

  return (
    <>
      <Header title={title}></Header>
      <div className={styles.ContentContainer}>
        <TodosList />

        <PracticeOverview
          siteSummary={siteSummary}
          siteSummaryStatus={siteSummaryStatus}
          site_id={site_id}
          prescribers={prescribers}
          token={token}
        />
      </div>
    </>
  );
};
