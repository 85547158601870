import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import reportsAPI from "./reportsAPI";
import testResultsAPI from "../testResults/testResultsAPI";
import { transformTestResult } from "../testResults/testResultsSlice";

const reportsAdapter = createEntityAdapter({
  selectId: (report) => report.month,
  sortComparer: (a, b) => (new Date(a.month) < new Date(b.month) ? 1 : -1),
});

const initialState = reportsAdapter.getInitialState({
  status: "idle",
  error: null,
  testResultStatus: "idle",
  testResults: [],
  loadedReportID: null,
});

export const fetchReports = createAsyncThunk(
  "reports/fetchReports",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await reportsAPI.get(site_id, token);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const attestReportThunk = createAsyncThunk(
  "report/attest",
  async ({ site_id, token, attestData }, { rejectWithValue }) => {
    try {
      const response = await reportsAPI.attest(site_id, token, attestData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const fetchTestResultByIdsThunk = createAsyncThunk(
  "testResults/getByIds",
  async ({ ids, token }, { rejectWithValue }) => {
    try {
      const response = await testResultsAPI.getByIds(ids, token);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setLoadedReportId: (state, action) => {
      state.loadedReportID = action.payload.reportId;
      return state;
    },
  },
  extraReducers: {
    [fetchTestResultByIdsThunk.pending]: (state, action) => {
      state.testResultStatus = "loading";
    },
    [fetchTestResultByIdsThunk.fulfilled]: (state, action) => {
      if (state.testResultStatus === "loading") {
        const transformedTestResults = action.payload.map(transformTestResult);
        state.testResults = transformedTestResults;
        state.testResultStatus = "succeeded";
      }
    },
    [fetchTestResultByIdsThunk.rejected]: (state, action) => {
      if (state.testResultStatus === "loading") {
        state.testResultStatus = "failed";
        state.error = action.payload;
      }
    },
    [fetchReports.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchReports.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        // const transformedReports = action.payload.reports.map(transformReport);
        reportsAdapter.upsertMany(state, action.payload.reports);
        state.status = "succeeded";
      }
    },
    [fetchReports.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
    [attestReportThunk.fulfilled]: (state, action) => {
      // ID format "2020-12-01"
      const mm = action.payload.month.toString().padStart(2, "0");
      const id = `${action.payload.year}-${mm}-01`;
      state.entities[id].data.attested = true;
    },
  },
});

export const { reset, setLoadedReportId } = reportsSlice.actions;

export const {
  selectAll: selectAllReports,
  selectById: selectReportById,
  selectIds: selectReportIds,
} = reportsAdapter.getSelectors((state) => state.reports);

export default reportsSlice.reducer;
