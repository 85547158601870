import axios from "axios";

const OnboardAPI = {
  progress: (token) => {
    return axios.get(`${process.env.REACT_APP_HOST}/sites/onboard/progress`, {
      headers: { Authorization: token },
    });
  },
  agree_on_tc: (token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/onboard/agree_on_tc`,
      {},
      {
        headers: { Authorization: token },
      }
    );
  },
  save_credit_card: (token) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/onboard/save_credit_card`,
      {},
      {
        headers: { Authorization: token },
      }
    );
  },
  save_bank_account: (
    token,
    bankName,
    nameOnAccount,
    accountType,
    routingNumber,
    accountNumber
  ) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/onboard/save_bank_account`,
      {
        bankName,
        nameOnAccount,
        accountType,
        routingNumber,
        accountNumber,
      },
      {
        headers: { Authorization: token },
      }
    );
  },
  set_password: (token, new_password) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/onboard/set_password`,
      {
        new_password,
      },
      {
        headers: { Authorization: token },
      }
    );
  },
  submit_referrals: (token, numbers, names) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/onboard/add_referrals`,
      {
        numbers,
        names,
      },
      {
        headers: { Authorization: token },
      }
    );
  },
  submit_practice_information: ({ token, ptan, tax_id, npi }) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/onboard/add_ptan_npi_tax_id`,
      {
        ptan,
        tax_id,
        npi,
      },
      {
        headers: { Authorization: token },
      }
    );
  },
};

export default OnboardAPI;
