import styles from "../../../features/patients/PatientsList.module.css";

export type PropsType = {
  text: string;
  variant: "danger" | "success";
};

export const StatusPill = (props: PropsType) => {
  const variants = {
    danger: styles.OffScheduleText,
    success: styles.OnScheduleText,
  };

  return <div className={variants[props.variant]}>{props.text}</div>;
};
