import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import patientsAPI from "../patients/patientsAPI";

const medicationPatientsAdapter = createEntityAdapter();

const initialState = medicationPatientsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchMedicationPatients = createAsyncThunk(
  "medicationPatients/fetchMedicationPatients",
  async ({ site_id, token }, { rejectWithValue }) => {
    try {
      const response = await patientsAPI.getMedicationPatients(site_id, token);
      return response.data.patients;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const medicationPatientSlice = createSlice({
  name: "medicationPatients",
  initialState,
  reducers: {
    reset: () => initialState,
    updateOnePatient: (state, action) => {
      medicationPatientsAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: {
    [fetchMedicationPatients.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchMedicationPatients.fulfilled]: (state, action) => {
      if (state.status === "loading") {
        medicationPatientsAdapter.upsertMany(state, action.payload);
        state.status = "succeeded";
        state.error = null;
      }
    },
    [fetchMedicationPatients.rejected]: (state, action) => {
      if (state.status === "loading") {
        state.status = "failed";
        state.error = action.payload;
      }
    },
  },
});

export const { reset, updateOnePatient } = medicationPatientSlice.actions;

export const {
  selectAll: selectAllMedicationPatients,
  selectById: selectMedicationPatientById,
  selectIds: selectMedicationPatientIds,
  selectEntities: selectMedicationPatientEntities,
} = medicationPatientsAdapter.getSelectors((state) => state.medicationPatients);

export default medicationPatientSlice.reducer;
