import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Chart from "react-apexcharts";
import Loader from "react-loader-spinner";
import { Form } from "react-bootstrap";
import moment from "moment";

import { Header } from "../header/Header";
import { selectPatientById, fetchPatient } from "./patientsSlice";
import styles from "./PatientsList.module.css";

import GlucoseHistoryTable from "./historyTables/GlucoseHistoryTable";
import BloodPressureHistoryTable from "./historyTables/BloodPressureHistoryTable";
import WBCHistoryTable from "./historyTables/WBCHistoryTable";
import HeartRateHistoryTable from "./historyTables/HeartRateHistoryTable";
import PulseOximetryHistoryTable from "./historyTables/PulseOximetryHistoryTable";
import DefaultHistoryTable from "./historyTables/DefaultHistoryTable";
import WeightHistoryTable from "./historyTables/WeightHistoryTable";

import { PythonDatetimeStrToDDMMMYYYY } from "../../utils/date";
import { PatientMedicationsList } from "./PatientMedicationsList";
import patientsAPI from "./patientsAPI";

import { DeviceSummary } from "../deviceSummary/deviceSummary";
import { EnrollmentStatus } from "../enrollmentStatus/enrollmentStatus";

import { CardiacCarePatientsSummary } from "../cardiacCare/CardiacCarePatientsSummary";
import { NurseNotesClient } from "../nurseNotes/NurseNotesClient";
import { DownloadTab } from "../downloadResults/DownloadTab";
import { useFeatureFlag } from "../../utils/feature_flag";
import { isInIframe } from "../../utils/utils";

const testTypeNames = {
  GLUCOSE: "Glucose",
  BLOOD_PRESSURE: "Blood Pressure",
  WEIGHT: "Weight",
  HEART_RATE: "Heart Rate",
  PULSE_OXIMETRY: "Pulse Oximetry",
  UNKNOWN: "Unknown",
};

const TestTypeSequences = [
  "GLUCOSE",
  "BLOOD_PRESSURE",
  "WEIGHT",
  "HEART_RATE",
  "PULSE_OXIMETRY",
];

const SummaryCard = ({ testType, value, date, metadata }) => {
  const displayDate = PythonDatetimeStrToDDMMMYYYY(date);
  const millisSinceTest = Date.now() - Date.parse(date);
  const daysSinceTest = Math.floor(millisSinceTest / 1000 / 60 / 60 / 24);
  const onSchedule = <div className={styles.OnScheduleText}>On Schedule</div>;
  const offSchedule = (
    <div className={styles.OffScheduleText}>Behind Schedule</div>
  );

  return (
    <div className={styles.SummaryCard}>
      <div className={styles.CardTestTitle}>
        <span className={styles.TestType}>{testTypeNames[testType]}</span>
        <span className={styles.TestTypeSmall}>(Latest Result)</span>
      </div>
      <div className={styles.CardValue}>
        <div className={styles.CardValueText}>
          {value} <span>{metadata !== "" ? `(${metadata})` : ""}</span>
        </div>
        <div>{displayDate}</div>
      </div>
      {daysSinceTest >= 30 ? offSchedule : onSchedule}
    </div>
  );
};

const DetailBlock = ({ testType, testResults }) => {
  // early comes first for displaying timeline
  testResults = testResults.slice().sort((a, b) => {
    return new Date(a.test_time) >= new Date(b.test_time) ? 1 : -1;
  });

  const [timeRangeFilter, setTimeRangeFilter] = useState("All");
  if (timeRangeFilter === "AM") {
    testResults = testResults.slice().filter((time_result) => {
      return moment(time_result.test_time).hour() < 12;
    });
  }
  if (timeRangeFilter === "PM") {
    testResults = testResults.slice().filter((time_result) => {
      return moment(time_result.test_time).hour() > 12;
    });
  }

  // for the trendline area chart
  const options = {
    chart: {
      id: testType + "area-chart",
      toolbar: {
        show: true,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      fontFamily: "Montserrat-Regular",
      foreColor: "#999999",
    },
    xaxis: {
      type: "datetime",
      categories: testResults.map((t) => t.test_time),
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip: {
      x: {
        show: true,
        format: "MMM dd, yyyy - h:mm TT",
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
    },
  };

  let series;

  switch (testType) {
    case "GLUCOSE":
      options["yaxis"] = {
        title: {
          text: "mg/dL",
          rotate: 270,
        },
      };

      series = [
        {
          name: "glucose",
          data: testResults.map((t) => t.glucose_value),
        },
      ];

      break;
    case "BLOOD_PRESSURE":
      options["yaxis"] = {
        title: {
          text: "mm/Hg",
          rotate: 270,
        },
      };

      series = [
        {
          name: "systolic",
          data: testResults.map((t) => t.systolic),
        },
        {
          name: "diastolic",
          data: testResults.map((t) => t.diastolic),
        },
      ];

      break;
    case "WEIGHT":
      options["yaxis"] = {
        title: {
          text: "pounds",
          rotate: 270,
        },
      };

      series = [
        {
          name: "weight",
          data: testResults.map((t) => t.weight),
        },
      ];

      break;
    case "WBC":
      series = [
        {
          name: "lymphocytes",
          data: testResults.map((t) => t.lymphocytes),
        },
        {
          name: "neutrophils",
          data: testResults.map((t) => t.neutrophils),
        },
      ];

      break;
    case "HEART_RATE":
      options["yaxis"] = {
        title: {
          text: "BPM",
          rotate: 270,
        },
      };

      series = [
        {
          name: "BPM",
          data: testResults.map((t) => t.bpm),
        },
      ];

      break;
    case "PULSE_OXIMETRY":
      options["yaxis"] = {
        title: {
          text: "SpO2",
        },
      };

      series = [
        {
          name: "SpO2",
          data: testResults.map((t) => t.spo2),
        },
      ];
      break;
    default:
      series = [
        {
          name: "values",
          data: testResults.map((t) => t.value),
        },
      ];
  }

  // for history table content
  let historyTable;
  switch (testType) {
    case "GLUCOSE":
      historyTable = <GlucoseHistoryTable testResults={testResults} />;
      break;

    case "BLOOD_PRESSURE":
      historyTable = <BloodPressureHistoryTable testResults={testResults} />;
      break;

    case "WEIGHT":
      historyTable = <WeightHistoryTable testResults={testResults} />;
      break;

    case "WBC":
      historyTable = <WBCHistoryTable testResults={testResults} />;
      break;

    case "HEART_RATE":
      historyTable = <HeartRateHistoryTable testResults={testResults} />;
      break;

    case "PULSE_OXIMETRY":
      historyTable = <PulseOximetryHistoryTable testResults={testResults} />;
      break;

    default:
      historyTable = <DefaultHistoryTable testResults={testResults} />;
  }

  // the summary card, duplicated logic
  // order is reversed due to need to display trendline
  let value = "No Result";
  let latest = {
    test_time: "No Result",
  };
  let metadata = "";

  if (testResults.length > 0) {
    latest = testResults[testResults.length - 1];
    switch (testType) {
      case "GLUCOSE":
        value = `${latest.glucose_value} mg/dL`;
        metadata = latest.simhub_data.reading_period;
        break;
      case "BLOOD_PRESSURE":
        value = `${latest.systolic}/${latest.diastolic} mm/Hg`;
        break;
      case "WEIGHT":
        value = `${latest.weight} pounds`;
        break;
      case "WBC":
        value = latest.wbc_count;
        break;
      case "HEART_RATE":
        value = `${latest.bpm} bpm`;
        break;
      case "PULSE_OXIMETRY":
        value = `${latest.spo2} SpO2`;
        break;
      default:
        value = latest.value;
    }
  }

  return (
    <section className={styles.DetailBlock}>
      <h2>{testTypeNames[testType]}</h2>

      <div className={styles.DetailsContentContainer}>
        <div>
          Test Time Range Filter
          <Form.Group style={{ width: "100%", margin: 8 }}>
            <Form.Control
              as="select"
              id="time_range_filter"
              value={timeRangeFilter}
              onChange={(e) => {
                setTimeRangeFilter(e.target.value);
              }}
            >
              <option value="All" key="emptySelect" selected>
                All
              </option>
              <option value="AM" key="emptySelect" selected>
                Morning Only
              </option>
              <option value="PM" key="emptySelect" selected>
                Afternoon/Night Only
              </option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className={styles.SummaryAndTrendLineContainer}>
          <SummaryCard
            testType={testType}
            value={value}
            date={latest.test_time}
            metadata={metadata}
          ></SummaryCard>

          <div className={styles.TrendLineCard}>
            <h5>Trendline</h5>
            <Chart
              options={options}
              series={series}
              type="area"
              height="200px"
            />
          </div>
        </div>
        <div className={styles.ResultHistoryContainer}>
          <h5>{testTypeNames[testType]} Result History</h5>
          <div className={styles.HistoryTableContainer}>{historyTable}</div>
        </div>
      </div>
    </section>
  );
};

export const SinglePatientPage = () => {
  const { patientId } = useParams();
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const [mode, setMode] = useState(params.get("tab") || "result"); // result, medication, patient_info
  const patient = useSelector((state) => selectPatientById(state, patientId));
  const token = useSelector((state) => state.user.token);
  const site_id = useSelector((state) => state.user.currSiteId);

  const [hasCardiacCareData, setHasCardiacCareData] = useState(false);

  const [hasDevices, setHasDevices] = useState(false);

  const singlePatientStatus = useSelector(
    (state) => state.patients.singlePatientStatus
  );
  const error = useSelector((state) => state.patients.error);
  const dispatch = useDispatch();

  patientsAPI.getCardiacCarePatientSummary(patientId, token).then((res) => {
    if (Object.keys(res.data).length > 0) {
      setHasCardiacCareData(true);
    }
  });

  patientsAPI.getPatientDevicesSummary(patientId, token).then((res) => {
    if (
      res.data?.device_summaries &&
      Object.keys(res.data?.device_summaries).length > 0
    ) {
      for (var i = 0; i < res.data?.device_summaries?.length; i++) {
        if (res.data?.device_summaries[i]?.battery_voltage !== -1) {
          setHasDevices(true);
        }
      }
    }
  });

  //feature is only available for athelas demo
  const showEnrollmentStatus = useFeatureFlag(
    "show_enrollment_status_tab_in_single_patient_page",
    site_id
  );

  var patientViewModes = [
    { mode_id: "result", name: "Result", active: true },
    { mode_id: "nurse_notes", name: "Nurse Notes", active: true },
    { mode_id: "medication", name: "Medication", active: true },
    { mode_id: "patient_info", name: "Edit Patient Info", active: false },
    {
      mode_id: "cardiac_care",
      name: "Total Care",
      active: hasCardiacCareData,
    },
    {
      mode_id: "device_summary",
      name: "Devices",
      active: hasDevices,
    },
    {
      mode_id: "download",
      name: "Download",
      active: true,
    },
    {
      mode_id: "enrollment_status",
      name: "Enrollment Status",
      active: showEnrollmentStatus,
    },
  ];

  // initial fetch
  if (
    !patient ||
    (!patient && singlePatientStatus !== "loading") ||
    (!patient.hasOwnProperty("test_results") &&
      singlePatientStatus !== "loading")
  ) {
    dispatch(
      fetchPatient({
        id: patientId,
        token: token,
      })
    );
  }

  let testResults = [];
  if (patient && patient.hasOwnProperty("test_results")) {
    testResults = patient.test_results.slice().sort((a, b) => {
      return new Date(a.test_time) >= new Date(b.test_time) ? 1 : -1;
    });
  }

  const testsByType = {};
  for (var t of testResults) {
    if (testsByType[t.test_type]) {
      testsByType[t.test_type].push(t);
    } else {
      testsByType[t.test_type] = [t];
    }
  }
  // don't show unknow test results for now
  delete testsByType.UNKNOWN;
  delete testsByType.WBC;

  const summaryCards = TestTypeSequences.map((testType) => {
    if (testsByType[testType] === undefined) {
      return <></>;
    }

    const latest = testsByType[testType][testsByType[testType].length - 1];
    let value = "";
    let metadata = "";
    // duplicated, to be refactored
    switch (testType) {
      case "GLUCOSE":
        value = `${latest.glucose_value} mg/dL`;
        metadata = latest.simhub_data.reading_period;
        break;
      case "BLOOD_PRESSURE":
        value = `${latest.systolic}/${latest.diastolic} mm/Hg`;
        break;
      case "WEIGHT":
        value = `${latest.weight} pounds`;
        break;
      case "WBC":
        value = latest.wbc_count;
        break;
      case "HEART_RATE":
        value = `${latest.bpm} bpm`;
        break;
      case "PULSE_OXIMETRY":
        value = `${latest.spo2} spo2`;
        break;
      default:
        value = latest.value;
    }

    return (
      <SummaryCard
        key={`summary-${testType}`}
        testType={testType}
        value={value}
        date={latest.test_time}
        metadata={metadata}
      ></SummaryCard>
    );
  });

  const DetailBlocks = TestTypeSequences.map((testType) => {
    if (testsByType[testType] === undefined) {
      return <></>;
    }

    return (
      <DetailBlock
        key={`detailblock-${testType}`}
        testType={testType}
        testResults={testsByType[testType]}
      ></DetailBlock>
    );
  });

  let summarySection = (
    <section className={styles.SummarySection}>
      <h2>Summary</h2>
      <p className={styles.PatientDOB}>
        {patient && patient.hasOwnProperty("date_of_birth")
          ? `Patient DOB: ${patient.date_of_birth}`
          : ""}
      </p>
      <div className={styles.SummaryCardsContainer}>{summaryCards}</div>
    </section>
  );

  const noDataDiv = (
    <div>
      <h3>No test results for this patient</h3>
    </div>
  );

  let content;

  if (singlePatientStatus === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (singlePatientStatus === "succeeded") {
    const patientDataView = {
      result: (
        <>
          {Object.keys(testsByType).length > 1 ? summarySection : <></>}
          {DetailBlocks}
        </>
      ),
      nurse_notes: <NurseNotesClient patientId={patientId} />,
      medication: <PatientMedicationsList patientId={patientId} />,
      cardiac_care: <CardiacCarePatientsSummary patientId={patientId} />,
      device_summary: <DeviceSummary patientId={patientId} />,
      download: (
        <DownloadTab
          patientId={patientId}
          testResults={testResults}
          name={patient?.first_name + " " + patient?.last_name}
        />
      ),
      enrollment_status: <EnrollmentStatus patientId={patientId} />,
    };

    content = (
      <>
        <section className={styles.TabSection}>
          {patientViewModes.map(({ mode_id, name, active }) => {
            if (active) {
              return (
                <div
                  className={
                    mode === mode_id ? styles.ActiveTab : styles.InactiveTab
                  }
                  onClick={() => {
                    setMode(mode_id);
                  }}
                >
                  {name}
                </div>
              );
            }

            return null;
          })}
        </section>
        {testResults.length === 0 ? noDataDiv : <></>}
        {/* Only Show Summary Section when patient has one more than one test type */}
        {patientDataView[mode]}
      </>
    );
  } else if (singlePatientStatus === "failed") {
    content = <div>{error}</div>;
  }

  let patient_name = "Loading";
  if (patient) {
    patient_name = patient?.first_name + " " + patient?.last_name;
  }

  return (
    <>
      <Header
        title={patient_name}
        showBackButton={true && !isInIframe()}
        iframeView={isInIframe()}
      ></Header>
      <div className={styles.SinglePatientContainer}>{content}</div>
    </>
  );
};
