import axios from "axios";

const documentsAPI = {
  download_contracts: (site_id, token, contract_type) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/download_contracts?contract_type=${contract_type}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      }
    );
  },
  agreement_status: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/agreement_status`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  claim_collections: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/claim_collections`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  download_claim_collection: (site_id, token, claim_month) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/download_claim_collection?claim_month=${claim_month}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      }
    );
  },
};

export default documentsAPI;
