import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OnboardAPI from "./OnboardAPI";
import RoutableAPI from "./RoutableAPI";

// init state
const initialState = {
  apiCallStatus: "idle",
  token: window.location.href.split("/")[4],
  step: 1,
  email: "",
  site_address: "",
  site_id: null,
  site_name: "",
  site_type: "",
  deal_type: "",
  routable_company_exists: false,
  routable_site_id: "",
  npi: "",
  taxID: "",
  ptan: "",
  contract: {},
};

// thunks
export const fetchProgress = createAsyncThunk(
  "onboard/fetch_progress",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await OnboardAPI.progress(token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const AgreeOnTC = createAsyncThunk(
  "onboard/agree_on_tc",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await OnboardAPI.agree_on_tc(token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const SaveCreditCard = createAsyncThunk(
  "onboard/save_credit_card",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await OnboardAPI.save_credit_card(token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const SetNewPassword = createAsyncThunk(
  "onboard/set_password",
  async ({ token, newPassword }) => {
    try {
      const response = await OnboardAPI.set_password(token, newPassword);
      return {
        status: response.status,
        message: response.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: err.response.data.message,
      };
    }
  }
);

export const SubmitReferrals = createAsyncThunk(
  "onboard/submit_referrals",
  async ({ token, numbers, names }, { rejectWithValue }) => {
    try {
      const response = await OnboardAPI.submit_referrals(token, numbers, names);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const CreateRoutableCompanyAndSendInitialInvoice = createAsyncThunk(
  "routable/create_routable_company_and_send_initial_invoice",
  async ({
    token,
    contactFirstNameRoutable,
    contactLastNameRoutable,
    contactEmailRoutable,
    contactPhoneNbRoutable,
    is_sandbox,
  }) => {
    const e164number = "+1" + contactPhoneNbRoutable;
    try {
      const response = await RoutableAPI.routable_create_company_and_send_initial_invoice(
        {
          token: token,
          contact_first_name: contactFirstNameRoutable,
          contact_last_name: contactLastNameRoutable,
          contact_email: contactEmailRoutable,
          contact_phone_number: e164number,
          is_sandbox: is_sandbox,
        }
      );
      return {
        status: response.status,
        message: response.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: err.response.data.message,
      };
    }
  }
);

export const CheckRoutableCompanyExists = createAsyncThunk(
  "routable/site_has_routable_company",
  async ({ token }) => {
    try {
      const response = await RoutableAPI.site_has_routable_company({
        token: token,
      });
      return {
        status: response.status,
        message: response.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: err.response.data.message,
      };
    }
  }
);

export const SubmitPracticeInformation = createAsyncThunk(
  "onboard/submit_practice_information",
  async ({ token, ptan, taxID, npi }, { rejectWithValue }) => {
    try {
      const response = await OnboardAPI.submit_practice_information({
        token: token,
        ptan: ptan,
        tax_id: taxID,
        npi: npi,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// slice
const onboardSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.step = state.step + 1;
      return state;
    },
    previousStep: (state) => {
      state.step = state.step - 1;
      return state;
    },
    setLoadingState: (state, action) => {
      state.apiCallStatus = action.payload.status;
      return state;
    },
  },
  extraReducers: {
    [fetchProgress.pending]: (state, action) => {
      state.apiCallStatus = "loading";
    },
    [fetchProgress.fulfilled]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        const payload = action.payload;
        if (!payload.agree_on_tc) {
          state.step = 1;
        } else if (!payload.have_payment_info) {
          state.step = 2;
        } else if (!payload.reset_password) {
          state.step = 3;
        } else if (!payload.tax_id || !payload.npi) {
          state.step = 4;
        } else {
          state.step = 5;
        }
        state.email = payload.email;
        state.site_address = payload.site_address;
        state.site_name = payload.site_name;
        state.site_type = payload.site_type;
        state.deal_type = payload.deal_type;
        state.npi = payload.npi;
        state.taxID = payload.tax_id;
        state.ptan = payload.ptan;
        state.site_id = payload.site_id;

        // new contract flow
        state.contract = payload.contract;

        state.routable_company_exists = payload.routable_company_exists;

        state.apiCallStatus = "succeeded";
      }
    },
    [fetchProgress.rejected]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "failed";
      }
    },
    [CreateRoutableCompanyAndSendInitialInvoice.pending]: (state, action) => {
      state.apiCallStatus = "loading";
    },
    [CreateRoutableCompanyAndSendInitialInvoice.fulfilled]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
    },
    [CreateRoutableCompanyAndSendInitialInvoice.rejected]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "failed";
      }
    },
    [CheckRoutableCompanyExists.pending]: (state, action) => {
      state.apiCallStatus = "loading";
    },
    [CheckRoutableCompanyExists.fulfilled]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
      const payload = action.payload;
      if (payload.status === 200) {
        state.routable_company_exists = true;
      }
    },
    [CheckRoutableCompanyExists.rejected]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "failed";
      }
    },
    [AgreeOnTC.pending]: (state, action) => {
      if (state.apiCallStatus === "idle") {
        state.apiCallStatus = "loading";
      }
    },
    [AgreeOnTC.fulfilled]: (state, action) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
    },
    [SaveCreditCard.pending]: (state) => {
      state.apiCallStatus = "loading";
    },
    [SaveCreditCard.fulfilled]: (state) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
    },
    [SetNewPassword.pending]: (state) => {
      state.apiCallStatus = "loading";
    },
    [SetNewPassword.fulfilled]: (state) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
    },
    [SubmitReferrals.pending]: (state) => {
      state.apiCallStatus = "loading";
    },
    [SubmitReferrals.fulfilled]: (state) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
    },
    [SubmitPracticeInformation.pending]: (state) => {
      state.apiCallStatus = "loading";
    },
    [SubmitPracticeInformation.fulfilled]: (state) => {
      if (state.apiCallStatus === "loading") {
        state.apiCallStatus = "succeeded";
      }
    },
  },
});

export const { nextStep, previousStep, setLoadingState } = onboardSlice.actions;

export default onboardSlice.reducer;
