import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// react bootstrap
import Loader from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Header } from "../header/Header";
import styles from "../chronicCare/ChronicCareList.module.css";

import {
  fetchUnapprovedCarePlan,
  fetchCarePlanSignedUrl,
  approveCarePlan,
} from "./chronicCareSlice";
import { isInIframe } from "../../utils/utils";

export const SingleUnapprovedCarePlanPage = () => {
  const { id } = useParams();
  const userId = useSelector((state) => state.user.user_id);
  const token = useSelector((state) => state.user.token);
  const status = useSelector((state) => state.chronicCare.singleEntryStatus);
  const info = useSelector((state) => state.chronicCare.currRecordInfo);
  const signedUrl = useSelector((state) => state.chronicCare.signedUrl);
  const error = useSelector((state) => state.chronicCare.error);
  const approveCarePlanStatus = useSelector(
    (state) => state.chronicCare.approveCarePlanStatus
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchUnapprovedCarePlan({ id, token }));
  }, []);

  useEffect(() => {
    if (status === "succeeded" && info) {
      dispatch(
        fetchCarePlanSignedUrl({ bucketUrl: info["bucket_url"], token })
      );
    }
  }, [info]);

  useEffect(() => {
    if (approveCarePlanStatus === "succeeded") {
      alert("Care plan approved!");
      history.push("/chronic_care");
      window.location.reload();
    } else if (approveCarePlanStatus === "failed") {
      alert(error.message);
    }
  }, [approveCarePlanStatus]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userId) {
      dispatch(
        approveCarePlan({ carePlanId: id, approverPrescriberId: userId, token })
      );
    }
  };

  const CarePlanField = ({ fieldName, fieldValue, isUrl = false }) => {
    const normalValue = <div>{fieldValue}</div>;
    const urlValue = (
      <a href={fieldValue} target="_blank" rel="noreferrer">
        Care Plan PDF
      </a>
    );
    return (
      <div className={styles.FieldCardInfo}>
        <div className={styles.FieldCardInfoText}>{fieldName}</div>
        {isUrl ? urlValue : normalValue}
      </div>
    );
  };

  let content;
  if (status === "loading") {
    content = (
      <div className={styles.LoaderContainer}>
        <Loader type="TailSpin" color="#42A1F8" height={70} width={70} />
      </div>
    );
  } else if (status === "succeeded") {
    content = (
      <>
        <Header
          title={info["first_name"] + " " + info["last_name"]}
          showBackButton={true && !isInIframe()}
          iframeView={isInIframe()}
        />

        <div className={styles.FormContainer}>
          <div className={styles.FormCard}>
            <div className={styles.FormCardTitle}>
              <span className={styles.FormCardTitleText}>
                Care Plan Details (ID: {info["id"]})
              </span>
            </div>
            <CarePlanField
              fieldName={"Patient ID"}
              fieldValue={info["patient_id"]}
            />
            <CarePlanField
              fieldName={"First Name"}
              fieldValue={info["first_name"]}
            />
            <CarePlanField
              fieldName={"Last Name"}
              fieldValue={info["last_name"]}
            />
            <CarePlanField
              fieldName={"Care Plan URL"}
              fieldValue={signedUrl}
              isUrl={true}
            />
            <CarePlanField
              fieldName={"Email Plan Was Sent To"}
              fieldValue={info["email_sent_to"]}
            />
            <CarePlanField
              fieldName={"Care Plan Submission Date"}
              fieldValue={info["submission_date"]}
            />
          </div>

          <div className={styles.ApproveFormCard}>
            <div className={styles.FormCardTitle}>
              <span className={styles.FormCardTitleText}>
                Approve This Care Plan
              </span>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group>
                <Form.Label>Prescriber Full Name *</Form.Label>
                <Form.Control required type="text" placeholder="Full Name" />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  required
                  type="checkbox"
                  label="By checking this box, I have reviewed the care plan for this patient and approve it for this patient's enrollment into the Chronic Care program. *"
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </form>
          </div>
        </div>
      </>
    );
  }
  return content;
};
