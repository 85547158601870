import axios from "axios";

const patientEnrollmentAPI = {
  send_enrollment_message: (site_id, token, message, patients) => {
    const send_messages = false;
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/${site_id}/send_enrollment_message`,
      {
        message,
        patients,
        send_messages,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  send_test_enrollment_message: (message, phone) => {
    return axios.post(
      `${process.env.REACT_APP_HOST}/sites/send_sample_enrollment_message`,
      {
        message,
        phone,
      }
    );
  },
  getInitialPatientConnectMessage: (site_id, token) => {
    return axios.get(
      `${process.env.REACT_APP_HOST}/sites/get_patient_connect_template/${site_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

export default patientEnrollmentAPI;
