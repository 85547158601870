import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import jwtDecode from "jwt-decode";

import userAPI from "./userAPI";
import sitesAPI from "../home/sitesAPI";

// init state
const initialState = {
  token: "",
  errorMsg: "",
  email: "",
  sites: {},
  currSiteId: null,
  requires_mfa: false,
  isAdmin: false,
  impersonatorUserId: null,
  rpm_site_role: null,
  site_refresh_attempts: 0,
  user_id: null,
};

export const statusThunk = createAsyncThunk(
  "user/status",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await userAPI.status(token);
      const userStatus = response.data;

      const sitesInfo = {};
      try {
        const sitesInfoResponse = await sitesAPI.getAllSites(token);
        const sites = sitesInfoResponse.data.sites;
        sites.forEach((site) => (sitesInfo[site.id] = site.name));
        userStatus.sites = sitesInfo;
      } catch (err) {
        return rejectWithValue(err.response.data.message);
      }

      return userStatus;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const loginThunk = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await userAPI.login(email, password);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const verifyMFAThunk = createAsyncThunk(
  "user/mfa",
  async ({ email, OTP }, { rejectWithValue }) => {
    try {
      const response = await userAPI.verifyMFA(email, OTP);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const impersonateThunk = createAsyncThunk(
  "user/impersonate/start",
  async ({ impersonateTarget, token }, { rejectWithValue }) => {
    try {
      const response = await userAPI.impersonate(impersonateTarget, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const stopImpersonateThunk = createAsyncThunk(
  "user/impersonate/stop",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await userAPI.stopImpersonate(token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "user/resetpassword",
  async ({ token, email, old_password, new_password }) => {
    try {
      const response = await userAPI.resetPassword(
        token,
        email,
        old_password,
        new_password
      );
      return {
        status: response.status,
        message: response.data.message,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: err.response.data.message,
      };
    }
  }
);

// slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => initialState,
    setCurrSiteId: (state, action) => {
      state.currSiteId = action.payload.site_id;
    },
  },
  extraReducers: {
    [statusThunk.fulfilled]: (state, action) => {
      state.email = action.payload.email;
      state.sites = {};
      state.sites = action.payload.sites;
      if (state.currSiteId == null) {
        if (
          action.payload.sites &&
          Object.keys(action.payload.sites).length > 0
        ) {
          state.currSiteId = parseInt(Object.keys(action.payload.sites)[0]); // set default to first site
        }
      }
      state.rpm_site_role = action.payload.rpm_site_role;
      state.user_id = action.payload.user_id;
      state.site_refresh_attempts += 1;
    },
    [statusThunk.rejected]: (state, action) => {
      console.log(action);
    },
    [loginThunk.fulfilled]: (state, action) => {
      state.errorMsg = "";
      if ("auth_token" in action.payload) {
        state.token = action.payload.auth_token;
        const userData = jwtDecode(state.token);
        state.isAdmin = userData.is_admin;
        state.impersonatorUserId = userData.impersonator_user_id;
      } else {
        state.requires_mfa = true;
      }
    },
    [loginThunk.rejected]: (state, action) => {
      state.errorMsg = action.payload;
    },
    [verifyMFAThunk.fulfilled]: (state, action) => {
      state.errorMsg = "";
      state.token = action.payload.auth_token;
      const userData = jwtDecode(state.token);
      state.isAdmin = userData.is_admin;
      state.impersonatorUserId = userData.impersonator_user_id;
    },
    [verifyMFAThunk.rejected]: (state, action) => {
      state.errorMsg = action.payload;
    },
    [impersonateThunk.fulfilled]: (state, action) => {
      const authToken = action.payload.auth_token;
      state.token = authToken;
      const impersonateData = jwtDecode(authToken);
      state.impersonatorUserId = impersonateData.impersonator_user_id;
      state.isAdmin = impersonateData.is_admin;
      state.rpm_site_role = action.payload.rpm_site_role;

      localStorage.setItem("impersonate", true);
    },
    [stopImpersonateThunk.fulfilled]: (state, action) => {
      const authToken = action.payload.auth_token;
      state.token = authToken;
      const adminData = jwtDecode(authToken);
      state.impersonatorUserId = adminData.impersonator_user_id;
      state.isAdmin = adminData.is_admin;
      state.rpm_site_role = action.payload.rpm_site_role;

      localStorage.setItem("impersonate", false);
    },
  },
});

// export
export const { logout, setCurrSiteId } = userSlice.actions;

export default userSlice.reducer;
